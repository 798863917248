import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const CandidateFormWithStep = ({ section, activeFormPage, children }) => {
  const candidateSections = useSelector((state) => state.candidateSections);

  return (
    <>
      {candidateSections.includes(section) && (
        <div hidden={activeFormPage !== candidateSections.indexOf(section)}>{children}</div>
      )}
    </>
  );
};

export default CandidateFormWithStep;

CandidateFormWithStep.propTypes = {
  section: PropTypes.string.isRequired,
  activeFormPage: PropTypes.number.isRequired,
  children: PropTypes.element.isRequired,
};
