import styled from 'styled-components/macro';

export const DatePickerCustomYearDropdownContainer = styled.div`
  .react-datepicker__year-read-view {
    margin-bottom: 4px;
  }

  .react-datepicker__year-dropdown--scrollable {
    height: auto;
    max-height: 150px;
  }
`;
