import React from 'react';
// import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { LoaderContainer, LoaderDiv, LoaderSmallContainer, LoaderSmallDiv } from 'components/Shared/Loader/styleLoader';

export const Loader = ({ fullScreen = true, position, top, scale }) => (
  <LoaderContainer fullScreen={fullScreen} position={position} top={top} scale={scale}>
    <LoaderDiv>
      <div />
      <div />
      <div />
    </LoaderDiv>
  </LoaderContainer>
);

Loader.propTypes = {
  fullScreen: PropTypes.bool,
  position: PropTypes.oneOf(['relative', 'absolute']),
  scale: PropTypes.number,
  top: PropTypes.string,
};

export const LoaderInsideElements = () => <Loader fullScreen={false} position="absolute" />;

export const LoaderSmall = () => (
  <LoaderSmallContainer>
    <LoaderSmallDiv>
      <div />
      <div />
      <div />
    </LoaderSmallDiv>
  </LoaderSmallContainer>
);

// export const LoadingPortal = ({ elementRef }) => {
//   const loader = <Loader fullScreen={true} />;
//   return elementRef ? ReactDOM.createPortal(loader, elementRef) : loader;
// };

// LoadingPortal.propTypes = {
//   elementRef: PropTypes.object,
//   // customClass: PropTypes.string,
// };
