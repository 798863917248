import React from 'react';
import Link from 'components/Shared/Link';
import Tooltip from 'react-tooltip';
import store from 'store/store';

import { routesCreators } from 'constants/routes';
import { claimCandidate, sendEmailsToCandidates } from 'api/candidate';
import { dateToStringYearMonthDayHoursMin } from 'utils/dateUtils';
import icons from 'constants/icons';
import { downloadFile } from 'utils/downloadFile';
import { downloadReport } from 'api/customer';
import { evaluationColor } from 'constants/evaluation';
import { statusColor, status, statusNames } from 'constants/status';
import { IconButton, Icon, Flexbox, Div } from 'components/Shared/sharedStyle';
import { Circle, ActionsColumn } from 'components/CandidatesList/styleCandidatesList';
import EventsCell from 'components/CandidatesList/EventsCell/EventsCell';
import { setInvitationCandidatesIds } from 'store/actions';

const renderStatusCell = (evaluations, candidateStatus) => {
  const specificStatuses = [
    status.created,
    status.emailSent,
    status.pending,
    status.editing,
    status.rejected,
    status.refused,
  ];

  const requiredEvaluationArray = Object.values(evaluations).filter((evaluation) => evaluation !== null);

  return (
    <>
      {[status.accepted].includes(candidateStatus) ? (
        <Flexbox
          pointer
          justify="center"
          data-tip={`Status: ${statusNames[candidateStatus]}`}
          data-for="status"
          data-event="click"
        >
          <Icon icon={icons.checkbox} data-tip="Candidate hired" data-for="candidate-hired" />
        </Flexbox>
      ) : (
        <Div pointer data-tip={`Status: ${statusNames[candidateStatus]}`} data-for="status" data-event="click">
          {requiredEvaluationArray.map((value, i) =>
            specificStatuses.includes(candidateStatus) || !evaluations ? (
              <Circle key={i} color={statusColor[candidateStatus]} statusCode={candidateStatus} />
            ) : (
              <Circle key={i} color={evaluationColor[value]} />
            )
          )}
        </Div>
      )}
      <Tooltip
        id="status"
        effect="solid"
        place="left"
        className="custom-tooltip custom-tooltip-right"
        globalEventOff="click"
      />
    </>
  );
};

export const headlines = [
  { id: 'selected' },
  { id: 'shortId', sortable: true },
  { id: 'lastName', sortable: true }, // Grid is sending sorting parameters based on the headers Ids, that's why this field is named 'lastName' instead e.g. 'fullName'
  { id: 'countries' },
  { id: 'customer', sortable: true },
  { id: 'status' },
  { id: 'startDate', sortable: true },
  { id: 'daysInProcess', sortable: true },
  { id: 'level', sortable: true },
  { id: 'events' },
  { id: 'investigator', sortable: true },
  { id: 'actions' },
];

export const customerHeadlines = [
  { id: 'lastName', sortable: true }, // Grid is sending sorting parameters based on the headers Ids, that's why this field is named 'lastName' instead e.g. 'fullName'
  { id: 'email', sortable: true },
  { id: 'status' },
  { id: 'level', sortable: true },
  { id: 'startDate', sortable: true },
  { id: 'submittedDate', sortable: true },
  { id: 'daysInProcess', sortable: true },
  { id: 'report' },
  { id: 'countries' },
];

const handleSelect = (e, candidateId) => {
  const selected = e.target.checked;
  store.dispatch(setInvitationCandidatesIds({ candidateId, selected }));
};

export const candidatesMapper = ({ data: candidates, request }) =>
  candidates.map((candidate) => ({
    selected: <input type="checkbox" onChange={(e) => handleSelect(e, candidate.id)} />,
    shortId: candidate.shortId,
    lastName: (
      <Link to={routesCreators.candidate(candidate.id)}>
        <b>{`${candidate.firstName} ${candidate.lastName}`}</b>
      </Link>
    ),
    countries: candidate.countries,
    customer: candidate.customer,
    status: renderStatusCell(candidate.evaluation, candidate.status),
    startDate: dateToStringYearMonthDayHoursMin(candidate.startDate),
    daysInProcess: candidate.daysInProcess,
    level: candidate.level,
    events: (
      <EventsCell
        events={candidate.activities}
        newActivity={candidate.newActivity}
        newMessage={candidate.newMessage}
        candidateName={`${candidate.firstName} ${candidate.lastName}`}
        status={candidate.status}
        candidateId={candidate.id}
      />
    ),
    investigator: candidate.employee,
    id: candidate.id,
    actions: (
      <ActionsColumn>
        <IconButton
          icon={icons.send}
          onClick={() => request(() => sendEmailsToCandidates({ candidateIds: [candidate.id] }))}
          data-tip="Send invitation for Background Check"
          data-for="email"
        >
          <Tooltip effect="solid" place="left" className="custom-tooltip custom-tooltip-right" id="email" />
        </IconButton>
        <IconButton
          icon={icons.play}
          onClick={() => request(() => claimCandidate({ id: candidate.id }))}
          data-tip="Assign to me"
          data-for="assign"
        >
          <Tooltip effect="solid" place="left" className="custom-tooltip custom-tooltip-right" id="assign" />
        </IconButton>
      </ActionsColumn>
    ),
    disabled: candidate.status === 12 || candidate.status === 13,
  }));

export const customerCandidatesMapper = ({ data: candidates }) =>
  candidates.map((candidate) => ({
    lastName: <b>{`${candidate.firstName} ${candidate.lastName}`}</b>,
    email: candidate.email,
    status: renderStatusCell(candidate.evaluation, candidate.status),
    level: candidate.level,
    startDate: dateToStringYearMonthDayHoursMin(candidate.startDate),
    submittedDate: dateToStringYearMonthDayHoursMin(candidate.submittedDate),
    daysInProcess: candidate.daysInProcess,
    report: (
      <Flexbox justify="center">
        {[status.sentToCustomer, status.accepted, status.rejected].includes(candidate.status) && (
          <IconButton
            icon={icons.fileBlack}
            onClick={() =>
              downloadFile({
                customFunction: downloadReport,
                id: candidate.id,
                fileName: `${candidate.firstName}_${candidate.lastName}-Report.pdf`,
              })
            }
            data-tip="Download report"
            data-for="download-report"
          >
            <Tooltip effect="solid" place="left" className="custom-tooltip custom-tooltip-right" id="download-report" />
          </IconButton>
        )}
      </Flexbox>
    ),
    countries: candidate.countries,
    id: candidate.id,
    disabled: candidate.status === 12 || candidate.status === 13,
  }));
