import React from 'react';
import { useTranslation } from 'react-i18next';

import { getCustomersList } from 'api/customer';
import Grid from 'components/Shared/Grid/Grid';
import Link from 'components/Shared/Link';
import { Button, PageWithTableContainer } from 'components/Shared/sharedStyle';
import { customerHeadlines, customersMapper } from '../configCustomer';

const CustomersList = () => {
  const { t } = useTranslation();

  return (
    <PageWithTableContainer>
      <Link to="/add-customer">
        <Button>{t('customer.addCustomer')}</Button>
      </Link>
      <Grid
        headlines={customerHeadlines}
        fetchDataFunction={getCustomersList}
        mapperFunction={customersMapper}
        rowsPerPage={15}
        translationsKey="customer.customerGrid"
      />
    </PageWithTableContainer>
  );
};

export default CustomersList;
