import React, { useMemo, useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import countries from 'i18n-iso-countries';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { personalDetailsType, personalDetailsFilesTypes } from 'constants/types';
import { savePersonalInformation } from 'api/candidate';
import { uploadFiles } from 'utils/uploadMultipleFiles';
import { addCandidateFormErrors } from 'store/actions';
import { Button, Form, Divider } from 'components/Shared/sharedStyle';
import { getPersonalInfoSectionNum } from 'utils/candidateFormSections';
import { NextStepsButtons, ButtonsContainer } from 'components/CandidateForm/styleCandidateForm';
import useDispatchNotification from 'components/Shared/Notification/DispatchNotificationHook';
import {
  personalDetailsBodyMapper,
  personalDetailsFilesMapper,
  mapDefaultPersonalDetailsValues,
} from 'components/CandidateForm/Form/configCandidateForm';
import { personalDetailsSchema } from 'components/CandidateForm/Form/candidateFormSchema';
import GeneralInfoSection from './GeneralInfoSection';
import AdditionalFieldsSection from './AdditionalFieldsSection';
import CurrentAddressSection from './CurrentAddressSection';
import PreviousAddressesSection from './PreviousAddressesSection';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/sv.json'));

const PersonalDetailsStep = ({
  nextStep,
  previousStep,
  defaultFormState,
  defaultFiles,
  requiredYears,
  editingLocked,
  fetchFiles,
  validationFunctionsArray,
  dirtySectionsChecker,
  saveFormFunctions,
  findChangesAndSaveCandidateForm,
}) => {
  const { dispatchSuccessNotification, dispatchErrorNotification } = useDispatchNotification();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const sectionNumber = getPersonalInfoSectionNum();
  const isCandidateFormSubmitted = useSelector((state) => state.isCandidateFormSubmitted);

  const [isLoading, setLoadingStatus] = useState(false);

  const formFunctions = useForm({
    validationSchema: personalDetailsSchema,
    validationContext: { requiredYears },
  });

  const { handleSubmit, reset, getValues, errors } = formFunctions;

  const lastSavedFormState = useRef('');
  // setTimeout waits till files are registerd because it happends in useEffect in MultipleFilesUploader
  const setLastSavedFormState = useCallback(
    () =>
      setTimeout(() => {
        lastSavedFormState.current = JSON.stringify(getValues());
      }, 0),
    [getValues]
  );

  const submitForm = useCallback(async () => {
    const form = getValues({ nest: true });

    setLoadingStatus(true);
    const mappedForm = personalDetailsBodyMapper(form);
    const mappedFiles = personalDetailsFilesMapper(form);

    try {
      await Promise.all([savePersonalInformation(mappedForm), uploadFiles(mappedFiles)]);
      await fetchFiles();

      setLastSavedFormState();
      dispatchSuccessNotification(t('notifications.saved'));
    } catch (catchedError) {
      dispatchErrorNotification({ catchedError });
    }

    setLoadingStatus(false);
  }, [dispatchErrorNotification, dispatchSuccessNotification, fetchFiles, getValues, setLastSavedFormState, t]);

  // should run only once
  useEffect(() => {
    // "handleSubmit" will be run in candidateForm and SubmittingDataStep,
    // it changes "hook-form" state to submitted which runs validation on every keystroke
    validationFunctionsArray.push(handleSubmit(() => {}));

    // used to determine whether to show "Leave Guard" and then save form
    dirtySectionsChecker.personalInformation = () => lastSavedFormState.current !== JSON.stringify(getValues());
    saveFormFunctions.personalInformation = submitForm;
  }, [dirtySectionsChecker, getValues, handleSubmit, saveFormFunctions, validationFunctionsArray, submitForm]);

  const defaultPassportValue = useMemo(() => {
    if (defaultFiles && defaultFiles.Passport) {
      return defaultFiles.Passport;
    }

    return null;
  }, [defaultFiles]);

  useEffect(() => {
    if (!defaultFormState) return;
    reset(mapDefaultPersonalDetailsValues(defaultFormState));

    setLastSavedFormState();
  }, [defaultFormState, getValues, reset, setLastSavedFormState]);

  // adds errors to redux state only after form was submitted
  // no dependecy array, so it adds errors on every keystroke
  useEffect(() => {
    if (isCandidateFormSubmitted) {
      // makes deep copy of error object - fixes "Invariant failed" redux error
      const newErrors = JSON.parse(JSON.stringify(errors));

      dispatch(addCandidateFormErrors({ personalInformation: newErrors }));
    }
  });

  const proceedToNextStep = async () => {
    if (!editingLocked) await submitForm();
    nextStep();
  };

  return (
    <Form>
      <fieldset disabled={editingLocked}>
        <h2>{`${sectionNumber}. ${t('candidateForm.personalDetails.title')}`}</h2>
        <p>{t('candidateForm.personalDetails.description')}</p>
        <Divider small />
        <GeneralInfoSection
          formFunctions={formFunctions}
          defaultPassportValue={defaultPassportValue}
          editingLocked={editingLocked}
          defaultFormState={defaultFormState}
        />
        <CurrentAddressSection
          formFunctions={formFunctions}
          editingLocked={editingLocked}
          defaultFormState={defaultFormState}
        />
        <PreviousAddressesSection
          formFunctions={formFunctions}
          editingLocked={editingLocked}
          defaultState={defaultFormState ? defaultFormState.previousAddresses : []}
          requiredYears={requiredYears}
        />
        <AdditionalFieldsSection
          formFunctions={formFunctions}
          editingLocked={editingLocked}
          defaultFiles={defaultFiles}
          setLastSavedFormState={setLastSavedFormState}
          findChangesAndSaveCandidateForm={findChangesAndSaveCandidateForm}
          requiredYears={requiredYears}
        />
      </fieldset>
      <Divider />
      <ButtonsContainer>
        <Button onClick={previousStep} outlined type="button">
          {t('common.previous')}
        </Button>
        <NextStepsButtons>
          <Button onClick={submitForm} disabled={editingLocked || isLoading} type="button">
            {t('common.save')}
          </Button>
          <Button disabled={isLoading} onClick={proceedToNextStep} type="button">
            {t('common.next')}
          </Button>
        </NextStepsButtons>
      </ButtonsContainer>
    </Form>
  );
};

PersonalDetailsStep.propTypes = {
  previousStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  editingLocked: PropTypes.bool.isRequired,
  defaultFormState: personalDetailsType,
  defaultFiles: personalDetailsFilesTypes,
  requiredYears: PropTypes.number,
  fetchFiles: PropTypes.func.isRequired,
  validationFunctionsArray: PropTypes.array.isRequired,
  dirtySectionsChecker: PropTypes.object.isRequired,
  saveFormFunctions: PropTypes.object.isRequired,
  findChangesAndSaveCandidateForm: PropTypes.func.isRequired,
};

PersonalDetailsStep.defaultProps = {
  defaultFormState: null,
  defaultFiles: {},
};

export default PersonalDetailsStep;
