import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import hasPermission from 'utils/hasPermission';
import { getSignedIn } from 'utils/localStorage';

const ProtectedRoute = ({ roles, ...routeParams }) => {
  if (!getSignedIn()) return <Redirect to="/login" />;

  return !roles.length || hasPermission(roles) ? <Route {...routeParams} /> : <Redirect to="/" />;
};

ProtectedRoute.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string),
};

ProtectedRoute.defaultProps = {
  roles: [],
};

export default ProtectedRoute;
