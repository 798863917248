import { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useGotoLogoutPage } from 'hooks/useLogout';
import useOnClickOutside from 'hooks/useOnClickOutsideHook';
import { getUserName, getCustomerName, getUserEmail } from 'utils/localStorage';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow_right.svg';
import { changeLanguageAction } from 'store/actions';
import {
  UserNavigationContainer,
  UserNavigationToggler,
  UserNavigationList,
  UserNavigationItem,
  UserNavigationText,
  LanguageItem,
} from './styles';

const UserNavigation = memo(() => {
  const { t, i18n } = useTranslation();
  const { ref, isVisible, toggleVisibility } = useOnClickOutside();
  const dispatch = useDispatch();
  const gotoLogoutPage = useGotoLogoutPage();

  const isMobile = useSelector((state) => state.isMobile);
  const userName = getUserName();
  const customerName = getCustomerName();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    dispatch(changeLanguageAction(lang));
  };

  return (
    <UserNavigationContainer>
      <UserNavigationToggler onClick={() => toggleVisibility(!isVisible)} isVisible={isVisible}>
        {userName}
        <ArrowRight />
      </UserNavigationToggler>
      {!isMobile && (
        <div hidden={!isVisible} ref={ref}>
          <UserNavigationList>
            {false && ( // TODO: Remove false condition to enable multilang functionality
              <UserNavigationItem>
                <LanguageItem onClick={() => changeLanguage('en')} isActive={i18n.language === 'en'}>
                  ENG
                </LanguageItem>
                <LanguageItem onClick={() => changeLanguage('sv')} isActive={i18n.language === 'sv'}>
                  SWE
                </LanguageItem>
              </UserNavigationItem>
            )}
            <UserNavigationText>
              User email: <br /> {getUserEmail()}
            </UserNavigationText>
            {customerName && (
              <UserNavigationText>
                Customer name: <br /> {customerName}
              </UserNavigationText>
            )}
            <UserNavigationItem onClick={gotoLogoutPage} type="button">
              {t('logout')}
            </UserNavigationItem>
          </UserNavigationList>
        </div>
      )}
    </UserNavigationContainer>
  );
});

export default UserNavigation;
