import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Select as SelectStyled, ErrorMessageFe } from './sharedStyle';

const Select = ({
  label,
  name,
  selectFunctions,
  options,
  defaultValue,
  error,
  resetAfterSubmit,
  disabled,
  isClearable,
  isSearchable,
  isMulti,
  minWidth,
}) => {
  const [value, setSelectValue] = useState(null);
  const isFirstRender = useRef(true);

  const { register, unregister, setValue, triggerValidation } = selectFunctions;

  useEffect(() => {
    register(name);
    return () => unregister(name);
  }, [register, unregister, name]);

  useEffect(() => {
    if (isFirstRender.current) {
      setSelectValue(defaultValue);
      isFirstRender.current = false;
    }
    // using setValue here causes infinite rendering when adding previousAddresses in Candidate Form
  }, [defaultValue]);

  useEffect(() => {
    if (resetAfterSubmit) {
      setValue(name, null);
      setSelectValue(null);
    }
  }, [name, resetAfterSubmit, setValue]);

  const onChange = (event) => {
    if (isMulti) {
      setValue(name, event);
      setSelectValue(event);
    } else {
      setValue(name, event ? event.value : '');
      setSelectValue({
        value: event ? event.value : '',
        label: event ? event.label : '',
      });
    }

    triggerValidation(name);
  };

  return (
    <>
      <label>
        {label}
        <SelectStyled
          options={options}
          classNamePrefix="react-select"
          placeholder=""
          hasError={!!error}
          value={value}
          onChange={onChange}
          name={name}
          isDisabled={disabled}
          isClearable={isClearable}
          isSearchable={isSearchable}
          isMulti={isMulti}
          minWidth={minWidth}
        />
      </label>
      {error && <ErrorMessageFe>{error.message}</ErrorMessageFe>}
    </>
  );
};

Select.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]).isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ),
  selectFunctions: PropTypes.object.isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]).isRequired,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      })
    ),
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]).isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ]),
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  resetAfterSubmit: PropTypes.bool,
  disabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isMulti: PropTypes.bool,
  minWidth: PropTypes.string,
};

Select.defaultProps = {
  error: null,
  resetAfterSubmit: false,
  disabled: false,
};

export default Select;
