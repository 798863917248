const ACCESS_TOKEN = 'accessToken';
const REFRESH_TOKEN = 'refreshToken';
const AUTH_LIFE_TIME_IN_MINUTES = 'authLifeTimeInMinutes';
const REFRESH_TIMEOUT_ID = 'refreshTimeoutId';

const USER_NAME = 'userDisplayName';
const USER_EMAIL = 'userEmail';
const CUSTOMER_NAME = 'customerName';
const SIGNED_IN = 'signedIn';
const USER_ROLES = 'userRoles';
const ACTIVE_CANDIDATE_FORM_PAGE = 'activeCandidateFormPage';

const PREVIOUSLY_UPLOADED_FILE = 'previouslyUploadedFile';
const PREVIOUSLY_UPLOADED_FILE_ID = 'previouslyUploadedFileId';

const ls = window.localStorage;
const getItem = (itemName) => ls.getItem(itemName);
const setItem = (itemName, value) => ls.setItem(itemName, value);
const removeItem = (itemName) => ls.removeItem(itemName);

export const getAccessToken = () => getItem(ACCESS_TOKEN);
export const setAccessToken = (accessToken) => setItem(ACCESS_TOKEN, accessToken);
export const removeAccessToken = () => removeItem(ACCESS_TOKEN);

export const getAuthLifeTime = () => getItem(AUTH_LIFE_TIME_IN_MINUTES);
export const setAuthLifeTime = (time) => setItem(AUTH_LIFE_TIME_IN_MINUTES, time);
export const removeAuthLifeTime = () => removeItem(AUTH_LIFE_TIME_IN_MINUTES);

export const getRefreshToken = () => getItem(REFRESH_TOKEN);
export const setRefreshToken = (refreshToken) => setItem(REFRESH_TOKEN, refreshToken);
export const removeRefreshToken = () => removeItem(REFRESH_TOKEN);

export const getRefreshTimeoutId = () => getItem(REFRESH_TIMEOUT_ID);
export const setRefreshTimeoutId = (refreshTimeoutId) => setItem(REFRESH_TIMEOUT_ID, refreshTimeoutId);
export const removeRefreshTimeoutId = () => removeItem(REFRESH_TIMEOUT_ID);

export const getUserName = () => getItem(USER_NAME);
export const setUserName = (userName) => setItem(USER_NAME, userName);
export const removeUserName = () => removeItem(USER_NAME);

export const getUserEmail = () => getItem(USER_EMAIL);
export const setUserEmail = (userEmail) => setItem(USER_EMAIL, userEmail);
export const removeUserEmail = () => removeItem(USER_EMAIL);

export const getCustomerName = () => getItem(CUSTOMER_NAME);
export const setCustomerName = (customerName) => setItem(CUSTOMER_NAME, customerName);
export const removeCustomerName = () => removeItem(CUSTOMER_NAME);

export const getSignedIn = () => Boolean(getItem(SIGNED_IN));
export const setSignedIn = (value) => setItem(SIGNED_IN, value);
export const removeSignedIn = () => removeItem(SIGNED_IN);

export const getUserRoles = () => getItem(USER_ROLES);
export const setUserRoles = (value) => setItem(USER_ROLES, value);
export const removeUserRoles = () => removeItem(USER_ROLES);

export const getActiveCandidateFormPage = () => parseInt(getItem(ACTIVE_CANDIDATE_FORM_PAGE));
export const setActiveCandidateFormPage = (value) => setItem(ACTIVE_CANDIDATE_FORM_PAGE, value);
export const removeActiveCandidateFormPage = () => removeItem(ACTIVE_CANDIDATE_FORM_PAGE);

export const getPreviouslyUploadedFile = () => getItem(PREVIOUSLY_UPLOADED_FILE);
export const setPreviouslyUploadedFile = (value) => setItem(PREVIOUSLY_UPLOADED_FILE, value);
export const removePreviouslyUploadedFile = () => removeItem(PREVIOUSLY_UPLOADED_FILE);

export const getUploadedAdditionalFileId = () => getItem(PREVIOUSLY_UPLOADED_FILE_ID);
export const setUploadedAdditionalFileId = (value) => setItem(PREVIOUSLY_UPLOADED_FILE_ID, value);
export const removeUploadedAdditionalFileId = () => removeItem(PREVIOUSLY_UPLOADED_FILE_ID);
