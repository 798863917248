import styled from 'styled-components/macro';

import icons from '../../../constants/icons';

export const RadioOption = styled.span`
  position: relative;
  min-width: 150px;
  margin: 10px 0;

  @media (max-width: 760px) {
    min-width: 100px;
  }

  input {
    position: absolute;
    opacity: 0;
  }

  label {
    position: relative;
    cursor: pointer;
    margin: 10px 0;
    padding-left: 25px;
    display: inline-block;
  }

  label:before {
    content: '';
    left: 0;
    position: absolute;
    width: 20px;
    height: 20px;
    background-image: url(${icons.radio});
    background-size: cover;
    display: inline-block;
    top: -1px;
  }

  input:checked + label:before {
    background-image: url(${icons.radioChecked});
  }
`;
