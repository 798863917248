import styled from 'styled-components/macro';
import colors from 'constants/colors';
import icons from 'constants/icons';

export const Table = styled.table`
  width: 100%;
  font-size: 0.9rem;
`;

export const TableHead = styled.thead`
  background-color: ${colors.darkGrey};
  color: ${colors.white};
  text-transform: uppercase;
`;

export const TableHeadCell = styled.th`
  font-weight: ${(props) => (props.isActive ? 'bold' : 'normal')};
  padding: 12px 10px;
  text-align: left;
  position: relative;

  ${({ sortable, direction, isActive }) =>
    sortable &&
    `
    cursor: pointer;

    &::after {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid ${colors.textColor};
      top: 48%;
      transform: translateY(-50%) rotate(${direction === 'asc' && isActive ? 180 : 0}deg);
      margin-left: 5px;
    }
  `}

  @media (max-width: 760px) {
    font-size: 0.8rem;
    padding: 9px 20px 9px 5px;
  }
`;

export const TableBody = styled.tbody`
  td {
    padding: 12px 10px;
  }

  tr:nth-child(even) {
    background-color: ${colors.white};
  }
`;

export const Pagination = styled.div`
  padding: 0 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Arrow = styled.span`
  display: inline-block;
  width: 23px;
  height: 23px;
  background-image: url(${icons.arrow_right});
  background-size: cover;
  transform: rotate(${(props) => (props.direction === 'left' ? 180 : 0)}deg);
  padding: 6px;
  position: relative;
  top: -0.5px;
  cursor: pointer;
`;

export const ArrowLastElement = styled(Arrow)`
  background-image: url(${icons.arrow_right_last_element});
  margin: auto 5px;
`;

export const FiltersContainer = styled.div`
  position: relative;
`;

export const FiltersToggler = styled.div`
  width: 300px;
  border-radius: ${({ isVisible }) => (isVisible ? '15px 15px 0 0' : '15px')};
  border: 1px solid ${colors.primaryGray};
  ${({ isVisible }) => isVisible && `border-bottom-color: ${colors.white}`};
  padding: 5px 15px;
  cursor: pointer;
  background-color: ${colors.white};

  &::after {
    content: '';
    position: absolute;
    background-image: url(${icons.arrow_right});
    width: 24px;
    height: 24px;
    transform: rotate(${({ isVisible }) => (isVisible ? 270 : 90)}deg);
    right: 15px;
    top: 4px;
  }
`;

export const FiltersList = styled.div`
  position: absolute;
  background-color: ${colors.white};
  width: 100%;
  padding: 15px;
  z-index: 1;
  border: 1px solid ${colors.primaryGray};
  border-top-color: ${colors.white};
  border-radius: 0 0 15px 15px;
`;

export const NoDataMessage = styled.h4`
  padding: 10px;
`;

export const TableRow = styled.tr`
  ${({ hasAction }) =>
    hasAction &&
    `
    cursor: pointer;
  `}

  ${({ disabled }) =>
    disabled &&
    `
    color: ${colors.secondaryGray};

    button {
      opacity: .4;
      pointer-events: none;
    }
  `}

  &:hover {
    background-color: ${colors.whiteBlue} !important;
  }

  @media (max-width: 760px) {
    td {
      font-size: 0.8rem;
    }
  }
`;
