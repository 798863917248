import * as yup from 'yup';

export const globalSelectorOption = { value: 'global', label: 'Global' };

export const frequentTextsFormNames = {
  Fcountry: 'country',
};

const { Fcountry } = frequentTextsFormNames;

export const frequentTextsSchema = yup.object().shape({
  [Fcountry]: yup.string().required(),
});

const frontendSectionsOrder = [
  'PersonalInformation',
  'Education',
  'Workplaces',
  'ConflictOfInterest',
  'Legal',
  'SanctionsList',
  'Economy',
  'Companies',
  'DrivingLicense',
  'Media',
  'Household',
];

export const defaultSections = frontendSectionsOrder.map((section) => ({ section, text: '' }));

export const mapDefaultSections = (sections) => {
  const mappedSections = [...defaultSections];

  frontendSectionsOrder.forEach((sectionFromOrder, index) => {
    Object.values(sections).forEach(
      (section) => section.section === frontendSectionsOrder[index] && section.text && (mappedSections[index] = section)
    );
  });

  return mappedSections;
};

const backendSectionsOrder = [
  'Companies',
  'ConflictOfInterest',
  'Legal',
  'DrivingLicense',
  'Education',
  'Economy',
  'Media',
  'Household',
  'PersonalInformation',
  'Workplaces',
  'SanctionsList',
];

export const sectionsMapper = (stateSections) => {
  const mappedSections = [];

  backendSectionsOrder.map((sectionFromOrder, index) =>
    Object.values(stateSections).forEach((stateSection) => {
      Object.keys(stateSection).forEach((stateSectionName) => {
        if (stateSectionName === sectionFromOrder)
          mappedSections[index] = {
            section: stateSectionName,
            text: stateSection[stateSectionName] ? stateSection[stateSectionName] : '',
          };
      });
    })
  );

  return mappedSections;
};
