import styled from 'styled-components/macro';
import { Logo } from '../Shared/sharedStyle';
import colors from '../../constants/colors';

export const Nav = styled.nav`
  display: flex;
  background-color: ${colors.darkGrey};
  flex-direction: column;
  width: 220px;
  height: 100vh;
  position: sticky;
  top: 0;

  ${Logo} {
    margin-bottom: 40px;
  }

  img {
    align-self: center;
  }

  @media (max-width: 760px) {
    width: 100%;
    position: fixed;
    z-index: 3;
    left: -100%;
    transition: left 0.3s ease-in;

    ${({ isOpen }) =>
      isOpen &&
      `
      left: 0;
    `}
  }
`;

export const AnchorWrapper = styled.div`
  a {
    text-decoration: unset;
    margin-left: 30px;
    padding-bottom: 15px;
    display: flex;
    align-items: center;

    ${({ underscored }) =>
      underscored &&
      `
      border-bottom: 1px solid;
      margin-bottom: 15px;
    `}

    ${({ isActive }) =>
      isActive &&
      `
      font-weight: bold;
    `}
  }
`;

export const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

export const Hamburger = styled.span`
  position: fixed;
  right: 15px;
  top: 17px;
  cursor: pointer;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 1.5rem;
  width: 1.5rem;

  span {
    width: 1.5rem;
    height: 0.1rem;
    background-color: ${({ isOpen }) => (isOpen ? colors.white : colors.primary)};
    transition: all 0.3s linear;
    transform-origin: 1px;

    ${({ isOpen }) =>
      isOpen
        ? `
      :first-child {
        transform: rotate(45deg);
      }
      :nth-child(2) {
        opacity: 0;
        transform: translateX(20px);
      }
      :nth-child(3) {
        transform: rotate(-45deg);
      }
    `
        : `
      :first-child {
        transform: rotate(0);
      }
      :nth-child(2) {
        opacity: 1;
        transform: translateX(0);
      }
      :nth-child(3) {
        transform: rotate(0);
      }
    `}
  }
`;
