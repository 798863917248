import store from 'store/store';
import { uploadFilesRequest, removeFiles } from 'api/import';
import { attachTemplates, deleteTemplates } from 'api/country';
import { clearFilesToRemoveList } from 'store/actions';

export const removeSelectedFiles = async (listOfIds) => {
  const filesToRemove = listOfIds || store.getState().filesToRemove;
  const alreadyUploadedFiles = filesToRemove.filter(Boolean);

  if (alreadyUploadedFiles.length) {
    await removeFiles({ ids: alreadyUploadedFiles });
    store.dispatch(clearFilesToRemoveList());
  }
};

export const uploadFiles = async (fields) => {
  await removeSelectedFiles();

  const uploadForm = new FormData();
  const fieldsWithoutNulls = fields.filter(([files]) => files);
  let index = 0;

  fieldsWithoutNulls.forEach(([files, type, additionalFieldId]) => {
    files.forEach((file) => {
      if (file.id) return; // already uploaded files have id

      uploadForm.append(`fileUploadRequest[${index}].FileType`, type);
      uploadForm.append(`fileUploadRequest[${index}].File`, file);
      uploadForm.append(`fileUploadRequest[${index}].Number`, file.number);
      additionalFieldId && uploadForm.append(`fileUploadRequest[${index}].AdditionalFieldId`, additionalFieldId);

      if (file.group) uploadForm.append(`fileUploadRequest[${index}].Group`, file.group);

      index += 1;
    });
  });

  if (index) await uploadFilesRequest(uploadForm);
};

export const uploadTemplate = async (templateArr) => {
  if (!templateArr.length) return;

  const uploadForm = new FormData();
  let index = 0;

  templateArr.forEach(({ files, id }) => {
    files.forEach((file) => {
      if (file.id) return; // already uploaded files have id

      uploadForm.append(`request[${index}].FieldDefinitionId`, id);
      uploadForm.append(`request[${index}].File`, file);

      index += 1;
    });
  });

  if (index) await attachTemplates(uploadForm);
};

export const removeTemplate = async (templatesIdsToRemove) => {
  const filesToRemove = [...templatesIdsToRemove, ...store.getState().filesToRemove];
  const filesToRemoveNoNulls = filesToRemove.filter(Boolean);

  if (!filesToRemoveNoNulls.length) return;

  await deleteTemplates({
    templateIds: filesToRemoveNoNulls,
  });
  store.dispatch(clearFilesToRemoveList());
};
