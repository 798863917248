import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { ProgressBarContainer, Circle } from 'components/CandidateForm/styleCandidateForm';

const ProgressBar = ({ activeFormPage }) => {
  const formSections = useSelector((state) => state.candidateSections);

  return (
    <ProgressBarContainer>
      {formSections?.map((page, index) => (
        <Circle key={index} isFilled={activeFormPage > index} isActive={activeFormPage === index}>
          {index}
        </Circle>
      ))}
    </ProgressBarContainer>
  );
};

ProgressBar.propTypes = {
  activeFormPage: PropTypes.number.isRequired,
};

export default ProgressBar;
