import colors from 'constants/colors';

export const status = {
  created: 0,
  emailSent: 1,
  pending: 2,
  editing: 3,
  submitted: 4,
  processing: 5,
  draftComplete: 6,
  awaitingApproval: 7,
  draftApproved: 8,
  reportCompleted: 9,
  sentToCustomer: 10,
  accepted: 11,
  rejected: 12,
  refused: 13,
};

export const statusNames = {
  [status.created]: 'created',
  [status.emailSent]: 'email sent',
  [status.pending]: 'pending',
  [status.editing]: 'editing',
  [status.submitted]: 'submitted',
  [status.processing]: 'processing',
  [status.draftComplete]: 'draft completed',
  [status.awaitingApproval]: 'awaiting approval',
  [status.draftApproved]: 'draft approved',
  [status.reportCompleted]: 'report completed',
  [status.sentToCustomer]: 'sent to customer',
  [status.accepted]: 'accepted',
  [status.rejected]: 'rejected',
  [status.refused]: 'refused',
};

export const statusColor = {
  [status.created]: 'inherit',
  [status.emailSent]: 'inherit',
  [status.pending]: colors.secondaryBlue,
  [status.editing]: colors.secondaryBlue,
  [status.rejected]: colors.secondaryGray,
  [status.refused]: colors.primaryGray,
};
