const randomNumber = () => 1 + Math.floor(Math.random() * 10);

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const closedCandidatesTimeline = (name) => {
  const data = [];

  for (let i = 0; i < 6; i++) {
    data.push({
      [`${name} 1`]: randomNumber(),
      [`${name} 2`]: randomNumber(),
      [`${name} 3`]: randomNumber(),
      [`${name} 4`]: randomNumber(),
      month: months[i],
    });
  }

  return data;
};

export const activeCandidatesData = (name) => {
  const data = [];

  for (let i = 1; i <= 5; i++) {
    data.push({
      name: `${name} ${i}`,
      value: randomNumber(),
    });
  }

  return data;
};

// export const allInvestigatorStats = () => {
//   const data = [];

//   for (let i = 0; i <= 6; i++) {
//     const created = randomNumber();
//     const ongoing = randomNumber();
//     const closed = randomNumber();

//     data.push({
//       name: `Investigator ${i}`,
//       created,
//       ongoing,
//       closed,
//       total: created + ongoing + closed,
//     });
//   }

//   return data;
// };
