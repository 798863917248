import styled from 'styled-components/macro';

import colors from 'constants/colors';
import { IconButton } from 'components/Shared/sharedStyle';

export const Portal = styled.div`
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 99;
`;

export const ModalBody = styled.div`
  background-color: ${colors.white};
  border-radius: 15px;
  padding: 50px 30px 40px 30px;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 760px) {
    padding: 10px;
    width: 90%;
    max-height: 80vh;
  }

  ${IconButton} {
    position: absolute;
    right: 15px;
    top: 15px;
  }
`;
