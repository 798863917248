import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, ResponsiveContainer, CartesianGrid, YAxis, XAxis, BarChart, Bar, Legend } from 'recharts';
import colors from 'constants/colors';
import { closedCandidatesTimeline } from './chartData';
import { Flexbox } from 'components/Shared/sharedStyle';
import { Rectangle } from './style';

const BarChartComponent = ({ title, name }) => {
  const data = closedCandidatesTimeline(name);

  const renderCustomBarLabel = ({ x, y, width, value }) => {
    return (
      <text x={x + width / 2} y={y} fill="#666" textAnchor="middle" dy={-6}>
        {value}
      </text>
    );
  };

  const barSize = 20;

  const renderLegend = ({ payload }) => {
    return (
      <Flexbox justify="center" align="center">
        {payload.map((entry, index) => (
          <Flexbox
            justify={'center'}
            align="center"
            key={`item-${index}`}
            style={{ width: 'auto', marginRight: '30px' }}
          >
            <Rectangle color={payload[index].color} />
            <div>{entry.value}</div>
          </Flexbox>
        ))}
      </Flexbox>
    );
  };

  return (
    <>
      <h2 style={{ marginLeft: '25px' }}>{title}</h2>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          margin={{
            top: 10,
            left: -35,
            bottom: 10,
          }}
        >
          <Tooltip />
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <XAxis dataKey="month" />
          <YAxis />
          <Legend content={renderLegend} />
          <Bar dataKey={`${name} 1`} barSize={barSize} fill={colors.darkGrey} label={renderCustomBarLabel} />
          <Bar dataKey={`${name} 2`} barSize={barSize} fill={colors.primaryOrangeBright} label={renderCustomBarLabel} />
          <Bar dataKey={`${name} 3`} barSize={barSize} fill={`#a76425`} label={renderCustomBarLabel} />
          <Bar dataKey={`${name} 4`} barSize={barSize} fill={`#c59438`} label={renderCustomBarLabel} />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

BarChartComponent.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default BarChartComponent;
