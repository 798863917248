import Link from 'components/Shared/Link';
import roles from 'constants/roles';

export const customerHeadlines = [
  { id: 'name' },
  { id: 'candidateCount' },
  { id: 'documentPin' },
  { id: 'printingPin' },
];

export const customersMapper = ({ data: customers }) =>
  customers.map((customer) => ({
    name: (
      <Link to={`/edit-customer/${customer.id}`}>
        <b>{customer.name}</b>
      </Link>
    ),
    candidateCount: customer.candidateCount,
    documentPin: customer.documentPin,
    printingPin: customer.printingPin,
    id: customer.name,
  }));

export const mapCustomersOptions = (customers) =>
  customers.map((customer) => ({
    value: customer.id,
    label: customer.name,
  }));

export const customerUsersHeadlines = [{ id: 'name' }, { id: 'email' }, { id: 'customer' }, { id: 'role' }];

export const customerUsersMapper = ({ data: users }) =>
  users.map((user) => ({
    name: user.displayName,
    email: user.email,
    customer: user.customerName,
    role: user.roleName,
    id: user.id,
  }));

export const myOrganizationHeadlines = [{ id: 'name' }, { id: 'email' }, { id: 'role' }];

export const myOrganizationMapper = ({ data: users }) =>
  users.map((user) => ({
    name: user.displayName,
    email: user.email,
    role: user.roleName === roles.customerSupervisor ? 'Supervisor' : 'Regular',
    id: user.id,
  }));
