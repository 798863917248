import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import { saveAgreement } from 'api/candidate';
import { getCustomerName } from 'utils/localStorage';
import useDispatchNotification from 'components/Shared/Notification/DispatchNotificationHook';
import { Button, Form, Divider, Flexbox, FormGroup } from 'components/Shared/sharedStyle';
import { FormCheckbox } from 'components/Shared/sharedComponents';
import { NextStepsButtons, AgreementsContainer } from 'components/CandidateForm/styleCandidateForm';
import { agreementsStepSchema } from 'components/CandidateForm/Form/candidateFormSchema';

const AgreementsStep = ({
  nextStep,
  agreed,
  setEditingLockedStatus,
  validationFunctionsArray,
  dirtySectionsChecker,
  saveFormFunctions,
}) => {
  const { dispatchSuccessNotification, dispatchErrorNotification } = useDispatchNotification();
  const { t } = useTranslation();

  const customerName = getCustomerName();
  const [isLoading, setLoadingStatus] = useState(false);

  const { register, handleSubmit, errors, getValues, reset } = useForm({
    validationSchema: agreementsStepSchema,
  });

  const lastSavedFormState = useRef('');
  // setTimeout waits till files are registerd because it happends in useEffect in MultipleFilesUploader
  const setLastSavedFormState = useCallback(
    () =>
      setTimeout(() => {
        lastSavedFormState.current = JSON.stringify(getValues());
      }, 0),
    [getValues]
  );

  const submitForm = useCallback(async () => {
    setLoadingStatus(true);

    try {
      await saveAgreement(getValues());

      setEditingLockedStatus(false);
      setLastSavedFormState();
      dispatchSuccessNotification(t('notifications.saved'));
    } catch (catchedError) {
      dispatchErrorNotification({ catchedError });
    }

    setLoadingStatus(false);
  }, [
    dispatchErrorNotification,
    dispatchSuccessNotification,
    getValues,
    setEditingLockedStatus,
    setLastSavedFormState,
    t,
  ]);

  useEffect(() => {
    // "handleSubmit" will be run in candidateForm and SubmittingDataStep,
    // it changes "hook-form" state to submitted which runs validation on every keystroke
    validationFunctionsArray.push(handleSubmit(() => {}));

    // used to determine whether to show "Leave Guard" and then save form
    dirtySectionsChecker.agreements = () => lastSavedFormState.current !== JSON.stringify(getValues());
    saveFormFunctions.agreements = submitForm;

    setLastSavedFormState();
  }, [
    dirtySectionsChecker,
    getValues,
    handleSubmit,
    saveFormFunctions,
    setLastSavedFormState,
    submitForm,
    validationFunctionsArray,
  ]);

  useEffect(() => reset({ agreed }), [agreed, reset]);

  const proceedToNextStep = async () => {
    if (!agreed) await submitForm();

    nextStep();
  };

  return (
    <Form onSubmit={handleSubmit(proceedToNextStep)}>
      <h2>{t('candidateForm.agreements.title')}</h2>
      <p>{t('candidateForm.agreements.description')}</p>
      <Divider small />
      <AgreementsContainer>
        <div>
          <p>{t('candidateForm.agreements.termsPart1', { customerName })}</p>
          <p>{t('candidateForm.agreements.termsPart2')}</p>
          <p>{t('candidateForm.agreements.termsPart3')}</p>
          <p>{t('candidateForm.agreements.termsPart4')}</p>
          <p>{t('candidateForm.agreements.termsPart5', { customerName })}</p>
          <p>{t('candidateForm.agreements.termsPart6')}</p>
          <p>{t('candidateForm.agreements.termsPart7a')}</p>
        </div>
        <div>
          <p>{t('candidateForm.agreements.termsPart7b')}</p>
          <p>{t('candidateForm.agreements.termsPart8')}</p>
          <p>{t('candidateForm.agreements.termsPart9')}</p>
          <p>{t('candidateForm.agreements.termsPart10')}</p>
          <FormGroup>
            <FormCheckbox
              label={<Trans i18nKey={'candidateForm.agreements.agreementLabel'} />}
              name="agreed"
              register={register}
              error={errors.agreed}
              disabled={agreed}
            />
          </FormGroup>
        </div>
      </AgreementsContainer>
      <Divider />
      <Flexbox>
        <NextStepsButtons>
          <Button disabled={isLoading} type="submit">
            {t('common.next')}
          </Button>
        </NextStepsButtons>
      </Flexbox>
    </Form>
  );
};

AgreementsStep.propTypes = {
  nextStep: PropTypes.func.isRequired,
  agreed: PropTypes.bool.isRequired,
  setEditingLockedStatus: PropTypes.func.isRequired,
  validationFunctionsArray: PropTypes.array.isRequired,
  dirtySectionsChecker: PropTypes.object.isRequired,
  saveFormFunctions: PropTypes.object.isRequired,
};

export default AgreementsStep;
