import React from 'react';
import { useTranslation } from 'react-i18next';
import JsonTree from 'react-json-view';
import PropTypes from 'prop-types';

import { evaluationItemType, referenceType } from 'constants/types';
import StatusButtons from 'components/CandidateView/StatusButtons';
import { WysiwygEditor } from 'components/Shared/sharedComponents';
import { Divider } from 'components/Shared/sharedStyle';
import { getEconomySectionNum } from 'utils/candidateFormSections';
import { textBoxesRefsNames } from 'components/CandidateView/configCandidateView';

const Economy = ({ textBoxesRefs, defaultEvaluation, evaluation, bisnodeData, updateEvaluationStatus }) => {
  const { t } = useTranslation();
  const sectionNumber = getEconomySectionNum() + 1;
  const sectionTitle = t('candidateViewSection.economy');

  return (
    <>
      <h2>{`${sectionNumber}. ${sectionTitle}`}</h2>
      <WysiwygEditor
        defaultValue={defaultEvaluation?.header}
        editorRef={textBoxesRefs}
        name={textBoxesRefsNames?.economyHeader}
        sectionTitle={`${sectionTitle} ${t('common.header')}`}
        saveRefInAnotherRef
      />
      <JsonTree
        src={bisnodeData}
        theme="hopscotch"
        enableClipboard={false}
        displayDataTypes={false}
        collapsed={2}
        name={null}
      />
      <WysiwygEditor
        defaultValue={defaultEvaluation?.comment}
        editorRef={textBoxesRefs}
        name={textBoxesRefsNames?.economyComment}
        sectionTitle={`${sectionTitle} ${t('common.footer')}`}
        saveRefInAnotherRef
      />
      <StatusButtons value={evaluation} evaluationKey="economy" onClick={updateEvaluationStatus} />
      <Divider />
    </>
  );
};

Economy.propTypes = {
  textBoxesRefs: referenceType.isRequired,
  defaultEvaluation: evaluationItemType.isRequired,
  evaluation: PropTypes.number,
  bisnodeData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  updateEvaluationStatus: PropTypes.func.isRequired,
};

export default Economy;
