import * as yup from 'yup';
import createRequiredError from 'utils/createRequiredError';
import createMultipleFileValidation from 'utils/createMultipleFileValidation';
import i18n from 'i18n';

const t = i18n.t;

export const editCountriesFormNames = {
  Fcountry: 'country',
  Fcountries: 'countries',
  FfieldName: 'name',
  FfieldType: 'type',
  Ffields: 'fields',
  Ftemplate: 'template',
  FisRequired: 'isRequired',
  FhideOnReport: 'hideOnReport',
  FelectronicSignature: 'electronicSignature',
  FelectronicSignatureId: 'electronicSignatureId',
};

const {
  Fcountry,
  FfieldName,
  FfieldType,
  Ffields,
  Ftemplate,
  FisRequired,
  FhideOnReport,
  FelectronicSignature,
  FelectronicSignatureId,
} = editCountriesFormNames;

export const getGroupNameInCountriesObj = (groupId) => `${editCountriesFormNames.Ffields}[${groupId}]`;

const requiredError = createRequiredError('countries');

export const editCountriesSchema = yup.lazy((form) => {
  const fieldsValidation = {};

  form.fields &&
    Object.keys(form.fields).forEach((id) => {
      fieldsValidation[id] = yup.object().shape({
        [FfieldName]: yup.string().required(requiredError),
        [FfieldType]: yup.string().required(requiredError),
        [FisRequired]: yup.bool().required(requiredError),
        [Ftemplate]: createMultipleFileValidation(
          requiredError,
          form.fields[id][FfieldType] === FieldTypes.uploadDownload
        ),
      });
    });

  return yup.object().shape({
    [Fcountry]: yup.string().required(),
    [Ffields]: yup.object().shape({
      ...fieldsValidation,
    }),
  });
});

export const FieldTypes = {
  text: 'Text',
  upload: 'Upload',
  uploadDownload: 'UploadDownload',
};

export const stringFields = [FieldTypes.text];
export const filesFields = [FieldTypes.upload, FieldTypes.uploadDownload];

export const getFieldTypes = () => [
  {
    label: `${t('countries.textField')}`,
    value: FieldTypes.text,
  },
  {
    label: `${t('countries.uploadField')}`,
    value: FieldTypes.upload,
  },
  {
    label: `${t('countries.uploadAndDownloadField')}`,
    value: FieldTypes.uploadDownload,
  },
];

export const getDefaultSelectValue = (type) => getFieldTypes().find((item) => item.value === type);

export const mapDefaultCountryValues = (fields, country) =>
  fields.reduce(
    (accumulator, field) => ({
      ...accumulator,
      [`fields[${field.id}].${FfieldName}`]: field[FfieldName],
      [`fields[${field.id}].${FfieldType}`]: field[FfieldType],
      [`fields[${field.id}].${FisRequired}`]: field[FisRequired],
      [`fields[${field.id}].${FhideOnReport}`]: field[FhideOnReport],
      [`fields[${field.id}].${FelectronicSignature}`]: field[FelectronicSignature],
      [`fields[${field.id}].${FelectronicSignatureId}`]: field[FelectronicSignatureId],
      [`fields[${field.id}].${Ftemplate}`]: field[Ftemplate] ? [field[Ftemplate]] : null,
    }),
    { country }
  );

export const fieldsMapper = ({ fields }) =>
  fields
    ? Object.keys(fields).map((key) => ({
        id: key,
        [FfieldName]: fields[key][FfieldName],
        [FfieldType]: fields[key][FfieldType],
        [FisRequired]: fields[key][FisRequired],
        [FhideOnReport]: fields[key][FhideOnReport],
        [FelectronicSignature]: fields[key][FelectronicSignature],
        [FelectronicSignatureId]: fields[key][FelectronicSignatureId],
        [Ftemplate]: fields[key]?.template ? fields[key]?.template[0] : null,
      }))
    : [];

export const updateFieldsMapper = ({ fields }) =>
  fields
    ? Object.keys(fields).map((key) => ({
        id: key,
        [FfieldName]: fields[key][FfieldName],
        [FfieldType]: fields[key][FfieldType],
        [FisRequired]: fields[key][FisRequired],
        [FhideOnReport]: fields[key][FhideOnReport],
        [FelectronicSignature]: fields[key][FelectronicSignature],
        [FelectronicSignatureId]: fields[key][FelectronicSignatureId],
      }))
    : [];
