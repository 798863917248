export function extractPreviousAdressess(form) {
  const inputArray = [];
  const previousData = {};

  for (const key in form) {
    if (key.startsWith('previous')) {
      const [id, field] = key.split('.');

      if (!previousData[id]) previousData[id] = {};

      previousData[id][field] = form[key];
    }
  }

  for (const id in previousData) {
    const { startDate, endDate, country } = previousData[id];

    // all data must be provided for additional fields to show
    if (startDate && endDate && country) {
      inputArray.push({ startDate, endDate, country });
    }
  }

  return inputArray;
}

function monthDifference(startDate, endDate) {
  const years = endDate.getFullYear() - startDate.getFullYear();
  const months = endDate.getMonth() - startDate.getMonth();
  return years * 12 + months;
}

function updateStartDateToStartWithinRequiredRange(datesInRequiredRange, currentDate, requiredMonths) {
  return datesInRequiredRange.map(({ startDate, endDate, country }) => {
    const newStartDate = new Date(startDate);

    if (monthDifference(newStartDate, currentDate) > requiredMonths) {
      const newYear = currentDate.getFullYear() - Math.floor(requiredMonths / 12);
      const newMonth = (currentDate.getMonth() - (requiredMonths % 12) + 12) % 12;

      newStartDate.setFullYear(newYear, newMonth);
    }

    return {
      startDate: newStartDate,
      endDate,
      country,
    };
  });
}

export function filterDatesByRequiredYears(arr, requiredYears) {
  const requiredMonths = requiredYears * 12;
  const currentDate = new Date();

  const datesInRequiredRange = arr.filter(({ endDate }) => monthDifference(endDate, currentDate) <= requiredMonths);

  return updateStartDateToStartWithinRequiredRange(datesInRequiredRange, currentDate, requiredMonths);
}

export function mergeOverlappingDatesByCountry(addressArray) {
  const mergedDates = {};

  addressArray.forEach(({ startDate, endDate, country }) => {
    const newStartDate = new Date(startDate);
    const newEndDate = new Date(endDate);

    if (!mergedDates.hasOwnProperty(country)) {
      mergedDates[country] = [{ startDate: newStartDate, endDate: newEndDate }];
    } else {
      let isMerged = false;

      mergedDates[country] = mergedDates[country].map((dateRange) => {
        if (
          (newStartDate >= dateRange.startDate && newStartDate <= dateRange.endDate) ||
          (newEndDate >= dateRange.startDate && newEndDate <= dateRange.endDate)
        ) {
          isMerged = true;
          return {
            startDate: new Date(Math.min(newStartDate, dateRange.startDate)),
            endDate: new Date(Math.max(newEndDate, dateRange.endDate)),
          };
        }

        return dateRange;
      });

      if (!isMerged) {
        mergedDates[country].push({ startDate: newStartDate, endDate: newEndDate });
      }
    }
  });

  const mergedArray = [];

  Object.keys(mergedDates).forEach((country) => {
    mergedDates[country].forEach((dateRange) => {
      mergedArray.push({ ...dateRange, country });
    });
  });

  return mergedArray;
}

export function sortAndAggregateMonthsByCountry(dataArray) {
  const sortedByCountry = {};

  dataArray.forEach((item) => {
    const { startDate, endDate, country } = item;
    const numberOfMonths = monthDifference(new Date(startDate), new Date(endDate)) + 1;

    if (sortedByCountry.hasOwnProperty(country)) {
      sortedByCountry[country] += numberOfMonths;
    } else {
      sortedByCountry[country] = numberOfMonths;
    }
  });

  return sortedByCountry;
}

export const removeCountiresWithLessThanMinNumberOfMonths = (countriesList, minNumberOfMonths) => {
  return Object.keys(countriesList).filter((key) => countriesList[key] >= minNumberOfMonths);
};
