import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import colors from 'constants/colors';
import useActivityTracker from 'hooks/useActivityTracker';
import WithModal from 'components/Shared/Modal/WithModal';
import { Button, Flexbox, Span, H2 } from 'components/Shared/sharedStyle';

const TIME_TO_CONFIRM_LOGOUT_POPUP_IN_SECONDS = process.env.REACT_APP_TIME_TO_CONFIRM_LOGOUT_POPUP_IN_SECONDS ?? 60;

const LogoutModal = () => {
  const { t } = useTranslation();
  const { isLogoutPopupActive, setLogoutPopupStatus, logoutUser, timeWhenLogoutPopupOpened } = useActivityTracker();

  const [remaningTimeToLogout, setRemaningTimeToLogout] = useState(null);
  const popupIntervalId = useRef(null);

  useEffect(() => {
    const runPopupLogic = () => {
      const remaningTime =
        parseFloat(TIME_TO_CONFIRM_LOGOUT_POPUP_IN_SECONDS) * 1000 - (Date.now() - timeWhenLogoutPopupOpened.current);

      if (isLogoutPopupActive) {
        setRemaningTimeToLogout(Math.floor(remaningTime / 1000));
      }

      if (isLogoutPopupActive && remaningTime <= 0) {
        logoutUser({ showInactivityMsg: true });
      }
    };

    runPopupLogic();
    popupIntervalId.current = setInterval(runPopupLogic, 1000);

    return () => {
      clearInterval(popupIntervalId.current);
    };
  }, [isLogoutPopupActive, logoutUser, timeWhenLogoutPopupOpened]);

  const stayLoggedIn = () => {
    setLogoutPopupStatus(false);
    clearInterval(popupIntervalId.current);
  };

  return (
    <WithModal isOpen={isLogoutPopupActive} onClose={stayLoggedIn}>
      <H2 color={colors.error}>{t('modal.youWillBeLoggedOut')}</H2>
      <Flexbox align="center" gap="20px" paddingTop="10px" paddingBottom="30px">
        <Span fontWeight="bold">{t('modal.timeRemaining')}:</Span>
        <Span paddingLeft="0px" fontSize="30px">
          {remaningTimeToLogout}s
        </Span>
      </Flexbox>
      <Flexbox justify="space-evenly" gap="20px">
        <Button onClick={logoutUser} outlined>
          {t('modal.logout')}
        </Button>
        <Button onClick={stayLoggedIn}>{t('modal.stayLoggedIn')}</Button>
      </Flexbox>
    </WithModal>
  );
};

LogoutModal.propTypes = {};

export default LogoutModal;
