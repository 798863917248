import {
  getAccessToken,
  setAccessToken,
  getRefreshToken,
  setRefreshToken,
  setRefreshTimeoutId,
  getAuthLifeTime,
  setAuthLifeTime,
} from 'utils/localStorage';
import { refreshToken } from 'api/account';

const UPDATE_TOKEN_WHEN_ITS_REMANING_LIFE_IS_LOWER_THAN_FOLLOWING_VALUE_IN_SECONDS =
  process.env.REACT_APP_UPDATE_TOKEN_WHEN_ITS_REMANING_LIFE_IS_LOWER_THAN_FOLLOWING_VALUE_IN_SECONDS ?? 120;

const defultTokenTimeToRefreshInMilliseconds = 10000;

const renewTokenWhenItsLifeBecomesLowerThanThisValue = parseInt(
  UPDATE_TOKEN_WHEN_ITS_REMANING_LIFE_IS_LOWER_THAN_FOLLOWING_VALUE_IN_SECONDS
);

const getRemaningTimeToRefresh = () => {
  const accessTokenRemaningLifeInSeconds = getAuthLifeTime() * 60;
  const timeToRefreshInSeconds = accessTokenRemaningLifeInSeconds - renewTokenWhenItsLifeBecomesLowerThanThisValue;

  if (timeToRefreshInSeconds < 0) {
    console.error(
      `Access token total life ${accessTokenRemaningLifeInSeconds}s is lower than refresh offset value ${renewTokenWhenItsLifeBecomesLowerThanThisValue}s`
    );
    return defultTokenTimeToRefreshInMilliseconds;
  }

  const timeToRefreshInMilliseconds = timeToRefreshInSeconds * 1000;

  return timeToRefreshInMilliseconds;
};

export const saveTokensToLocalStorage = ({ access, refresh, authLifeTime }) => {
  setAccessToken(access);
  setRefreshToken(refresh);
  setAuthLifeTime(authLifeTime);

  const remaningTimeToRefresh = getRemaningTimeToRefresh();

  const refreshTimeoutId = setTimeout(() => {
    refreshTokenFunc();
  }, remaningTimeToRefresh);

  setRefreshTimeoutId(refreshTimeoutId);
};

export const refreshTokenFunc = async () => {
  const access = getAccessToken();
  const refresh = getRefreshToken();

  try {
    const tokens = await refreshToken({ access, refresh });
    saveTokensToLocalStorage(tokens);
  } catch (error) {}
};
