export const getCurrentInvestigatorsStatistics = async () => ({
  data: [
    {
      investigator: 'Investigator 1',
      created: 3,
      ongoing: 2,
      closed: 1,
      total: 6,
    },
    {
      investigator: 'Investigator 2',
      created: 2,
      ongoing: 2,
      closed: 3,
      total: 7,
    },
    {
      investigator: 'Investigator 3',
      created: 1,
      ongoing: 4,
      closed: 3,
      total: 8,
    },
  ],
  pageNumber: 1,
  pageSize: 3,
  resultsTotal: 3,
  totalNumberOfPages: 1,
});

export const getCurrentCustomersStatistics = async () => ({
  data: [
    {
      investigator: 'Customer 1',
      created: 3,
      ongoing: 2,
      closed: 1,
      total: 6,
    },
    {
      investigator: 'Customer 2',
      created: 2,
      ongoing: 2,
      closed: 3,
      total: 7,
    },
    {
      investigator: 'Customer 3',
      created: 1,
      ongoing: 4,
      closed: 3,
      total: 8,
    },
  ],
  pageNumber: 1,
  pageSize: 3,
  resultsTotal: 3,
  totalNumberOfPages: 1,
});
