import React from 'react';
import PropTypes from 'prop-types';

import { FieldTypes } from 'components/EditCountries/configEditCountries';
import { Flexbox, SelectedFile, Span } from 'components/Shared/sharedStyle';
import { downloadFile } from 'utils/downloadFile';
import { additionalFieldsBeFilesPrefix } from 'components/CandidateForm/Form/configCandidateForm';

const AdditionalFieldsFile = ({ field, files }) => {
  if (field.type !== FieldTypes.uploadDownload && field.type !== FieldTypes.upload) return null;

  const foundFiles = files[additionalFieldsBeFilesPrefix]?.filter((file) => file.additionalFieldId === field.id);

  return (
    <Flexbox direction="column" gap="5px">
      {foundFiles && foundFiles.length ? (
        foundFiles.map((file) => (
          <SelectedFile onClick={() => downloadFile({ id: file.id, fileName: file.name })} download key={file.name}>
            {file.name}
          </SelectedFile>
        ))
      ) : (
        <Span paddingLeft="6px">-</Span>
      )}
    </Flexbox>
  );
};

AdditionalFieldsFile.propTypes = {
  field: PropTypes.any,
  files: PropTypes.any,
};

export default AdditionalFieldsFile;
