import { myAxios, axiosDownloadFile } from 'api/axios';

export const getCandidates = (params) =>
  myAxios.get('/candidate/getCandidates', {
    params,
  });

export const getCandidateDetails = (id) => myAxios.get(`/candidate/getCandidateDetails/${id}`);

export const getVerifiera = (id, params) =>
  myAxios.get(`/candidate/${id}/Verifiera`, {
    params,
  });

export const refreshVerifiera = (id) => myAxios.post(`/candidate/${id}/Verifiera/Refresh`);

export const sendEmailsToCandidates = (body) => myAxios.post('/candidate/SendEmailsToCandidates', body);

// export const sendReportToCandidate = (id) =>
//   myAxios.post(`/customer/SendReportToCandidate/SendReportToCandidate/${id}`);

export const saveAgreement = (body) => myAxios.post('/candidate/agreement', body);

export const savePersonalInformation = (body) => myAxios.put('/candidate/savePersonalInformation', body);

export const saveEducation = (body) => myAxios.put('/candidate/saveEducation', body);

export const saveWorkplace = (body) => myAxios.put('/candidate/saveWorkplace', body);

export const saveConflictOfInterest = (body) => myAxios.put('/candidate/saveConflictOfInterest', body);

export const getCandidateOwnDetails = () => myAxios.get('/candidate/candidateOwnDetails');

export const getCandidateOwnSimpleDetails = () => myAxios.get('/candidate/candidateOwnSimpleDetails');

export const editPersonalInformation = (id, body) =>
  myAxios.put(`/candidate/editPersonalInformation/${id}/editPersonalInformation`, body);

export const updateEvaluation = (body) => myAxios.put('/candidate/updateEvaluation', body);

export const submitData = (body) => myAxios.post('/candidate/submitData', body);

export const deleteCandidateData = (body) => myAxios.post('/candidate/deleteData', body);

export const claimCandidate = (body) => myAxios.put('/candidate/claimCandidate', body);

export const hireCandidate = (id) => myAxios.post(`/candidate/${id}/hire`);

export const unlockEditing = (id) => myAxios.put(`/candidate/${id}/unlockEditing`);

export const activityViewed = (userId) => myAxios.post(`/candidate/activityViewed/${userId}`);

export const consentDocumentDownload = () => axiosDownloadFile.get(`/candidate/consentDocumentDownload`);

export const signConsent = (body) => myAxios.post(`/candidate/signConsent`, body);

export const signFile = (body) => myAxios.post(`/candidate/signFile`, body);

export const isReportAvailable = () => myAxios.get(`/candidate/ReportAvailable`);

export const downloadCandidateReport = () => axiosDownloadFile.get(`/candidate/downloadReport`);

export const generateReport = (userId) => myAxios.post(`/candidate/generateReport/${userId}`);

export const registerCandidates = (body) => myAxios.post('/candidate/registerCandidates', body);

export const registerCandidatesByInvestigator = (body) =>
  myAxios.post('/candidate/registerCandidatesByInvestigator', body);

export const getCandidatesForCustomer = (params) => myAxios.get('/candidate/getCandidatesForCustomer', { params });
