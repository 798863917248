import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import colors from 'constants/colors';
import { viewEvaluationSummarySections, shouldDisplaySection } from 'utils/candidateFormSections';
import { evaluationColor } from 'constants/evaluation';
import { Flexbox } from 'components/Shared/sharedStyle';
import { EvaluationItem, EvaluationName, StatusCircle } from './styleCandidateView';

const EvaluationSummary = ({ evaluation }) => {
  const { t } = useTranslation();

  return (
    <Flexbox direction="column">
      <Flexbox backgroundColor={colors.whiteBlue}>
        {viewEvaluationSummarySections.map(
          (section, index) =>
            shouldDisplaySection(section) && (
              <EvaluationItem key={index}>
                <EvaluationName>{t(`candidateViewSection.${section}`)}</EvaluationName>
              </EvaluationItem>
            )
        )}
      </Flexbox>

      <Flexbox>
        {viewEvaluationSummarySections.map(
          (section, index) =>
            shouldDisplaySection(section) && (
              <EvaluationItem key={index}>
                <StatusCircle color={evaluation && evaluationColor[evaluation[section]]} />
              </EvaluationItem>
            )
        )}
      </Flexbox>
    </Flexbox>
  );
};

EvaluationSummary.propTypes = {
  evaluation: PropTypes.shape({
    personalInformation: PropTypes.number.isRequired,
    education: PropTypes.number.isRequired,
    workplaces: PropTypes.number.isRequired,
    legal: PropTypes.number.isRequired,
    sanctionsList: PropTypes.number.isRequired,
    economy: PropTypes.number.isRequired,
    companies: PropTypes.number.isRequired,
    drivingLicense: PropTypes.number.isRequired,
  }),
};

export default EvaluationSummary;
