import colors from './colors';

export const evaluationCode = {
  notSet: 0,
  disabled: 1,
  green: 2,
  yellow: 3,
  red: 4,
};

export const evaluationColor = {
  [evaluationCode.notSet]: 'inherit',
  [evaluationCode.disabled]: colors.secondaryGray,
  [evaluationCode.green]: colors.success,
  [evaluationCode.yellow]: colors.warning,
  [evaluationCode.red]: colors.error,
};
