export const yearMonthDatePickerFormat = 'yyyy-MM';
export const yearMonthDayDatePickerFormat = 'yyyy-MM-dd';

export const mapDateOnFormSchema = (date) => (date ? new Date(date) : null);

// export const addOneDay = (date) => {
//   const dateObject = new Date(date);
//   dateObject.setDate(dateObject.getDate() + 1);
//   return dateObject;
// };

export const subtractOneDay = (date) => {
  const dateObject = new Date(date);
  dateObject.setDate(dateObject.getDate() - 1);
  return dateObject;
};

export const dateToStringYearMonth = (date) => {
  if (!date) return '';

  const dateObject = date instanceof Date ? date : new Date(date);

  const month = `0${dateObject.getMonth() + 1}`.slice(-2);
  const year = dateObject.getFullYear();

  return `${year}-${month}`;
};

export const dateToStringYearMonthDay = (date) => {
  if (!date) return '';

  const dateObject = date instanceof Date ? date : new Date(date);

  const day = `0${dateObject.getDate()}`.slice(-2);
  const month = `0${dateObject.getMonth() + 1}`.slice(-2);
  const year = dateObject.getFullYear();

  return `${year}-${month}-${day}`;
};

export const dateToStringYearMonthDayHoursMin = (date) => {
  if (!date) return '';

  const dateObject = date instanceof Date ? date : new Date(date);

  const day = `0${dateObject.getDate()}`.slice(-2);
  const month = `0${dateObject.getMonth() + 1}`.slice(-2);
  const year = dateObject.getFullYear();
  const hour = dateObject.getHours() > 12 ? dateObject.getHours() - 12 : dateObject.getHours();
  const ampm = dateObject.getHours() >= 12 ? 'pm' : 'am';
  const minute = `0${dateObject.getMinutes()}`.slice(-2);

  return `${year}-${month}-${day} at ${hour}:${minute} ${ampm} UTC`;
};

export const setDateToMidnight = (date) => {
  date?.setHours(0);
  date?.setMinutes(0);
  date?.setSeconds(0);
  date?.setMilliseconds(0);
  return date;
};

const removeTimezone = (date) => {
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() - userTimezoneOffset);
};

export const backendFriendlyDate = (date) => {
  if (!date) return '';

  const isValidDate = date instanceof Date && !Number.isNaN(date);

  if (isValidDate) {
    return removeTimezone(date);
  }

  const [day, month, year] = date.split('.');
  const dateObject = new Date(year, month - 1, day);

  return removeTimezone(dateObject);
};

export const getFirstDayOfCurrentMonth = () => {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth(), 1);
};

export const getFirstDayOfPreviousMonthFromDate = (date) => new Date(date?.getFullYear(), date?.getMonth() - 1, 1);

export const getFirstDayOfNextMonthFromDate = (date) => new Date(date?.getFullYear(), date?.getMonth() + 1, 1);
