import i18n from 'i18n';

const createRequiredError =
  (errorsPath) =>
  ({ path }) => {
    const fieldName = path.split('.').slice(-1)[0];

    return `${i18n.t(`${errorsPath}.${fieldName}`)} ${i18n.t('errors.required')}`;
  };

export const createSimpleRequiredError = (fieldName) => `${fieldName} ${i18n.t('errors.required')}`;

export default createRequiredError;
