import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { evaluationItemType, referenceType } from 'constants/types';
import { WysiwygEditor } from 'components/Shared/sharedComponents';
import { Divider } from 'components/Shared/sharedStyle';
import StatusButtons from 'components/CandidateView/StatusButtons';
import { getMediaSectionNum } from 'utils/candidateFormSections';
import { textBoxesRefsNames } from 'components/CandidateView/configCandidateView';

const Media = ({ textBoxesRefs, defaultEvaluation, evaluation, updateEvaluationStatus }) => {
  const { t } = useTranslation();
  const sectionNumber = getMediaSectionNum() + 1;
  const sectionTitle = t('candidateViewSection.media');

  return (
    <>
      <h2>{`${sectionNumber}. ${sectionTitle}`}</h2>
      <WysiwygEditor
        defaultValue={defaultEvaluation?.comment}
        editorRef={textBoxesRefs}
        saveRefInAnotherRef
        name={textBoxesRefsNames?.mediaComment}
        sectionTitle={sectionTitle}
      />
      <StatusButtons value={evaluation} evaluationKey="media" onClick={updateEvaluationStatus} />
      <Divider />
    </>
  );
};

Media.propTypes = {
  textBoxesRefs: referenceType.isRequired,
  defaultEvaluation: evaluationItemType.isRequired,
  evaluation: PropTypes.number,
  updateEvaluationStatus: PropTypes.func.isRequired,
};

export default Media;
