const getUsedCountiresFields = (selectedCountriesCodes, allFields) => {
  if (!selectedCountriesCodes || !allFields) return;

  const onlyCountriesNamesWithAdditionalFields = selectedCountriesCodes.filter((country) => allFields[country]);

  const dynamicFields = onlyCountriesNamesWithAdditionalFields.map((country) => {
    const countryFields = [...allFields[country]]; // copy array to not mutate allFields in redux
    countryFields.unshift(country);

    return countryFields;
  });

  // removes fields with the same name (there are not diplayed)
  // right now because we don't have logic for sending data to not displyed fields this caused bugs, but might be usefull in the future
  // const uniqueFields = [...new Map(dynamicFields.map((item) => [item.name, item])).values()];

  return dynamicFields;
};

export default getUsedCountiresFields;
