import React from 'react';
import { useTranslation } from 'react-i18next';

import { getMyOrganizationUsers } from 'api/customer';
import Grid from 'components/Shared/Grid/Grid';
import { Divider, PageWithTableContainer } from 'components/Shared/sharedStyle';
import { myOrganizationHeadlines, myOrganizationMapper } from './configCustomer';

const MyOrganization = () => {
  const { t } = useTranslation();

  return (
    <PageWithTableContainer>
      <h2>{t('customer.myOrganization')}</h2>
      <Divider />
      <Grid
        headlines={myOrganizationHeadlines}
        fetchDataFunction={getMyOrganizationUsers}
        mapperFunction={myOrganizationMapper}
        translationsKey="customer.customerUsersGrid"
      />
    </PageWithTableContainer>
  );
};

export default MyOrganization;
