import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
// import JsonTree from 'react-json-view';
import PropTypes from 'prop-types';

import { evaluationItemType, referenceType } from 'constants/types';
import { WysiwygEditor } from 'components/Shared/sharedComponents';
import { Button, Flexbox, Paragraph, Divider } from 'components/Shared/sharedStyle';
import { refreshVerifiera } from 'api/candidate';
import useRequestHandler from 'api/RequestHandlerHook';
import { dateToStringYearMonthDayHoursMin } from 'utils/dateUtils';
import { getLegalSectionNum } from 'utils/candidateFormSections';
import StatusButtons from 'components/CandidateView/StatusButtons';
import { textBoxesRefsNames } from 'components/CandidateView/configCandidateView';

const Legal = ({
  textBoxesRefs,
  defaultEvaluation,
  evaluation,
  verifiera,
  getVerifieraFunc,
  countryOfIdNumber,
  updateEvaluationStatus,
}) => {
  const { t } = useTranslation();
  const requestHandler = useRequestHandler();
  const { id: candidateId } = useParams();
  const sectionNumber = getLegalSectionNum() + 1;
  const sectionTitle = t('candidateView.crimesTitle');

  const verifieraParagraph = (msg, item, index) => (
    <Paragraph key={index} margin="4px 0px">{`${dateToStringYearMonthDayHoursMin(item.timestamp)} - ${msg}`}</Paragraph>
  );

  const displayVerifieraData = () => {
    if (countryOfIdNumber !== 'SE') return t('candidateView.noSweedishId');

    return verifiera.map((item, index) => {
      if (!item.success) return verifieraParagraph(t('candidateView.verifieraFailed'), item, index);
      if (item.success && !item.found)
        return verifieraParagraph(t('candidateView.verifieraCandidateNotFound'), item, index);
      if (item.success && item.found)
        return verifieraParagraph(t('candidateView.verifieraCandidateFound'), item, index);
      return null;
    });
  };

  const displayInTextArea = () => {
    if (verifiera[verifiera.length - 1]?.success && !verifiera[verifiera.length - 1]?.found) {
      const verifieraText = `<div>${t('candidateView.verifieraCandidateNotFoundTextBox')}</div>`;

      if (!defaultEvaluation.comment) return '' + verifieraText;
      if (!defaultEvaluation.comment?.includes(verifieraText))
        return defaultEvaluation.comment + '<div><br></div>' + verifieraText;
    }

    return defaultEvaluation?.comment;
  };

  const refreshVerifieraData = async () => {
    await requestHandler({ requestFunction: refreshVerifiera(candidateId) });
    await getVerifieraFunc();
  };

  return (
    <>
      <h2>{`${sectionNumber}. ${sectionTitle}`}</h2>
      <Button disabled={countryOfIdNumber !== 'SE'} onClick={refreshVerifieraData}>
        {t('candidateView.refreshVerifieraData')}
      </Button>
      <Flexbox width="auto" direction="column" margin="10px 0px">
        {displayVerifieraData()}
      </Flexbox>
      <WysiwygEditor
        defaultValue={displayInTextArea()}
        editorRef={textBoxesRefs}
        saveRefInAnotherRef
        name={textBoxesRefsNames?.legalComment}
        sectionTitle={sectionTitle}
      />

      {/* <JsonTree
        src={verifiera}
        theme="hopscotch"
        enableClipboard={false}
        displayDataTypes={false}
        collapsed={2}
        name={null}
      /> */}
      {/* commentRef from props*/}
      {/* <WysiwygEditor defaultValue={evaluation.comment} editorRef={commentRef} name="crimesComment" /> */}
      <StatusButtons value={evaluation} evaluationKey="legal" onClick={updateEvaluationStatus} />
      <Divider />
    </>
  );
};

Legal.propTypes = {
  textBoxesRefs: referenceType.isRequired,
  defaultEvaluation: evaluationItemType.isRequired,
  evaluation: PropTypes.number,
  verifiera: PropTypes.array.isRequired,
  getVerifieraFunc: PropTypes.func.isRequired,
  countryOfIdNumber: PropTypes.string,
  updateEvaluationStatus: PropTypes.func.isRequired,
};

export default Legal;
