import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { evaluationItemType, referenceType, workplacesType, personalDetailsFilesTypes } from 'constants/types';
import { WysiwygEditor } from 'components/Shared/sharedComponents';
import { DetailRow, Divider, SelectedFile } from 'components/Shared/sharedStyle';
import { getWorkplacesSectionNum } from 'utils/candidateFormSections';
import { downloadFile } from 'utils/downloadFile';
import StatusButtons from 'components/CandidateView/StatusButtons';
import { SectionTitle } from 'components/CandidateView/styleCandidateView';
import { getLatestWorkplaceDefinition, textBoxesRefsNames } from 'components/CandidateView/configCandidateView';

const Workplaces = ({
  workplaces,
  noWorkplace,
  requiredNumberOfYears,
  files,
  textBoxesRefs,
  defaultEvaluation,
  evaluation,
  updateEvaluationStatus,
}) => {
  const { t } = useTranslation();

  const sectionNumber = getWorkplacesSectionNum() + 1;
  const latestWorkplaceDefinition = getLatestWorkplaceDefinition(workplaces, sectionNumber, t);
  const sectionTitle = t('candidateForm.workplace.title');

  return (
    <>
      <h2>{`${sectionNumber}. ${sectionTitle}`}</h2>
      <WysiwygEditor
        defaultValue={defaultEvaluation.header}
        editorRef={textBoxesRefs}
        saveRefInAnotherRef
        name={textBoxesRefsNames.workplaceHeader}
        sectionTitle={`${sectionTitle} ${t('common.header')}`}
      />
      {noWorkplace ? (
        <p>{t('candidateForm.workplace.noWorkplaceLabel', { requiredYears: requiredNumberOfYears?.workplaces })}</p>
      ) : (
        latestWorkplaceDefinition.map((section, index) => (
          <React.Fragment key={index}>
            <SectionTitle>
              <h4>{t('candidateForm.workplace.sectionName', { index: index + 1 })}</h4>
            </SectionTitle>
            {section.map((field, fieldIndex) => (
              <DetailRow key={fieldIndex}>
                <span>{field.label}</span>
                {field.name === 'payslips' ? (
                  <>
                    {files?.Payslip
                      ? files?.Payslip.map((file) => (
                          <SelectedFile
                            onClick={() => downloadFile({ id: file.id, fileName: file.name })}
                            download
                            key={file.name}
                          >
                            {file.name}
                          </SelectedFile>
                        ))
                      : '-'}
                  </>
                ) : (
                  <span>{field.value}</span>
                )}
              </DetailRow>
            ))}
          </React.Fragment>
        ))
      )}
      <WysiwygEditor
        defaultValue={defaultEvaluation.comment}
        editorRef={textBoxesRefs}
        saveRefInAnotherRef
        name={textBoxesRefsNames.workplaceComment}
        sectionTitle={`${sectionTitle} ${t('common.footer')}`}
      />
      <StatusButtons value={evaluation} evaluationKey="workplaces" onClick={updateEvaluationStatus} />
      <Divider />
    </>
  );
};

Workplaces.propTypes = {
  workplaces: workplacesType.isRequired,
  noWorkplace: PropTypes.bool.isRequired,
  requiredNumberOfYears: PropTypes.object.isRequired,
  files: personalDetailsFilesTypes.isRequired,
  textBoxesRefs: referenceType.isRequired,
  defaultEvaluation: evaluationItemType.isRequired,
  evaluation: PropTypes.number,
  updateEvaluationStatus: PropTypes.func.isRequired,
};

export default Workplaces;
