import React from 'react';
import { useTranslation } from 'react-i18next';

import { conflictOfInterestTypes, referenceType } from 'constants/types';
import { WysiwygEditor } from 'components/Shared/sharedComponents';
import { DetailRow, Divider } from 'components/Shared/sharedStyle';
import { getConflictSectionNum } from 'utils/candidateFormSections';
import { SectionTitle } from 'components/CandidateView/styleCandidateView';
import { getAssignmentsDefinition, textBoxesRefsNames } from 'components/CandidateView/configCandidateView';

const ConflictOfInterest = ({ conflictOfInterest, textBoxesRefs }) => {
  const { assignments, noConflictOfInterest, deviationsDisclosed, comment } = conflictOfInterest;
  const { t } = useTranslation();

  const sectionNum = getConflictSectionNum() + 1;
  const assignmentsArr = getAssignmentsDefinition(assignments, sectionNum);
  const deviationNum = assignmentsArr.length ? assignmentsArr.length * assignmentsArr[0]?.length + 1 : 1;
  const sectionTitle = t('candidateForm.conflictOfInterest.title');

  return (
    <>
      <h2>{`${sectionNum}. ${sectionTitle}`}</h2>
      {noConflictOfInterest ? (
        <p>{t('candidateForm.labels.noExternalEngagement')}</p>
      ) : (
        assignmentsArr.map((section, index) => (
          <React.Fragment key={index}>
            <SectionTitle>
              <h4>{t('candidateForm.conflictOfInterest.sectionName', { index: index + 1 })}</h4>
            </SectionTitle>
            {section.map((field, fieldIndex) => (
              <DetailRow key={fieldIndex}>
                <span>{field.label}</span>
                <span>{field.value}</span>
              </DetailRow>
            ))}
          </React.Fragment>
        ))
      )}
      <h3>{t('candidateForm.conflictOfInterest.deviationsTitle')}</h3>
      {deviationsDisclosed ? (
        <>
          <DetailRow>
            <span>{`${sectionNum}.${deviationNum} ${t('candidateForm.labels.potentialDeviations')}`}</span>
            <span>{t('common.yes')}</span>
          </DetailRow>
          <WysiwygEditor
            defaultValue={comment || ''}
            editorRef={textBoxesRefs}
            saveRefInAnotherRef
            name={textBoxesRefsNames.conflictOfInterest}
            sectionTitle={sectionTitle}
            readOnly
          />
        </>
      ) : (
        <DetailRow>
          <span>{`${sectionNum}.${deviationNum} ${t('candidateForm.labels.potentialDeviations')}`}</span>
          <span>{t('common.no')}</span>
        </DetailRow>
      )}
      <Divider />
    </>
  );
};

ConflictOfInterest.propTypes = {
  conflictOfInterest: conflictOfInterestTypes.isRequired,
  textBoxesRefs: referenceType.isRequired,
};

export default ConflictOfInterest;
