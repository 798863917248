import { myAxios, axiosDownloadFile } from 'api/axios';

export const getFields = (countryCode) => myAxios.get(`/v1/country/${countryCode}/fields`);

export const updateFields = (countryCode, body) => myAxios.post(`/v1/country/${countryCode}/fields/updateFields`, body);

export const getFrequentTexts = (countryCode) => myAxios.get(`/v1/country/${countryCode}/texts`);

export const updateFrequentTexts = (countryCode, body) =>
  myAxios.post(`/v1/country/${countryCode}/texts/updateTexts`, body);

export const attachTemplate = (body) => myAxios.post('/v1/country/attachTemplate', body);

export const attachTemplates = (body) => myAxios.post('/v1/country/attachTemplates', body);

export const deleteTemplates = (body) => myAxios.post('/v1/country/deleteTemplates', body);

export const downloadTemplate = (id) => axiosDownloadFile.get(`/v1/import/downloadTemplate/${id}`);

export const getAllFields = (params) => myAxios.get('/v1/country/getAllFields', { params });
