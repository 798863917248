import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { getFrequentTexts, updateFrequentTexts } from 'api/country';
import { getHtmlContentFromRefCurrent } from 'utils/getHtmlContent';
import { Divider, Button, Container, FormRow, FormGroup } from 'components/Shared/sharedStyle';
import { Select, WysiwygEditor } from 'components/Shared/sharedComponents';
import useDispatchNotification from 'components/Shared/Notification/DispatchNotificationHook';
import { ButtonsContainer } from 'components/CandidateForm/styleCandidateForm';
import {
  frequentTextsFormNames,
  frequentTextsSchema,
  sectionsMapper,
  defaultSections,
  globalSelectorOption,
  mapDefaultSections,
} from 'components/FrequentTexts/configFrequentTexts';
import { LoaderInsideElements } from 'components/Shared/Loader/Loaders';

const { Fcountry } = frequentTextsFormNames;

const FrequentTexts = () => {
  const { t } = useTranslation();
  const { dispatchSuccessNotification, dispatchErrorNotification } = useDispatchNotification();
  const countriesMappedList = useSelector((state) => state.countries.countriesMappedList);
  const countriesMappedListWithGlobal = [globalSelectorOption, ...countriesMappedList];

  const [isLoading, setIsLoading] = useState(false);
  const [sections, setSections] = useState(defaultSections);

  const sectionsRef = useRef({});

  const formFunctions = useForm({
    validationSchema: frequentTextsSchema,
    defaultValues: { [Fcountry]: globalSelectorOption.value },
  });

  const { errors, watch, handleSubmit } = formFunctions;

  const country = watch(Fcountry);

  const getCountryFields = useCallback(async () => {
    setIsLoading(true);

    const resSections = await getFrequentTexts(country);
    const mappedSections = mapDefaultSections(resSections);

    // updates text inside textBoxes
    mappedSections.map(
      (section, index) => (sectionsRef.current[section.section].firstChild.innerHTML = mappedSections[index].text)
    );
    setSections(mappedSections);
    setIsLoading(false);
  }, [country]);

  useEffect(() => {
    getCountryFields();
  }, [country, getCountryFields]);

  const getTextBoxValues = (sections) =>
    sections.map((section) => ({
      [section.section]: getHtmlContentFromRefCurrent(sectionsRef.current[section.section]),
    }));

  const saveCountry = async (form) => {
    setIsLoading(true);

    try {
      const textBoxValues = getTextBoxValues(sections);

      await updateFrequentTexts(form[Fcountry], sectionsMapper(textBoxValues));
      dispatchSuccessNotification(t('notifications.saved'));
    } catch (catchedError) {
      dispatchErrorNotification({ catchedError });
    }

    setIsLoading(false);
  };

  return (
    <Container>
      <h2>{t('frequentTexts.title')}</h2>
      <Divider />
      <FormRow>
        <FormGroup>
          <Select
            name={Fcountry}
            error={errors[Fcountry]}
            selectFunctions={formFunctions}
            label={t(`candidateForm.labels.${Fcountry}`)}
            options={countriesMappedListWithGlobal}
            defaultValue={globalSelectorOption}
          />
        </FormGroup>
        <FormGroup />
      </FormRow>
      {isLoading && <LoaderInsideElements />}
      {sections.map((section) => (
        <React.Fragment key={section.section}>
          <h3>{t(`frequentTexts.${section.section}`)}</h3>
          <WysiwygEditor name={section.section} editorRef={sectionsRef} saveRefInAnotherRef margin="0 0 25px 0" />
        </React.Fragment>
      ))}
      <Divider />
      <ButtonsContainer>
        {/* <NextStepsButtons> */}
        <Button disabled={isLoading} onClick={handleSubmit(saveCountry)}>
          {t('common.save')}
        </Button>
        {/* </NextStepsButtons> */}
      </ButtonsContainer>
    </Container>
  );
};

export default FrequentTexts;
