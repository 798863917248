import React from 'react';
import PropTypes from 'prop-types';

import { TextArea as TextAreaStyled, ErrorMessageFe } from './sharedStyle';

const TextArea = ({ label, register, name, error, placeholder, longMessage, noResize, resizeOnlyX, resizeOnlyY }) => (
  <>
    <label>
      {label}
      <TextAreaStyled
        ref={register}
        name={name}
        hasError={!!error}
        rows="5"
        noResize={noResize}
        resizeOnlyX={resizeOnlyX}
        resizeOnlyY={resizeOnlyY}
        placeholder={placeholder}
      />
    </label>
    {error && <ErrorMessageFe longMessage={longMessage}>{error.message}</ErrorMessageFe>}
  </>
);

TextArea.propTypes = {
  register: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  longMessage: PropTypes.bool,
  noResize: PropTypes.bool,
  resizeOnlyX: PropTypes.bool,
  resizeOnlyY: PropTypes.bool,
};

TextArea.defaultProps = {
  error: null,
  label: '',
  placeholder: '',
  longMessage: false,
  noResize: false,
};

export default TextArea;
