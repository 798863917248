import styled from 'styled-components/macro';
import colors from 'constants/colors';
import { mediumUp } from 'constants/media-queries';

export const CandidateViewContainer = styled.div`
  padding: 30px 40px;
  display: flex;

  /* & > div:first-child {
    width: 70%;
  } */

  & > div:last-child {
    margin-bottom: 310px;
  }

  @media (max-width: 760px) {
    padding: 20px 10px;
    overflow: scroll;

    & > div:first-child {
      width: 100%;
    }

    & > div:last-child {
      margin-bottom: 0;
      height: auto;

      button {
        height: max-content;
        position: fixed;
        bottom: 20px;
        left: 0;
        z-index: 2;
      }
    }
  }
`;

export const StatusButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: -15px;
`;

export const StatusCircle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  border: ${({ isSelected }) => (isSelected ? `3px ${colors.defaultFont}` : `1px ${colors.defaultFont}`)} solid;
  margin: 5px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const EvaluationItem = styled.div`
  flex: 1;

  ${StatusCircle} {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin: 10px 0;
  }
`;

export const EvaluationName = styled.div`
  color: ${colors.primary};
  text-transform: uppercase;
  font-size: 0.8rem;
  text-align: center;
  padding: 10px 0;
`;

export const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  z-index: 1;
  position: relative;
  margin-top: 10px;
  text-transform: capitalize;

  &::before {
    content: '';
    width: 100%;
    border-bottom: 1px solid ${colors.secondaryGray};
    left: 0;
    position: absolute;
    z-index: -1;
  }

  h4,
  h5 {
    padding-right: 7px;
    background-color: ${colors.primaryGray};
  }
`;

export const FixedContainer = styled.div`
  width: 0;
  position: sticky;
  top: 80vh;

  @media ${mediumUp} {
    width: 280px;
    margin-left: 20px;
  }
`;

export const ThreeColumnDetailRow = styled.div`
  display: flex;
  justify-content: space-between;

  & > span:nth-child(1),
  & > span:nth-child(2) {
    display: inline-block;
    width: 150px;
    margin: auto 0;
    margin-top: 13px;

    @media ${mediumUp} {
      width: 250px;
    }
  }

  & > div:nth-child(1),
  & > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    width: 150px;
    gap: 5px;
    margin: auto 0;
    margin-top: 13px;

    @media ${mediumUp} {
      width: 250px;
    }
  }

  & > span:nth-child(3) {
    width: 185px;
    @media ${mediumUp} {
      width: 350px;
    }
  }

  & > label {
    width: auto;
    @media ${mediumUp} {
      width: 350px;
    }
  }
`;
