import * as yup from 'yup';
import i18n from 'i18n';
import createRequiredError from 'utils/createRequiredError';
import createMultipleFileValidation from 'utils/createMultipleFileValidation';

const requiredError = createRequiredError('candidateForm.labels');
const multipleFileValidation = createMultipleFileValidation(requiredError, true);

export const agreementsStepSchema = yup.object().shape({
  agreed: yup.bool().oneOf([true], i18n.t('errors.agreements')),
});

// Schema is connected but it doesn't do anything because validation is never triggered
// Leaving it for now so I don't have to copy this data again from candidateFormSchema
// Problably to be deleted it in the future

export const personalDetailsSchema = yup.lazy((form) => {
  const { previous } = form;
  const isChecked = (id) => previous[id].noNationalIdNumber;
  const previousAddressesValidation = {};

  if (previous) {
    Object.keys(previous).forEach((id) => {
      previousAddressesValidation[id] = yup.object().shape({
        startDate: yup.string().nullable().required(requiredError),
        endDate: yup.string().nullable().required(requiredError),
        street: yup.string().required(requiredError),
        city: yup.string().required(requiredError),
        zipCode: yup.string().required(requiredError),
        country: yup.string().nullable().required(requiredError),
        noNationalIdNumber: yup.boolean(),
        nationalIdNumber: isChecked(id) ? yup.string() : yup.string().required(requiredError),
      });
    });
  }

  return yup.object().shape({
    name: yup.string(),
    surname: yup.string(),
    nationalIdNumber: yup.string(),
    countryOfIdNumber: yup.string().nullable(),
    dateOfBirth: yup.string().nullable(),
    placeOfBirth: yup.string(),
    citizenships: yup.array().required().min(0, i18n.t('errors.citizenships')),
    phoneNumber: yup.string(),
    street: yup.string(),
    city: yup.string(),
    zipCode: yup.string(),
    country: yup.string().nullable(),
    startDate: yup.string().nullable(),
    previous: yup.object().shape({
      ...previousAddressesValidation,
    }),
  });
});

export const submittingDataSchema = yup.object().shape({
  consented: yup.bool().oneOf([true], i18n.t('errors.agreements')),
  signedConsent: multipleFileValidation,
});
