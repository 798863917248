import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import DatePickerBase from 'react-datepicker';
import { setDateToMidnight, yearMonthDayDatePickerFormat } from 'utils/dateUtils';
import { DatePickerLabel, ErrorMessageFe } from 'components/Shared/sharedStyle';
import DatePickerCustomYearDropdown from './DatePickerCustomYearDropdown';

const DatePicker = ({
  label,
  name,
  dateFormat = yearMonthDayDatePickerFormat,
  showMonthYearPicker = false,
  error,
  onChange,
  formFunctions,
  disabled,
  minDate = null,
  maxDate = null,
  scrollableYearDropdown = false,
  yearDropdownItemNumber = 100,
  isClearable,
  filterDate,
}) => {
  const { watch, control } = formFunctions;

  const watchedDate = watch(name);
  const date = watchedDate ? setDateToMidnight(watchedDate) : undefined;

  return (
    <>
      <DatePickerLabel>
        {label}
        <Controller
          as={
            <DatePickerBase
              selected={date}
              dateFormat={dateFormat}
              disabled={disabled}
              className={error ? 'with-error' : ''}
              minDate={minDate}
              maxDate={maxDate}
              isClearable={isClearable}
              filterDate={filterDate}
              // showYearDropdown and showMonthYearPicker cannot be used at the same time
              {...(showMonthYearPicker
                ? {
                    showMonthYearPicker,
                    renderCustomHeader: (props) => (
                      <DatePickerCustomYearDropdown
                        {...props}
                        yearDropdownItemNumber={yearDropdownItemNumber}
                        minDate={minDate}
                        maxDate={maxDate}
                      />
                    ),
                  }
                : {
                    showYearDropdown: true,
                    scrollableYearDropdown,
                    yearDropdownItemNumber,
                  })}
            />
          }
          name={name}
          control={control}
          onChange={onChange}
        />
      </DatePickerLabel>
      {error && <ErrorMessageFe>{error.message}</ErrorMessageFe>}
    </>
  );
};

DatePicker.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  dateFormat: PropTypes.string,
  showMonthYearPicker: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  formFunctions: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  scrollableYearDropdown: PropTypes.bool,
  yearDropdownItemNumber: PropTypes.number,
  isClearable: PropTypes.bool,
  filterDate: PropTypes.func,
};

DatePicker.defaultProps = {
  error: null,
  value: new Date(),
  disabled: false,
};

export default DatePicker;
