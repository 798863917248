import { downloadFileAxios } from 'api/import';
import store from 'store/store';

const triggerDownload = (blob, fileName) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const downloadFile = async ({ file, customFunction, id, fileName }) => {
  if (file) return triggerDownload(file, file.name); // download not yet uploaded files

  const { data, headers } = await (customFunction ? customFunction(id) : downloadFileAxios(id));
  const name = headers['content-disposition']
    ? decodeURIComponent(headers['content-disposition'].split('; ')[2].replace("filename*=UTF-8''", ''))
    : fileName // If CORS blocks 'content-disposition on localhost use fileName or 'file.pdf',
    ? fileName // Currenty fileName is not neccessary for normal usage
    : 'file.pdf';

  triggerDownload(data, name);
};

// downloadLocalFePDF currently not used
export const downloadLocalFePDF = (e, filePath, universityCountry) => {
  e.preventDefault();
  const link = document.createElement('a');
  link.href = `${process.env.PUBLIC_URL}/documents/${filePath}`;
  link.setAttribute(
    'download',
    universityCountry
      ? `University_Consent_${store.getState().countries.countriesList[universityCountry]}`
      : filePath.split('/')[1]
  );
  document.body.appendChild(link);
  link.click();
  link.remove();
};
