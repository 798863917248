import React, { useState, useEffect, useLayoutEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import Quill from 'quill';
import QuillBetterTable from 'quill-better-table';
import useDispatchNotification from 'components/Shared/Notification/DispatchNotificationHook';
import {
  checkIfTableWasCopiedFromExcelOrEditedOrLoadedFirstTime,
  numberToOrdinal,
} from 'components/Shared/WysiwygEditor/WysiwygEditorFunctions';
import './quill-better-table.css'; // copy from quill-better-table package

import { Div, ErrorMessageFe } from 'components/Shared/sharedStyle';

Quill.register(
  {
    'modules/better-table': QuillBetterTable,
  },
  true
);
Quill.register(Quill.import('attributors/style/align'), true);

const configuration = {
  modules: {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ color: [] }, { background: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
    ],
    table: false, // disable table module
    'better-table': {
      operationMenu: {
        items: {
          unmergeCells: {
            text: 'Another unmerge cells name',
          },
        },
        color: {
          text: 'Background Colors:',
          colors: ['green', 'red', 'yellow', 'blue', 'white'],
        },
      },
    },
  },
  theme: 'snow',
  keyboard: {
    bindings: QuillBetterTable.keyboardBindings,
  },
};

const WysiwygEditor = ({
  defaultValue,
  editorRef,
  saveRefInAnotherRef,
  error,
  onChange,
  readOnly,
  name,
  margin,
  sectionTitle,
}) => {
  const { dispatchErrorNotification, dispatchSuccessNotification } = useDispatchNotification();

  const [editor, setEditorInstance] = useState(null);
  const [previousOnChangeInstance /*, setOnChangeInstance */] = useState(() => {});
  const prevTables = useRef([]);
  const tablesToFixIds = useRef([]);
  const isFirstRender = useRef(true);

  useLayoutEffect(() => {
    if (editor) return; // prevents multiple toolbar rendering on code change.

    const quillInstance = new Quill(`#${name}`, {
      ...configuration,
    });
    const Block = Quill.import('blots/block');
    Block.tagName = 'div';
    Quill.register(Block);
    quillInstance.container.firstChild.innerHTML = defaultValue; // works on quill 2.0.0-dev.4
    // quillInstance.setContents(quillInstance.clipboard.convert(defaultValue, 'silent')); // works on quill v1.3.7
    setEditorInstance(quillInstance);
  }, [name, defaultValue, editor]);

  const checkTablesWidthAndShowMessage = useCallback(() => {
    const MAX_PAGE_WIDTH = 630;

    const tables = editorRef.current[name]?.querySelectorAll('.quill-better-table');
    if (!tables) return;

    tables.forEach((table, index) => {
      if (prevTables.current[index] === table?.clientWidth) return; // proceed only if width changed.

      const tableId = `${sectionTitle} ${index + 1}`;

      if (table?.clientWidth > MAX_PAGE_WIDTH) {
        tablesToFixIds.current.push(tableId); // allows to display fixed message only once on fix
        dispatchErrorNotification({ errMsg: `${numberToOrdinal(index + 1)} table in "${sectionTitle}" is to wide` });
        return;
      }

      // display fixed message once when table returned to correct width
      if (tablesToFixIds.current.find((table) => table === tableId)) {
        tablesToFixIds.current = tablesToFixIds.current.filter((table) => table !== tableId);

        dispatchSuccessNotification(`${numberToOrdinal(index + 1)} table in "${sectionTitle}" fixed`);
      }
    });

    prevTables.current = [...tables].map((table) => table.clientWidth);
  }, [dispatchErrorNotification, dispatchSuccessNotification, editorRef, name, sectionTitle]);

  const alertIfTableIsToBig = useCallback(
    (event) => {
      const checkTablesWidthAndShowMessageWithTimeout = () => {
        setTimeout(() => checkTablesWidthAndShowMessage(), 100); // timeout to allow table width to update - probably because of autosave
      };

      if (isFirstRender.current) {
        isFirstRender.current = false;
        checkTablesWidthAndShowMessageWithTimeout();
        return;
      }

      const shouldTableBeChecked = !checkIfTableWasCopiedFromExcelOrEditedOrLoadedFirstTime(event);

      if (shouldTableBeChecked) checkTablesWidthAndShowMessageWithTimeout();
    },
    [checkTablesWidthAndShowMessage]
  );

  useEffect(() => {
    if (editor) {
      editor.off('text-change', previousOnChangeInstance);
      editor.on('text-change', (event) => {
        onChange && onChange();

        alertIfTableIsToBig(event);
      });

      // setOnChangeInstance(() => onChange); // couses big performance problem when changes view and goes back in candidate from
    }
  }, [alertIfTableIsToBig, editor, onChange, previousOnChangeInstance]);

  return (
    <Div margin={margin} className={readOnly ? 'wysiwyg-read-only' : ''}>
      <Div
        id={name}
        ref={saveRefInAnotherRef ? (ref) => editorRef.current && (editorRef.current[name] = ref) : editorRef}
      />
      {error && <ErrorMessageFe>{error.message}</ErrorMessageFe>}
    </Div>
  );
};

WysiwygEditor.propTypes = {
  defaultValue: PropTypes.string,
  editorRef: PropTypes.object,
  saveRefInAnotherRef: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  name: PropTypes.string.isRequired,
  margin: PropTypes.string,
  sectionTitle: PropTypes.string,
};

WysiwygEditor.defaultProps = {
  error: null,
  onChange: () => {},
  readOnly: false,
  editorRef: null,
  defaultValue: '',
};

export default WysiwygEditor;
