import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  showFullScreenLoader,
  hideFullScreenLoader,
  changeCandidateFormSubmitStatus,
  clearCandidateFormErrors,
} from 'store/actions';
import { deleteCandidateData } from 'api/candidate';
import { Button, Flexbox } from 'components/Shared/sharedStyle';
import useDispatchNotification from 'components/Shared/Notification/DispatchNotificationHook';
import { setActiveCandidateFormPage } from 'utils/localStorage';

const DeleteAllCandidateDataTemplate = ({ closeModal }) => {
  const { dispatchSuccessNotification, dispatchErrorNotification } = useDispatchNotification();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const deleteMyData = async () => {
    dispatch(showFullScreenLoader());

    try {
      await deleteCandidateData();
      dispatchSuccessNotification(t('notifications.successfullyDeletes'));

      closeModal();
      dispatch(changeCandidateFormSubmitStatus(false));
      dispatch(clearCandidateFormErrors());
      setActiveCandidateFormPage(0);
      history.push('/');
    } catch (catchedError) {
      dispatchErrorNotification({ catchedError });
    }

    dispatch(hideFullScreenLoader());
  };

  return (
    <>
      <b>{t('candidateForm.submittingData.deleteMyDataConfirm')}</b>
      <br />
      <Flexbox justify="space-evenly">
        <Button onClick={closeModal}>{t('common.no')}</Button>
        <Button onClick={deleteMyData}>{t('common.yes')}</Button>
      </Flexbox>
    </>
  );
};

DeleteAllCandidateDataTemplate.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default DeleteAllCandidateDataTemplate;
