import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getMinDateForCurrentAddress } from 'utils/candidateFormDateUtils';
import { getMatchingCountryValue } from 'utils/countries';
import { FormRow, FormGroup } from 'components/Shared/sharedStyle';
import { Input, Select, DatePicker } from 'components/Shared/sharedComponents';
import { getPersonalInfoSectionNum } from 'utils/candidateFormSections';
import { yearMonthDatePickerFormat } from 'utils/dateUtils';

const CurrentAddressSection = ({ formFunctions, defaultFormState, editingLocked }) => {
  const { t } = useTranslation();
  const sectionNumber = getPersonalInfoSectionNum();

  const countriesMappedList = useSelector((state) => state.countries.countriesMappedList);

  const { watch, getValues, formState, triggerValidation, register, errors } = formFunctions;
  const startDateStateValue = watch('startDate');

  useEffect(() => {
    if (formState.isSubmitted) {
      triggerValidation('dateRangeError');
    }
  }, [startDateStateValue, triggerValidation, formState.isSubmitted]);

  return (
    <>
      <h2>{t('candidateForm.personalDetails.currentAddress')}</h2>
      <p>{t('candidateForm.personalDetails.currentAddressDescription')}</p>
      <FormRow>
        <FormGroup>
          <DatePicker
            name="startDate"
            dateFormat={yearMonthDatePickerFormat}
            showMonthYearPicker
            error={errors.startDate}
            formFunctions={formFunctions}
            label={`${sectionNumber}.11 ${t('candidateForm.labels.FromYearMonth')}`}
            disabled={editingLocked}
            onChange={([date]) => date}
            scrollableYearDropdown
            maxDate={new Date()}
            minDate={getMinDateForCurrentAddress({ getValues })}
            yearDropdownItemNumber={70}
          />
        </FormGroup>
        <FormGroup>
          <Input
            register={register}
            name="street"
            error={errors.street}
            label={`${sectionNumber}.12 ${t('candidateForm.labels.street')}`}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Input
            register={register}
            name="city"
            error={errors.city}
            label={`${sectionNumber}.13 ${t('candidateForm.labels.city')}`}
          />
        </FormGroup>
        <FormGroup>
          <Input
            register={register}
            name="zipCode"
            error={errors.zipCode}
            label={`${sectionNumber}.14 ${t('candidateForm.labels.zipCode')}`}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Select
            name="country"
            error={errors.country}
            selectFunctions={formFunctions}
            label={`${sectionNumber}.15 ${t('candidateForm.labels.country')}`}
            options={countriesMappedList}
            defaultValue={defaultFormState && getMatchingCountryValue(defaultFormState.country)}
            disabled={editingLocked}
          />
        </FormGroup>
        <FormGroup />
      </FormRow>
    </>
  );
};

CurrentAddressSection.propTypes = {
  defaultFormState: PropTypes.object.isRequired,
  formFunctions: PropTypes.shape({
    register: PropTypes.func.isRequired,
    unregister: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    formState: PropTypes.object.isRequired,
    setValue: PropTypes.func.isRequired,
    triggerValidation: PropTypes.func.isRequired,
    control: PropTypes.object.isRequired,
    watch: PropTypes.func.isRequired,
    getValues: PropTypes.func.isRequired,
  }).isRequired,
  editingLocked: PropTypes.bool.isRequired,
};

export default CurrentAddressSection;
