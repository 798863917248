import * as yup from 'yup';
import i18n from 'i18n';
import store from 'store/store';

import { doDatesRangesCoverEveryMonthInGivenYears } from 'utils/candidateFormDateUtils';
import createRequiredError, { createSimpleRequiredError } from 'utils/createRequiredError';
import createMultipleFileValidation from 'utils/createMultipleFileValidation';
import { stringFields, filesFields } from 'components/EditCountries/configEditCountries';

const requiredError = createRequiredError('candidateForm.labels');
const multipleFileValidation = createMultipleFileValidation(requiredError, true);

export const agreementsStepSchema = yup.object().shape({
  agreed: yup.bool().oneOf([true], i18n.t('errors.agreements')),
});

const schemaAdditionalFields = (form) => {
  if (!form.additionalFields) return;

  const countriesAdditionalFields = store.getState().countriesAdditionalFields;

  const countriesFieldsFlatenToOneObject = {};
  Object.values(countriesAdditionalFields).forEach((values) =>
    values.forEach((field) => (countriesFieldsFlatenToOneObject[field.id] = field))
  );

  const areAdditionalFieldsRequired = {};
  Object.keys(form.additionalFields).forEach((key) => {
    areAdditionalFieldsRequired[key] = getAdditionalFieldSchema(countriesFieldsFlatenToOneObject[key]);
  });

  return areAdditionalFieldsRequired;
};

const getAdditionalFieldSchema = (field) => {
  const { isRequired, type, name } = field;

  if (!isRequired) return yup.mixed();
  if (stringFields.includes(type)) return yup.string().required(createSimpleRequiredError(name));
  if (filesFields.includes(type)) return createMultipleFileValidation(createSimpleRequiredError(name), true);
};

export const personalDetailsSchema = yup.lazy((form, { context }) => {
  const { previous } = form;
  const previousAddressesValidation = {};

  const dates = [{ startDate: form.startDate, endDate: new Date(), isCorrect: !!form.startDate }];

  if (previous) {
    Object.keys(previous).forEach((id) => {
      previousAddressesValidation[id] = yup.object().shape({
        startDate: yup.string().nullable().required(requiredError),
        endDate: yup.string().nullable().required(requiredError),
        street: yup.string().required(requiredError),
        city: yup.string().required(requiredError),
        zipCode: yup.string().required(requiredError),
        country: yup.string().nullable().required(requiredError),
        noNationalIdNumber: yup.boolean(),
        nationalIdNumber:
          previous[id]?.nationalIdNumber === undefined /* not rendered */ || previous[id]?.noNationalIdNumber
            ? yup.string()
            : yup.string().required(requiredError),
      });

      dates.push({
        startDate: previous[id].startDate,
        endDate: previous[id].endDate,
        isCorrect: !!previous[id].startDate && !!previous[id].endDate,
      });
    });
  }

  const areDateRangesCorrect = doDatesRangesCoverEveryMonthInGivenYears({
    dates,
    requiredYears: context.requiredYears,
  });

  return yup.object().shape({
    name: yup.string().required(requiredError),
    middleNames: yup.string(),
    surname: yup.string().required(requiredError),
    nationalIdNumber: yup.string().required(requiredError),
    countryOfIdNumber: yup.string().nullable().required(requiredError),
    dateOfBirth: yup.string().nullable().required(requiredError),
    placeOfBirth: yup.string().required(requiredError),
    citizenships: yup.array().required().min(1, i18n.t('errors.citizenships')),
    phoneNumber: yup.string().required(requiredError),
    passport: multipleFileValidation,
    street: yup.string().required(requiredError),
    city: yup.string().required(requiredError),
    zipCode: yup.string().required(requiredError),
    country: yup.string().nullable().required(requiredError),
    startDate: yup.string().nullable().required(requiredError),
    previous: yup.object().shape({
      ...previousAddressesValidation,
    }),
    dateRangeError:
      !areDateRangesCorrect &&
      yup.mixed().required(i18n.t('errors.dateRangeError', { requiredYears: context.requiredYears })),
    additionalFields: yup.object().shape({
      ...schemaAdditionalFields(form),
    }),
  });
});

export const educationSchema = yup.lazy((form) => {
  const { noEducation, education } = form;

  if (noEducation || !education) {
    return yup.object().shape({
      noEducation: yup.boolean().required(requiredError),
    });
  }

  const degreesValidation = {};

  Object.keys(education).forEach((id) => {
    degreesValidation[id] = yup.object().shape({
      highestDegree: yup.string().required(requiredError),
      fieldOfStudy: yup.string().required(requiredError),
      startDate: yup
        .date()
        .nullable()
        .max(yup.ref('endDate'), "Start date can't be after end date and diploma date")
        .required(requiredError),
      endDate: yup
        .date()
        .nullable()
        .min(yup.ref('startDate'), "End date can't be before starting date")
        .required(requiredError),
      diplomaReceivedDate: yup
        .date()
        .nullable()
        .min(yup.ref('startDate'), "Date can't be before starting date")
        .required(requiredError),
      universityName: yup.string().required(requiredError),
      universityCountry: yup.string().nullable().required(requiredError),
      universityZipCode: yup.string().required(requiredError),
      universityCity: yup.string().required(requiredError),
      universityStreet: yup.string().required(requiredError),
      universityWebsiteUrl: yup.string().required(requiredError),
      universityPhoneNumber: yup.string().required(requiredError),
      degree: createMultipleFileValidation(requiredError, !education[id].noCertificate),
    });
  });

  return yup.object().shape({
    noEducation: yup.boolean().required(),
    education: yup.object().shape({
      ...degreesValidation,
    }),
  });
});

export const workplaceSchema = yup.lazy((form, { context }) => {
  const { noWorkplace, workplace, noAdditionalEmploymentForRequestedTime } = form;

  if (noWorkplace || !workplace) {
    return yup.object().shape({
      noWorkplace: yup.boolean().required(requiredError),
    });
  }

  const workplacesValidation = {};
  const dates = [];

  Object.keys(workplace).forEach((id) => {
    const contactInfoSchema = () =>
      !workplace[id].currentEmployment || workplace[id].yesContactWorkplace
        ? yup.string().required(requiredError)
        : yup.string();

    workplacesValidation[id] = yup.object().shape({
      companyName: yup.string().required(requiredError),
      jobPosition: yup.string().required(requiredError),
      companyAddress: yup.string().required(requiredError),
      placeOfWork: yup.string(),
      employmentType: yup.number().nullable().required(requiredError),
      comments: yup.string().nullable(),
      startDate: yup.string().nullable().required(requiredError),
      endDate: workplace[id].currentEmployment
        ? yup.string().nullable()
        : yup.string().nullable().required(requiredError),
      canWeContactYourCurrentWorkplace:
        workplace[id].currentEmployment &&
        !workplace[id].yesContactWorkplace &&
        !workplace[id].dontContactWorkplace &&
        yup.mixed().required(i18n.t('errors.canWeContactYourCurrentWorkplace')),
      companyPhoneNumberSwitchboard: contactInfoSchema(),
      companyPhoneNumberHrDepartment: contactInfoSchema(),
      supervisorName: contactInfoSchema(),
      supervisorPhone: contactInfoSchema(),
      supervisorEmail: contactInfoSchema(),
    });

    dates.push({
      startDate: workplace[id].startDate,
      endDate: workplace[id].currentEmployment ? new Date() : workplace[id].endDate,
      isCorrect: workplace[id].startDate && workplace[id].endDate,
    });
  });

  const areDateRangesCorrect = noAdditionalEmploymentForRequestedTime
    ? true
    : doDatesRangesCoverEveryMonthInGivenYears({ dates, requiredYears: context.requiredYears });

  return yup.object().shape({
    noWorkplace: yup.boolean().required(),
    dateRangeError:
      !areDateRangesCorrect &&
      yup.mixed().required(i18n.t('errors.dateRangeErrorWorkplace', { requiredYears: context.requiredYears })),
    workplace: yup.object().shape({
      ...workplacesValidation,
    }),
  });
});

export const conflictOfInterestSchema = yup.lazy((form) => {
  if (form.noConflictOfInterest) {
    return yup.object().shape({
      noConflictOfInterest: yup.boolean().required(),
      deviationsDisclosed: yup.string().required().oneOf(['true', 'false']),
      comment: form.deviationsDisclosed === 'true' ? yup.string().required(requiredError) : yup.string(),
    });
  }

  const assignmentsValidation = {};

  Object.keys(form.assignments).forEach((id) => {
    assignmentsValidation[id] = yup.object().shape({
      typeOfAssignment: yup.string().required(requiredError),
      company: yup.string().required(requiredError),
    });
  });

  return yup.object().shape({
    noConflictOfInterest: yup.boolean().required(requiredError),
    assignments: yup.object().shape({
      ...assignmentsValidation,
    }),
    deviationsDisclosed: yup.string().required().oneOf(['true', 'false']),
    comment: form.deviationsDisclosed === 'true' ? yup.string().required(requiredError) : yup.string(),
  });
});

export const submittingDataFormNames = {
  signedConsent: 'signedConsent',
  signedScribeConsent: 'signedScribeConsent',
};

export const submittingDataSchema = yup.lazy((form) => {
  return yup.object().shape({
    [submittingDataFormNames.signedConsent]: createMultipleFileValidation(requiredError, !form.signedScribeConsent),
    [submittingDataFormNames.signedScribeConsent]: createMultipleFileValidation(requiredError, !form.signedConsent),
  });
});
