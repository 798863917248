import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getCustomerName, getUserName } from 'utils/localStorage';
import { routes } from 'constants/routes';
import { Button, Flexbox } from 'components/Shared/sharedStyle';
import { WelcomePageContainer, WelcomePageFlexbox } from 'components/WelcomePage/style';
import { useGetCandidateOwnSimpleDetails } from 'hooks/api/candidate/useGetCandidateOwnSimpleDetails';
import { LoaderInsideElements } from 'components/Shared/Loader/Loaders';

const candidateStatuses = {
  newCandidate: 'newCandidate',
  inProccess: 'inProccess',
  finished: 'finished',
  revising: 'revising',
};

const { newCandidate, inProccess, finished, revising } = candidateStatuses;

const getCandidateStatus = (candidateData) => {
  if (!candidateData) return newCandidate;

  const { agreed, isEditing, editingLocked } = candidateData;

  if (!agreed) return newCandidate;
  if (agreed && !isEditing && !editingLocked) return inProccess;
  if (agreed && !isEditing && editingLocked) return finished;
  if (agreed && isEditing && !editingLocked) return revising;

  return newCandidate;
};

const WelcomePage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const isMobile = useSelector((state) => state.isMobile);

  const userName = getUserName();
  const customerName = getCustomerName();

  const { data: candidateData, isLoading } = useGetCandidateOwnSimpleDetails();
  const candidateStatus = getCandidateStatus(candidateData);

  const redirectToMyRecruitment = () => history.push(routes.myRecruitment);

  return (
    <WelcomePageFlexbox align="center" justify="center">
      {isLoading ? (
        <LoaderInsideElements />
      ) : (
        <WelcomePageContainer>
          <h2>{t(`welcomePage.${candidateStatus}.title`, { name: userName })}</h2>
          <p>
            <Trans i18nKey={`welcomePage.${candidateStatus}.text`} values={{ customerName }} />
          </p>
          <Flexbox justify={isMobile ? 'center' : 'flex-end'}>
            <Button onClick={redirectToMyRecruitment}>{t(`welcomePage.${candidateStatus}.button`)}</Button>
          </Flexbox>
        </WelcomePageContainer>
      )}
    </WelcomePageFlexbox>
  );
};

export default WelcomePage;
