import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ErrorMessageFe, Flexbox, Paragraph } from 'components/Shared/sharedStyle';
import { LevelCheckboxContainer, LevelCheckboxNames } from 'components/Shared/FormLevelCheckbox/styleFormLevelCheckbox';
import { FormCheckbox, Select } from 'components/Shared/sharedComponents';

const SECTIONS_WITH_DROPDOWN = ['personalInformation', 'workplaces'];

const getNumberOptions = (number) => {
  const numberOptions = Array.from(new Array(number), (_, value) => ({ label: value + 1, value: value + 1 }));

  return numberOptions;
};

const FormLevelCheckbox = ({ label, name, formFunctions, longMessage, numberOfLevels }) => {
  const { register, errors, setValue, getValues, watch, formState, triggerValidation } = formFunctions;
  const { t } = useTranslation();

  const rowValues = watch(name);

  const numberOptions = getNumberOptions(10);

  const getDropdownName = ({ name, i }) => `${name}[${i}].numberOfYears`;
  const getDropdownError = ({ errors, name, i }) =>
    errors && errors[name] && errors[name][i] && errors[name][i]?.numberOfYears;

  const setLvl = (i) => {
    const values = getValues({ nest: true });
    setValue(`${name}.lvl${i + 1}`, values[name][`lvl${i + 1}`] ? true : false);
  };

  return (
    <LevelCheckboxContainer>
      <LevelCheckboxNames>
        <p>{label}</p>
        {SECTIONS_WITH_DROPDOWN.includes(name) && (
          <Paragraph paddingLeft={'18px'}>{t('customer.yearsRequired')}</Paragraph>
        )}
      </LevelCheckboxNames>
      {Array(numberOfLevels)
        .fill(0)
        .map((_, i) => {
          const checkBoxName = `${name}[${i}].number`;
          const dropdownName = getDropdownName({ name, i });

          return (
            <Flexbox key={i} direction="column" width="75px" gap="15px" align="center">
              <FormCheckbox
                onChange={() => {
                  setLvl(i);
                  if (formState.isSubmitted) triggerValidation();
                }}
                register={register}
                name={checkBoxName}
                error={errors[checkBoxName]}
              />
              {SECTIONS_WITH_DROPDOWN.includes(name) && (
                <Select
                  name={dropdownName}
                  error={getDropdownError({ errors, name, i })}
                  selectFunctions={formFunctions}
                  options={numberOptions}
                  defaultValue={
                    rowValues &&
                    rowValues[i] && {
                      label: rowValues[i]?.numberOfYears ?? '',
                      value: rowValues[i]?.numberOfYears ?? '',
                    }
                  }
                  disabled={!watch(checkBoxName)}
                />
              )}
            </Flexbox>
          );
        })}
      {errors[name] && <ErrorMessageFe longMessage={longMessage}>{errors[name].message}</ErrorMessageFe>}
    </LevelCheckboxContainer>
  );
};

FormLevelCheckbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string.isRequired,
  formFunctions: PropTypes.object.isRequired,
  longMessage: PropTypes.bool,
  numberOfLevels: PropTypes.number,
};

FormLevelCheckbox.defaultProps = {
  error: null,
  label: '',
  longMessage: false,
  onChange: () => {},
  register: () => {},
};

export default FormLevelCheckbox;
