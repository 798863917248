import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { showNotification } from 'store/actions';

const useDispatchNotification = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const dispatchError = useCallback((message) => dispatch(showNotification({ message, type: 'error' })), [dispatch]);

  return {
    dispatchSuccessNotification: useCallback(
      (message) => dispatch(showNotification({ message, type: 'success' })),
      [dispatch]
    ),
    dispatchErrorNotification: useCallback(
      ({ catchedError, errMsg }) => {
        if (catchedError?.message) return dispatchError(catchedError.message);
        if (catchedError?.errors) {
          if (catchedError.errors['']) {
            return dispatchError(
              catchedError.errors[''][0].includes('Multipart body length limit')
                ? 'Failed to upload at least one file because it was over 5MB'
                : catchedError.errors[''][0]
            );
          }
          // if below probably to delete
          if (catchedError.errors['country']) {
            return dispatchError(
              catchedError.errors['country'][0].includes('Multipart body length limit')
                ? 'Failed to upload at least one file because it was over 5MB'
                : catchedError.errors['country'][0]
            );
          }
        }
        if (errMsg) return dispatchError(errMsg);
        dispatchError(t('notifications.error'));
      },
      [dispatchError, t]
    ),
  };
};

export default useDispatchNotification;
