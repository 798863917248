import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Prompt, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import WithModal from 'components/Shared/Modal/WithModal';
import { Button, Flexbox } from 'components/Shared/sharedStyle';

const RouteLeaveGuard = ({ when, shouldBlockNavigation, isSaving, onConfirmClick, navigateOnDecline = false }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isActive, setIsActive] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => setIsActive(false);

  const openModal = () => setIsActive(true);

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation()) {
      openModal();
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const confirmNavigation = () => setConfirmedNavigation(true);

  const confirmClick = () => {
    closeModal();
    // onConfirmClick?.(lastLocation!);
    if (onConfirmClick && lastLocation) onConfirmClick(lastLocation); // lastLocation currently not used
    if (!navigateOnDecline) confirmNavigation();
  };

  const discardClick = () => {
    closeModal();

    if (navigateOnDecline) confirmNavigation();
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation); // raczej mozna usunac confirmedNavigation z ifa
    }
  }, [confirmedNavigation, lastLocation, history]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} /> {/* Prompt - responsible for stoping route chaning */}
      <WithModal isOpen={isActive} onClose={closeModal}>
        <Flexbox direction="column" align="center">
          <b>{t('modal.changesOnPage')}</b>
        </Flexbox>
        <br />
        <Flexbox justify="space-evenly" gap="30px">
          <Button onClick={discardClick}>{t('common.no')}</Button>
          <Button onClick={confirmClick} disabled={isSaving}>
            {t('common.yes')}
          </Button>
        </Flexbox>
      </WithModal>
    </>
  );
};

RouteLeaveGuard.propTypes = {
  when: PropTypes.bool,
  shouldBlockNavigation: PropTypes.func.isRequired,
  isSaving: PropTypes.bool, // saving doesnt do anything because it was used internaly for loading animation
  onConfirmClick: PropTypes.func, // must be func in order to calculete on leaving view
  navigateOnDecline: PropTypes.bool,
};

export default RouteLeaveGuard;
