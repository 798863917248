import React, { createContext, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { getAccessToken } from 'utils/localStorage';

export const SignalRContext = createContext();
export const useSignalR = () => useContext(SignalRContext);

export const SignalRProvider = ({ children }) => {
  let signalRConnection;

  if (getAccessToken()) {
    signalRConnection = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_BASE_URL}/NotificationHub`, {
        accessTokenFactory: () => encodeURIComponent(getAccessToken()),
      })
      .build();

    signalRConnection.start().catch((err) => {
      console.error(err.toString());
    });
  } else {
    signalRConnection = null;
  }

  // disconnects signalR on logout
  useEffect(() => {
    return () => signalRConnection && signalRConnection.stop();
  });

  return <SignalRContext.Provider value={{ signalRConnection }}>{children}</SignalRContext.Provider>;
};

SignalRProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
