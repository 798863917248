import styled from 'styled-components/macro';
import colors from 'constants/colors';

export const HeaderContainer = styled.header`
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.secondaryGray};
  display: flex;
  justify-content: flex-end;
  padding: 0 40px;

  @media (max-width: 760px) {
    position: sticky;
    top: 0;
    z-index: 2;
  }
`;

export const UserNavigationContainer = styled.div`
  position: relative;
  display: flex;
  font-size: 0.9rem;
  z-index: 2;
`;

export const UserNavigationToggler = styled.div`
  padding: 16.8px 15px;
  cursor: pointer;
  color: ${colors.textColor};
  font-weight: bold;
  margin-right: 15px;
  display: flex;
  align-items: center;

  #arrow_right-24px {
    transform: rotate(${({ isVisible }) => (isVisible ? 270 : 90)}deg);

    path {
      fill: ${colors.blueContour};
    }
  }

  @media (max-width: 760px) {
    pointer-events: none;
    margin-right: 10px;
    padding-top: 20px;

    svg {
      display: none;
    }

    &::after {
      display: none;
    }
  }
`;

export const UserNavigationList = styled.nav`
  border-radius: 0 0 15px 15px;
  position: absolute;
  right: 0;
  top: 100%;
  background-color: ${colors.white};
  border: 1px solid ${colors.secondaryGray};
  border-top: unset;
  overflow: hidden;
`;

export const UserNavigationItem = styled.div`
  cursor: pointer;
  padding: 10px 15px;
  margin: 0;
  text-align: center;
  color: ${colors.textColor};
  background-color: ${colors.darkGrey};

  &:hover {
    background-color: ${colors.primaryOrangeBright};
    color: ${colors.white};
  }
`;

export const UserNavigationText = styled.div`
  padding: 10px 15px;
  margin: 0;
  text-align: center;
`;

export const LanguageItem = styled.div`
  width: 50%;
  display: inline-block;
  text-align: center;

  ${({ isActive }) =>
    isActive &&
    `
    font-weight: bold;
    color: ${colors.primary};
  `}

  &:hover {
    background-color: ${colors.primary};
    color: ${colors.white};
  }
`;
