import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Tooltip from 'react-tooltip';
import { useDispatch } from 'react-redux';

import { addFileToRemoveList } from 'store/actions';
import icons from 'constants/icons';
import useDispatchNotification from 'components/Shared/Notification/DispatchNotificationHook';
import { SelectedFile, SelectedFileOneLine, IconButton, IconButtonRounded } from 'components/Shared/sharedStyle';
import {
  MultipleFileContainer,
  MultipleFileErrorMessage,
  UploadInput,
} from 'components/Shared/MultipleFilesUploader/styleMultipleFilesUploader';
import { LoaderSmall } from 'components/Shared/Loader/Loaders';
import { downloadFile } from 'utils/downloadFile';

const MultipleFilesUploader = ({
  label,
  name,
  errors,
  formFunctions,
  redirectFunction,
  defaultFiles,
  disabled,
  grayedText,
  tooltipMessage,
  fileGroup,
  shouldReset,
  narrow,
  width,
  margin,
  oneLineAndPassWidth,
  uploadOnlyOneFile,
  preventUploadField,
  preventUploadFunction,
  showLoader,
  canDownloadFiles,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dispatchErrorNotification } = useDispatchNotification();

  const [files, setFiles] = useState([]);

  const { register, unregister, triggerValidation, setValue, getValues } = formFunctions;

  useEffect(() => {
    if (register) register(name);

    return () => unregister(name);
  }, [register, unregister, name]);

  useEffect(() => {
    setValue(name, defaultFiles);
  }, [defaultFiles, setValue, name]);

  useEffect(() => {
    // you have to pass defaultFiles as (array or null!!!) in useMemo
    if (defaultFiles) setFiles(defaultFiles);
  }, [defaultFiles]);

  useEffect(() => {
    if (shouldReset) {
      setFiles([]);
      setValue(name, null);
    }
  }, [shouldReset, name, setValue]);

  const handleUpload = (e) => {
    const receivedFiles = [...e.target.files];
    e.target.value = null; // clears input buffer so user can upload file again and trigger visual error

    const onlyNewFiles = receivedFiles.filter((file) => {
      if (files.some((f) => f.name === file.name)) {
        dispatchErrorNotification({ errMsg: t('errors.alreadyUploadedFile', { fileName: file.name }) });
        return false;
      }
      return true;
    });

    if (onlyNewFiles.length === 0) return;

    onlyNewFiles.forEach((file, index) => {
      file.group = fileGroup ? fileGroup : 0;
      file.number = files.length ? Math.max(...files.map((file) => file.number)) + 1 + index : index;
    });

    let allFiles = [...(files || []), ...onlyNewFiles];

    if (uploadOnlyOneFile && allFiles.length > 1) {
      allFiles = allFiles.slice(0, 1);
      dispatchErrorNotification({ errMsg: t('errors.uploadOnlyOneFile') });
    }

    if (allFiles.length > 10) return alert(t('errors.maximumFiles'));

    setValue(name, allFiles);
    setFiles(allFiles);
    triggerValidation(name);
  };

  const removeFile = (e, fileToRemove) => {
    e.preventDefault();
    const fileName = fileToRemove.name;
    const fileId = fileToRemove.id;
    const updatedFiles = files.filter((file) => file.name !== fileName);

    if (fileId) dispatch(addFileToRemoveList(fileId));

    setValue(name, updatedFiles.length ? updatedFiles : null);
    setFiles(updatedFiles);
    triggerValidation(name);
  };

  const getErrorMessage = (fileName) => {
    if (!errors.length) return null;

    const errorObject = errors.find((file) => fileName === (file && file.message.fileName));
    if (!errorObject) return null;

    return errorObject.message.error;
  };

  const downloadFileFunc = (file) => {
    canDownloadFiles &&
      downloadFile({
        file: !file.id && file, // not uploaded file
        id: file.id && file.id, // uploaded file
      });
  };

  const disabledInput = disabled || (uploadOnlyOneFile && files.length >= 1);

  return (
    <>
      <UploadInput disabled={disabledInput} narrow={narrow} margin={margin}>
        {label}
        <input
          name={name}
          type={(preventUploadField && getValues()[preventUploadField]) || redirectFunction ? 'button' : 'file'}
          onClick={
            preventUploadField && getValues()[preventUploadField]
              ? preventUploadFunction
              : redirectFunction
              ? redirectFunction
              : null
          }
          onChange={!redirectFunction ? handleUpload : null}
          multiple
          disabled={disabledInput}
        />
        {showLoader && <LoaderSmall />}
        {tooltipMessage && (
          <IconButtonRounded icon={icons.helpOutlined} data-tip={tooltipMessage} onClick={(e) => e.preventDefault()}>
            <Tooltip effect="solid" className="custom-tooltip" place="right" />
          </IconButtonRounded>
        )}
      </UploadInput>
      {files &&
        files.map((file, index) => (
          <div key={index}>
            <MultipleFileContainer width={width} grayedText={grayedText}>
              {oneLineAndPassWidth ? (
                <SelectedFileOneLine
                  onClick={() => downloadFileFunc(file)}
                  width={oneLineAndPassWidth}
                  download={canDownloadFiles}
                >
                  {file.name}
                </SelectedFileOneLine>
              ) : (
                <SelectedFile onClick={() => downloadFileFunc(file)} download={canDownloadFiles}>
                  {file.name}
                </SelectedFile>
              )}
              {!disabled && <IconButton icon={icons.close} onClick={(e) => removeFile(e, file)} />}
            </MultipleFileContainer>
            {errors && <MultipleFileErrorMessage>{getErrorMessage(file.name)}</MultipleFileErrorMessage>}
          </div>
        ))}
      {errors && errors.message && <MultipleFileErrorMessage>{errors.message}</MultipleFileErrorMessage>}
    </>
  );
};

MultipleFilesUploader.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.shape({ message: PropTypes.string })]),
  formFunctions: PropTypes.object.isRequired,
  redirectFunction: PropTypes.func,
  defaultFiles: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(File)])),
  disabled: PropTypes.bool,
  grayedText: PropTypes.bool,
  tooltipMessage: PropTypes.string,
  fileGroup: PropTypes.number,
  shouldReset: PropTypes.bool,
  narrow: PropTypes.bool,
  width: PropTypes.string,
  margin: PropTypes.string,
  oneLineAndPassWidth: PropTypes.string,
  uploadOnlyOneFile: PropTypes.bool,
  preventUploadField: PropTypes.string,
  preventUploadFunction: PropTypes.func,
  showLoader: PropTypes.bool,
  canDownloadFiles: PropTypes.bool,
};

MultipleFilesUploader.defaultProps = {
  errors: null,
  defaultFiles: null,
  disabled: false,
  grayedText: false,
  tooltipMessage: null,
  fileGroup: null,
  shouldReset: false,
  width: null,
  margin: null,
  oneLineAndPassWidth: undefined,
  uploadOnlyOneFile: false,
  showLoader: false,
  canDownloadFiles: false,
};

export default MultipleFilesUploader;
