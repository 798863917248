import axios from 'axios';
import QS from 'qs';
import { logout } from 'hooks/useLogout';
import { getAccessToken } from 'utils/localStorage';

export const baseURL = `${process.env.REACT_APP_BASE_URL}/api`;

const requestInterceptor = (request) => {
  request.headers.Authorization = `Bearer ${getAccessToken()}`;

  if (request.params) {
    const paramsWithoutEmptyKeys = {};
    Object.keys(request.params).forEach((key) => {
      paramsWithoutEmptyKeys[key] = request.params[key] !== '' ? request.params[key] : undefined;
    });
    request.params = paramsWithoutEmptyKeys;
  }

  return request;
};

const responseDataInterceptor = (response) => response.data;

const responseErrorInterceptor = async (error) => {
  if (!error?.response || error?.response?.status === 401) {
    await logout();
  }
  return Promise.reject(error?.response?.data);
};

export const myAxios = axios.create({
  baseURL,
  withCredentials: true,
  paramsSerializer: (params) => QS.stringify(params, { arrayFormat: 'repeat' }),
});

myAxios.interceptors.request.use(requestInterceptor);
myAxios.interceptors.response.use(responseDataInterceptor, responseErrorInterceptor);

export const axiosDownloadFile = axios.create({
  baseURL,
  withCredentials: true,
  responseType: 'arraybuffer',
});

axiosDownloadFile.interceptors.request.use(requestInterceptor);
