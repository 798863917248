import { setUserName, setUserEmail, setCustomerName, setSignedIn, setUserRoles } from 'utils/localStorage';
import userRoles from 'constants/roles';
import hasPermission from 'utils/hasPermission';

export const saveUserInfoToLocalStorage = ({ formData, roles, displayName, customerName }) => {
  setSignedIn(true);

  setUserRoles(roles);

  setUserName(displayName);
  setUserEmail(formData.email);

  if (hasPermission([userRoles.candidate])) setCustomerName(customerName);
};
