import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { filterTypes, filterForm } from 'constants/filters-form-consts';
import { Input, Form, FormRow, Select } from 'components/Shared/sharedStyle';
import { useUrlQuery, queryNames } from 'hooks/useUrlQuery';

const FiltersForm = ({
  rangeFilter,
  setRangeFilter,
  searchText,
  setSearchText,
  filterType,
  countries,
  setCountries,
}) => {
  const { t } = useTranslation();
  const countriesMappedList = useSelector((state) => state.countries.countriesMappedList);
  const { setUrlQuery } = useUrlQuery();

  return (
    <Form>
      <FormRow>
        <Select
          options={filterForm[filterType]}
          classNamePrefix="react-select"
          value={rangeFilter}
          onChange={(option) => {
            setRangeFilter(option);
            setUrlQuery({ name: queryNames.range, value: option?.label });
          }}
          isSearchable={false}
        />
        {filterType === filterTypes.candidates && (
          <Input
            type="text"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              setUrlQuery({ name: queryNames.search, value: e.target.value });
            }}
            placeholder={t('filtersForm.candidate')}
            height="34px"
          />
        )}
        {filterType === filterTypes.candidates && (
          <Select
            placeholder={t('filtersForm.selectCountries')}
            options={countriesMappedList}
            classNamePrefix="react-select"
            value={countries}
            onChange={(countriesObj) => {
              setCountries(countriesObj);
              const countriesShortcuts = countriesObj.map((country) => country.value);
              setUrlQuery({ name: queryNames.countries, value: countriesShortcuts });
            }}
            isSearchable
            isMulti
            width="330px"
          />
        )}
      </FormRow>
    </Form>
  );
};

FiltersForm.propTypes = {
  rangeFilter: PropTypes.object.isRequired,
  setRangeFilter: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  setSearchText: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
  setCountries: PropTypes.func.isRequired,
  filterType: PropTypes.oneOf([filterTypes.candidates, filterTypes.statistics]).isRequired,
};

export default FiltersForm;
