import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'react-tooltip';

import { Input as InputStyled, ErrorMessageFe, LabelWrapper, TooltipIcon } from './sharedStyle';
import icons from 'constants/icons';

const Input = ({
  label,
  register,
  name,
  error,
  placeholder,
  longMessage,
  tooltipMessage,
  type,
  disabled,
  defaultValue,
}) => (
  <>
    <label>
      <LabelWrapper>
        {label}
        {tooltipMessage && (
          <TooltipIcon icon={icons.helpOutlined} data-tip={tooltipMessage}>
            <Tooltip effect="solid" className="custom-tooltip" place="right" />
          </TooltipIcon>
        )}
      </LabelWrapper>
      <InputStyled
        ref={register}
        name={name}
        hasError={!!error}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        defaultValue={defaultValue}
      />
    </label>
    {error && <ErrorMessageFe longMessage={longMessage}>{error.message}</ErrorMessageFe>}
  </>
);

Input.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  longMessage: PropTypes.bool,
  tooltipMessage: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
};

Input.defaultProps = {
  register: () => {},
  error: null,
  label: '',
  placeholder: '',
  longMessage: false,
  tooltipMessage: '',
};

export default Input;
