import styled from 'styled-components/macro';

export const Rectangle = styled.div`
  display: inline-block;
  width: 15px;
  height: 12px;
  background: ${({ color }) => color};
  margin-right: 7px;
  border-radius: 3px;
`;
