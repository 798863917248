import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { A } from 'components/Shared/sharedStyle';

/*
	This component exist to stop error 'insta page' showing up in console on test environments.
	Error is caused because web browser tries to prefetch href link from <a> tag.
	Link component from react router also creates <a> tag with href.
	Prefetch fails because only "/" is valid for Azure server.
	Besides we are on SPA so prefetch is probably pointless.
	There same happens when we enter route other than "/" to url bar.
	Server will throw an 404 error but it will still serve index file, so react loads and displays correct page.

	Component is named Link so you can easly switch it with react router Link in the future
*/

const Link = ({ to, children }) => {
  const history = useHistory();

  return (
    <A onClick={() => history.push(to)} cursor="pointer">
      {children}
    </A>
  );
};

export default Link;

Link.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
};
