import styled from 'styled-components/macro';
import { mediumUp, largeUp } from 'constants/media-queries';

export const EditCountriesRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 25px;

  @media ${mediumUp} {
    flex-direction: row;
  }

  & > div:nth-child(1),
  & > div:nth-child(2),
  & > div:nth-child(3) {
    width: 100%;
    margin-right: 20px;
    margin-bottom: 20px;

    @media ${mediumUp} {
      width: 100%;
    }
  }
`;

export const CheckboxRequiredDiv = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 20px;
  margin-top: 12px;

  @media ${mediumUp} {
    gap: 10px;
  }

  @media ${largeUp} {
    gap: 20px;
  }
`;
