import styled from 'styled-components/macro';
import { basicCSSproperties } from 'components/Shared/sharedStyle';

import icons from 'constants/icons';

export const CheckboxDiv = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  ${basicCSSproperties}
`;

export const CheckboxNode = styled.input`
  opacity: 0;
  width: 0;

  & + label {
    position: relative;
    cursor: pointer;
    margin: 10px 0;
    padding-left: 25px;
    display: inline-block;
  }

  & + label:before {
    content: '';
    left: -2px;
    position: absolute;
    width: 20px;
    height: 20px;
    background-image: url(${icons.checkboxBlank});
    background-size: cover;
    display: inline-block;
    top: -10px;
  }

  &:checked + label:before {
    background-image: url(${icons.checkbox});
  }

  &:disabled + label {
    cursor: initial;
    opacity: 0.8;
  }
`;
