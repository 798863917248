import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getMatchingCountryValue, setDefalutMultiSelectValue } from 'utils/countries';
import { FormRow, FormGroup } from 'components/Shared/sharedStyle';
import { getPersonalInfoSectionNum } from 'utils/candidateFormSections';

import { Input, Select, DatePicker, MultipleFilesUploader } from 'components/Shared/sharedComponents';
import { idNumberMessage, documentMessage } from 'components/CandidateForm/Form/configCandidateForm';

const GeneralInfoSection = ({ formFunctions, defaultPassportValue, defaultFormState, editingLocked }) => {
  const { t } = useTranslation();

  const sectionNumber = getPersonalInfoSectionNum();
  const countriesMappedList = useSelector((state) => state.countries.countriesMappedList);

  const { register, errors, watch } = formFunctions;

  return (
    <>
      <FormRow>
        <FormGroup>
          <Input
            register={register}
            name="name"
            error={errors.name}
            label={`${sectionNumber}.1 ${t('candidateForm.labels.name')}`}
          />
        </FormGroup>
        <FormGroup>
          <Input
            register={register}
            name="middleNames"
            error={errors.middleNames}
            label={`${sectionNumber}.2 ${t('candidateForm.labels.middleNames')}`}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Input
            register={register}
            name="surname"
            error={errors.surname}
            label={`${sectionNumber}.3 ${t('candidateForm.labels.surname')}`}
          />
        </FormGroup>
        <FormGroup>
          <Input
            register={register}
            name="nationalIdNumber"
            error={errors.nationalIdNumber}
            disabled={editingLocked}
            label={`${sectionNumber}.4 ${t('candidateForm.labels.nationalIdNumber')}`}
            tooltipMessage={idNumberMessage[watch('countryOfIdNumber')]}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Select
            name="countryOfIdNumber"
            error={errors.countryOfIdNumber}
            selectFunctions={formFunctions}
            label={`${sectionNumber}.5 ${t('candidateForm.labels.countryOfIdNumber')}`}
            options={countriesMappedList}
            defaultValue={defaultFormState && getMatchingCountryValue(defaultFormState.countryOfIdNumber)}
            disabled={editingLocked}
          />
        </FormGroup>
        <FormGroup>
          <DatePicker
            name="dateOfBirth"
            error={errors.dateOfBirth}
            formFunctions={formFunctions}
            label={`${sectionNumber}.6 ${t('candidateForm.labels.dateOfBirth')}`}
            disabled={editingLocked}
            onChange={([date]) => date}
            scrollableYearDropdown={true}
            maxDate={new Date()}
            yearDropdownItemNumber={100}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Input
            register={register}
            name="placeOfBirth"
            error={errors.placeOfBirth}
            label={`${sectionNumber}.7 ${t('candidateForm.labels.placeOfBirth')}`}
            scrollableYearDropdown={true}
            maxDate={new Date()}
            yearDropdownItemNumber={100}
          />
        </FormGroup>
        <FormGroup>
          <Select
            name="citizenships"
            error={errors.citizenships}
            label={`${sectionNumber}.8 ${t('candidateForm.labels.citizenships')}`}
            selectFunctions={formFunctions}
            options={countriesMappedList}
            defaultValue={defaultFormState && setDefalutMultiSelectValue(defaultFormState.citizenships)}
            disabled={editingLocked}
            isSearchable
            isMulti
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Input
            register={register}
            name="phoneNumber"
            error={errors.phoneNumber}
            label={`${sectionNumber}.9 ${t('candidateForm.labels.phoneNumberIncludingCountryCode')}`}
          />
        </FormGroup>
        <FormGroup>
          <label>{`${sectionNumber}.10 ${t('candidateForm.labels.passport')}`}</label>
          <MultipleFilesUploader
            label={t('common.uploadScan')}
            name="passport"
            errors={errors.passport}
            formFunctions={formFunctions}
            defaultFiles={defaultPassportValue}
            disabled={editingLocked}
            tooltipMessage={documentMessage[watch('countryOfIdNumber')]}
          />
        </FormGroup>
      </FormRow>
    </>
  );
};

GeneralInfoSection.propTypes = {
  defaultFormState: PropTypes.object.isRequired,
  formFunctions: PropTypes.object.isRequired,
  defaultPassportValue: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      number: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
    })
  ),
  editingLocked: PropTypes.bool.isRequired,
};

GeneralInfoSection.defaultProps = {
  defaultCountry: {
    value: '',
    label: '',
  },
  defaultPassportValue: null,
};

export default GeneralInfoSection;
