import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Paragraph } from 'components/Shared/sharedStyle';
import { TitleButton, AccordionContent } from 'components/Messages/styleMessages';

const FloatingMessageAccordion = ({ render }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState('0px');

  const contentRef = useRef(null);
  const animateTransitionRef = useRef(true);

  useEffect(() => {
    setHeight(isOpen ? `${contentRef.current.scrollHeight}px` : '0px');
  }, [isOpen]);

  const setHeightOnChange = () => setHeight(`${contentRef.current.scrollHeight}px`);

  const handleClocking = () => {
    animateTransitionRef.current = true;
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div>
      <AccordionContent animateTransition={animateTransitionRef.current} ref={contentRef} height={height}>
        {render(setHeightOnChange, animateTransitionRef)}
      </AccordionContent>
      <TitleButton isOpen={isOpen} onClick={handleClocking}>
        <Paragraph uppercase>{t('message.newMessage')}</Paragraph>
      </TitleButton>
    </div>
  );
};

FloatingMessageAccordion.propTypes = {
  candidate: PropTypes.object,
  render: PropTypes.func,
};

FloatingMessageAccordion.defaultProps = {
  setHeightOnParent: null,
};

export default FloatingMessageAccordion;
