import * as yup from 'yup';
import { createSimpleRequiredError } from 'utils/createRequiredError';
import i18n from 'i18n';

const t = i18n.t;

export const customerFormNames = {
  FcompanyName: 'name',
};

export const customerSchema = yup.lazy(() => {
  const sectionValidation = yup.array().of(
    yup.object().shape({
      number: yup.boolean(),
      numberOfYears: yup
        .number()
        .nullable()
        .test('test-number-of-years', createSimpleRequiredError('Year'), function testNumberOfYears() {
          if (this.parent?.number && !this.parent?.numberOfYears) return false;
          return true;
        }),
    })
  );

  return yup.object().shape({
    [customerFormNames.FcompanyName]: yup.string().required(createSimpleRequiredError(customerFormNames.FcompanyName)),
    personalInformation: sectionValidation,
    workplaces: sectionValidation,
  });
});

export const atLeastOneCheckboxSelected = (formData) => {
  let numberOfSelectedCheckboxes = 0;

  Object.entries(formData).forEach(([, value]) => {
    if (Array.isArray(value)) {
      value.forEach((val) => val?.number && numberOfSelectedCheckboxes++);
    }
  });

  return numberOfSelectedCheckboxes !== 0;
};

export const mapSaveCustometer = (formData) => {
  Object.entries(formData).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      const newValuesArray = [];

      value.forEach((item, index) => {
        if (item?.number) newValuesArray.push({ number: index + 1, numberOfYears: item.numberOfYears });
      });

      formData[key] = newValuesArray;
    }
  });

  return formData;
};

export const mapGetCustometerData = (res) => {
  Object.entries(res).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      const newValuesArray = [];

      for (let i = 0; i < res.numberOfLevels; i++) {
        let valueToPush = null;

        value.forEach((item) => {
          if (item.number === i + 1) {
            valueToPush = item;
          }
        });

        valueToPush ? newValuesArray.push(valueToPush) : newValuesArray.push({});
      }
      res[key] = newValuesArray;
    }
  });

  return res;
};

export const getCustomerSections = () => [
  {
    label: `1. ${t('candidateViewSection.personalInformation')}`,
    name: 'personalInformation',
  },
  {
    label: `2. ${t('candidateViewSection.education')}`,
    name: 'education',
  },
  {
    label: `3. ${t('candidateViewSection.workplaces')}`,
    name: 'workplaces',
  },
  {
    label: `4. ${t('candidateForm.conflictOfInterest.title')}`,
    name: 'conflictOfInterest',
  },
  {
    label: `5. ${t('candidateViewSection.legal')}`,
    name: 'legal',
  },
  {
    label: `6. ${t('candidateViewSection.sanctionsList')}`,
    name: 'sanctionsList',
  },
  {
    label: `7. ${t('candidateViewSection.economy')}`,
    name: 'economy',
  },
  {
    label: `8. ${t('candidateViewSection.companies')}`,
    name: 'companies',
  },
  {
    label: `9. ${t('candidateViewSection.drivingLicense')}`,
    name: 'drivingLicense',
  },
  {
    label: `10. ${t('candidateViewSection.media')}`,
    name: 'media',
  },
  {
    label: `11. ${t('candidateViewSection.household')}`,
    name: 'household',
  },
];
