import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import icons from 'constants/icons';
import hasPermission from 'utils/hasPermission';
import roles from 'constants/roles';
import { IconButton, Div } from 'components/Shared/sharedStyle';
import { Input, Select, MultipleFilesUploader, FormCheckbox } from 'components/Shared/sharedComponents';
import { CheckboxRequiredDiv, EditCountriesRow } from 'components/EditCountries/stylesEditCountires';
import { SectionName } from 'components/CandidateForm/styleCandidateForm';
import {
  FieldTypes,
  editCountriesFormNames,
  getFieldTypes,
  getDefaultSelectValue,
  getGroupNameInCountriesObj,
} from 'components/EditCountries/configEditCountries';

const {
  Fcountries,
  FfieldType,
  FfieldName,
  Ffields,
  Ftemplate,
  FisRequired,
  FhideOnReport,
  FelectronicSignature,
  FelectronicSignatureId,
} = editCountriesFormNames;

const EditField = ({ formFunctions, remove, groupId, index }) => {
  const { t } = useTranslation();
  const groupName = getGroupNameInCountriesObj(groupId);
  const { register, errors, watch } = formFunctions;

  const errorsReference = (field) => errors[Ffields] && errors[Ffields][groupId] && errors[Ffields][groupId][field];

  const type = watch(`${groupName}.${FfieldType}`);
  const templates = watch(`${groupName}.${Ftemplate}`);
  const electronicSignature = watch(`${groupName}.${FelectronicSignature}`);

  // console.log(templates);
  return (
    <>
      <SectionName>
        <h3>{`${t(`${Fcountries}.field`)} ${index + 1}`}</h3>
        {remove && <IconButton onClick={() => remove(groupId)} icon={icons.delete_bin} />}
      </SectionName>
      <EditCountriesRow>
        <Div>
          <Input
            label={t(`${Fcountries}.${FfieldName}`)}
            name={`${groupName}.${FfieldName}`}
            register={register}
            error={errorsReference(FfieldName)}
          />
        </Div>
        <Div>
          <Select
            label={t(`${Fcountries}.${FfieldType}`)}
            name={`${groupName}.${FfieldType}`}
            error={errorsReference(FfieldType)}
            selectFunctions={formFunctions}
            options={getFieldTypes()}
            defaultValue={getDefaultSelectValue(type)}
            disabled={!hasPermission([roles.admin, roles.investigator])}
            minWidth="0"
          />
        </Div>
        <CheckboxRequiredDiv>
          <FormCheckbox
            label={t(`${Fcountries}.${FisRequired}`)}
            name={`${groupName}.${FisRequired}`}
            register={register}
            error={errorsReference(`${FisRequired}`)}
          />
          {type === FieldTypes.text && (
            <FormCheckbox
              label={t(`${Fcountries}.${FhideOnReport}`)}
              name={`${groupName}.${FhideOnReport}`}
              register={register}
              error={errorsReference(`${FhideOnReport}`)}
            />
          )}
        </CheckboxRequiredDiv>
      </EditCountriesRow>
      <EditCountriesRow style={{ display: type === FieldTypes.uploadDownload ? 'flex' : 'none' }}>
        <Div position="relative">
          {/* position relative is needed for the error message to be displayed correctly */}
          <label>{t(`${Fcountries}.${Ftemplate}`)}</label>
          <MultipleFilesUploader
            label={t(`${Fcountries}.uploadField`)}
            name={`${groupName}.${Ftemplate}`}
            errors={errorsReference(`${Ftemplate}`)}
            formFunctions={formFunctions}
            fileGroup={index}
            defaultFiles={templates ? templates : type === FieldTypes.uploadDownload ? null : []} // empty array resets files state when upload button is hidden. I need to have uploaded documents stored so I have ids to remove them later
            disabled={!hasPermission([roles.admin, roles.investigator])}
            uploadOnlyOneFile
            width="auto"
          />
        </Div>
        <Div>
          <FormCheckbox
            label={t(`${Fcountries}.${FelectronicSignature}`)}
            name={`${groupName}.${FelectronicSignature}`}
            register={register}
            error={errorsReference(`${FelectronicSignature}`)}
            marginTop="-17px"
            marginMobile="0px"
          />
          <Input
            label={t(`${Fcountries}.${FelectronicSignatureId}`)}
            name={`${groupName}.${FelectronicSignatureId}`}
            register={register}
            error={errorsReference(FelectronicSignatureId)}
            disabled={!electronicSignature}
          />
        </Div>
        <Div />
      </EditCountriesRow>
    </>
  );
};

EditField.propTypes = {
  formFunctions: PropTypes.object.isRequired,
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired,
  remove: PropTypes.func,
  certificatesToRemoveFromView: PropTypes.arrayOf(PropTypes.string),
};

export default EditField;
