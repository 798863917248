import React from 'react';

import UserNavigation from './UserNavigation';
import { HeaderContainer } from './styles';

const Header = () => (
  <HeaderContainer>
    <UserNavigation />
  </HeaderContainer>
);

export default Header;
