import { useQuery } from '@tanstack/react-query';
import { getCandidateOwnSimpleDetails } from 'api/candidate';

export function useGetCandidateOwnSimpleDetails() {
  return useQuery({
    queryKey: ['candidateOwnSimpleDetails'],
    queryFn: async () => {
      return await getCandidateOwnSimpleDetails();
    },
  });
}
