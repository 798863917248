import { useState } from 'react';
import PropTypes from 'prop-types';
import { DatePickerCustomYearDropdownContainer } from 'components/Shared/DatePicker/DatePickerCustomYearDropdownStyle';
import 'react-datepicker/dist/react-datepicker.css';

const DatePickerCustomYearDropdown = ({
  date,
  changeYear,
  decreaseYear,
  increaseYear,
  prevYearButtonDisabled,
  nextYearButtonDisabled,
  yearDropdownItemNumber = 100,
  minDate,
  maxDate,
}) => {
  const selectedYear = date.getFullYear();

  const minYear = isNaN(minDate) || !minDate ? 1900 : minDate?.getFullYear();
  const currentYear = new Date().getFullYear();
  const maxYear = isNaN(maxDate) || !maxDate ? currentYear : maxDate?.getFullYear();

  const years = [...Array(yearDropdownItemNumber)]
    .map((_, i) => new Date().getFullYear() - i)
    .filter((year) => year >= minYear && year <= maxYear);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleYearDropdown = () => setIsDropdownOpen((current) => !current);

  const onYearSelected = (year) => {
    changeYear(year);
    toggleYearDropdown();
  };

  return (
    <DatePickerCustomYearDropdownContainer>
      <div className="header">
        {!prevYearButtonDisabled && (
          <button
            type="button"
            className="react-datepicker__navigation react-datepicker__navigation--previous"
            onClick={decreaseYear}
            aria-label="Previous Month"
          />
        )}
        {!nextYearButtonDisabled && (
          <button
            type="button"
            className="react-datepicker__navigation react-datepicker__navigation--next"
            onClick={increaseYear}
            aria-label="Next Month"
          />
        )}
        <div className="react-datepicker__header__dropdown react-datepicker__header__dropdown--scroll">
          <div className="react-datepicker__year-dropdown-container react-datepicker__year-dropdown-container--scroll">
            <div className="react-datepicker__year-read-view" onClick={toggleYearDropdown}>
              <span className="react-datepicker__year-read-view--down-arrow"></span>
              <span className="">{selectedYear}</span>
            </div>
          </div>
        </div>
        {isDropdownOpen && (
          <div className="react-datepicker__year-dropdown react-datepicker__year-dropdown--scrollable">
            {years.map((year) => (
              <div
                key={year}
                className={`react-datepicker__year-option ${
                  year === selectedYear ? 'react-datepicker__year-option--selected_year' : ''
                }`}
                onClick={() => onYearSelected(year)}
              >
                {year === selectedYear && <span className="react-datepicker__year-option--selected">✓</span>}
                {year}
              </div>
            ))}
          </div>
        )}
      </div>
    </DatePickerCustomYearDropdownContainer>
  );
};

export default DatePickerCustomYearDropdown;

DatePickerCustomYearDropdown.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  changeYear: PropTypes.func.isRequired,
  decreaseYear: PropTypes.func.isRequired,
  increaseYear: PropTypes.func.isRequired,
  prevYearButtonDisabled: PropTypes.bool.isRequired,
  nextYearButtonDisabled: PropTypes.bool.isRequired,
  yearDropdownItemNumber: PropTypes.number,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
};
