import * as yup from 'yup';

const MAX_FILE_SIZE = 1024 * 1024 * 5;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];

const dynamicMaxFileMessage = (file) => ({
  error: 'Max file size is 5mb',
  fileName: file.value.name,
});

const dynamicFileTypeMessage = (file) => ({
  error: 'Unsupported file format',
  fileName: file.value.name,
});

const createMultipleFileValidation = (requiredError, isRequired) =>
  yup
    .array()
    .of(
      yup
        .mixed()
        .required()
        .test('fileSize', dynamicMaxFileMessage, (value) => {
          if (value.id) {
            return true;
          }
          return !value ? false : value.size <= MAX_FILE_SIZE;
        })
        .test('fileType', dynamicFileTypeMessage, (value) => {
          if (value.id) {
            return true;
          }
          return !value ? false : SUPPORTED_FORMATS.includes(value.type);
        })
    )
    .nullable()
    [isRequired ? 'required' : 'notRequired'](requiredError);

export default createMultipleFileValidation;
