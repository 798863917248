import PropTypes from 'prop-types';

import { ErrorMessageFe, Label, Div } from 'components/Shared/sharedStyle';
import { CheckboxNode } from 'components/Shared/FormCheckbox/styleFormCheckbox';

const FormCheckbox = ({
  label,
  register,
  name,
  error,
  longMessage,
  onChange,
  margin,
  marginMobile,
  disabled,
  marginTop,
  paddingRight,
  width,
  backgroundColor,
  textTransform,
}) => {
  return (
    <Div
      marginTop={marginTop}
      width={width}
      backgroundColor={backgroundColor}
      paddingRight={paddingRight}
      textTransform={textTransform}
    >
      <CheckboxNode
        onChange={onChange}
        ref={register}
        name={name}
        id={name}
        hasError={!!error}
        disabled={disabled}
        type="checkbox"
        margin={margin}
        marginMobile={marginMobile}
      />
      <Label htmlFor={name}>{label}</Label>
      {error && <ErrorMessageFe longMessage={longMessage}>{error.message}</ErrorMessageFe>}
    </Div>
  );
};

FormCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  register: PropTypes.func,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  longMessage: PropTypes.bool,
  onChange: PropTypes.func,
  margin: PropTypes.string,
  marginMobile: PropTypes.string,
  disabled: PropTypes.bool,
  marginTop: PropTypes.string,
  paddingRight: PropTypes.string,
  width: PropTypes.string,
  backgroundColor: PropTypes.string,
  textTransform: PropTypes.string,
};

FormCheckbox.defaultProps = {
  error: null,
  label: '',
  longMessage: false,
  onChange: () => {},
  register: () => {},
};

export default FormCheckbox;
