import * as yup from 'yup';
import i18n from 'i18n';

export default yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

export const resetPasswordSchema = yup.object().shape({
  email: yup.string().email().required(),
});

export const setPasswordSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required(i18n.t('signIn.passwordIsRequiredMsg'))
    .min(8, i18n.t('signIn.setPasswordError'))
    .matches(/^(?=.*[a-zà-ÿ])(?=.*[A-ZÀ-Ý])(?=.*[0-9])(?=.*[!@#$%^&*])/, i18n.t('signIn.setPasswordError')),
  // (?=.*[]) - password must contain at least one of the characters in brackets
  // [a-z] - lowercase latin alphabet,
  // [a-zà-ÿ] - lowercase latin alphabet with accented characters
  // [A-ZÀ-Ý] - uppercase latin alphabet with accented characters
  confirmPassword: yup
    .string()
    .required('You must confirm the password')
    .oneOf([yup.ref('newPassword'), null], 'Passwords do not match'),
});
