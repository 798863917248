import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Link from 'components/Shared/Link';
import { Logo, LogoContainer, SidebarButton, Flexbox } from 'components/Shared/sharedStyle';
import hasPermission from 'utils/hasPermission';
import { useGotoLogoutPage } from 'hooks/useLogout';
import icons from 'constants/icons';
import roles from 'constants/roles';
import menuConfig from 'components/Sidebar/sidebarMenuConfig';
import { Nav, Icon, Hamburger, AnchorWrapper } from 'components/Sidebar/style';

const Sidebar = () => {
  const location = useLocation();
  const gotoLogoutPage = useGotoLogoutPage();

  const isMobile = useSelector((state) => state.isMobile);
  const unreadMessagesNumber = useSelector((state) => state.unreadMessagesNumber);

  const [isOpen, setHamburgerStatus] = useState(false);
  let menuPositions;

  if (hasPermission([roles.admin])) {
    menuPositions = menuConfig.admin;
  } else if (hasPermission([roles.investigator])) {
    menuPositions = menuConfig.investigator;
  } else if (hasPermission([roles.customer])) {
    menuPositions = menuConfig.customer;
  } else if (hasPermission([roles.customerSupervisor])) {
    menuPositions = menuConfig.customerSupervisor;
  } else {
    menuPositions = menuConfig.candidate;
  }

  const toggleHamburger = () => setHamburgerStatus((status) => !status);

  return (
    <>
      {isMobile && (
        <Hamburger onClick={toggleHamburger} isOpen={isOpen}>
          <span />
          <span />
          <span />
        </Hamburger>
      )}
      <Nav isOpen={isOpen}>
        <LogoContainer>
          <Logo src={icons.logoHorizontal} />
        </LogoContainer>
        {menuPositions(!!unreadMessagesNumber).map(({ id, title, href, underscored, icon }) => (
          <AnchorWrapper
            key={id}
            underscored={underscored}
            isActive={location.pathname.includes(href)}
            onClick={() => setHamburgerStatus(false)}
          >
            <Link to={href}>
              <Icon src={icon} />
              {title}
            </Link>
          </AnchorWrapper>
        ))}
        {isMobile && (
          <Flexbox justify="center">
            <SidebarButton onClick={gotoLogoutPage}>Logout</SidebarButton>
          </Flexbox>
        )}
      </Nav>
    </>
  );
};

export default Sidebar;
