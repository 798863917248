import { useEffect, useRef, useState } from 'react';

const useOnClickOutside = () => {
  const [isVisible, toggleVisibility] = useState(false);
  const ref = useRef(null);

  const onClickOutside = (event) => {
    if (ref.current && !ref.current.parentNode.contains(event.target)) {
      toggleVisibility(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', onClickOutside, true);

    return () => document.removeEventListener('click', onClickOutside, true);
  }, [isVisible]);

  return { ref, isVisible, toggleVisibility };
};

export default useOnClickOutside;
