import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import icons from 'constants/icons';
import { FormRow, FormGroup, IconButton, Divider, Flexbox } from 'components/Shared/sharedStyle';
import { Input, Select } from 'components/Shared/sharedComponents';

const AddCandidateForm = ({
  formFunctions,
  remove,
  groupId,
  hasInvestigatorAdminPermissions,
  customersOptions,
  customersMaxLevel,
}) => {
  const { t } = useTranslation();

  const { register, errors, watch } = formFunctions;

  const errorsReference = (field) => errors.candidate && errors.candidate[groupId] && errors.candidate[groupId][field];

  const selectedCustomer = watch(`candidate[${groupId}].customer`);

  const [shouldReset, setShouldReset] = useState(false);

  useEffect(() => {
    setShouldReset(true);
    setTimeout(() => setShouldReset(false));
  }, [selectedCustomer]);

  const maxLevel =
    customersMaxLevel ??
    customersOptions.find((customer) => customer?.value?.id === selectedCustomer?.id)?.value?.maxLevel;

  const levels = Array(maxLevel)
    .fill(0)
    .map((_, index) => ({ value: index + 1, label: index + 1 }));

  return (
    <>
      <Flexbox align="center">
        <Divider />
        {remove && <IconButton onClick={() => remove(groupId)} icon={icons.delete_bin} />}
      </Flexbox>
      <FormRow>
        <FormGroup>
          <Input
            register={register}
            name={`candidate[${groupId}].firstName`}
            error={errorsReference('firstName')}
            label={t('customer.labels.firstName')}
          />
        </FormGroup>
        <FormGroup>
          <Input
            register={register}
            name={`candidate[${groupId}].lastName`}
            error={errorsReference('lastName')}
            label={t('customer.labels.lastName')}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Input
            register={register}
            name={`candidate[${groupId}].email`}
            error={errorsReference('email')}
            label={t('customer.labels.email')}
          />
        </FormGroup>
        {hasInvestigatorAdminPermissions && (
          <FormGroup>
            <Select
              name={`candidate[${groupId}].customer`}
              error={errorsReference('customer')}
              selectFunctions={formFunctions}
              label={t('customer.labels.customer')}
              options={customersOptions}
            />
          </FormGroup>
        )}
        {!hasInvestigatorAdminPermissions && (
          <FormGroup>
            <Select
              name={`candidate[${groupId}].level`}
              error={errorsReference('level')}
              selectFunctions={formFunctions}
              label={t('customer.labels.level')}
              options={levels}
              resetAfterSubmit={shouldReset}
            />
          </FormGroup>
        )}
      </FormRow>
      {hasInvestigatorAdminPermissions && (
        <FormRow>
          <FormGroup>
            <Select
              name={`candidate[${groupId}].level`}
              error={errorsReference('level')}
              selectFunctions={formFunctions}
              label={t('customer.labels.level')}
              options={levels}
              disabled={!selectedCustomer}
              resetAfterSubmit={shouldReset}
            />
          </FormGroup>
          <FormGroup />
        </FormRow>
      )}
    </>
  );
};

AddCandidateForm.propTypes = {
  formFunctions: PropTypes.object.isRequired,
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  remove: PropTypes.func,
  hasInvestigatorAdminPermissions: PropTypes.bool,
  customersOptions: PropTypes.array,
  customersMaxLevel: PropTypes.number,
};

AddCandidateForm.defaultProps = {
  remove: null,
};

export default AddCandidateForm;
