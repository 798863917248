import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { confirmEmail, resetPassword } from 'api/account';
import icons from 'constants/icons';
import { Logo, ErrorMessageBe, FormGroup } from 'components/Shared/sharedStyle';
import Input from 'components/Shared/Input';
import {
  Form,
  Button,
  FormContainer,
  LoginFlexbox,
  LoginContainer,
  LogoContainer,
  PasswordIcon,
  PasswordErrorMessage,
} from 'components/Login/styleLogin';
import { setPasswordSchema } from 'components/Login/loginSchema';

const SetPasswordPage = ({ match, history, isNewCandidate }) => {
  const { register, handleSubmit, errors } = useForm({
    validationSchema: setPasswordSchema,
  });

  const { t } = useTranslation();

  const [isLoading, toggleLoading] = useState(false);
  const [backendError, setBackendError] = useState('');
  const [isPasswordVisible, setPasswordVisibility] = useState(false);

  const submitResetPasswordForm = async (formData) => {
    const { token, email } = match.params;
    toggleLoading(true);
    try {
      isNewCandidate
        ? await confirmEmail({
            ...formData,
            token,
            email,
          })
        : await resetPassword({
            ...formData,
            token,
            email,
          });

      setBackendError('');
      toggleLoading(false);
      history.push('/login');
    } catch (responseError) {
      setBackendError(responseError.message);
      toggleLoading(false);
    }
  };

  return (
    <LoginFlexbox align="center" justify="center">
      <LoginContainer>
        <LogoContainer>
          <Logo src={icons.logoVertical} />
        </LogoContainer>
        <FormContainer padding="40px 60px">
          {isNewCandidate && (
            <>
              <h3>{t('signIn.newPassword')}</h3>
              <p>
                {t('signIn.invitationMessage')} <br />
                {t('signIn.setNewPassword')}
              </p>
            </>
          )}
          <Form onSubmit={handleSubmit(submitResetPasswordForm)}>
            <FormGroup>
              <Input
                register={register}
                name="newPassword"
                placeholder={t('signIn.newPassword')}
                type={isPasswordVisible ? 'text' : 'password'}
              />
              {errors.newPassword && (
                <PasswordErrorMessage
                  width="395px"
                  left={errors.newPassword.message === t('signIn.passwordIsRequiredMsg') ? '10px' : '-66px'}
                  top="40px"
                >
                  {errors.newPassword.message.split('\n').map((item, index) => (
                    <span key={index}>{item}</span>
                  ))}
                </PasswordErrorMessage>
              )}
              <PasswordIcon
                onClick={() => setPasswordVisibility(!isPasswordVisible)}
                isPasswordVisible={isPasswordVisible}
                src={icons.visibility}
              />
            </FormGroup>
            <FormGroup>
              <Input
                register={register}
                name="confirmPassword"
                error={errors.confirmPassword}
                placeholder={t('signIn.confirmPassword')}
                type={isPasswordVisible ? 'text' : 'password'}
              />
            </FormGroup>

            <Button disabled={isLoading} type="submit">
              {t('common.submit')}
            </Button>
            {backendError && <ErrorMessageBe>{backendError}</ErrorMessageBe>}
          </Form>
        </FormContainer>
      </LoginContainer>
    </LoginFlexbox>
  );
};

SetPasswordPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isNewCandidate: PropTypes.bool,
};
SetPasswordPage.defaultProps = {
  isNewCandidate: false,
};

export default SetPasswordPage;
