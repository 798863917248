const isValueEmpty = (editorHTML) => {
  return editorHTML.replace(/>/g, '>.').split('.')[1] === '<br>'; // editor reference return '<tag><br></tag>' value when form is empty
};

export const getHtmlContentFromRef = (ref) => {
  if (!ref.current) return null;

  const editorHTML = ref.current.firstChild.innerHTML;
  return isValueEmpty(editorHTML) ? null : editorHTML;
};

export const getHtmlContentFromRefCurrent = (refCurrent) => {
  if (!refCurrent) return null;

  const editorHTML = refCurrent.firstChild.innerHTML;

  if (isValueEmpty(editorHTML)) return null;

  return editorHTML;
};
