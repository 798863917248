import React from 'react';
import i18n from 'i18n';

import { downloadFile } from 'utils/downloadFile';
import { dateToStringYearMonthDay, dateToStringYearMonth } from 'utils/dateUtils';
import { SelectedFile } from 'components/Shared/sharedStyle';
import { getCountriesList } from 'utils/countries';
import {
  getPreviousAdressBaseNumber,
  getEducationBaseNumber,
  getWorkplacesBaseNumber,
  getConflictBaseNumber,
} from 'utils/candidateFormSections';
import { getHtmlContentFromRefCurrent } from 'utils/getHtmlContent';
import { getEmplymentOptions } from 'constants/emplymentTypes';

const t = i18n.t;

export const getGeneralInformationDefinition = (personalDetails, personalInfoSectionNumber) => [
  {
    label: 'Email',
    name: 'email',
    value: <a href={`mailto:${personalDetails.email}`}>{personalDetails.email}</a>,
  },
  {
    label: (index) => `${personalInfoSectionNumber}.${index} ${t('candidateForm.labels.name')}`,
    name: 'name',
    value: personalDetails.name,
  },
  {
    label: (index) => `${personalInfoSectionNumber}.${index} ${t('candidateForm.labels.middleNames')}`,
    name: 'middleNames',
    value: personalDetails?.middleNames,
  },
  {
    label: (index) => `${personalInfoSectionNumber}.${index} ${t('candidateForm.labels.surname')}`,
    name: 'surname',
    value: personalDetails?.surname,
  },
  {
    label: (index) => `${personalInfoSectionNumber}.${index} ${t('candidateForm.labels.nationalIdNumber')}`,
    name: 'nationalIdNumber',
    value: personalDetails.nationalIdNumber,
  },
  {
    label: (index) => `${personalInfoSectionNumber}.${index} ${t('candidateForm.labels.countryOfIdNumber')}`,
    name: 'countryOfIdNumber',
    value: getCountriesList()[personalDetails.countryOfIdNumber],
  },
  {
    label: (index) => `${personalInfoSectionNumber}.${index} ${t('candidateForm.labels.dateOfBirth')}`,
    name: 'dateOfBirth',
    value: dateToStringYearMonthDay(personalDetails.dateOfBirth),
  },
  {
    label: (index) => `${personalInfoSectionNumber}.${index} ${t('candidateForm.labels.placeOfBirth')}`,
    name: 'placeOfBirth',
    value: personalDetails.placeOfBirth,
  },
  {
    label: (index) => `${personalInfoSectionNumber}.${index} ${t('candidateForm.labels.citizenships')}`,
    name: 'citizenships',
    value: personalDetails.citizenships
      .filter((countryName) => countryName)
      .toString()
      .replaceAll(',', ', '),
  },
  {
    label: (index) => `${personalInfoSectionNumber}.${index} ${t('candidateForm.labels.phoneNumber')}`,
    name: 'phoneNumber',
    value: personalDetails.phoneNumber,
  },
  {
    label: (index) => `${personalInfoSectionNumber}.${index} ${t('candidateForm.labels.passport')}`,
    name: 'passport',
    value: null,
  },
];

export const getCurrentAddressDefinition = (personalDetails, personalInfoSectionNumber) => [
  {
    label: (index) => `${personalInfoSectionNumber}.${index} ${t('candidateForm.labels.startDate')}`,
    name: 'startDate',
    value: dateToStringYearMonth(personalDetails.startDate),
  },
  {
    label: (index) => `${personalInfoSectionNumber}.${index} ${t('candidateForm.labels.street')}`,
    name: 'street',
    value: personalDetails.street,
  },
  {
    label: (index) => `${personalInfoSectionNumber}.${index} ${t('candidateForm.labels.city')}`,
    name: 'city',
    value: personalDetails.city,
  },
  {
    label: (index) => `${personalInfoSectionNumber}.${index} ${t('candidateForm.labels.zipCode')}`,
    name: 'zipCode',
    value: personalDetails.zipCode,
  },
  {
    label: (index) => `${personalInfoSectionNumber}.${index} ${t('candidateForm.labels.country')}`,
    name: 'country',
    value: getCountriesList()[personalDetails.country],
  },
];

export const getPreviousAddressesDefinition = (
  personalDetails,
  editedPersonalInformation,
  personalInfoSectionNumber
) => {
  const getNationalIdNumber = (personalDetails, address, index) => {
    if (address.noNationalIdNumber) return t('candidateForm.labels.noNationalIdNumber');

    const arrayToCheck = [
      {
        country: personalDetails.country,
        nationalIdNumber: personalDetails.nationalIdNumber,
        noNationalIdNumber: personalDetails.noNationalIdNumber,
      },
    ];
    arrayToCheck.push(...personalDetails.previousAddresses.slice(0, index));

    const alreadyProvidedCountry = arrayToCheck.find((prevAdress) => prevAdress.country === address.country);

    if (alreadyProvidedCountry)
      return alreadyProvidedCountry?.nationalIdNumber
        ? alreadyProvidedCountry?.nationalIdNumber
        : t('candidateForm.labels.noNationalIdNumber');

    return address.nationalIdNumber;
  };

  return personalDetails.previousAddresses.map((address, index) => {
    const addressBase = getPreviousAdressBaseNumber(index);
    const prevAdress = `previous[${editedPersonalInformation.previousAddresses[index]?.id}]`;

    return [
      {
        label: `${personalInfoSectionNumber}.${addressBase + 1} ${t('candidateForm.labels.startDate')}`,
        name: `${prevAdress}.startDate`,
        value: dateToStringYearMonth(address.startDate),
      },
      {
        label: `${personalInfoSectionNumber}.${addressBase + 2} ${t('candidateForm.labels.endDate')}`,
        name: `${prevAdress}.endDate`,
        value: dateToStringYearMonth(address.endDate),
      },
      {
        label: `${personalInfoSectionNumber}.${addressBase + 3} ${t('candidateForm.labels.street')}`,
        name: `${prevAdress}.street`,
        value: address.street,
      },
      {
        label: `${personalInfoSectionNumber}.${addressBase + 4} ${t('candidateForm.labels.city')}`,
        name: `${prevAdress}.city`,
        value: address.city,
      },
      {
        label: `${personalInfoSectionNumber}.${addressBase + 5} ${t('candidateForm.labels.zipCode')}`,
        name: `${prevAdress}.zipCode`,
        value: address.zipCode,
      },
      {
        label: `${personalInfoSectionNumber}.${addressBase + 6} ${t('candidateForm.labels.country')}`,
        name: `${prevAdress}.country`,
        value: getCountriesList()[address.country],
      },
      {
        label: `${personalInfoSectionNumber}.${addressBase + 7} ${t('candidateForm.labels.nationalIdNumber')}`,
        name: `${prevAdress}.nationalIdNumber`,
        value: getNationalIdNumber(personalDetails, address, index),
      },
    ];
  });
};

export const getEducationDefinition = (education, files, eduSectionNumber) => {
  return education.map((education, index) => {
    const eduBaseNumber = getEducationBaseNumber(index);

    return [
      {
        label: `${eduSectionNumber}.${index * eduBaseNumber + 1} ${t('candidateForm.labels.highestDegree')}`,
        value: education.highestDegree,
      },
      {
        label: `${eduSectionNumber}.${index * eduBaseNumber + 2} ${t('candidateForm.labels.fieldOfStudy')}`,
        value: education.fieldOfStudy,
      },
      {
        label: `${eduSectionNumber}.${index * eduBaseNumber + 3} ${t('candidateForm.labels.startDate')}`,
        value: dateToStringYearMonth(education.startDate),
      },
      {
        label: `${eduSectionNumber}.${index * eduBaseNumber + 4} ${t('candidateForm.labels.endDate')}`,
        value: dateToStringYearMonth(education.endDate),
      },
      {
        label: `${eduSectionNumber}.${index * eduBaseNumber + 5} ${t('candidateForm.labels.universityName')}`,
        value: education.universityName,
      },
      {
        label: `${eduSectionNumber}.${index * eduBaseNumber + 6} ${t('candidateForm.labels.universityCountry')}`,
        value: education.universityCountry,
      },
      {
        label: `${eduSectionNumber}.${index * eduBaseNumber + 7} ${t('candidateForm.labels.street')}`,
        value: education.universityStreet,
      },
      {
        label: `${eduSectionNumber}.${index * eduBaseNumber + 8} ${t('candidateForm.labels.zipCode')}`,
        value: education.universityZipCode,
      },
      {
        label: `${eduSectionNumber}.${index * eduBaseNumber + 9} ${t('candidateForm.labels.city')}`,
        value: education.universityCity,
      },
      {
        label: `${eduSectionNumber}.${index * eduBaseNumber + 10} ${t('candidateForm.labels.universityPhoneNumber')}`,
        value: education.universityPhoneNumber,
      },
      {
        label: `${eduSectionNumber}.${index * eduBaseNumber + 11} ${t('candidateForm.labels.universityWebsiteUrl')}`,
        value: education.universityWebsiteUrl,
      },
      {
        label: `${eduSectionNumber}.${index * eduBaseNumber + 12} ${t('candidateForm.labels.diplomaReceivedDate')}`,
        value: dateToStringYearMonth(education.diplomaReceivedDate),
      },
      {
        label: `${eduSectionNumber}.${index * eduBaseNumber + 13} ${t('candidateForm.labels.degree')}`,
        value: (
          <>
            {files.Degree &&
              files.Degree.filter((degree) => degree.group === education.fileGroup).map((file) => (
                <SelectedFile key={file.id} onClick={() => downloadFile({ id: file.id, fileName: file.name })} download>
                  {file.name}
                </SelectedFile>
              ))}
          </>
        ),
      },
    ];
  });
};

export const getLatestWorkplaceDefinition = (workplaces, workplacesSectionNumber, t) =>
  workplaces.map((workplace, index) => {
    const workBaseNumber = getWorkplacesBaseNumber(index);

    const canWeContactYourWorkplace = () => {
      if (workplace.yesContactWorkplace) return t('common.yes');
      if (workplace.dontContactWorkplace) return t('common.no');

      return t('candidateForm.labels.didntAnwser');
    };

    const standardWorkplaceFields = [
      {
        label: `${workplacesSectionNumber}.${workBaseNumber + 1} ${t('candidateForm.labels.companyName')}`,
        value: workplace.companyName,
      },
      {
        label: `${workplacesSectionNumber}.${workBaseNumber + 2} ${t('candidateForm.labels.jobPosition')}`,
        value: workplace.jobPosition,
      },
      {
        label: `${workplacesSectionNumber}.${workBaseNumber + 3} ${t('candidateForm.labels.companyAddress')}`,
        value: workplace.companyAddress,
      },
      {
        label: `${workplacesSectionNumber}.${workBaseNumber + 4} ${t('candidateForm.labels.placeOfWork')}`,
        value: workplace.placeOfWork,
      },
      {
        label: `${workplacesSectionNumber}.${workBaseNumber + 5} ${t('candidateForm.labels.employmentType')}`,
        value: getEmplymentOptions(t).find((option) => option?.value === workplace?.employmentType)?.label,
      },
      {
        label: `${workplacesSectionNumber}.${workBaseNumber + 6} ${t('candidateForm.labels.comments')}`,
        value: workplace.comments,
      },
      {
        label: `${workplacesSectionNumber}.${workBaseNumber + 7} ${t('candidateForm.labels.startDate')}`,
        value: dateToStringYearMonth(workplace.startDate),
      },
      {
        label: `${workplacesSectionNumber}.${workBaseNumber + 8} ${t('candidateForm.labels.endDate')}`,
        value: workplace.currentEmployment
          ? t('candidateForm.labels.currentEmployment')
          : dateToStringYearMonth(workplace.endDate),
      },
      {
        label: `${t('candidateForm.labels.canWeContactYourWorkplace')}`,
        value: canWeContactYourWorkplace(),
      },
    ];

    const payslips = [
      {
        label: `${t('candidateForm.labels.payslips')}`,
        name: 'payslips',
      },
    ];

    const currentWorkplaceContactInfo = [
      {
        label: `${workplacesSectionNumber}.${workBaseNumber + 9} ${t(
          'candidateForm.labels.companyPhoneNumberSwitchboard'
        )}`,
        value: workplace.companyPhoneNumberSwitchboard,
      },
      {
        label: `${workplacesSectionNumber}.${workBaseNumber + 10} ${t(
          'candidateForm.labels.companyPhoneNumberHrDepartment'
        )}`,
        value: workplace.companyPhoneNumberHrDepartment,
      },
      {
        label: `${workplacesSectionNumber}.${workBaseNumber + 11} ${t('candidateForm.labels.supervisorName')}`,
        value: workplace.supervisorName,
      },
      {
        label: `${workplacesSectionNumber}.${workBaseNumber + 12} ${t('candidateForm.labels.supervisorPhone')}`,
        value: workplace.supervisorPhone,
      },
      {
        label: `${workplacesSectionNumber}.${workBaseNumber + 13} ${t('candidateForm.labels.supervisorEmail')}`,
        value: workplace.supervisorEmail,
      },
    ];

    return [
      ...standardWorkplaceFields,
      ...(workplace.dontContactWorkplace ? payslips : []),
      ...(!workplace.currentEmployment || workplace.yesContactWorkplace ? currentWorkplaceContactInfo : []),
    ];
  });

export const getAssignmentsDefinition = (assignments, conflictSectionNumber) =>
  assignments.map((assignment, index) => {
    const conflictBaseNumber = getConflictBaseNumber(index);

    return [
      {
        label: `${conflictSectionNumber}.${conflictBaseNumber + 1} ${t('candidateForm.labels.company')}`,
        value: assignment.company,
      },
      {
        label: `${conflictSectionNumber}.${conflictBaseNumber + 2} ${t('candidateForm.labels.typeOfAssignment')}`,
        value: assignment.typeOfAssignment,
      },
    ];
  });

export const getCandidateCountires = (personalInformation) => {
  let userCountries = [personalInformation.country];
  personalInformation.previousAddresses.forEach((previous) => userCountries.push(previous.country));
  userCountries = [...new Set(userCountries)]; // removes duplicates

  return userCountries;
};

export const textBoxesRefsNames = {
  personalDetailsHeader: 'personalDetailsHeader',
  personalDetailsComment: 'personalDetailsComment',
  educationHeader: 'educationHeader',
  educationComment: 'educationComment',
  workplaceHeader: 'workplaceHeader',
  workplaceComment: 'workplaceComment',
  conflictOfInterest: 'conflictOfInterest',
  legalComment: 'legalComment',
  sanctionsListComment: 'sanctionsListComment',
  economyHeader: 'economyHeader',
  economyComment: 'economyComment',
  companiesComment: 'companiesComment',
  drivingLicenseComment: 'drivingLicenseComment',
  mediaComment: 'mediaComment',
  householdComment: 'householdComment',
  generalSummary: 'generalSummary',
};

export const getEvaluationContract = (passedEvaluation, textBoxesRefs) => {
  return passedEvaluation && textBoxesRefs
    ? {
        personalInformation: {
          evaluation: passedEvaluation.personalInformation,
          header: getHtmlContentFromRefCurrent(textBoxesRefs.current[textBoxesRefsNames.personalDetailsHeader]),
          comment: getHtmlContentFromRefCurrent(textBoxesRefs.current[textBoxesRefsNames.personalDetailsComment]),
        },
        education: {
          evaluation: passedEvaluation.education,
          header: getHtmlContentFromRefCurrent(textBoxesRefs.current[textBoxesRefsNames.educationHeader]),
          comment: getHtmlContentFromRefCurrent(textBoxesRefs.current[textBoxesRefsNames.educationComment]),
        },
        workplace: {
          evaluation: passedEvaluation.workplaces,
          header: getHtmlContentFromRefCurrent(textBoxesRefs.current[textBoxesRefsNames.workplaceHeader]),
          comment: getHtmlContentFromRefCurrent(textBoxesRefs.current[textBoxesRefsNames.workplaceComment]),
        },
        legal: {
          evaluation: passedEvaluation.legal,
          comment: getHtmlContentFromRefCurrent(textBoxesRefs.current[textBoxesRefsNames.legalComment]),
        },
        sanctionsList: {
          evaluation: passedEvaluation.sanctionsList,
          header: getHtmlContentFromRefCurrent(textBoxesRefs.current[textBoxesRefsNames.sanctionsListHeader]),
          comment: getHtmlContentFromRefCurrent(textBoxesRefs.current[textBoxesRefsNames.sanctionsListComment]),
        },
        economy: {
          evaluation: passedEvaluation.economy,
          header: getHtmlContentFromRefCurrent(textBoxesRefs.current[textBoxesRefsNames.economyHeader]),
          comment: getHtmlContentFromRefCurrent(textBoxesRefs.current[textBoxesRefsNames.economyComment]),
        },
        companies: {
          evaluation: passedEvaluation.companies,
          comment: getHtmlContentFromRefCurrent(textBoxesRefs.current[textBoxesRefsNames.companiesComment]),
        },
        drivingLicenseEvaluation: {
          evaluation: passedEvaluation.drivingLicense,
          comment: getHtmlContentFromRefCurrent(textBoxesRefs.current[textBoxesRefsNames.drivingLicenseComment]),
        },
        media: {
          evaluation: passedEvaluation.media,
          comment: getHtmlContentFromRefCurrent(textBoxesRefs.current[textBoxesRefsNames.mediaComment]),
        },
        household: {
          evaluation: passedEvaluation.household,
          comment: getHtmlContentFromRefCurrent(textBoxesRefs.current[textBoxesRefsNames.householdComment]),
        },
        generalSummary: getHtmlContentFromRefCurrent(textBoxesRefs.current[textBoxesRefsNames.generalSummary]),
      }
    : null;
};
