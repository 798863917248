import * as yup from 'yup';
import createRequiredError from 'utils/createRequiredError';
import i18n from '../../i18n';

const requiredError = createRequiredError('customer.labels');

const emailError = i18n.t('errors.email');

export const customerUserSchemaAsGp = yup.object().shape({
  firstName: yup.string().required(requiredError),
  lastName: yup.string().required(requiredError),
  email: yup.string().email(emailError).required(requiredError),
  role: yup.string().required(requiredError),
  companyName: yup.string().required(requiredError),
});

export const customerUserSchema = yup.object().shape({
  firstName: yup.string().required(requiredError),
  lastName: yup.string().required(requiredError),
  email: yup.string().email(emailError).required(requiredError),
  role: yup.string().required(requiredError),
});

export const candidateSchemaAsCustomer = yup.lazy((form) => {
  const { candidate } = form;

  const candidatesValidation = {};

  Object.keys(candidate).forEach((id) => {
    candidatesValidation[id] = yup.object().shape({
      firstName: yup.string().required(requiredError),
      lastName: yup.string().required(requiredError),
      email: yup.string().email(emailError).required(requiredError),
      level: yup.string().required(requiredError),
    });
  });

  return yup.object().shape({
    candidate: yup.object().shape({
      ...candidatesValidation,
    }),
  });
});

export const candidateSchemaAsInvestigator = yup.lazy((form) => {
  const { candidate } = form;

  const candidatesValidation = {};

  Object.keys(candidate).forEach((id) => {
    candidatesValidation[id] = yup.object().shape({
      firstName: yup.string().required(requiredError),
      lastName: yup.string().required(requiredError),
      email: yup.string().email(emailError).required(requiredError),
      level: yup.string().nullable().required(requiredError),
      customer: yup.object().required(requiredError),
    });
  });

  return yup.object().shape({
    candidate: yup.object().shape({
      ...candidatesValidation,
    }),
  });
});
