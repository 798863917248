import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Table,
  TableHead,
  TableHeadCell,
  TableBody,
  Pagination,
  Arrow,
  ArrowLastElement,
  NoDataMessage,
  TableRow,
} from 'components/Shared/Grid/styleGrid';
import { Flexbox, Div } from 'components/Shared/sharedStyle';
import { filterTypes, filterForm, candidateListDefaultOption } from 'constants/filters-form-consts';
import useDispatchNotification from 'components/Shared/Notification/DispatchNotificationHook';
import usePrevious from 'hooks/usePrevious';
import FiltersForm from 'components/Shared/Grid/FiltersForm';
import { Loader } from 'components/Shared/Loader/Loaders';
import { useUrlQuery, queryNames } from 'hooks/useUrlQuery';
import { useSelector } from 'react-redux';

const Grid = ({
  headlines,
  rowsPerPage,
  fetchDataFunction,
  onFirstRenderFunction,
  filtersToRemember,
  mapperFunction,
  translationsKey,
  filterType,
  onRowClick,
  refreshMapperDependency,
  triggerUpdate,
  candidateId,
  children,
}) => {
  const { t } = useTranslation();
  const { dispatchErrorNotification } = useDispatchNotification();
  const countriesMappedList = useSelector((state) => state.countries.countriesMappedList);
  const { getUrlQuery, setUrlQuery } = useUrlQuery();
  const urlQuery = getUrlQuery();

  const [totalRows, setTotalRows] = useState(null);
  const [sortingBy, setSorting] = useState({ id: 'lastname', direction: 'asc' }); // don't set default "id: ''" because it will brake first fetch
  const [body, setBody] = useState([]);
  const [plainBody, setPlainBody] = useState([]);

  const [activePage, setPage] = useState(1);

  useEffect(() => {
    urlQuery[queryNames.page] && setPage(parseInt(urlQuery[queryNames.page]));
  }, [urlQuery]);

  const savePage = useCallback(
    (pageNumber) => {
      setPage(pageNumber);
      filtersToRemember &&
        filtersToRemember[queryNames.page] &&
        setUrlQuery({ name: queryNames.page, value: pageNumber });
    },
    [filtersToRemember, setUrlQuery]
  );

  const rangeFilterInitState = () => {
    const range = urlQuery[queryNames.range];
    if (range && filterType === filterTypes.candidates) {
      return filterForm[filterType].find((option) => option.label === range);
    }

    if (filterType === filterTypes.candidates) return candidateListDefaultOption;

    return {};
  };

  const [rangeFilter, setRangeFilter] = useState(rangeFilterInitState());
  const prevRangeFilter = usePrevious(rangeFilter);
  const [searchText, setSearchText] = useState(urlQuery[queryNames.search] ?? '');
  const prevSearchText = usePrevious(searchText);
  const [countries, setCountries] = useState(
    urlQuery[queryNames.countries]?.length
      ? urlQuery[queryNames.countries].map((country) => countriesMappedList.find((item) => item.value === country))
      : []
  );
  const prevCountries = usePrevious(countries);
  const [paginationEnabled, togglePagination] = useState(false);

  const [loading, setLoading] = useState(false);

  const didGetSortingId = useRef(false);
  const tableHeadRef = useRef(null);

  const firstRenderFunc = useCallback(async () => {
    setLoading(true);

    try {
      const res = await onFirstRenderFunction();

      const [id, direction] = res.split('_');

      if (id || direction) {
        setSorting({
          id: id ?? 'lastname',
          direction: direction ?? 'asc',
        });
      }
    } catch (catchedError) {
      dispatchErrorNotification({ catchedError });
    }

    didGetSortingId.current = true; // must be after await and before setLoading
    setLoading(false);
  }, [dispatchErrorNotification, onFirstRenderFunction]);

  useEffect(() => {
    if (onFirstRenderFunction && didGetSortingId.current === false) {
      firstRenderFunc();
    }
  }, [firstRenderFunc, onFirstRenderFunction]);

  const shouldPreventFetch = useMemo(() => {
    if (prevRangeFilter !== rangeFilter || prevSearchText !== searchText || prevCountries !== countries) {
      if (activePage !== 1) {
        savePage(1);
        return true;
      }
    }
    return false;
  }, [activePage, countries, prevCountries, prevRangeFilter, prevSearchText, rangeFilter, searchText, savePage]);

  const fetchData = useCallback(async () => {
    if (shouldPreventFetch) return;
    setLoading(true);

    try {
      const response = await fetchDataFunction({
        sortOrder: sortingBy.direction === 'asc' ? sortingBy.id : `${sortingBy.id}_${sortingBy.direction}`,
        pageNumber: activePage,
        pageSize: rowsPerPage,
        searchName: searchText,
        ...rangeFilter.value,
        Countries: countries.map((country) => country.value),
      });

      const { data = response, resultsTotal } = response;

      if (rowsPerPage && rowsPerPage < resultsTotal) {
        setTotalRows(resultsTotal);
        togglePagination(true);
      } else {
        togglePagination(false);
      }

      if (candidateId) {
        data.forEach((message) => {
          candidateId === message.receiver.id
            ? (message.investigatorIsSender = true)
            : (message.investigatorIsSender = false);
        });
      }

      setBody(mapperFunction ? mapperFunction({ data, setBody }) : data);
      setPlainBody(data);
    } catch (catchedError) {
      dispatchErrorNotification({ catchedError });
      setBody([]);
    }

    setLoading(false);
  }, [
    shouldPreventFetch,
    fetchDataFunction,
    sortingBy,
    activePage,
    rowsPerPage,
    searchText,
    rangeFilter.value,
    countries,
    candidateId,
    mapperFunction,
    dispatchErrorNotification,
  ]);

  useEffect(() => {
    if (!onFirstRenderFunction || (onFirstRenderFunction && didGetSortingId.current === true)) {
      fetchData();
    }
  }, [fetchData, onFirstRenderFunction, triggerUpdate]);

  useEffect(() => {
    if (mapperFunction && plainBody.length) {
      setBody(mapperFunction({ data: plainBody, setBody }));
    }
  }, [refreshMapperDependency, mapperFunction, plainBody]);

  const numberOfPages = Math.ceil(totalRows / rowsPerPage);

  const sortingDirection = (currentSorting, id) => {
    if (currentSorting.id === id) {
      return currentSorting.direction === 'desc' ? 'asc' : 'desc';
    }

    return 'asc';
  };

  const changeSorting = (id, sortable) => {
    if (sortable) {
      setSorting((currentState) => {
        const newState = { id, direction: sortingDirection(currentState, id) };

        return newState;
      });
    }
  };

  const onArrowClick = (direction) => {
    if ((activePage === 1 && direction === 'left') || (activePage === numberOfPages && direction === 'right')) {
      return null;
    }

    if (direction === 'first') return savePage(1);
    if (direction === 'last') return savePage(numberOfPages);

    return savePage(direction === 'left' ? activePage - 1 : activePage + 1);
  };

  return (
    <>
      <Flexbox justify={!filterType && !children ? 'flex-end' : 'space-between'} align="center">
        <Flexbox>
          {children}
          {filterType && (
            <FiltersForm
              rangeFilter={rangeFilter}
              setRangeFilter={setRangeFilter}
              searchText={searchText}
              setSearchText={setSearchText}
              countries={countries}
              setCountries={setCountries}
              filterType={filterType}
            />
          )}
        </Flexbox>
        {paginationEnabled && (
          <Pagination>
            <ArrowLastElement direction="left" onClick={() => onArrowClick('first')} />
            <Arrow direction="left" onClick={() => onArrowClick('left')} />
            <Flexbox justify="center">{t('filtersForm.pageOfPages', { activePage, numberOfPages })}</Flexbox>
            <Arrow direction="right" onClick={() => onArrowClick('right')} />
            <ArrowLastElement direction="right" onClick={() => onArrowClick('last')} />
          </Pagination>
        )}
      </Flexbox>
      <Div position="relative" width="100%">
        {loading && (
          <Loader
            fullScreen={false}
            position="absolute"
            top={`${45 + tableHeadRef.current?.clientHeight > 85 ? 45 + tableHeadRef.current?.clientHeight : 85}px`}
            scale={0.9}
          />
        )}
      </Div>
      <Table>
        <TableHead ref={tableHeadRef}>
          <tr>
            {headlines.map(({ id, sortable }) => (
              <TableHeadCell
                key={id}
                sortable={sortable}
                direction={sortingBy.direction}
                isActive={sortingBy.id === id}
                onClick={() => changeSorting(id, sortable)}
              >
                {t(`${translationsKey}.${id}`)}
              </TableHeadCell>
            ))}
          </tr>
        </TableHead>
        <TableBody>
          {body.length ? (
            body.map((row) => (
              <TableRow
                key={row.id}
                onClick={onRowClick ? () => onRowClick(row) : null}
                hasAction={!!onRowClick}
                disabled={row.disabled}
              >
                {headlines.map(({ id, width }) => (
                  <td key={id} style={{ width: width || 'auto' }}>
                    {row[id]}
                  </td>
                ))}
              </TableRow>
            ))
          ) : (
            <tr>
              <td>{!loading && <NoDataMessage>{t('common.noData')}</NoDataMessage>}</td>
            </tr>
          )}
        </TableBody>
      </Table>
    </>
  );
};

Grid.propTypes = {
  headlines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      sortable: PropTypes.bool,
    }).isRequired
  ).isRequired,
  fetchDataFunction: PropTypes.func.isRequired,
  onFirstRenderFunction: PropTypes.func,
  rowsPerPage: PropTypes.number,
  mapperFunction: PropTypes.func,
  filtersToRemember: PropTypes.objectOf(PropTypes.bool),
  translationsKey: PropTypes.string.isRequired,
  filterType: PropTypes.oneOf([filterTypes.candidates, filterTypes.statistics]),
  onRowClick: PropTypes.func,
  refreshMapperDependency: PropTypes.arrayOf(PropTypes.string),
  candidateId: PropTypes.string,
  triggerUpdate: PropTypes.number,
  children: PropTypes.node,
};

Grid.defaultProps = {
  mapperFunction: null,
  rowsPerPage: null,
  isFiltersEnabled: false,
  children: null,
  onRowClick: null,
  refreshMapperDependency: null,
  triggerUpdate: 0,
};

export default Grid;
