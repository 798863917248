import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import icons from 'constants/icons';
import { IconButton } from 'components/Shared/sharedStyle';
import { Portal, ModalBody } from './style';

const WithModal = ({ children, onClose, isOpen }) => {
  if (!isOpen) return null;

  const ModalComponent = (
    <Portal>
      <ModalBody>
        <IconButton onClick={onClose} icon={icons.close} />
        {children}
      </ModalBody>
    </Portal>
  );

  return createPortal(ModalComponent, document.querySelector('#root'));
};

WithModal.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default WithModal;
