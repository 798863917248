import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { changeLanguageAction, redirectFromLoginPage } from 'store/actions';
import icons from 'constants/icons';
import { signIn } from 'api/account';
import { Input, ErrorMessageFe, ErrorMessageBe, FormGroup, TextButton, Span } from 'components/Shared/sharedStyle';
import { Form, Button, PasswordIcon } from 'components/Login/styleLogin';
import loginSchema from 'components/Login/loginSchema';
import { saveUserInfoToLocalStorage } from 'components/Login/loginService';
import { saveTokensToLocalStorage } from 'utils/authService';
import hasPermission from 'utils/hasPermission';
import { Loader } from 'components/Shared/Loader/Loaders';

const LoginForm = ({ toggleForm }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { automaticLogout } = useParams();

  const { register, handleSubmit, errors } = useForm({
    validationSchema: loginSchema,
  });

  const [isLoading, toggleLoading] = useState(false);
  const [backendError, setBackendError] = useState('');
  const [isPasswordVisible, setPasswordVisibility] = useState(false);

  const submitLoginForm = async (formData) => {
    toggleLoading(true);

    try {
      const { locale, roles, displayName, customerName, tokens } = await signIn({
        ...formData,
      });

      saveTokensToLocalStorage(tokens);
      saveUserInfoToLocalStorage({ formData, roles, displayName, customerName });

      dispatch(redirectFromLoginPage()); // Prevents refreshing token in App component
      if (locale !== 'en') dispatch(changeLanguageAction(locale));

      if (hasPermission([roles.investigator, roles.customer, roles.customerSupervisor])) {
        history.push('/candidates');
      }
      if (hasPermission([roles.candidate])) {
        history.push('/welcome');
      }
    } catch (err) {
      setBackendError(!err?.message || err?.message?.includes('undefined') ? 'An error occurred' : err?.message);
      toggleLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <h3>{t('signIn.title')}</h3>

      {automaticLogout && (
        <Span maxWidth="380px" paddingBottom="10px">
          <Trans i18nKey={'signIn.inactivityMessage'} />
        </Span>
      )}

      <Form onSubmit={handleSubmit(submitLoginForm)}>
        <FormGroup>
          <Input ref={register} name="email" placeholder="Email" hasError={!!errors.email} />
          {errors.email && <ErrorMessageFe>{errors.email.message}</ErrorMessageFe>}
        </FormGroup>
        <FormGroup>
          <Input
            ref={register}
            name="password"
            placeholder="Password"
            type={isPasswordVisible ? 'text' : 'password'}
            hasError={!!errors.password}
          />
          <PasswordIcon
            onClick={() => setPasswordVisibility(!isPasswordVisible)}
            isPasswordVisible={isPasswordVisible}
            src={icons.visibility}
          />
          {errors.password && <ErrorMessageFe>{errors.password.message}</ErrorMessageFe>}
        </FormGroup>
        <Button disabled={isLoading} marginTop="5px" type="submit">
          {t('signIn.logIn')}
        </Button>
        {backendError && <ErrorMessageBe>{backendError}</ErrorMessageBe>}
      </Form>
      <TextButton onClick={toggleForm}>{t('signIn.forgotPassword')}</TextButton>
    </>
  );
};

LoginForm.propTypes = {
  toggleForm: PropTypes.func.isRequired,
};

export default LoginForm;
