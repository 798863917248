import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import colors from 'constants/colors';
import { evaluationItemType, referenceType, personalDetailsType, personalDetailsFilesTypes } from 'constants/types';
import { downloadFile } from 'utils/downloadFile';
import getUsedCountiresFields from 'utils/getUsedCountiresFields';
import { getMatchingCountryValue, setDefalutMultiSelectValue } from 'utils/countries';
import { getPersonalInfoSectionNum, getAdditionalFieldLabel } from 'utils/candidateFormSections';
import { yearMonthDatePickerFormat } from 'utils/dateUtils';
import { SelectedFile, Divider, Flexbox, Div } from 'components/Shared/sharedStyle';
import { WysiwygEditor, Input, DatePicker, Select, FormCheckbox } from 'components/Shared/sharedComponents';
import {
  mapDefaultPersonalDetailsValues,
  additionalFieldsPrefix,
  additionalFieldName,
  additionalFieldError,
} from 'components/CandidateForm/Form/configCandidateForm';
import { SectionTitle, ThreeColumnDetailRow } from 'components/CandidateView/styleCandidateView';
import StatusButtons from 'components/CandidateView/StatusButtons';
import { FieldTypes } from 'components/EditCountries/configEditCountries';
import {
  getGeneralInformationDefinition,
  getCurrentAddressDefinition,
  getPreviousAddressesDefinition,
  getCandidateCountires,
  textBoxesRefsNames,
} from 'components/CandidateView/configCandidateView';
import AdditionalFieldsFile from 'components/CandidateView/sections/PersonalDetails/AdditionalFieldsFile';

const PersonalDetails = ({
  personalInformation,
  editedPersonalInformation,
  requiredNumberOfYears,
  formFunctions,
  setLastSavedPersonalDetails,
  files,
  textBoxesRefs,
  defaultEvaluation,
  evaluation,
  updateEvaluationStatus,
}) => {
  const { t } = useTranslation();
  const countriesList = useSelector((state) => state.countries.countriesList);
  const countriesMappedList = useSelector((state) => state.countries.countriesMappedList);
  const sectionNumber = getPersonalInfoSectionNum() + 1;
  const countriesAdditionalFields = useSelector((state) => state.countriesAdditionalFields);

  const { register, reset, errors } = formFunctions;

  const generalInformationDefinition = getGeneralInformationDefinition(personalInformation, sectionNumber);
  const currentAddressDefinition = getCurrentAddressDefinition(personalInformation, sectionNumber);
  const previousAddressesDefinition = getPreviousAddressesDefinition(
    personalInformation,
    editedPersonalInformation,
    sectionNumber
  );

  const displayLabel = ({ label, index }) => (typeof label === 'function' ? label(index) : label);

  const additionalFieldsDefinition = useMemo(() => {
    const uniqueCountries = getCandidateCountires(personalInformation);

    return getUsedCountiresFields(uniqueCountries, countriesAdditionalFields);
  }, [personalInformation, countriesAdditionalFields]);

  useEffect(() => {
    if (!editedPersonalInformation) return;

    const mappedEditedPersonalInfo = mapDefaultPersonalDetailsValues(editedPersonalInformation);
    reset(mappedEditedPersonalInfo);

    setLastSavedPersonalDetails();
  }, [reset, editedPersonalInformation, setLastSavedPersonalDetails]);

  const sectionTitle = t('candidateForm.personalDetails.title');

  return (
    <>
      <h2>{`${sectionNumber}. ${sectionTitle}`}</h2>
      <WysiwygEditor
        defaultValue={defaultEvaluation.header}
        editorRef={textBoxesRefs}
        saveRefInAnotherRef
        name={textBoxesRefsNames.personalDetailsHeader}
        sectionTitle={`${sectionTitle} ${t('common.header')}`}
      />
      {generalInformationDefinition.map(({ label, value, name }, index) => (
        <ThreeColumnDetailRow key={index}>
          <span>{displayLabel({ label, index })}</span>
          {name === 'passport' ? (
            <Div>
              {files?.Passport
                ? files?.Passport.map((file) => (
                    <SelectedFile
                      onClick={() => downloadFile({ id: file.id, fileName: file.name })}
                      download
                      key={file.name}
                    >
                      {file.name}
                    </SelectedFile>
                  ))
                : '-'}
            </Div>
          ) : (
            <span>{value}</span>
          )}
          {name === 'email' || name === 'passport' ? (
            <span></span>
          ) : name === 'countryOfIdNumber' ? (
            <Select
              name="countryOfIdNumber"
              error={errors.countryOfIdNumber}
              selectFunctions={formFunctions}
              options={countriesMappedList}
              defaultValue={getMatchingCountryValue(editedPersonalInformation.countryOfIdNumber)}
              isClearable
            />
          ) : name === 'dateOfBirth' ? (
            <DatePicker
              name="dateOfBirth"
              error={errors.dateOfBirth}
              formFunctions={formFunctions}
              onChange={([date]) => date}
              scrollableYearDropdown={true}
              maxDate={new Date()}
              yearDropdownItemNumber={100}
              isClearable
            />
          ) : name === 'citizenships' ? (
            <Select
              name="citizenships"
              error={errors.citizenships}
              selectFunctions={formFunctions}
              options={countriesMappedList}
              defaultValue={setDefalutMultiSelectValue(editedPersonalInformation.citizenships)}
              isSearchable
              isMulti
            />
          ) : (
            <Input register={register} name={name} error={errors[name]} />
          )}
        </ThreeColumnDetailRow>
      ))}
      <Flexbox gap="15px" align="center">
        <h3>{t('candidateForm.personalDetails.currentAddress')}</h3>
        <FormCheckbox
          label={t('candidateForm.labels.hideAddressOnReport')}
          name={`hideAddressOnReport`}
          register={register}
          backgroundColor={colors.primaryGray}
          paddingRight="10px"
          textTransform="none"
        />
      </Flexbox>
      {currentAddressDefinition.map(({ label, value, name }, index) => (
        <ThreeColumnDetailRow key={index}>
          <span>{displayLabel({ label, index: index + generalInformationDefinition.length })}</span>
          <span>{value}</span>
          {name === 'startDate' ? (
            <DatePicker
              name="startDate"
              dateFormat={yearMonthDatePickerFormat}
              error={errors.startDate}
              formFunctions={formFunctions}
              onChange={([date]) => date}
              scrollableYearDropdown={true}
              maxDate={new Date()}
              yearDropdownItemNumber={100}
              isClearable
            />
          ) : name === 'country' ? (
            <Select
              name="country"
              error={errors.country}
              selectFunctions={formFunctions}
              options={countriesMappedList}
              defaultValue={getMatchingCountryValue(editedPersonalInformation.country)}
              isClearable
            />
          ) : (
            <Input register={register} name={name} error={errors[name]} />
          )}
        </ThreeColumnDetailRow>
      ))}
      {!!previousAddressesDefinition.length && (
        <h3>
          {t('candidateForm.personalDetails.previousAddresses', {
            requiredYears: requiredNumberOfYears?.personalInformation,
          })}
        </h3>
      )}
      {previousAddressesDefinition?.map((section, index) => (
        <React.Fragment key={index}>
          <SectionTitle>
            <h4>{t('candidateForm.personalDetails.sectionName', { index: index + 1 })}</h4>
            <FormCheckbox
              label={t('candidateForm.labels.hideAddressOnReport')}
              name={`previous[${editedPersonalInformation.previousAddresses[index]?.id}].hideAddressOnReport`}
              register={register}
              backgroundColor={colors.primaryGray}
              paddingRight="10px"
              textTransform="none"
            />
          </SectionTitle>
          {section.map((field, fieldIndex) => (
            <ThreeColumnDetailRow key={fieldIndex}>
              <span>{field.label}</span>
              <span>{field.value}</span>
              {fieldIndex <= 1 ? (
                <DatePicker
                  name={field.name}
                  dateFormat={yearMonthDatePickerFormat}
                  error={errors[field.name]}
                  formFunctions={formFunctions}
                  onChange={([date]) => date}
                  scrollableYearDropdown={true}
                  maxDate={new Date()}
                  yearDropdownItemNumber={100}
                  isClearable
                />
              ) : fieldIndex === 5 ? (
                <Select
                  name={field.name}
                  error={errors[field.name]}
                  selectFunctions={formFunctions}
                  options={countriesMappedList}
                  defaultValue={getMatchingCountryValue(editedPersonalInformation.previousAddresses[index]?.country)}
                  isClearable
                />
              ) : (
                <Input register={register} name={field.name} error={errors[field.name]} />
              )}
            </ThreeColumnDetailRow>
          ))}
        </React.Fragment>
      ))}
      {!!additionalFieldsDefinition?.length && <h3>{t('candidateForm.personalDetails.additionalFields')}</h3>}
      {additionalFieldsDefinition?.map((section, sectionIndex) =>
        section.map((field, fieldIndex) => (
          <div key={fieldIndex === 0 ? field : field.id}>
            {typeof field === 'string' && (
              <SectionTitle>
                <h4>{countriesList[field]}</h4>
              </SectionTitle>
            )}
            {typeof field !== 'string' && (
              <ThreeColumnDetailRow>
                <span>
                  {getAdditionalFieldLabel({
                    sectionNumber,
                    numberOfPreviousAdresses: previousAddressesDefinition.length,
                    sections: additionalFieldsDefinition,
                    sectionIndex,
                    fieldIndex,
                    fieldName: field.name,
                  })}
                </span>
                <span>
                  {field.type === FieldTypes.text && (
                    <span>
                      {
                        personalInformation[additionalFieldsPrefix]?.find((fieldData) => fieldData.fieldId === field.id)
                          ?.contents
                      }
                    </span>
                  )}
                  <AdditionalFieldsFile field={field} files={files} />
                </span>
                <span>
                  {field.type === FieldTypes.text && (
                    <Input
                      register={register}
                      name={additionalFieldName(field)}
                      error={additionalFieldError(field, errors)}
                    />
                  )}
                </span>
              </ThreeColumnDetailRow>
            )}
          </div>
        ))
      )}
      <WysiwygEditor
        margin="10px 0px"
        defaultValue={defaultEvaluation.comment}
        editorRef={textBoxesRefs}
        saveRefInAnotherRef
        name={textBoxesRefsNames.personalDetailsComment}
        sectionTitle={`${sectionTitle} ${t('common.footer')}`}
      />
      <StatusButtons value={evaluation} evaluationKey="personalInformation" onClick={updateEvaluationStatus} />
      <Divider />
    </>
  );
};

PersonalDetails.propTypes = {
  personalInformation: personalDetailsType.isRequired,
  editedPersonalInformation: PropTypes.object.isRequired,
  requiredNumberOfYears: PropTypes.object.isRequired,
  formFunctions: PropTypes.object.isRequired,
  setLastSavedPersonalDetails: PropTypes.func.isRequired,
  textBoxesRefs: referenceType.isRequired,
  defaultEvaluation: evaluationItemType.isRequired,
  evaluation: PropTypes.number,
  files: personalDetailsFilesTypes.isRequired,
  updateEvaluationStatus: PropTypes.func.isRequired,
};

export default PersonalDetails;
