import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { routesCreators } from 'constants/routes';
import { getMessagesHistory } from 'api/messages';
import { hideNewMessagesNotification } from 'store/actions';
import { Flexbox, Button } from 'components/Shared/sharedStyle';
import { WithModal } from 'components/Shared/sharedComponents';
import Grid from 'components/Shared/Grid/Grid';
import MessagePreview from 'components/Messages/MessagePreview';
import { MessagesContainer, MessagesListColumn } from 'components/Messages/styleMessages';
import { messagesHistoryHeadlines, receivedMessagesMapper } from 'components/Messages/configMessages';

const Messages = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { candidateId, candidateName, candidateSurName } = useParams();

  const isMobile = useSelector((state) => state.isMobile);
  const unreadMessagesNumber = useSelector((state) => state.unreadMessagesNumber);

  const [selectedMessage, setSelectedMessage] = useState(null);
  const [openedMessages, addNewOpenedMessage] = useState([]);
  const [isMobilePreviewVisible, setMobilePreviewStatus] = useState(false);

  useEffect(() => {
    if (openedMessages.length === unreadMessagesNumber) {
      dispatch(hideNewMessagesNotification());
    }
  }, [openedMessages, unreadMessagesNumber, dispatch]);

  const selectMessage = (message) => {
    if (isMobile) setMobilePreviewStatus(true);

    setSelectedMessage({
      subject: message.plainSubject ? message.plainSubject : message.subject,
      body: message.body,
      date: message.plainDate ? message.plainDate : message.date,
      id: message.id,
      isViewed: message.isViewed,
      isReceived: message.isReceived,
      attachments: message.attachments,
    });
  };

  const receivedMapper = useCallback(
    ({ data: messages }) => receivedMessagesMapper({ messages, openedMessages, Trans }),
    [openedMessages]
  );

  return (
    <MessagesContainer>
      <Button onClick={() => history.push(routesCreators.candidate(candidateId))} marginBottom="10px">
        {t('message.goBackToCandidateView')}
      </Button>
      <h2>{t('message.candidatesMessagesHistory', { candidateName: `${candidateName} ${candidateSurName}` })}</h2>
      <Flexbox>
        <MessagesListColumn>
          <Grid
            headlines={messagesHistoryHeadlines}
            fetchDataFunction={async (params) => getMessagesHistory(params, candidateId)}
            mapperFunction={receivedMapper}
            translationsKey="messagesGrid"
            onRowClick={selectMessage}
            refreshMapperDependency={openedMessages}
            rowsPerPage={15}
            candidateId={candidateId}
          />
        </MessagesListColumn>
        {!isMobile && selectedMessage && (
          <MessagePreview
            message={selectedMessage}
            addNewOpenedMessage={addNewOpenedMessage}
            openedMessages={openedMessages}
          />
        )}
        {isMobile && selectedMessage && (
          <WithModal isOpen={isMobilePreviewVisible} onClose={() => setMobilePreviewStatus(false)}>
            <MessagePreview
              message={selectedMessage}
              addNewOpenedMessage={addNewOpenedMessage}
              openedMessages={openedMessages}
            />
          </WithModal>
        )}
      </Flexbox>
    </MessagesContainer>
  );
};

export default Messages;
