import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import roles from 'constants/roles';
import { getCandidates, getCandidatesForCustomer } from 'api/candidate';
import { getSavedParameter } from 'api/account';
import useRequestHandler from 'api/RequestHandlerHook';
import { filterTypes } from 'constants/filters-form-consts';
import hasPermission from 'utils/hasPermission';
import Grid from 'components/Shared/Grid/Grid';
import { CandidatesContainer } from 'components/CandidatesList/styleCandidatesList';
import CandidatesEmailsButton from './CandidatesEmailsButton';
import {
  headlines,
  customerHeadlines,
  candidatesMapper,
  customerCandidatesMapper,
} from 'components/CandidatesList/configCandidatesList';
import { queryNames } from 'hooks/useUrlQuery';

const CandidatesList = () => {
  const { t } = useTranslation();
  const requestHandler = useRequestHandler();

  const triggerUpdateWithModal = useSelector((state) => state.modal.triggerUpdateWithModal);

  const request = async (func) =>
    await requestHandler({ requestFunction: func(), successMsg: t('notifications.sent') });

  return (
    <CandidatesContainer>
      {hasPermission([roles.investigator]) ? (
        <>
          <h2>{t('dashboardTitle')}</h2>
          <Grid
            headlines={headlines}
            fetchDataFunction={getCandidates}
            onFirstRenderFunction={() => getSavedParameter({ parameter: 'GetCandidates_SortOrder' })}
            filtersToRemember={{ [queryNames.page]: true }} // don't need to pass other filters because other components don't use them
            mapperFunction={({ data }) => candidatesMapper({ data, request })}
            rowsPerPage={15}
            translationsKey="candidatesGrid"
            filterType={filterTypes.candidates}
          >
            <CandidatesEmailsButton />
          </Grid>
        </>
      ) : (
        <>
          <h2>{t('customer.myCandidates')}</h2>
          <Grid
            headlines={customerHeadlines}
            fetchDataFunction={getCandidatesForCustomer}
            mapperFunction={({ data }) => customerCandidatesMapper({ data })}
            rowsPerPage={15}
            translationsKey="candidatesGrid"
            triggerUpdate={triggerUpdateWithModal}
          />
        </>
      )}
    </CandidatesContainer>
  );
};

export default CandidatesList;
