import { dateToStringYearMonthDayHoursMin } from 'utils/dateUtils';
import { Flexbox } from 'components/Shared/sharedStyle';
import { setDefaultMessageAddressee } from 'api/investigator';
import { Checkbox } from 'components/Shared/sharedComponents';
import { triggerUpdateWithModal } from 'store/actions';
import store from 'store/store';

export const investigatorsHeadlines = [
  { id: 'name', width: '25%' },
  { id: 'email', width: '25%' },
  { id: 'activeCandidates', width: '15%' },
  { id: 'lastLogin' },
  { id: 'messageAddressee' },
];

const assignDefaultMessageAddressee = async ({ investigators, investigatorId, setBody }) => {
  let clickedAlreadySelectedInvestigator = false;

  investigators.forEach((investigator) => {
    investigator.disabled = true; // block all chechboxes, they will be unblocked after triggerUpdateWithModal
    if (investigator.id === investigatorId) {
      if (investigator.defaultMessageAddressee) return (clickedAlreadySelectedInvestigator = true);

      investigator.defaultMessageAddressee = true;
    } else {
      investigator.defaultMessageAddressee = false;
    }
  });

  if (clickedAlreadySelectedInvestigator) return;

  setBody(investigatorsMapper({ data: investigators, setBody }));
  await setDefaultMessageAddressee(investigatorId);
  store.dispatch(triggerUpdateWithModal()); // doesn't work without this
};

export const investigatorsMapper = ({ data: investigators, setBody }) =>
  investigators.map((investigator) => ({
    id: investigator.id,
    name: <b>{`${investigator.name} ${investigator.surname}`}</b>,
    email: investigator.email,
    activeCandidates: investigator.activeCandidates,
    lastLogin: dateToStringYearMonthDayHoursMin(investigator.lastLogin),
    messageAddressee: (
      <Flexbox justify="center">
        <Checkbox
          name={`checkbox-${investigator.id}`}
          value={investigator.defaultMessageAddressee}
          onChange={() => assignDefaultMessageAddressee({ investigators, investigatorId: investigator.id, setBody })}
          disabled={investigator.disabled}
        />
      </Flexbox>
    ),
  }));
