import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import getUsedCountiresFields from 'utils/getUsedCountiresFields';
import { personalDetailsFilesTypes } from 'constants/types';
import { scriveFileFinishedLoading } from 'store/actions';
import {
  getPreviouslyUploadedFile,
  setPreviouslyUploadedFile,
  getUploadedAdditionalFileId,
  setUploadedAdditionalFileId,
} from 'utils/localStorage';
import { Divider } from 'components/Shared/sharedStyle';
import {
  extractPreviousAdressess,
  sortAndAggregateMonthsByCountry,
  filterDatesByRequiredYears,
  mergeOverlappingDatesByCountry,
  removeCountiresWithLessThanMinNumberOfMonths,
} from 'utils/candidateFormAdditionalFieldsDateUtils';
import AdditionalFieldsForm from './AdditionalFieldsForm';

const AdditionalFieldsSection = ({
  formFunctions,
  editingLocked,
  defaultFiles,
  setLastSavedFormState,
  findChangesAndSaveCandidateForm,
  requiredYears,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isScriveFileLoading = useSelector((state) => state.isScriveFileLoading);
  const countriesAdditionalFields = useSelector((state) => state.countriesAdditionalFields);
  const countriesList = useSelector((state) => state.countries.countriesList);

  const [selectedCountriesCodes, setSelectedCountriesCodes] = useState([]);
  const [isFileSignedWithScriveLoading, setIsFileSignedWithScriveLoading] = useState(false);
  const [countiresSections, setCountiresFields] = useState(null);

  const { getValues, watch } = formFunctions;

  const watched = useMemo(
    () =>
      watch([
        ...Object.keys(getValues()).filter((key) => key === 'country' || key.includes('.country')),
        'previousAddressesWatcher',
      ]),
    [getValues, watch]
  );

  useEffect(() => {
    const form = getValues();

    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    const currentAddress = {
      startDate: form.startDate,
      endDate: firstDayOfMonth,
      country: form.country,
    };

    const previosAddressesArray = extractPreviousAdressess(form);

    const addressArray = [currentAddress, ...previosAddressesArray];

    const filteredAddressArray = filterDatesByRequiredYears(addressArray, requiredYears);

    const mergedAddressArray = mergeOverlappingDatesByCountry(filteredAddressArray);

    const countriesList = sortAndAggregateMonthsByCountry(mergedAddressArray);

    const minNumberOfMonths = 6;
    const countriesCodes = removeCountiresWithLessThanMinNumberOfMonths(countriesList, minNumberOfMonths);

    setSelectedCountriesCodes(countriesCodes);
  }, [requiredYears, getValues, watched]);

  const getCountryFields = useCallback(() => {
    const fields = getUsedCountiresFields(selectedCountriesCodes, countriesAdditionalFields);

    if (fields) setCountiresFields(fields);
  }, [countriesAdditionalFields, selectedCountriesCodes]);

  useEffect(() => {
    getCountryFields();
  }, [getCountryFields]);

  const startScriveLoader = useCallback(() => {
    setTimeout(() => {
      if (
        getValues({ nest: true }).additionalFields &&
        getPreviouslyUploadedFile() ===
          JSON.stringify(getValues({ nest: true }).additionalFields[getUploadedAdditionalFileId()])
      ) {
        setIsFileSignedWithScriveLoading(true);
        setPreviouslyUploadedFile(null);
        setUploadedAdditionalFileId(null);
      }
    }, 0);
  }, [getValues]);

  useEffect(() => {
    startScriveLoader();
  }, [startScriveLoader]);

  useEffect(() => {
    if (isScriveFileLoading) {
      setLastSavedFormState();
      setIsFileSignedWithScriveLoading(false);
      dispatch(scriveFileFinishedLoading());
    }
  }, [dispatch, isScriveFileLoading, setLastSavedFormState]);

  return (
    <div>
      {!!countiresSections?.length && (
        <>
          <Divider />
          <h3>
            {t('candidateForm.personalDetails.additionalFieldsDescription', {
              countries: countiresSections.map((code) => countriesList[code[0]]).join(', '),
            })}
          </h3>
        </>
      )}

      {defaultFiles && countiresSections?.length
        ? countiresSections?.map((countrySection, index) => (
            <AdditionalFieldsForm
              key={countrySection[0]}
              sectionIndex={index}
              countiresSections={countiresSections}
              formFunctions={formFunctions}
              defaultFiles={defaultFiles}
              editingLocked={editingLocked}
              isFileSignedWithScriveLoading={isFileSignedWithScriveLoading}
              findChangesAndSaveCandidateForm={findChangesAndSaveCandidateForm}
            />
          ))
        : null}
    </div>
  );
};

AdditionalFieldsSection.propTypes = {
  formFunctions: PropTypes.object.isRequired,
  editingLocked: PropTypes.bool.isRequired,
  defaultFiles: personalDetailsFilesTypes,
  setLastSavedFormState: PropTypes.func.isRequired,
  findChangesAndSaveCandidateForm: PropTypes.func.isRequired,
  requiredYears: PropTypes.number,
};

AdditionalFieldsSection.defaultProps = {
  defaultFiles: {},
};

export default AdditionalFieldsSection;
