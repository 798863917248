import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getDocumentPins } from 'api/customer';
import hasPermission from 'utils/hasPermission';
import roles from 'constants/roles';
import { FullWidthContainer } from 'components/Shared/sharedStyle';

const DocumentPins = () => {
  const [documentPin, setDocumentPin] = useState(null);
  const [printingPin, setPrintingPin] = useState(null);

  const hasSupervisorPermission = hasPermission([roles.customerSupervisor]);

  useEffect(() => {
    (async () => {
      const { printingPin, documentPin } = await getDocumentPins();
      if (hasSupervisorPermission) {
        setDocumentPin(documentPin);
        setPrintingPin(printingPin);
      } else {
        setDocumentPin(documentPin);
      }
    })();
  }, [hasSupervisorPermission]);

  const { t } = useTranslation();

  return (
    <FullWidthContainer>
      {documentPin ? (
        <>
          <h3>{`${t('customer.documentPin')}: ${documentPin}`}</h3>
          {hasSupervisorPermission && <h3>{`${t('customer.printingPin')}: ${printingPin}`}</h3>}
        </>
      ) : (
        <h3>{t('customer.noPins')}</h3>
      )}
    </FullWidthContainer>
  );
};

export default DocumentPins;
