import React from 'react';
import { dateToStringYearMonthDayHoursMin } from 'utils/dateUtils';

export const headlines = [
  { id: 'name', sortable: true, width: '34%' },
  { id: 'subject', sortable: true, width: '34%' },
  { id: 'date', sortable: true, width: '32%' },
];

export const candidateHeadlines = [
  { id: 'subject', sortable: true, width: '65%' },
  { id: 'date', sortable: true, width: '35%' },
];

export const messagesHistoryHeadlines = [
  { id: 'subject', sortable: true, width: '50%' },
  { id: 'date', sortable: true, width: '35%' },
  { id: 'direction', width: '15%' },
];

export const receivedMessagesMapper = ({ messages, openedMessages, Trans }) =>
  messages.map((message) => {
    if (message.isViewed || openedMessages.includes(message.id) || message.investigatorIsSender) {
      return {
        name: message.sender && `${message.sender.name} ${message.sender.surname}`,
        subject: message.subject,
        date: dateToStringYearMonthDayHoursMin(message.created),
        id: message.id,
        body: message.body,
        isViewed: message.isViewed,
        isReceived: true,
        attachments: message.attachments,
        direction:
          message.investigatorIsSender !== undefined ? (
            message.investigatorIsSender ? (
              <Trans i18nKey="message.sent" />
            ) : (
              <Trans i18nKey="message.received" />
            )
          ) : null,
      };
    }

    return {
      name: message.sender && <b>{`${message.sender.name} ${message.sender.surname}`}</b>,
      subject: <b>{message.subject}</b>,
      date: <b>{dateToStringYearMonthDayHoursMin(message.created)}</b>,
      id: message.id,
      body: message.body,
      plainSubject: message.subject,
      plainDate: dateToStringYearMonthDayHoursMin(message.created),
      isViewed: message.isViewed,
      isReceived: true,
      attachments: message.attachments,
      direction:
        message.investigatorIsSender !== undefined ? (
          message.investigatorIsSender ? (
            <Trans i18nKey="message.sent" />
          ) : (
            <Trans i18nKey="message.received" />
          )
        ) : null,
    };
  });

export const sentMessagesMapper = ({ data: messages }) =>
  messages.map((message) => ({
    name: message.receiver && `${message.receiver.name} ${message.receiver.surname}`,
    subject: message.subject,
    date: dateToStringYearMonthDayHoursMin(message.created),
    id: message.id,
    body: message.body,
    isViewed: message.isViewed,
    attachments: message.attachments,
  }));

export const mapAssignedUsers = (data) =>
  data.map((user) => ({
    value: user.id,
    label: `${user.name} ${user.surname}`,
    unlockingAllowed: user.unlockingAllowed,
  }));
