import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import icons from 'constants/icons';
import { FormRow, FormGroup, IconButton } from 'components/Shared/sharedStyle';
import { Input } from 'components/Shared/sharedComponents';
import { getConflictBaseNumber, getConflictSectionNum } from 'utils/candidateFormSections';
import { SectionName } from 'components/CandidateForm/styleCandidateForm';

const AssignmentForm = ({ formFunctions, remove, groupId, index }) => {
  const { t } = useTranslation();
  const sectionNumber = getConflictSectionNum();
  const baseNumber = getConflictBaseNumber(index);

  const { register, errors } = formFunctions;

  const errorsReference = (field) =>
    errors.assignments && errors.assignments[groupId] && errors.assignments[groupId][field];

  return (
    <>
      <SectionName>
        <h3>{t('candidateForm.conflictOfInterest.sectionName', { index: index + 1 })}</h3>
        {remove && <IconButton onClick={() => remove(groupId)} icon={icons.delete_bin} />}
      </SectionName>
      <FormRow>
        <FormGroup>
          <Input
            register={register}
            name={`assignments[${groupId}].company`}
            error={errorsReference('company')}
            label={`${sectionNumber}.${baseNumber + 1} ${t('candidateForm.labels.company')}`}
          />
        </FormGroup>
        <FormGroup>
          <Input
            register={register}
            name={`assignments[${groupId}].typeOfAssignment`}
            error={errorsReference('typeOfAssignment')}
            label={`${sectionNumber}.${baseNumber + 2} ${t('candidateForm.labels.typeOfAssignment')}`}
          />
        </FormGroup>
      </FormRow>
    </>
  );
};

AssignmentForm.propTypes = {
  formFunctions: PropTypes.object.isRequired,
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired,
  remove: PropTypes.func,
};

AssignmentForm.defaultProps = {
  remove: null,
};

export default AssignmentForm;
