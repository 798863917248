import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { referenceType } from 'constants/types';
import { WysiwygEditor } from 'components/Shared/sharedComponents';
import { Divider } from 'components/Shared/sharedStyle';
import { getGeneralSummarySectionNum } from 'utils/candidateFormSections';
import { textBoxesRefsNames } from 'components/CandidateView/configCandidateView';

const GeneralSummary = ({ textBoxesRefs, evaluation }) => {
  const { t } = useTranslation();
  const sectionNumber = getGeneralSummarySectionNum() + 1;
  const sectionTitle = t('candidateViewSection.generalSummary');

  return (
    <>
      <h2>{`${sectionNumber}. ${sectionTitle}`}</h2>
      <WysiwygEditor
        defaultValue={evaluation}
        editorRef={textBoxesRefs}
        saveRefInAnotherRef
        name={textBoxesRefsNames?.generalSummary}
        sectionTitle={sectionTitle}
      />
      <Divider />
    </>
  );
};

GeneralSummary.propTypes = {
  textBoxesRefs: referenceType.isRequired,
  evaluation: PropTypes.string,
};

export default GeneralSummary;
