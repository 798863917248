import { myAxios, axiosDownloadFile } from 'api/axios';

export const getMessagesHistory = (params, id) => myAxios.get(`/candidate/${id}/messagesHistory`, { params });

export const sendMessageAsCandidate = (message) => myAxios.post('/candidate/message', message);

export const sendMessageAsGP = (message) => myAxios.post('/message/sendMessage', message);

export const markMessageViewed = (id) => myAxios.put(`/message/markMessageViewed/${id}`);

export const messagesReceived = (params) => myAxios.get('/message/messagesReceived', { params });

export const messagesSent = (params) => myAxios.get('/message/messagesSent', { params });

export const messagesReceivedByAssignedCandidates = (params) =>
  myAxios.get('message/receivedByAssignedCandidates', { params });

export const messagesSentByAssignedCandidates = (params) => myAxios.get('message/sentByAssignedCandidates', { params });

export const getAssignedCandidatesList = () => myAxios.get('/message/assignedUsersMessagingDetails');

export const getNumberOfUnreadMessages = () => myAxios.get('/message/numberOfUnreadMessages');

export const downloadAttachment = (id) => axiosDownloadFile.get(`/candidate/message/${id}`);
