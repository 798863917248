import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n';

import { Flexbox } from '../sharedStyle';
import { RadioOption } from './style';

const Radio = ({ label, register, name, options, onChange }) => (
  <>
    <label>{label}</label>
    <Flexbox>
      {options.map(({ optionLabel, value }) => (
        <RadioOption key={value}>
          <input type="radio" name={name} ref={register} value={value} id={`${name}.${value}`} onChange={onChange} />
          <label htmlFor={`${name}.${value}`}>{optionLabel}</label>
        </RadioOption>
      ))}
    </Flexbox>
  </>
);

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      optionLabel: PropTypes.string.isRequired,
    }).isRequired
  ),
  register: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func,
};

Radio.defaultProps = {
  options: [
    {
      optionLabel: i18n.t('common.yes'),
      value: 'true',
    },
    {
      optionLabel: i18n.t('common.no'),
      value: 'false',
    },
  ],
  label: '',
  register: () => {},
  onChange: () => {},
};

export default Radio;
