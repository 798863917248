import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setModalStatus } from 'store/actions';
import DeleteAllCandidateDataTemplate from 'components/Shared/Modal/Templates/DeleteAllCandidateDataTemplate';
import WithModal from 'components/Shared/Modal/WithModal';

export const modalTypes = {
  deleteAllData: 'deleteAllData',
};

// React.memo prevents running component loginc on route change
const Modal = React.memo(() => {
  const dispatch = useDispatch();
  const { isVisible, type } = useSelector((state) => state.modal);

  const closeModal = () => dispatch(setModalStatus({ isVisible: false, type: '', data: null }));

  return (
    <WithModal isOpen={isVisible} onClose={closeModal}>
      {type === modalTypes.deleteAllData && <DeleteAllCandidateDataTemplate closeModal={closeModal} />}
    </WithModal>
  );
});

export default Modal;
