import styled from 'styled-components/macro';
import { smallUp, mediumUp } from 'constants/media-queries';

export const LevelColumns = styled.div`
  display: flex;
  align-items: center;

  & > div {
    font-weight: bold;

    @media ${mediumUp} {
      width: 75px;
    }
  }

  & > span:nth-child(1) {
    width: 159px;
  }

  @media ${smallUp} {
    & > span:nth-child(1) {
      width: 212px;
    }
  }
`;
