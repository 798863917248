import styled from 'styled-components/macro';

import { mediumUp } from 'constants/media-queries';
import icons from 'constants/icons';
import colors from 'constants/colors';
import { Flexbox, ErrorMessageFe, Button, UploadInput, FormGroup } from 'components/Shared/sharedStyle';
import { MultipleFileContainer } from 'components/Shared/MultipleFilesUploader/styleMultipleFilesUploader';

export const CandidateFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  width: 100%;
  padding: 40px;

  @media (max-width: 760px) {
    padding: 30px 20px;
  }
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  margin-bottom: 30px;

  &::after {
    content: '';
    background-color: ${colors.secondaryGray};
    position: absolute;
    width: 100%;
    height: 1px;
  }
`;

export const Circle = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  z-index: 1;
  box-shadow: 0 0 0 15px ${colors.primaryGray};
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid ${({ isFilled, isActive }) => (isFilled || isActive ? colors.darkGrey : colors.secondaryGray)};
  background-color: ${({ isActive }) => (isActive ? colors.darkGrey : colors.white)};
  color: ${({ isFilled, isActive }) => (isFilled ? colors.darkGrey : isActive ? colors.white : colors.defaultFont)};

  @media (max-width: 760px) {
    width: 26px;
    height: 26px;
    font-size: 1rem;
    box-shadow: 0 0 0 4px ${colors.primaryGray};
  }
`;

export const ButtonsContainer = styled(Flexbox)`
  align-items: flex-start;
  @media (max-width: 760px) {
    ${Button} {
      padding: 7px 30px;
    }
  }
`;

export const NextStepsButtons = styled.div`
  flex-grow: 1;
  text-align: right;
  & > button {
    margin-left: 10px;
  }

  & > button:first-child {
    margin-bottom: 10px;
  }

  @media (min-width: 760px) {
    & > button {
      margin-left: 20px;
    }
  }
`;

export const AgreementsContainer = styled(Flexbox)`
  & > div:first-child {
    margin-right: 25px;
  }

  & > div:last-child {
    margin-left: 25px;
  }

  @media (max-width: 760px) {
    flex-direction: column;

    & > div:first-child {
      margin-right: 0;
    }

    & > div:last-child {
      margin-left: 0;
    }
  }

  p {
    font-size: 0.8rem;
  }
`;

export const ListOfErrorsContainer = styled.div`
  position: sticky;
  top: 20px;
  margin-top: 150px;
  margin-right: 10px;
  height: min-content;
  max-width: 300px;
  z-index: 1;

  @media (max-width: 760px) {
    bottom: 0;
    top: unset;
    background-color: ${colors.primaryGray};
    border-top: 1px solid ${colors.secondaryGray};
    max-width: unset;
    padding: 0 10px;
    margin: 0;
    height: 100vh;
    max-height: ${({ isOpen, isEditing }) => (isOpen ? 'calc(100vh - 55px)' : isEditing ? '12vh' : '0vh')};
    padding-top: ${({ isOpen }) => (isOpen ? '35px' : '0')};
    transition: all 0.3s linear;
  }
`;

export const StaticErrorMessage = styled(ErrorMessageFe)`
  position: relative;
  margin: 5px 0;
  font-size: 0.8rem;
  left: 0;
  bottom: 0;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const SectionName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  position: relative;

  &::before {
    content: '';
    width: calc(100% - 25px);
    border-bottom: 1px solid ${colors.secondaryGray};
    left: 0;
    position: absolute;
    z-index: -1;
  }

  h3,
  h4 {
    padding-right: 7px;
    background-color: ${colors.primaryGray};
  }
`;

export const AdditionalFieldsSectionName = styled(SectionName)`
  &::before {
    width: calc(100%);

    @media ${mediumUp} {
      width: calc(100% + 20px);
    }
  }
`;

export const AdditionalFieldsFormGroup = styled(FormGroup)`
  margin-bottom: ${({ isSectionTitle }) => isSectionTitle && '5px'};
`;

export const Notification = styled.h4`
  color: ${colors.error};

  @media (max-width: 760px) {
    font-size: 0.8rem;
  }
`;

export const DownloadAndUpload = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 5px;

  @media (max-width: 760px) {
    flex-direction: column;
    position: relative;

    ${({ withDownload }) =>
      withDownload &&
      `
      label {
        position: absolute;
        right: 0;
        top: 0;
      }
    `}
  }

  ${Button}, ${UploadInput} {
    padding: 7px 30px;

    @media ${mediumUp} {
      padding: 7px 40px;
    }
  }

  ${Button} {
    margin: 0 10px 5px 0;
  }

  ${UploadInput} {
    margin: 0 0 5px 0;
  }

  ${MultipleFileContainer} {
    width: 100%;
    margin-left: 10px;

    @media (max-width: 760px) {
      margin-left: 0;
    }
  }

  .files-list {
    position: relative;
  }
`;

export const MobileErrorsSwitcher = styled.div`
  width: max-content;
  position: absolute;
  top: 10px;
  left: 0%;
  padding: 5px 30px 5px 10px;
  background-color: ${colors.primaryGray};
  display: flex;
  align-items: center;
  color: ${colors.error};
  font-weight: bold;

  ${({ isOpen }) =>
    !isOpen &&
    `
    border: 1px solid ${colors.secondaryGray};
    border-bottom-color: ${colors.primaryGray};
    border-left-color: ${colors.primaryGray};
    border-radius: 0 5px 0 0;
    top: -30px;
  `}

  transition: top 0.3 linear;

  &::after {
    content: '';
    position: absolute;
    background-image: url(${icons.arrow_right});
    width: 24px;
    height: 24px;
    transform: rotate(${({ isOpen }) => (isOpen ? 270 : 90)}deg);
    right: 3px;
  }
`;

export const List = styled.div`
  @media (max-width: 760px) {
    height: ${({ isOpen }) => (isOpen ? 'min-content' : '0')};
    overflow: hidden;
  }
`;

export const SubmittingButtons = styled.div`
  display: flex;
  align-items: flex-start;

  @media (max-width: 760px) {
    flex-direction: column;

    ${NextStepsButtons} {
      margin-top: 10px;
      display: flex;
      flex-direction: column;

      ${Button} {
        margin-left: 0px;
      }
    }
  }
`;

export const FormGroupWithCheckbox = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  flex: 1;
  gap: 20px;
  margin-bottom: 25px;
`;
