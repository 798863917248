import * as types from 'store/actionTypes';
import { activityViewed } from 'api/candidate';
import { getNumberOfUnreadMessages } from 'api/messages';

export const onSignInSuccess = () => ({
  type: types.SIGN_IN_SUCCESS,
  payload: true,
});

export const onUserLogout = () => ({
  type: types.USER_LOGOUT,
});

export const changeLanguageAction = (langCode) => ({
  type: types.CHANGE_LANGUAGE,
  payload: langCode,
});

export const setCountriesAdditionalFields = (countriesAdditionalFields) => ({
  type: types.SET_COUNTRIES_ADDITIONAL_FIELDS,
  payload: countriesAdditionalFields,
});

export const showFullScreenLoader = () => ({
  type: types.SHOW_FULL_SCREEN_LOADER,
});

export const hideFullScreenLoader = () => ({
  type: types.HIDE_FULL_SCREEN_LOADER,
});

export const redirectFromLoginPage = () => ({
  type: types.REDIRECT_FROM_LOGIN_PAGE,
});

export const showNotification = (config) => ({
  type: types.SHOW_NOTIFICATION,
  payload: config,
});

export const hideNotification = () => ({
  type: types.HIDE_NOTIFICATION,
});

export const setModalStatus = ({ isVisible, type, data }) => ({
  type: types.SET_MODAL_STATUS,
  payload: {
    isVisible,
    type,
    data,
  },
});

export const findUnsavedCandidateFormChanges = (sections) => ({
  type: types.FIND_UNSAVED_CANDIDATE_FORM_CHANGES,
  payload: sections,
});

export const saveCandidateForm = (saveFormFunctions) => async (dispatch, getState) => {
  const sectionsToSave = getState().candidateFormSectionsToSave;

  // saveFormFunctions must execute one after another so app doesn't throw any error
  for (const key of Object.keys(saveFormFunctions)) {
    if (sectionsToSave[key]) await saveFormFunctions[key]();
  }

  const copiedSectionsToSave = JSON.parse(JSON.stringify(sectionsToSave));
  Object.keys(copiedSectionsToSave).forEach((key) => (copiedSectionsToSave[key] = false));

  dispatch(savedCandidateForm(copiedSectionsToSave));
};

export const savedCandidateForm = (resetedSectionsToSave) => ({
  type: types.SAVED_CANDIDATE_FORM,
  payload: resetedSectionsToSave,
});

export const triggerUpdateWithModal = () => ({
  type: types.TRIGGER_UPDATE_WITH_MODAL,
});

export const setCandidateSections = (sections) => ({
  type: types.SET_CANDIDATE_SECTIONS,
  payload: sections,
});

export const addCandidateFormErrors = (errors) => ({
  type: types.ADD_CANDIDATE_FORM_ERRORS,
  payload: errors,
});

export const clearCandidateFormErrors = () => ({
  type: types.CLEAR_CANDIDATE_FORM_ERRORS,
});

export const changeCandidateFormValidStatus = (isValid) => ({
  type: types.CHANGE_VALID_STATUS,
  payload: isValid,
});

export const changeCandidateFormSubmitStatus = (isSubmitted) => ({
  type: types.CHANGE_SUBMIT_STATUS,
  payload: isSubmitted,
});

export function markActivityAsViewed(userId) {
  return async (dispatch) => {
    await activityViewed(userId);
    dispatch({
      type: types.MARK_ACTIVITIES_AS_VIEWED,
      payload: userId,
    });
  };
}

export function checkUnreadMessages() {
  return async (dispatch) => {
    const { unreadMessages } = await getNumberOfUnreadMessages();
    dispatch({
      type: types.CHECK_UNREAD_MESSAGES,
      payload: unreadMessages,
    });
  };
}

export const hideNewMessagesNotification = () => ({
  type: types.HIDE_NEW_MESSAGES_NOTIFICATION,
});

export const onResize = () => (dispatch, getState) => {
  const isMobile = getState().isMobile;
  const width = window.innerWidth;

  if (width <= 760 && !isMobile) {
    dispatch({
      type: types.MOBILE_LAYOUT,
    });
  }

  if (width > 760 && isMobile) {
    dispatch({
      type: types.DESKTOP_LAYOUT,
    });
  }
};

export const addFileToRemoveList = (fileId) => ({
  type: types.ADD_FILE_TO_REMOVE_LIST,
  payload: fileId,
});

export const clearFilesToRemoveList = () => ({
  type: types.CLEAR_FILES_TO_REMOVE_LIST,
});

export const scriveFileLoading = () => ({
  type: types.SCRIVE_FILE_LOADING,
});

export const scriveFileFinishedLoading = () => ({
  type: types.SCRIVE_FILE_FINISHED_LOADING,
});

export const setInvitationCandidatesIds = ({ candidateId, selected }) => ({
  type: types.SET_INVITATION_CANDIDATES_IDS,
  payload: { candidateId, selected },
});
