import styled from 'styled-components/macro';
import colors from 'constants/colors';
import { basicCSSproperties } from 'components/Shared/sharedStyle';

export const MessagesContainer = styled.div`
  padding: 30px 40px;

  @media (max-width: 760px) {
    padding: 10px 20px;
  }
`;

export const TabItem = styled.div`
  width: 150px;
  margin: 20px 0;
  border-bottom: 1px solid ${colors.darkGrey};
  ${({ isActive }) =>
    isActive &&
    `
    color: ${colors.textColor};
    font-weight: bold;
    box-shadow: 0px 4px 0px ${colors.textColor};
  `}
  padding: 5px;
  text-align: center;
  cursor: pointer;

  @media (max-width: 760px) {
    margin-top: 0;
  }
`;

export const MessagePreviewContainer = styled.div`
  padding: 20px;
  background-color: ${colors.white};
  width: 50%;
  height: max-content;

  @media (max-width: 760px) {
    padding: 20px 10px 10px 10px;
    width: 100%;
    overflow-y: scroll;
  }
`;

export const MessagesListColumn = styled.div`
  margin-right: 30px;
  width: calc(50% + 30px);

  @media (max-width: 760px) {
    margin-right: 0;
    width: 100%;
  }
`;

export const FloatingMessageContainer = styled.div`
  width: 280px;
  padding: 20px;
  padding-right: 0px;
  border: 1px solid ${colors.secondaryGray};
  border-radius: 20px 20px 0 0;
  background-color: ${colors.white};
`;

export const FloatingMessageScroll = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 68vh;
  padding-right: 20px;
`;

// == Acordion styles ==
export const TitleButton = styled.button`
  width: 280px;
  position: relative;
  z-index: 2;
  background-color: ${colors.darkGrey};
  border: 1px solid ${colors.darkGrey};
  border-radius: 15px;
  color: ${colors.white};
  cursor: pointer;
  ${basicCSSproperties};

  p {
    text-align: left;
    padding-left: 22px;
    margin: 7px 0px;
  }
`;

const transitionTime = '0.3s';

export const AccordionContent = styled.div`
  position: absolute;
  bottom: 134px;
  overflow: hidden;
  max-height: ${({ height }) => height};
  transition: ${({ animateTransition }) => animateTransition && `max-height ${transitionTime} ease-in-out`};
`;
