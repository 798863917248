const icons = {
  logoHorizontal: '/icons/logo-horizontal.svg',
  logoVertical: '/icons/logo-vertical.svg',
  // search: '/icons/search-24px.svg',
  arrow_right: '/icons/arrow_right-24px.svg',
  arrow_right_last_element: '/icons/arrow_right_last_element-24px.svg',
  visibility: '/icons/visibility-24px.svg',
  description: '/icons/description-24px.svg',
  add: '/icons/add_circle-24px.svg',
  contacts: '/icons/contacts-24px.svg',
  // error: '/icons/error-24px.svg',
  help: '/icons/help-24px.svg',
  helpOutlined: '/icons/help-outlined-24px.svg',
  hireCandidate: '/icons/hire-candidate.svg',
  message: '/icons/message-24px.svg',
  unread_message: '/icons/unread_message-24px.svg',
  messageBlack: '/icons/message-black-24px.svg',
  newMessageBlack: '/icons/new-message-black-24px.svg',
  supervisor: '/icons/supervised_user-24px.svg',
  close: '/icons/close-24px.svg',
  delete_bin: '/icons/delete_outline-24px.svg',
  checkbox: '/icons/checkbox-24px.svg',
  checkboxBlank: '/icons/checkbox_blank-24px.svg',
  domain: '/icons/domain-24px.svg',
  accountCircle: '/icons/account_circle-24px.svg',
  // accountBox: '/icons/account_box-24px.svg',
  home: '/icons/home-24px.svg',
  file: '/icons/file-24px.svg',
  newFileBlack: '/icons/new-file-black-24px.svg',
  fileBlack: '/icons/file-black-24px.svg',
  fileRed: '/icons/file-red-24px.svg',
  radio: '/icons/radio_button_unchecked-24px.svg',
  radioChecked: '/icons/radio_button_checked-24px.svg',
  play: '/icons/play_circle_outline-24px.svg',
  send: '/icons/send-24px.svg',
  print: '/icons/print-24px.svg',
};

export default icons;
