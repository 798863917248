import styled from 'styled-components/macro';

import colors from 'constants/colors';
import { ErrorMessageFe, UploadInput as UploadInputBase, IconButtonRounded } from 'components/Shared/sharedStyle';
import { mediumUp } from 'constants/media-queries';

export const MultipleFileContainer = styled.div`
  padding: 2px 0;
  display: flex;
  justify-content: flex-start;
  width: ${({ width }) => (width ? width : '300px')};

  & > span {
    color: ${({ grayedText }) => grayedText && colors.secondaryGray};
  }

  &:hover {
    box-shadow: inset 0 -1px 0 ${colors.secondaryGray};
  }

  @media (max-width: 760px) {
    width: 100%;
  }
`;

export const MultipleFileErrorMessage = styled(ErrorMessageFe)`
  position: static;

  @media ${mediumUp} {
    position: absolute;
  }
`;

export const UploadInput = styled(UploadInputBase)`
  margin: ${({ margin }) => margin};
  position: relative;
  padding: ${({ narrow }) => narrow && '7px 50px'};

  ${IconButtonRounded} {
    position: absolute;
    right: -30px;
    top: 0;
  }

  // disabled styles
  cursor: ${({ disabled }) => disabled && 'default'};
  background-color: ${({ disabled }) => disabled && colors.lightGrey};
  color: ${({ disabled }) => disabled && colors.textColor};

  &:hover {
    background-color: ${({ disabled }) => disabled && colors.greyBackgroundNotClickable};
    border-color: ${({ disabled }) => disabled && colors.greyContourNotClickable};
    color: ${({ disabled }) => disabled && colors.textColor};
  }
`;
