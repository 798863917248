import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { createInvestigator } from 'api/account';
import useDispatchNotification from 'components/Shared/Notification/DispatchNotificationHook';
import { Divider, Button, FormRow, FormGroup, Container, Form, Flexbox } from 'components/Shared/sharedStyle';
import { Input } from 'components/Shared/sharedComponents';
import { addInvestigatorSchema } from 'components/Investigators/AddInvestigator/InvestigatorSchema';
import { routes } from 'constants/routes';

const AddInvestigator = () => {
  const { dispatchSuccessNotification, dispatchErrorNotification } = useDispatchNotification();
  const { t } = useTranslation();
  const history = useHistory();

  const [isLoading, setLoadingStatus] = useState('');

  const formFunctions = useForm({
    validationSchema: addInvestigatorSchema,
  });

  const { register, handleSubmit, errors } = formFunctions;

  const addNewInvestigator = async (form) => {
    setLoadingStatus(true);

    try {
      await createInvestigator({
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email,
        locale: 'en',
      });

      dispatchSuccessNotification(t('notifications.saved'));
      history.push(routes.investigatorsList);
    } catch (catchedError) {
      dispatchErrorNotification({ catchedError });
    }

    setLoadingStatus(false);
  };

  return (
    <Container>
      <h2>{t('investigator.addInvestigator')}</h2>
      <Divider />
      <Form onSubmit={handleSubmit(addNewInvestigator)}>
        <FormRow>
          <FormGroup>
            <Input
              label={t('customer.labels.firstName')}
              name="firstName"
              error={errors.firstName}
              register={register}
            />
          </FormGroup>
          <FormGroup>
            <Input label={t('customer.labels.lastName')} name="lastName" error={errors.lastName} register={register} />
          </FormGroup>
        </FormRow>
        <FormRow>
          <FormGroup>
            <Input label={t('customer.labels.email')} name="email" error={errors.email} register={register} />
          </FormGroup>
          <FormGroup />
        </FormRow>
        <Flexbox justify="flex-end">
          <Button disabled={isLoading}>{t('investigator.addInvestigator')}</Button>
        </Flexbox>
      </Form>
    </Container>
  );
};

export default AddInvestigator;
