import React, { useState, useLayoutEffect, useEffect, useRef, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import { downloadFile } from 'utils/downloadFile';
import {
  changeCandidateFormSubmitStatus,
  setModalStatus,
  showFullScreenLoader,
  hideFullScreenLoader,
  scriveFileFinishedLoading,
  clearCandidateFormErrors,
} from 'store/actions';
import { submitData, consentDocumentDownload, signConsent } from 'api/candidate';
import { uploadFiles } from 'utils/uploadMultipleFiles';
import useDispatchNotification from 'components/Shared/Notification/DispatchNotificationHook';
import { Button, Form, Divider, FormRow, FormGroup, Paragraph } from 'components/Shared/sharedStyle';
import { modalTypes, MultipleFilesUploader } from 'components/Shared/sharedComponents';
import { getSectionNumber, sections } from 'utils/candidateFormSections';
import {
  setActiveCandidateFormPage,
  setPreviouslyUploadedFile,
  getPreviouslyUploadedFile,
  getUploadedAdditionalFileId,
  setUploadedAdditionalFileId,
  getUserName,
} from 'utils/localStorage';
import { NextStepsButtons, SubmittingButtons } from 'components/CandidateForm/styleCandidateForm';
import {
  submittingDataSchema,
  submittingDataFormNames as formNames,
} from 'components/CandidateForm/Form/candidateFormSchema';

const consentSigned = 'ConsentSigned';

const SubmittingDataStep = ({
  previousStep,
  editingLocked,
  showSection,
  defaultFiles,
  fetchFiles,
  validationFunctionsArray,
  dirtySectionsChecker,
  saveFormFunctions,
}) => {
  const { dispatchSuccessNotification, dispatchErrorNotification } = useDispatchNotification();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const isCandidateFormValid = useSelector((state) => state.isCandidateFormValid);
  const isScriveFileLoading = useSelector((state) => state.isScriveFileLoading);
  const sectionNumber = getSectionNumber(sections.submittingData);

  const [isFileSignedWithScriveLoading, setIsFileSignedWithScriveLoading] = useState(false);

  const formFunctions = useForm({
    validationSchema: submittingDataSchema,
  });

  const { handleSubmit, errors, setValue, watch, getValues } = formFunctions;

  const lastSavedFormState = useRef('');

  const getFormState = useCallback(
    () =>
      JSON.stringify(getValues()[formNames.signedConsent]) + JSON.stringify(getValues()[formNames.signedScribeConsent]),
    [getValues]
  );

  // setTimeout waits till files are registerd because it happends in useEffect in MultipleFilesUploader
  const setLastSavedFormState = useCallback(
    () =>
      setTimeout(() => {
        lastSavedFormState.current = getFormState();
      }, 0),
    [getFormState]
  );

  const onSubmit = async () => {
    dispatch(showFullScreenLoader());
    const signedConsent = getValues()[formNames.signedConsent];

    try {
      await uploadFiles([[signedConsent, 'ConsentForm']]);

      const { validationErrors } = await submitData({
        consented: true,
      });

      if (validationErrors) throw validationErrors;

      await fetchFiles();

      setTimeout(() => {
        // setTimeout waits till files are registerd because it happends in useEffect in MultipleFilesUploader
        // after lastSavedFormState is updated we can go to other route
        lastSavedFormState.current = getFormState();

        dispatch(changeCandidateFormSubmitStatus(false));
        dispatch(clearCandidateFormErrors());
        dispatchSuccessNotification(t('notifications.saved'));

        setActiveCandidateFormPage(0);
        history.push('/');
      }, 0);
    } catch (catchedError) {
      dispatchErrorNotification({ catchedError });
    }

    dispatch(hideFullScreenLoader());
  };

  const uploadConsent = useCallback(async () => {
    const signedConsent = getValues()[formNames.signedConsent];

    try {
      await uploadFiles([[signedConsent, 'ConsentForm']]);
      await fetchFiles();

      dispatchSuccessNotification(t('notifications.saved'));
      setLastSavedFormState();
    } catch (catchedError) {
      dispatchErrorNotification({ catchedError });
    }
  }, [dispatchErrorNotification, dispatchSuccessNotification, fetchFiles, getValues, setLastSavedFormState, t]);

  // useLayoutEffect runs before component renders. Buttons render with correct "disable" value and will not flash
  useLayoutEffect(() => {
    if (showSection && !editingLocked) {
      dispatch(changeCandidateFormSubmitStatus(true));
      validationFunctionsArray.forEach((func) => func());

      setLastSavedFormState();

      // used to determine whether to show "Leave Guard" and then save form
      dirtySectionsChecker.submittingData = () => lastSavedFormState.current !== getFormState();
      saveFormFunctions.submittingData = uploadConsent;
    }
  }, [
    dirtySectionsChecker,
    dispatch,
    editingLocked,
    getFormState,
    getValues,
    saveFormFunctions,
    setLastSavedFormState,
    showSection,
    uploadConsent,
    validationFunctionsArray,
  ]);

  const ConsentForm = useMemo(() => {
    if (defaultFiles && defaultFiles.ConsentForm) {
      return defaultFiles.ConsentForm;
    }

    return null;
  }, [defaultFiles]);

  const onPreviousStepClick = (e) => {
    setValue('report', null);
    previousStep(e);
  };

  const downloadConsent = () => {
    downloadFile({
      customFunction: consentDocumentDownload,
      fileName: `ConsentForm-${getUserName()}.pdf`,
    });
  };

  const startScriveLoader = useCallback(() => {
    setTimeout(() => {
      if (getPreviouslyUploadedFile() === JSON.stringify(getValues()[formNames.signedScribeConsent])) {
        setIsFileSignedWithScriveLoading(true);
        setPreviouslyUploadedFile(null);
        setUploadedAdditionalFileId(null);
      }
    }, 0);
  }, [getValues]);

  useEffect(() => {
    startScriveLoader();
  }, [startScriveLoader]);

  useEffect(() => {
    if (isScriveFileLoading) {
      setLastSavedFormState();
      setIsFileSignedWithScriveLoading(false);
      dispatch(scriveFileFinishedLoading());
    }
  }, [dispatch, isScriveFileLoading, setLastSavedFormState]);

  const eSignConsent = async () => {
    dispatch(showFullScreenLoader());

    setPreviouslyUploadedFile(JSON.stringify(getValues()[formNames.signedScribeConsent]));

    try {
      const [redirectUrl] = await Promise.all([
        signConsent({ returnUrl: `${window.location.href}/${consentSigned}`, rejectUrl: window.location.href }),
        uploadConsent(),
      ]);
      window.location.href = redirectUrl;
    } catch (catchedError) {
      dispatch(hideFullScreenLoader());
      dispatchErrorNotification({ catchedError });
    }
  };

  const preventUploadFunction = () => {
    dispatchErrorNotification({
      errMsg: t('errors.alreadyUploadedFileUsingOtherMethodDeleteOldFile', { fileName: 'Consent' }),
    });
  };

  const watchSignedScribeConsent = watch(formNames.signedScribeConsent); // foreces submitt button check on file deletion
  const watchSignedConsent = watch(formNames.signedConsent);

  const isScriveConsentLoading = isFileSignedWithScriveLoading && getUploadedAdditionalFileId() === consentSigned;

  // clears "Sign with Scrive" error if user uploads file using second method
  if (watchSignedConsent) {
    errors[formNames.signedScribeConsent] = null;
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h2>{`${sectionNumber}. ${t('candidateForm.submittingData.title')}`}</h2>
        <p>
          {isCandidateFormValid
            ? t('candidateForm.submittingData.descriptionFinished')
            : t('candidateForm.submittingData.descriptionValidationErrors')}
        </p>
        <Divider small />
        <div>
          <p>{t('candidateForm.submittingData.preparingDocument')}</p>
          <FormRow>
            <FormGroup>
              <MultipleFilesUploader
                label={t('candidateForm.submittingData.sign')}
                name={formNames.signedScribeConsent}
                errors={errors[formNames.signedScribeConsent]}
                formFunctions={formFunctions}
                redirectFunction={eSignConsent}
                defaultFiles={ConsentForm && ConsentForm[0].electronicSignature ? ConsentForm : null}
                disabled={!isCandidateFormValid || editingLocked || !!watchSignedConsent}
                preventUploadField={watchSignedConsent && formNames.signedConsent}
                preventUploadFunction={watchSignedConsent && preventUploadFunction}
                showLoader={isFileSignedWithScriveLoading && getUploadedAdditionalFileId() === consentSigned}
                uploadOnlyOneFile
                canDownloadFiles
              />
            </FormGroup>
          </FormRow>
          <FormRow>
            <Paragraph marginBottom="25px">
              <Trans i18nKey="candidateForm.submittingData.instruction" />
            </Paragraph>
          </FormRow>
          <FormRow>
            <FormGroup>
              <Button
                disabled={
                  !isCandidateFormValid || watchSignedScribeConsent || watchSignedConsent || isScriveConsentLoading
                }
                onClick={downloadConsent}
                type="button"
              >
                {t('candidateForm.submittingData.downloadConsent')}
              </Button>
            </FormGroup>
            <FormGroup>
              <MultipleFilesUploader
                label={t('candidateForm.submittingData.uploadConsent')}
                name={formNames.signedConsent}
                errors={errors[formNames.signedConsent]}
                formFunctions={formFunctions}
                defaultFiles={ConsentForm && !ConsentForm[0].electronicSignature ? ConsentForm : null}
                disabled={
                  !isCandidateFormValid || editingLocked || !!watchSignedScribeConsent || !!isScriveConsentLoading
                }
                preventUploadField={watchSignedScribeConsent && formNames.signedScribeConsent}
                preventUploadFunction={watchSignedScribeConsent && preventUploadFunction}
                uploadOnlyOneFile
                canDownloadFiles
                margin="0px"
              />
            </FormGroup>
          </FormRow>
        </div>
        <Divider />
        <SubmittingButtons editingLocked={editingLocked}>
          <Button onClick={onPreviousStepClick} outlined type="button">
            {t('common.previous')}
          </Button>
          {!editingLocked && (
            <NextStepsButtons>
              <Button
                onClick={() => dispatch(setModalStatus({ isVisible: true, type: modalTypes.deleteAllData }))}
                outlined
                type="button"
              >
                {t('candidateForm.submittingData.deleteMyData')}
              </Button>
              <Button
                disabled={
                  !isCandidateFormValid ||
                  (!watchSignedScribeConsent && !watchSignedConsent) ||
                  (Object.keys(errors).length !== 0 && !Object.values(errors).every((x) => x === null))
                }
                type="submit"
              >
                {t('candidateForm.submittingData.submitMyData')}
              </Button>
            </NextStepsButtons>
          )}
        </SubmittingButtons>
      </Form>
    </>
  );
};

SubmittingDataStep.propTypes = {
  previousStep: PropTypes.func.isRequired,
  editingLocked: PropTypes.bool.isRequired,
  showSection: PropTypes.bool.isRequired,
  defaultFiles: PropTypes.object,
  fetchFiles: PropTypes.func.isRequired,
  validationFunctionsArray: PropTypes.array.isRequired,
  dirtySectionsChecker: PropTypes.object.isRequired,
  saveFormFunctions: PropTypes.object.isRequired,
};

export default SubmittingDataStep;
