import countries from 'i18n-iso-countries';
import store from 'store/store';

export const getCountriesNamesBasedOnLanguage = (language) => {
  countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/sv.json'));

  return countries.getNames(language);
};

// const compare = (a, b) => {
//   if (a.label < b.label) return -1;
//   if (a.label > b.label) return 1;
//   return 0;
// };

export const countriesMapper = (countriesList) => {
  const countriesArray = Object.keys(countriesList).map((key) => ({
    value: key,
    label: countriesList[key],
  }));

  // return countriesArray.sort(compare); // for english has same results without sorting
  return countriesArray;
};

export const setCountriesRedux = (language) => {
  const countriesList = getCountriesNamesBasedOnLanguage(language);
  const countriesMappedList = countriesMapper(countriesList);

  return {
    countriesList,
    countriesMappedList,
  };
};

export const getMatchingCountryValue = (code) =>
  store.getState().countries.countriesMappedList.find((country) => country.value === code);

export const setDefalutMultiSelectValue = (countriesNamesArray) => {
  return countriesNamesArray
    ? countriesNamesArray.map((countryName) =>
        store.getState().countries.countriesMappedList.find((country) => country.label === countryName)
      )
    : [];
};

export const getCountriesList = () => store.getState().countries.countriesList;
