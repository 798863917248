import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Shared/sharedStyle';
import PreviousAddressForm from './PreviousAddressForm';

const PreviousAddressesSection = ({ formFunctions, defaultState, editingLocked, requiredYears }) => {
  const [previousAddresses, updatePreviousAddresses] = useState([]);

  const { register, unregister, setValue, triggerValidation, formState } = formFunctions;

  useEffect(() => {
    if (defaultState.length) {
      updatePreviousAddresses(defaultState.map((address) => address.id));
    }
  }, [defaultState]);

  useEffect(() => {
    register('previousAddressesWatcher');

    return () => unregister('previousAddressesWatcher');
  }, [register, unregister]);

  const { t } = useTranslation();

  const addAnotherAddress = (e) => {
    e.preventDefault();
    const groupId = `${new Date().getTime()}-1`;
    updatePreviousAddresses(previousAddresses.concat(groupId));
    setTimeout(() => {
      setValue('previousAddressesWatcher', {}); // Used to inform logic in AdditionalFieldsSection about adding previous address

      if (formState.isSubmitted) {
        triggerValidation(`previous[${groupId}]`);
      }
    });
  };

  const removeGroup = (id) => {
    updatePreviousAddresses((state) => state.filter((groupId) => groupId !== id));
    setTimeout(() => {
      setValue('previousAddressesWatcher', {}); // Used to inform logic in AdditionalFieldsSection about removing previous address
      if (formState.isSubmitted) {
        triggerValidation();
        triggerValidation(`dateRangeError`);
      }
    });
  };

  return (
    <>
      <h2>{t('candidateForm.personalDetails.previousAddresses', { requiredYears })}</h2>
      <p>{t('candidateForm.personalDetails.previousAddressesDescription')}</p>
      {previousAddresses.map((id, index) => (
        <PreviousAddressForm
          key={id} // key can't be index, because inputs will not unmount correctly - values will shfit to other address
          formFunctions={formFunctions}
          groupId={id}
          remove={removeGroup}
          defaultState={defaultState}
          editingLocked={editingLocked}
          index={index}
        />
      ))}
      <Button marginTop="10px" onClick={addAnotherAddress}>
        {t('candidateForm.personalDetails.moreAddresses')}
      </Button>
    </>
  );
};

PreviousAddressesSection.propTypes = {
  formFunctions: PropTypes.object.isRequired,
  defaultState: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      street: PropTypes.string,
      city: PropTypes.string,
      zipCode: PropTypes.string,
      country: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    })
  ).isRequired,
  editingLocked: PropTypes.bool.isRequired,
  requiredYears: PropTypes.number,
};

export default PreviousAddressesSection;
