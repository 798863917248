import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useDispatchNotification from 'components/Shared/Notification/DispatchNotificationHook';
import hasPermission from 'utils/hasPermission';
import roles from 'constants/roles';
import { registerCandidates, registerCandidatesByInvestigator } from 'api/candidate';
import { getCustomerData, getCustomersList } from 'api/customer';
import { Button, Form, Divider, Flexbox, Container, Span } from 'components/Shared/sharedStyle';
import { candidateSchemaAsCustomer, candidateSchemaAsInvestigator } from 'components/Customers/customerSchema';
import AddCandidateForm from 'components/Customers/AddCandidate/AddCandidateForm';
import colors from 'constants/colors';

const AddCandidate = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const hasInvestigatorAdminPermissions = hasPermission([roles.admin, roles.investigator]);
  const [isLoading, setLoadingStatus] = useState(false);
  const [candidatesGroups, setCandidatesGroups] = useState([`${new Date().getTime()}-1`]);
  const [customersOptions, setCustomersOptions] = useState([]);
  const [customersMaxLevel, setcustomersMaxLevel] = useState(null);

  const { dispatchSuccessNotification, dispatchErrorNotification } = useDispatchNotification();

  const getCustomersUsersData = useCallback(async () => {
    try {
      if (hasInvestigatorAdminPermissions) {
        const customersUsers = await getCustomersList();
        const customersOptions = customersUsers.map((customer) => ({
          value: customer,
          label: customer.name,
        }));
        setCustomersOptions(customersOptions);
      } else {
        const customersUser = await getCustomerData();
        setcustomersMaxLevel(customersUser.maxLevel);
      }
    } catch (error) {}
  }, [hasInvestigatorAdminPermissions]);

  useEffect(() => {
    getCustomersUsersData();
  }, [getCustomersUsersData]);

  const formFunctions = useForm({
    validationSchema: hasInvestigatorAdminPermissions ? candidateSchemaAsInvestigator : candidateSchemaAsCustomer,
  });
  const { handleSubmit } = formFunctions;

  const addAnotherCandidate = () => setCandidatesGroups((groups) => groups.concat(`${new Date().getTime()}-1`));

  const removeGroupFromList = (id) => {
    setCandidatesGroups((groups) => groups.filter((groupId) => groupId !== id));
  };

  const submitForm = async (form) => {
    setLoadingStatus(true);

    const formConvertedToArray = Object.keys(form.candidate).map((key) => ({
      ...form.candidate[key],
    }));

    try {
      if (hasInvestigatorAdminPermissions) {
        const mappedForm = formConvertedToArray.map((candidate) => {
          const customerId = candidate.customer.id;
          delete candidate['customer'];

          return {
            ...candidate,
            customerId,
          };
        });

        await registerCandidatesByInvestigator({
          candidates: mappedForm,
        });
      } else {
        await registerCandidates({
          candidates: formConvertedToArray,
        });
      }
      dispatchSuccessNotification(t('notifications.saved'));
      history.push('/');
    } catch (catchedError) {
      dispatchErrorNotification({ catchedError });
    }

    setLoadingStatus(false);
  };

  const shouldIncludesRemoveFunction = candidatesGroups.length !== 1;

  return (
    <Container>
      <Form onSubmit={handleSubmit(submitForm)}>
        <h2>{t('customer.addNewCandidate')}</h2>
        {candidatesGroups.map((id, index) => (
          <AddCandidateForm
            key={id}
            formFunctions={formFunctions}
            groupId={id}
            remove={shouldIncludesRemoveFunction ? removeGroupFromList : null}
            index={index + 1}
            hasInvestigatorAdminPermissions={hasInvestigatorAdminPermissions}
            customersOptions={customersOptions}
            customersMaxLevel={customersMaxLevel}
          />
        ))}
        <Button padding="3px 60px" type="button" onClick={addAnotherCandidate}>
          <Flexbox align="center" hoverColor={colors.white}>
            <Span fontSize="22px" paddingRight="4px">
              {'+'}
            </Span>
            <Span>{t('customer.addAnother')}</Span>
          </Flexbox>
        </Button>
        <Divider />
        <Flexbox justify="flex-end">
          <Button disabled={isLoading} type="submit">
            {t('customer.addCandidates')}
          </Button>
        </Flexbox>
      </Form>
    </Container>
  );
};

export default AddCandidate;
