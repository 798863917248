import styled from 'styled-components/macro';

import icons from 'constants/icons';
import { mediumUp } from 'constants/media-queries';

export const CheckboxNode = styled.input`
  opacity: 0;
  width: 0;
  max-height: 0;
  margin-left: -5px;

  & + label {
    position: relative;
    cursor: pointer;
    margin-bottom: 15px; // margin top is added by hidden input
    margin: ${({ marginMobile }) => marginMobile};
    padding-left: 25px;
    width: calc(100% + 3px);
    display: inline-block;

    @media ${mediumUp} {
      margin: ${({ margin }) => margin};
    }
  }

  & + label:before {
    content: '';
    left: 0px;
    position: absolute;
    width: 20px;
    height: 20px;
    background-image: url(${icons.checkboxBlank});
    background-size: cover;
    display: inline-block;
    top: -2px;
  }

  &:checked + label:before {
    background-image: url(${icons.checkbox});
  }

  &:disabled + label {
    cursor: initial;
    opacity: 0.8;
  }
`;
