import styled, { keyframes } from 'styled-components/macro';
import colors from 'constants/colors';
import images from 'constants/images';
import { mediumUp } from 'constants/media-queries';
import { basicCSSproperties } from 'components/Shared/sharedStyle';

const loaderAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; }
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: ${({ fullScreen }) => (fullScreen ? 'fixed' : 'relative')};
  width: 100%;
  height: ${({ fullScreen }) => (fullScreen ? '100vh' : '100%')};
  top: 0;
  left: 0;

  background: ${({ fullScreen }) => (fullScreen ? 'rgba(250, 250, 250, 0.2)' : 'transparent')};
  z-index: 1000;

  opacity: 0;
  animation: ${fadeIn} 1s linear forwards;
  animation-delay: 0.3s;

  /* background-image: url(${images.loginBackground}); */
  /* background-size: cover; */

  transform: ${({ scale }) => scale && `scale(${scale})`};

  ${basicCSSproperties};
`;

export const LoaderDiv = styled.div`
  display: block;
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: ${colors.primaryOrangeBright} transparent transparent transparent;
  border-radius: 50%;
  position: absolute;
  animation: ${loaderAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

  &:first-child {
    animation-delay: -0.45s;
  }

  &:nth-child(2) {
    animation-delay: -0.3s;
  }

  &:last-child {
    animation-delay: -0.15s;
  }
`;

export const LoaderSmallContainer = styled(LoaderContainer)`
  position: relative;
  justify-content: flex-end;
  left: 27px;
  top: -9px;
  animation-delay: 0;

  @media ${mediumUp} {
    left: 32px;
  }
`;

export const LoaderSmallDiv = styled(LoaderDiv)`
  width: 24px;
  height: 24px;
  border: 3.5px solid;
  border-color: ${colors.primaryGray} transparent transparent transparent;
`;

// export const LoadingPortal = styled.div`
//   background: rgba(250, 250, 250, 0.8);
//   z-index: 1;
// `;
