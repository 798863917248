import { useEffect, useContext, useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { logout } from 'hooks/useLogout';
import { LogoutContext } from 'contexts/LogoutContext';

const TIME_TO_SHOW_LOGOUT_POPUP_IN_SECONDS = process.env.REACT_APP_TIME_TO_SHOW_LOGOUT_POPUP_IN_SECONDS ?? 600;

const useActivityTracker = () => {
  const history = useHistory();
  const { logoutCallback } = useContext(LogoutContext);
  const [isLogoutPopupActive, setLogoutPopupStatus] = useState(false);
  const timeWhenLogoutPopupOpened = useRef(null);

  const logoutUser = useCallback(
    async ({ showInactivityMsg }) => {
      logoutCallback && (await logoutCallback());
      logout();
      showInactivityMsg && history.push(`/login/automaticLogout`);
    },
    [logoutCallback, history]
  );

  useEffect(() => {
    const events = ['mouseup', 'keydown', 'scroll', 'mousemove'];
    let lastEventTime = new Date();

    const saveEventTime = () => (lastEventTime = new Date());

    for (const event of events) {
      window.addEventListener(event, saveEventTime);
    }

    const checkUserActivity = setInterval(() => {
      const timeDiff = new Date() - lastEventTime;

      if (!isLogoutPopupActive && timeDiff >= parseFloat(TIME_TO_SHOW_LOGOUT_POPUP_IN_SECONDS) * 1000) {
        timeWhenLogoutPopupOpened.current = new Date();
        setLogoutPopupStatus(true);
      }
    }, 1000);

    return () => {
      clearInterval(checkUserActivity);

      for (const event of events) {
        window.removeEventListener(event, saveEventTime);
      }
    };
  }, [isLogoutPopupActive, logoutCallback, logoutUser, timeWhenLogoutPopupOpened]);

  return { isLogoutPopupActive, setLogoutPopupStatus, logoutUser, timeWhenLogoutPopupOpened };
};

export default useActivityTracker;
