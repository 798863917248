import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Form, FormGroup, Button, Divider, Flexbox } from 'components/Shared/sharedStyle';
import { Input, Select, TextArea, MultipleFilesUploader } from 'components/Shared/sharedComponents';
import useDispatchNotification from 'components/Shared/Notification/DispatchNotificationHook';
import { sendMessageAsGP, getAssignedCandidatesList } from 'api/messages';
import { unlockEditing } from 'api/candidate';
import { sendAsGPSchema } from 'components/Messages/messagesSchema';
import { mapAssignedUsers } from 'components/Messages/configMessages';

const NewMessageAsGP = ({ onSubmit, subject }) => {
  const { dispatchSuccessNotification, dispatchErrorNotification } = useDispatchNotification();
  const { t } = useTranslation();

  const [isLoading, setLoadingStatus] = useState(false);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [shouldReset, setShouldResetStatus] = useState(null);

  useEffect(() => {
    (async () => {
      const data = await getAssignedCandidatesList();
      setAssignedUsers(mapAssignedUsers(data));
    })();
  }, []);

  const formFunctions = useForm({
    validationSchema: sendAsGPSchema,
  });

  const { reset, handleSubmit, register, errors, watch } = formFunctions;

  const formReceiver = watch('receiver');
  const formSubject = watch('subject');
  const formBody = watch('body');

  const isUnlockingAllowed = assignedUsers.find((obj) => obj.value === formReceiver)?.unlockingAllowed;

  const submitForm = async (form) => {
    setLoadingStatus(true);

    try {
      const uploadForm = new FormData();
      uploadForm.append('request.Subject', form.subject);
      uploadForm.append('request.Body', form.body);
      uploadForm.append('request.Receiver', form.receiver);

      if (form.attachment) {
        form.attachment.forEach((file, index) => {
          uploadForm.append(`request.Attachments[${index}].FileType`, 'Attachment');
          uploadForm.append(`request.Attachments[${index}].File`, file);
          uploadForm.append(`request.Attachments[${index}].Number`, index);
        });
      }

      await sendMessageAsGP(uploadForm);
      dispatchSuccessNotification(t('notifications.saved'));

      onSubmit((state) => state + 1);
      reset();
      setShouldResetStatus(true);
      setShouldResetStatus(false);
    } catch (catchedError) {
      dispatchErrorNotification({ catchedError });
    }

    setLoadingStatus(false);
  };

  const unlock = async (e) => {
    e.preventDefault();
    setLoadingStatus(true);

    try {
      await unlockEditing(formReceiver);
      dispatchSuccessNotification(t('notifications.saved'));
    } catch (catchedError) {
      dispatchErrorNotification({ catchedError });
    }

    setLoadingStatus(false);
  };

  return (
    <>
      <h3>{t('message.newMessage')}</h3>
      <Form onSubmit={handleSubmit(submitForm)}>
        <FormGroup>
          <Select
            name="receiver"
            selectFunctions={formFunctions}
            label={t('message.nameAndSurnameOfCandidate')}
            options={assignedUsers}
            error={errors.receiver}
            resetAfterSubmit={shouldReset}
          />
        </FormGroup>
        <FormGroup>
          <Input
            register={register}
            name="subject"
            error={errors.subject}
            label={t('common.topic')}
            defaultValue={subject}
          />
        </FormGroup>
        <FormGroup>
          <TextArea register={register} name="body" error={errors.body} label={t('common.message')} resizeOnlyY />
        </FormGroup>
        <FormGroup>
          <label>{t('message.attachment')}</label>
          <MultipleFilesUploader
            label={t('message.addAttachment')}
            name="attachment"
            errors={errors.attachment}
            formFunctions={formFunctions}
            shouldReset={shouldReset}
          />
        </FormGroup>
        <Divider small />
        <Flexbox justify="space-between">
          <Button disabled={isLoading || !formReceiver || !formSubject || !formBody} type="submit">
            {t('common.send')}
          </Button>
          <Button disabled={!formReceiver || !isUnlockingAllowed} onClick={unlock}>
            {t('candidateForm.unlockEditing')}
          </Button>
        </Flexbox>
      </Form>
    </>
  );
};

NewMessageAsGP.defaultProps = {
  subject: '',
};

NewMessageAsGP.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  subject: PropTypes.string,
};

export default NewMessageAsGP;
