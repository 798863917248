import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { LogoutContext } from 'contexts/LogoutContext';
import { useSignalR } from 'contexts/SignalRContext';
import { getCandidateOwnDetails } from 'api/candidate';
import { getAllFields } from 'api/country';
import { routes } from 'constants/routes';
import { fileSigned } from 'constants/signalREvents';
import { Flexbox } from 'components/Shared/sharedStyle';
import CandidateFormWithStep from 'components/CandidateForm/CandidateFormWithStep';
import {
  getActiveCandidateFormPage,
  setActiveCandidateFormPage,
  setUploadedAdditionalFileId,
} from 'utils/localStorage';
import {
  setCountriesAdditionalFields,
  findUnsavedCandidateFormChanges,
  saveCandidateForm,
  scriveFileLoading,
} from 'store/actions';
import useDispatchNotification from 'components/Shared/Notification/DispatchNotificationHook';
import RouteLeaveGuard from 'components/Shared/Modal/RouteLeaveGuard';
import { additionalFieldsBeFilesPrefix } from 'components/CandidateForm/Form/configCandidateForm';
import { sections, mapCandidateFormSections } from 'utils/candidateFormSections';
import { CandidateFormContainer } from 'components/CandidateForm/styleCandidateForm';
import { LoaderInsideElements } from 'components/Shared/Loader/Loaders';
import ProgressBar from './ProgressBar';
import PersonalDetailsStep from './Form/PersonalDetailsStep/PersonalDetailsStep';
import EducationStep from './Form/EducationStep/EducationStep';
import WorkplaceStep from './Form/WorkplaceStep/WorkplaceStep';
import ConflictOfInterestStep from './Form/ConflictOfInterestStep/ConflictOfInterestStep';
import AgreementsStep from './Form/AgreementsStep';
import SubmittingDataStep from './Form/SubmittingDataStep';
import ListOfErrors from './ListOfErrors';

const { agreement, personalInformation, education, workplaces, conflictOfInterest, submittingData } = sections;

const CandidateForm = () => {
  const dispatch = useDispatch();
  const { signalRConnection } = useSignalR();
  const { dispatchErrorNotification } = useDispatchNotification();
  const { setLogoutCallback } = useContext(LogoutContext);

  const isMobile = useSelector((state) => state.isMobile);
  const candidateSections = useSelector((state) => state.candidateSections);
  const isCandidateFormSubmitted = useSelector((state) => state.isCandidateFormSubmitted);

  const [activeFormPage, setActiveFormPage] = useState(getActiveCandidateFormPage() || 1);
  const [candidateDetails, setCandidateDetails] = useState(null);
  const [editingLocked, setEditingLockedStatus] = useState(true);
  const [files, setFiles] = useState(null);

  const validationFunctionsArray = useRef([]);
  const dirtySectionsChecker = useRef({});
  const saveFormFunctions = useRef({});

  const { fieldId } = useParams();

  const setFilesFunc = useCallback((details) => {
    const defaultFiles = {};

    Object.keys(details.files).forEach((key) => {
      if (key !== additionalFieldsBeFilesPrefix) {
        defaultFiles[key] = details.files[key];
        return;
      }

      // every AdditionalField has to have it's own array so updating files will not glith
      details.files[additionalFieldsBeFilesPrefix].forEach((additionalFile) => {
        const additionalFieldName = `${additionalFieldsBeFilesPrefix}.${additionalFile.additionalFieldId}`;
        if (!defaultFiles[additionalFieldName]) {
          defaultFiles[additionalFieldName] = [];
        }

        defaultFiles[additionalFieldName].push(additionalFile);
      });
    });

    setFiles(defaultFiles);
  }, []);

  const fetchFiles = useCallback(async () => {
    try {
      const details = await getCandidateOwnDetails();
      setFilesFunc(details);
    } catch (catchedError) {
      dispatchErrorNotification({ catchedError });
    }
  }, [dispatchErrorNotification, setFilesFunc]);

  const fetchCandidateDetails = useCallback(async () => {
    try {
      const [details, countriesAdditionalFields] = await Promise.all([
        getCandidateOwnDetails(),
        getAllFields({ includeEmpty: false }),
      ]);

      dispatch(setCountriesAdditionalFields(countriesAdditionalFields));

      const mappedSections = mapCandidateFormSections(details.sectionsToDisplay);
      if (getActiveCandidateFormPage() > mappedSections.length - 1) setActiveFormPage(mappedSections.length - 1);

      setCandidateDetails(details);
      setFilesFunc(details);
      setEditingLockedStatus(details.editingLocked);
      details.personalInformation.agreed === false && setActiveFormPage(0);
    } catch (catchedError) {
      dispatchErrorNotification({ catchedError });
    }
  }, [dispatch, setFilesFunc, dispatchErrorNotification]);

  useEffect(() => {
    fetchCandidateDetails();
  }, [fetchCandidateDetails]);

  // if user submitted form and left the page, this will show errors when he comes back
  useEffect(() => {
    if (isCandidateFormSubmitted) {
      // setTimeout waits for a hook-form to be populated, otherwise personalDetials errors will be shown
      setTimeout(() => {
        validationFunctionsArray.current.forEach((func) => func());
      }, 0);
    }
  }, [isCandidateFormSubmitted, candidateDetails]);

  const findUnsavedFormChanges = useCallback(() => {
    const dirtyFormSections = {};
    Object.keys(dirtySectionsChecker.current).forEach((key) => {
      dirtyFormSections[key] = dirtySectionsChecker.current[key]();
    });

    dispatch(findUnsavedCandidateFormChanges(dirtyFormSections));

    return Object.keys(dirtyFormSections).find((key) => dirtyFormSections[key]);
  }, [dispatch]);

  const saveCandidateFormFunc = useCallback(async () => {
    await dispatch(saveCandidateForm(saveFormFunctions.current));
  }, [dispatch]);

  const findChangesAndSaveCandidateForm = useCallback(async () => {
    findUnsavedFormChanges();
    await saveCandidateFormFunc();
  }, [findUnsavedFormChanges, saveCandidateFormFunc]);

  useEffect(() => {
    setLogoutCallback(() => findChangesAndSaveCandidateForm);

    return () => {
      setLogoutCallback(null);
    };
  }, [dispatch, findChangesAndSaveCandidateForm, setLogoutCallback]);

  useEffect(() => {
    // fetch Files after 15s in case signalR didn't send message
    const timeOutId = setTimeout(async () => {
      if (fieldId) {
        await fetchFiles();
        dispatch(scriveFileLoading());
      }
    }, 15000);

    signalRConnection.on(fileSigned, async () => {
      clearTimeout(timeOutId);
      await fetchFiles();
      dispatch(scriveFileLoading());
    });

    window.history.replaceState(null, '', routes.myRecruitment); // clears params from url and history
    setUploadedAdditionalFileId(fieldId);

    return () => clearTimeout(timeOutId);
  }, [dispatch, fetchFiles, fieldId, signalRConnection]);

  const previousStep = useCallback(() => {
    setActiveFormPage((activePage) => {
      setActiveCandidateFormPage(activePage - 1);
      return activePage - 1;
    });
  }, []);

  const nextStep = useCallback(() => {
    setActiveFormPage((activePage) => {
      setActiveCandidateFormPage(activePage + 1);
      return activePage + 1;
    });
  }, []);

  if (!candidateDetails) return <LoaderInsideElements />;

  return (
    <Flexbox direction={isMobile ? 'column' : 'row'}>
      <CandidateFormContainer>
        <ProgressBar activeFormPage={activeFormPage} />
        <CandidateFormWithStep activeFormPage={activeFormPage} section={agreement}>
          <AgreementsStep
            nextStep={nextStep}
            agreed={candidateDetails.personalInformation.agreed}
            setEditingLockedStatus={setEditingLockedStatus}
            validationFunctionsArray={validationFunctionsArray.current}
            dirtySectionsChecker={dirtySectionsChecker.current}
            saveFormFunctions={saveFormFunctions.current}
          />
        </CandidateFormWithStep>
        <CandidateFormWithStep activeFormPage={activeFormPage} section={personalInformation}>
          <PersonalDetailsStep
            nextStep={nextStep}
            previousStep={previousStep}
            defaultFormState={candidateDetails.personalInformation}
            requiredYears={candidateDetails.requiredNumberOfYears.personalInformation}
            defaultFiles={files}
            editingLocked={editingLocked}
            fetchFiles={fetchFiles}
            validationFunctionsArray={validationFunctionsArray.current}
            dirtySectionsChecker={dirtySectionsChecker.current}
            saveFormFunctions={saveFormFunctions.current}
            findChangesAndSaveCandidateForm={findChangesAndSaveCandidateForm}
          />
        </CandidateFormWithStep>
        <CandidateFormWithStep activeFormPage={activeFormPage} section={education}>
          <EducationStep
            previousStep={previousStep}
            nextStep={nextStep}
            defaultFormState={candidateDetails.education}
            defaultFiles={files}
            noEducation={candidateDetails.personalInformation && candidateDetails.personalInformation.noEducation}
            editingLocked={editingLocked}
            fetchFiles={fetchFiles}
            fetchCandidateDetails={fetchCandidateDetails}
            validationFunctionsArray={validationFunctionsArray.current}
            dirtySectionsChecker={dirtySectionsChecker.current}
            saveFormFunctions={saveFormFunctions.current}
          />
        </CandidateFormWithStep>
        <CandidateFormWithStep activeFormPage={activeFormPage} section={workplaces}>
          <WorkplaceStep
            previousStep={previousStep}
            nextStep={nextStep}
            defaultFormState={candidateDetails.workplaces}
            requiredYears={candidateDetails.requiredNumberOfYears.workplaces}
            defaultFiles={files}
            noWorkplace={candidateDetails.personalInformation && candidateDetails.personalInformation.noWorkplace}
            noAdditionalEmploymentForRequestedTime={
              candidateDetails.personalInformation &&
              candidateDetails.personalInformation.noAdditionalEmploymentForRequestedTime
            }
            editingLocked={editingLocked}
            fetchFiles={fetchFiles}
            fetchCandidateDetails={fetchCandidateDetails}
            validationFunctionsArray={validationFunctionsArray.current}
            dirtySectionsChecker={dirtySectionsChecker.current}
            saveFormFunctions={saveFormFunctions.current}
          />
        </CandidateFormWithStep>
        <CandidateFormWithStep activeFormPage={activeFormPage} section={conflictOfInterest}>
          <ConflictOfInterestStep
            previousStep={previousStep}
            nextStep={nextStep}
            defaultFormState={candidateDetails.conflictOfInterest}
            editingLocked={editingLocked}
            validationFunctionsArray={validationFunctionsArray.current}
            dirtySectionsChecker={dirtySectionsChecker.current}
            saveFormFunctions={saveFormFunctions.current}
          />
        </CandidateFormWithStep>
        <CandidateFormWithStep activeFormPage={activeFormPage} section={submittingData}>
          <SubmittingDataStep
            showSection={activeFormPage === candidateSections.indexOf(submittingData)}
            previousStep={previousStep}
            defaultFiles={files}
            fetchFiles={fetchFiles}
            editingLocked={editingLocked}
            validationFunctionsArray={validationFunctionsArray.current}
            dirtySectionsChecker={dirtySectionsChecker.current}
            saveFormFunctions={saveFormFunctions.current}
          />
        </CandidateFormWithStep>
      </CandidateFormContainer>
      <ListOfErrors setActiveFormStep={setActiveFormPage} isEditing={candidateDetails.isEditing} />
      <RouteLeaveGuard
        when={true}
        shouldBlockNavigation={findUnsavedFormChanges}
        onConfirmClick={saveCandidateFormFunc}
        navigateOnDecline
      />
    </Flexbox>
  );
};

export default CandidateForm;
