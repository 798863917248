import icons from 'constants/icons';
import { routes } from 'constants/routes';

const adminConfig = (hasUnreadMessages) => [
  {
    href: routes.candidates,
    title: 'Candidates',
    id: 'candidates',
    icon: icons.contacts,
    underscored: true,
  },
  {
    href: routes.investigatorsList,
    title: 'Investigators',
    id: 'investigators',
    icon: icons.domain,
  },
  {
    href: '/customers',
    title: 'Customers',
    id: 'customers',
    icon: icons.domain,
  },
  {
    href: '/customer-users',
    title: 'Customer Users',
    id: 'customer_users',
    icon: icons.accountCircle,
  },
  {
    href: '/add-investigator',
    title: 'Add Investigator',
    id: 'add_investigator',
    icon: icons.add,
  },
  {
    href: routes.addCandidate,
    title: 'Add candidate',
    id: 'add_candidate',
    icon: icons.add,
  },
  {
    href: '/edit-countries',
    title: 'Edit Countries',
    id: 'edit_countries',
    icon: icons.supervisor,
  },
  {
    href: '/frequent-texts',
    title: 'Frequent Texts',
    id: 'frequent_texts',
    icon: icons.supervisor,
    underscored: true,
  },
  {
    href: routes.messages,
    title: 'Messages',
    id: 'messages',
    icon: hasUnreadMessages ? icons.unread_message : icons.message,
  },
];

const investigatorConfig = (hasUnreadMessages) => [
  {
    href: routes.candidates,
    title: 'Candidates',
    id: 'candidates',
    icon: icons.contacts,
    underscored: true,
  },
  {
    href: '/customers',
    title: 'Customers',
    id: 'customers',
    icon: icons.domain,
  },
  {
    href: '/customer-users',
    title: 'Customer Users',
    id: 'customer_users',
    icon: icons.accountCircle,
  },
  {
    href: routes.addCandidate,
    title: 'Add candidate',
    id: 'add_candidate',
    icon: icons.add,
  },
  {
    href: routes.messages,
    title: 'Messages',
    id: 'messages',
    icon: hasUnreadMessages ? icons.unread_message : icons.message,
  },
];

const customerSidebarConfig = () => [
  {
    href: routes.candidates,
    title: 'My candidates',
    id: 'my_candidates',
    icon: icons.supervisor,
  },
  {
    href: routes.addCandidate,
    title: 'Add candidate',
    id: 'add_candidate',
    icon: icons.add,
  },
  {
    href: routes.users,
    title: 'Users',
    id: 'users',
    icon: icons.domain,
  },
  {
    href: routes.myPins,
    title: 'Pin codes',
    id: 'pin_codes',
    icon: icons.help,
  },
];

const customerSupervisorSidebarConfig = () => [
  {
    href: routes.candidates,
    title: 'Candidates',
    id: 'candidates',
    icon: icons.supervisor,
  },
  {
    href: routes.addCandidate,
    title: 'Add candidate',
    id: 'add_candidate',
    icon: icons.add,
  },
  {
    href: routes.users,
    title: 'Users',
    id: 'users',
    icon: icons.domain,
  },
  {
    href: routes.addCustomerUser,
    title: 'Add user',
    id: 'add_customer_user',
    icon: icons.accountCircle,
  },
  {
    href: routes.myPins,
    title: 'Pin codes',
    id: 'pin_codes',
    icon: icons.help,
  },
];

const candidateSidebarConfig = (hasUnreadMessages) => [
  {
    href: routes.myRecruitment,
    title: 'My recruitment',
    id: 'my_recruitment',
    icon: icons.home,
    underscored: true,
  },
  {
    href: routes.messages,
    title: 'Messages',
    id: 'messages',
    icon: hasUnreadMessages ? icons.unread_message : icons.message,
  },
];

const sidebarMenuConfig = {
  admin: adminConfig,
  investigator: investigatorConfig,
  customer: customerSidebarConfig,
  customerSupervisor: customerSupervisorSidebarConfig,
  candidate: candidateSidebarConfig,
};

export default sidebarMenuConfig;
