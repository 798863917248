export const routes = {
  // shared routes
  messages: '/messages',
  logoutPage: '/logout',
  candidates: '/candidates',
  // investigator routes
  investigatorsList: '/investigators',
  // customer routes
  addCandidate: '/add-candidate',
  users: '/users',
  addCustomerUser: '/add-customer-user',
  myPins: '/my-pins',
  // candidate routes
  welcome: '/welcome',
  myRecruitment: '/my-recruitment',
  myReport: '/my-report',
};

export const routesCreators = {
  candidate: (id) => `/candidate/${id}`,
};
