import { createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

export const LogoutContext = createContext({
  logoutCallback: () => null,
  setLogoutCallback: () => null,
});

const useLogoutContext = () => {
  const [logoutCallback, setLogoutCallback] = useState(null);

  const logoutContext = useMemo(
    () => ({
      logoutCallback,
      setLogoutCallback,
    }),
    [logoutCallback]
  );

  return logoutContext;
};

export const LogoutContextProvider = ({ children }) => {
  const value = useLogoutContext();

  return <LogoutContext.Provider value={value}>{children}</LogoutContext.Provider>;
};

LogoutContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
