import React from 'react';
import { v4 as uuid } from 'uuid'; // not needed when provided real data

export const currentInvestigatorsHeadlines = [
  { id: 'investigator', sortable: true },
  { id: 'created', sortable: true },
  { id: 'ongoing', sortable: true },
  { id: 'closed', sortable: true },
  { id: 'total', sortable: true },
];

export const statisticsMapper = ({ data: statistics }) =>
  statistics.map((statistic) => ({
    id: uuid(), // id is used as key in Grid component, must be provided
    investigator: <b>{statistic.investigator}</b>,
    created: statistic.created,
    ongoing: statistic.ongoing,
    closed: statistic.closed,
    total: statistic.total,
  }));
