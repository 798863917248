import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { uploadFiles, removeSelectedFiles } from 'utils/uploadMultipleFiles';
import { addCandidateFormErrors, addFileToRemoveList } from 'store/actions';
import useDispatchNotification from 'components/Shared/Notification/DispatchNotificationHook';
import { getWorkplacesSectionNum } from 'utils/candidateFormSections';
import { workplacesType, fileTypes } from 'constants/types';
import { saveWorkplace } from 'api/candidate';
import { Button, Form, Divider, Flexbox } from 'components/Shared/sharedStyle';
import { FormCheckbox } from 'components/Shared/sharedComponents';
import { NextStepsButtons, ButtonsContainer } from 'components/CandidateForm/styleCandidateForm';
import { mapDefaultWorkplaces, workplaceBodyMapper } from 'components/CandidateForm/Form/configCandidateForm';
import { workplaceSchema } from 'components/CandidateForm/Form/candidateFormSchema';
import WorkplaceForm from './WorkplaceForm';

const WorkplaceStep = ({
  previousStep,
  nextStep,
  defaultFormState,
  defaultFiles,
  requiredYears,
  noWorkplace,
  noAdditionalEmploymentForRequestedTime, // This prop and noWorkplace must be seperate because it doesn't update view correctly if they are in one object
  editingLocked,
  fetchFiles,
  fetchCandidateDetails,
  validationFunctionsArray,
  dirtySectionsChecker,
  saveFormFunctions,
}) => {
  const { dispatchSuccessNotification, dispatchErrorNotification } = useDispatchNotification();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const sectionNumber = getWorkplacesSectionNum();
  const isCandidateFormSubmitted = useSelector((state) => state.isCandidateFormSubmitted);

  const [isLoading, setLoadingStatus] = useState(false);
  const [workplaceState, updateWorkplaces] = useState([]);
  const [payslipsToRemoveFromView, setPayslipsToRemoveFromView] = useState([]);

  const formFunctions = useForm({
    validationSchema: workplaceSchema,
    validationContext: { requiredYears },
  });

  const {
    register,
    handleSubmit,
    errors,
    getValues,
    reset,
    setValue,
    formState,
    triggerValidation,
    clearError,
    watch,
  } = formFunctions;

  const lastSavedFormState = useRef('');
  // setTimeout waits till files are registerd because it happends in useEffect in MultipleFilesUploader
  const setLastSavedFormState = useCallback(
    () =>
      setTimeout(() => {
        lastSavedFormState.current = JSON.stringify(getValues());
      }, 0),
    [getValues]
  );

  const submitForm = useCallback(async () => {
    const form = getValues({ nest: true });

    setLoadingStatus(true);

    try {
      const mappedBody = workplaceBodyMapper(form);

      await saveWorkplace(mappedBody);

      // Clear inputs after update.
      Object.keys(form.workplace).forEach((id) => {
        form.workplace[id].currentEmployment && setValue(`workplace[${id}].endDate`, null);
        if (form.workplace[id].currentEmployment && !form.workplace[id].yesContactWorkplace) {
          setValue(`workplace[${id}].companyPhoneNumberSwitchboard`, '');
          setValue(`workplace[${id}].companyPhoneNumberHrDepartment`, '');
          setValue(`workplace[${id}].supervisorName`, '');
          setValue(`workplace[${id}].supervisorPhone`, '');
          setValue(`workplace[${id}].supervisorEmail`, '');
        }

        if (!form.workplace[id].currentEmployment) {
          setValue(`workplace[${id}].yesContactWorkplace`, null);
          setValue(`workplace[${id}].dontContactWorkplace`, null);
        }
      });

      // Add and remove files logic
      if (form.noWorkplace) {
        const filesToRemove = [];
        Object.keys(form.workplace).forEach((id) => {
          form.workplace[id]?.payslips &&
            form.workplace[id]?.payslips.forEach((payslips) => filesToRemove.push(payslips.id));
        });

        if (filesToRemove.length) await removeSelectedFiles(filesToRemove);

        await fetchCandidateDetails(); // to delet later and make dates reset without refreshing data
      } else if (form.workplace) {
        const payslipsToRemove = [];

        Object.keys(form.workplace).forEach((id) => {
          if (!form.workplace[id].currentEmployment || !form.workplace[id].dontContactWorkplace) {
            payslipsToRemove.push(id);

            if (form.workplace[id].payslips) {
              form.workplace[id].payslips.forEach(
                (payslips) => payslips?.id && dispatch(addFileToRemoveList(payslips.id))
              );
            }
          }
        });

        setPayslipsToRemoveFromView(payslipsToRemove);

        const filesToUpload = [];
        Object.keys(form.workplace).forEach((id) => {
          form.workplace[id].dontContactWorkplace &&
            form.workplace[id].payslips &&
            filesToUpload.push([form.workplace[id].payslips, fileTypes.payslip]);
        });

        await uploadFiles(filesToUpload);
        await fetchFiles();
      }

      setLastSavedFormState();
      dispatchSuccessNotification(t('notifications.saved'));
    } catch (catchedError) {
      dispatchErrorNotification({ catchedError });
    }

    setLoadingStatus(false);
  }, [
    dispatch,
    dispatchErrorNotification,
    dispatchSuccessNotification,
    fetchCandidateDetails,
    fetchFiles,
    getValues,
    setLastSavedFormState,
    setValue,
    t,
  ]);

  // should run only once
  useEffect(() => {
    // "handleSubmit" will be run in candidateForm and SubmittingDataStep,
    // it changes "hook-form" state to submitted which runs validation on every keystroke
    validationFunctionsArray.push(handleSubmit(() => {}));

    // used to determine whether to show "Leave Guard" and then save form
    dirtySectionsChecker.workplace = () => lastSavedFormState.current !== JSON.stringify(getValues());
    saveFormFunctions.workplace = submitForm;
  }, [validationFunctionsArray, handleSubmit, dirtySectionsChecker, saveFormFunctions, submitForm, getValues]);

  useEffect(() => {
    if (!defaultFormState) return;

    const firstElementId = `${new Date().getTime()}-1`; // Workaround to present workplaces as object instead of array in form state
    updateWorkplaces(defaultFormState.length ? defaultFormState.map((workplace) => workplace.id) : [firstElementId]);

    reset(mapDefaultWorkplaces({ workplaces: defaultFormState, noWorkplace, noAdditionalEmploymentForRequestedTime }));
    setLastSavedFormState();
  }, [defaultFormState, reset, noWorkplace, noAdditionalEmploymentForRequestedTime, setLastSavedFormState]);

  // adds errors to redux state on every keystroke when condition is true
  useEffect(() => {
    if (isCandidateFormSubmitted) {
      // makes deep copy of error object - fixes "Invariant failed" redux error
      const newErrors = JSON.parse(JSON.stringify(errors));

      dispatch(addCandidateFormErrors({ workplace: newErrors || {} }));
    }
  });

  const proceedToNextStep = async () => {
    if (!editingLocked) submitForm();
    nextStep();
  };

  const addWorkplace = () => {
    const id = `${new Date().getTime()}-1`; // must be a string to be parsed correctly as object key by redux, Date() returns number.

    updateWorkplaces((workplaceState) => workplaceState.concat(id));

    setTimeout(() => {
      if (isCandidateFormSubmitted) {
        triggerValidation(`workplace[${id}]`);
      }
    }, 0);
  };

  const removeGroup = (id) => {
    updateWorkplaces((state) => state.filter((groupId) => groupId !== id));

    if (workplaceState.length - 1 === 0) {
      setValue('noWorkplace', true);
    }
  };

  const shouldIncludesRemoveFunction = workplaceState.length !== 1 && !editingLocked;

  const onNoWorkplaceCheck = () => {
    if (formState.isSubmitted) {
      // both triggerValidation are needed
      triggerValidation();
      getValues().noWorkplace ? clearError('dateRangeError') : triggerValidation('dateRangeError');
    }
  };

  return (
    <Form>
      <fieldset disabled={editingLocked}>
        <h2>{`${sectionNumber}. ${t('candidateForm.workplace.title')}`}</h2>
        <h4>{t('candidateForm.workplace.description', { requiredYears })}</h4>
        <FormCheckbox
          label={t('candidateForm.workplace.noWorkplaceLabel', { requiredYears })}
          name="noWorkplace"
          register={register}
          onChange={onNoWorkplaceCheck}
        />
        <div hidden={formFunctions.watch('noWorkplace')}>
          <Flexbox direction="column">
            {workplaceState.map((id, index) => (
              <WorkplaceForm
                key={id} // passing index as key may lead to incorrect unmounting
                formFunctions={formFunctions}
                groupId={id}
                remove={shouldIncludesRemoveFunction ? removeGroup : null}
                index={index}
                editingLocked={editingLocked}
                defaultFiles={defaultFiles}
                payslipsToRemoveFromView={payslipsToRemoveFromView}
                defaultFormState={defaultFormState}
              />
            ))}
          </Flexbox>
          <Divider />
          <Flexbox gap="20px" align="center">
            <Button
              onClick={addWorkplace}
              type="button"
              disabled={watch('noAdditionalEmploymentForRequestedTime')}
              height="35px"
            >
              {t('candidateForm.workplace.addWorkplace')}
            </Button>
            <FormCheckbox
              label={t('candidateForm.labels.noAdditionalEmploymentForRequestedTime')}
              name="noAdditionalEmploymentForRequestedTime"
              register={register}
              error={errors.noAdditionalEmploymentForRequestedTime}
            />
          </Flexbox>
        </div>
      </fieldset>
      <Divider />
      <ButtonsContainer>
        <Button onClick={previousStep} outlined type="button">
          {t('common.previous')}
        </Button>
        <NextStepsButtons>
          <Button onClick={submitForm} disabled={editingLocked || isLoading} type="button">
            {t('common.save')}
          </Button>
          <Button disabled={isLoading} onClick={proceedToNextStep} type="button">
            {t('common.next')}
          </Button>
        </NextStepsButtons>
      </ButtonsContainer>
    </Form>
  );
};

WorkplaceStep.propTypes = {
  nextStep: PropTypes.func.isRequired,
  defaultFormState: workplacesType,
  defaultFiles: PropTypes.object,
  requiredYears: PropTypes.number,
  noWorkplace: PropTypes.bool,
  noAdditionalEmploymentForRequestedTime: PropTypes.bool,
  previousStep: PropTypes.func.isRequired,
  editingLocked: PropTypes.bool.isRequired,
  fetchFiles: PropTypes.func.isRequired,
  fetchCandidateDetails: PropTypes.func.isRequired,
  validationFunctionsArray: PropTypes.array.isRequired,
  dirtySectionsChecker: PropTypes.object.isRequired,
  saveFormFunctions: PropTypes.object.isRequired,
};

WorkplaceStep.defaultProps = {
  defaultFormState: [],
  noWorkplace: false,
};

export default WorkplaceStep;
