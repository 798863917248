import React from 'react';
import PropTypes from 'prop-types';

import { StatusCircle, StatusButtonsContainer } from './styleCandidateView';
import colors from 'constants/colors';
import { evaluationCode } from 'constants/evaluation';

const StatusButtons = ({ onClick, evaluationKey, value }) => (
  <StatusButtonsContainer>
    <StatusCircle
      color={colors.success}
      onClick={() => onClick(evaluationKey, evaluationCode.green)}
      isSelected={value === evaluationCode.green}
    />
    <StatusCircle
      color={colors.warning}
      onClick={() => onClick(evaluationKey, evaluationCode.yellow)}
      isSelected={value === evaluationCode.yellow}
    />
    <StatusCircle
      color={colors.error}
      onClick={() => onClick(evaluationKey, evaluationCode.red)}
      isSelected={value === evaluationCode.red}
    />
  </StatusButtonsContainer>
);

StatusButtons.propTypes = {
  onClick: PropTypes.func.isRequired,
  evaluationKey: PropTypes.string.isRequired,
  value: PropTypes.number,
};

export default StatusButtons;
