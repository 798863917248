import PropTypes from 'prop-types';

import { personalDetailsFiles } from './files';

export const fileTypes = {
  degree: 'Degree',
  payslip: 'Payslip',
};

export const evaluationItemType = PropTypes.shape({
  comment: PropTypes.string,
  header: PropTypes.string,
  evaluation: PropTypes.number.isRequired,
});

export const referenceType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  PropTypes.shape({ current: PropTypes.objectOf(PropTypes.instanceOf(Element)) }),
]);

export const personalDetailsType = PropTypes.shape({
  name: PropTypes.string,
  surname: PropTypes.string,
  nationalIdNumber: PropTypes.string,
  socialSecurityNumber: PropTypes.string,
  dateOfBirth: PropTypes.string,
  placeOfBirth: PropTypes.string,
  citizenship: PropTypes.string,
  phoneNumber: PropTypes.string,
  street: PropTypes.string,
  city: PropTypes.string,
  zipCode: PropTypes.string,
  country: PropTypes.string,
  email: PropTypes.string,
  previousAddresses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      street: PropTypes.string,
      city: PropTypes.string,
      zipCode: PropTypes.string,
      country: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      nationalIdNumber: PropTypes.string,
      noNationalIdNumber: PropTypes.bool,
    })
  ).isRequired,
});

export const educationType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    highestDegree: PropTypes.string,
    fieldsOfStudy: PropTypes.string,
    startDate: PropTypes.string,
    endData: PropTypes.string,
    diplomaReceivedDate: PropTypes.string,
    universityName: PropTypes.string,
    universityCity: PropTypes.string,
    universityStreet: PropTypes.string,
    universityZipCode: PropTypes.string,
    universityPhoneNumber: PropTypes.string,
    universityWebsiteUrl: PropTypes.string,
  })
);

export const workplacesType = PropTypes.arrayOf(
  PropTypes.shape({
    jobPosition: PropTypes.string,
    companyName: PropTypes.string,
    companyAddress: PropTypes.string,
    companyPhoneNumberSwitchboard: PropTypes.string,
    companyPhoneNumberHrDepartment: PropTypes.string,
    supervisorName: PropTypes.string,
    supervisorPhone: PropTypes.string,
    supervisorEmail: PropTypes.string,
    placeOfWork: PropTypes.string,
    employmentType: PropTypes.number,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    comments: PropTypes.string,
    id: PropTypes.string,
  })
);

export const conflictOfInterestTypes = PropTypes.shape({
  noConflictOfInterest: PropTypes.bool.isRequired,
  assignments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      company: PropTypes.string,
      typeOfAssignment: PropTypes.string,
    })
  ).isRequired,
  deviationsDisclosed: PropTypes.bool.isRequired,
  comment: PropTypes.string,
});

export const personalDetailsFilesTypes = PropTypes.shape({
  ...Object.values(personalDetailsFiles)
    .map((key) => ({
      [key]: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          number: PropTypes.number,
          name: PropTypes.string,
          type: PropTypes.string,
        })
      ),
    }))
    .reduce(
      (accumulator, current) => ({
        ...accumulator,
        ...current,
      }),
      {}
    ),
});
