import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';

import { requestPasswordReset } from 'api/account';
import { Form, Button } from 'components/Login/styleLogin';
import { Input, ErrorMessageFe, ErrorMessageBe, FormGroup, TextButton, Span } from 'components/Shared/sharedStyle';
import { resetPasswordSchema } from 'components/Login/loginSchema';
import { Loader } from 'components/Shared/Loader/Loaders';

const ResetPasswordForm = ({ toggleForm }) => {
  const { t } = useTranslation();

  const [isLoading, toggleLoading] = useState(false);
  const [backendError, setBackendError] = useState('');
  const [isSubmitted, setSubmittedStatus] = useState(false);

  const { register, handleSubmit, errors } = useForm({
    validationSchema: resetPasswordSchema,
  });

  const submitResetPasswordForm = async (formData) => {
    toggleLoading(true);
    try {
      await requestPasswordReset({
        ...formData,
      });

      setBackendError('');
      toggleLoading(false);
      setSubmittedStatus(true);
    } catch (err) {
      toggleLoading(false);
      setSubmittedStatus(true);
    }
  };

  return (
    <>
      {!isSubmitted ? (
        <>
          {isLoading && <Loader />}
          <h3>{t('signIn.resetPasswordTitle')}</h3>
          <Form onSubmit={handleSubmit(submitResetPasswordForm)}>
            {backendError && <ErrorMessageBe>{backendError}</ErrorMessageBe>}
            <FormGroup>
              <Input ref={register({ required: true })} name="email" placeholder="Email" hasError={!!errors.email} />
              {errors.email && <ErrorMessageFe>{errors.email.message}</ErrorMessageFe>}
            </FormGroup>
            <Button disabled={isLoading} type="submit">
              {t('signIn.resetPassword')}
            </Button>
          </Form>
        </>
      ) : (
        <Span maxWidth="350px" paddingBottom="20px" textAlign="center">
          <Trans i18nKey="signIn.resetPasswordSubmitted" />
        </Span>
      )}
      <TextButton onClick={toggleForm}>{t('signIn.logIn')}</TextButton>
    </>
  );
};

ResetPasswordForm.propTypes = {
  toggleForm: PropTypes.func.isRequired,
};

export default ResetPasswordForm;
