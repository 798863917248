import { personalDetailsFiles as files } from 'constants/files';
import { backendFriendlyDate, mapDateOnFormSchema } from 'utils/dateUtils';
import { setDefalutMultiSelectValue } from 'utils/countries';

export const personalDetailsFilesMapper = (form) => [
  [form.passport, files.passport],
  ...mapFilesInAdditionalFields(form.additionalFields),
];

export const mapFilesInAdditionalFields = (additionalFields) =>
  additionalFields
    ? Object.entries(additionalFields)
        .filter(([, value]) => value && typeof value === 'object')
        .map(([key, value]) => [value, additionalFieldsBeFilesPrefix, key])
    : [];

export const personalDetailsBodyMapper = (form) => {
  return {
    name: form.name,
    middleNames: form.middleNames,
    surname: form.surname,
    nationalIdNumber: form.nationalIdNumber,
    countryOfIdNumber: form.countryOfIdNumber,
    dateOfBirth: backendFriendlyDate(form.dateOfBirth),
    placeOfBirth: form.placeOfBirth,
    citizenships: form.citizenships?.filter((country) => country).map((country) => country?.label),
    street: form.street,
    phoneNumber: form.phoneNumber,
    city: form.city,
    zipCode: form.zipCode,
    country: form.country,
    startDate: backendFriendlyDate(form.startDate),
    hideAddressOnReport: form.hideAddressOnReport,
    previousAddresses: form.previous ? mapPreviousAddresses(form.previous) : [],
    additionalFields: form[additionalFieldsPrefix] ? mapUpdateFields(form[additionalFieldsPrefix]) : [],
  };
};

const mapPreviousAddresses = (addresses) =>
  Object.keys(addresses).map((key) => ({
    ...addresses[key],
    startDate: backendFriendlyDate(addresses[key].startDate),
    endDate: backendFriendlyDate(addresses[key].endDate),
    country: addresses[key].country,
    hideAddressOnReport: addresses[key].hideAddressOnReport,
  }));

export const additionalFieldsPrefix = 'additionalFields';
export const additionalFieldsBeFilesPrefix = 'AdditionalField';

export const additionalFieldName = (field) => `${additionalFieldsPrefix}.${field.id}`;
export const additionalFieldError = (field, errors) =>
  errors[additionalFieldsPrefix] && errors[additionalFieldsPrefix][field.id];

export const mapUpdateFields = (additionalFields) =>
  Object.entries(additionalFields)
    .filter(([, value]) => typeof value === 'string')
    .map(([key, value]) => ({
      fieldId: key,
      contents: value,
    }));

export const mapWorkplaces = (workplaces) => {
  const mapContactField = (workplace, name) =>
    !workplace.currentEmployment || workplace.yesContactWorkplace ? workplace[name] : '';

  return Object.keys(workplaces).map((key) => ({
    ...workplaces[key],
    yesContactWorkplace: workplaces[key].currentEmployment ? workplaces[key].yesContactWorkplace : null,
    dontContactWorkplace: workplaces[key].currentEmployment ? workplaces[key].dontContactWorkplace : null,
    startDate: backendFriendlyDate(workplaces[key].startDate),
    endDate: workplaces[key].currentEmployment ? null : backendFriendlyDate(workplaces[key].endDate),
    companyPhoneNumberSwitchboard: mapContactField(workplaces[key], 'companyPhoneNumberSwitchboard'),
    companyPhoneNumberHrDepartment: mapContactField(workplaces[key], 'companyPhoneNumberHrDepartment'),
    supervisorName: mapContactField(workplaces[key], 'supervisorName'),
    supervisorPhone: mapContactField(workplaces[key], 'supervisorPhone'),
    supervisorEmail: mapContactField(workplaces[key], 'supervisorEmail'),
  }));
};

export const workplaceBodyMapper = (form) => ({
  noWorkplace: form.noWorkplace,
  noAdditionalEmploymentForRequestedTime: form.noAdditionalEmploymentForRequestedTime,
  workplaces: form.workplace && !form.noWorkplace ? mapWorkplaces(form.workplace) : [],
});

export const educationBodyMapper = ({ education, noEducation }) => ({
  noEducation,
  education:
    education && !noEducation
      ? Object.keys(education).map((key) => ({
          ...education[key],
          startDate: backendFriendlyDate(education[key].startDate),
          endDate: backendFriendlyDate(education[key].endDate),
          diplomaReceivedDate: backendFriendlyDate(education[key].diplomaReceivedDate),
        }))
      : [],
});

export const mapDefaultWorkplaces = ({ workplaces, noWorkplace, noAdditionalEmploymentForRequestedTime }) =>
  workplaces.reduce(
    (accumulator, workplace) => ({
      ...accumulator,
      [`workplace[${workplace.id}].companyName`]: workplace.companyName,
      [`workplace[${workplace.id}].jobPosition`]: workplace.jobPosition,
      [`workplace[${workplace.id}].companyAddress`]: workplace.companyAddress,
      [`workplace[${workplace.id}].placeOfWork`]: workplace.placeOfWork,
      [`workplace[${workplace.id}].employmentType`]: workplace.employmentType,
      [`workplace[${workplace.id}].comments`]: workplace.comments,
      [`workplace[${workplace.id}].startDate`]: mapDateOnFormSchema(workplace.startDate),
      [`workplace[${workplace.id}].endDate`]: mapDateOnFormSchema(workplace.endDate),
      [`workplace[${workplace.id}].currentEmployment`]: workplace.currentEmployment,
      [`workplace[${workplace.id}].yesContactWorkplace`]: workplace.yesContactWorkplace,
      [`workplace[${workplace.id}].dontContactWorkplace`]: workplace.dontContactWorkplace,
      [`workplace[${workplace.id}].companyPhoneNumberSwitchboard`]: workplace.companyPhoneNumberSwitchboard,
      [`workplace[${workplace.id}].companyPhoneNumberHrDepartment`]: workplace.companyPhoneNumberHrDepartment,
      [`workplace[${workplace.id}].supervisorName`]: workplace.supervisorName,
      [`workplace[${workplace.id}].supervisorPhone`]: workplace.supervisorPhone,
      [`workplace[${workplace.id}].supervisorEmail`]: workplace.supervisorEmail,
    }),
    { noWorkplace, noAdditionalEmploymentForRequestedTime }
  );

export const mapDefaultPersonalDetailsValues = (form) => ({
  name: form.name,
  middleNames: form.middleNames,
  surname: form.surname,
  nationalIdNumber: form.nationalIdNumber,
  countryOfIdNumber: form.countryOfIdNumber,
  dateOfBirth: mapDateOnFormSchema(form.dateOfBirth),
  placeOfBirth: form.placeOfBirth,
  citizenships: setDefalutMultiSelectValue(form.citizenships),
  phoneNumber: form.phoneNumber,
  street: form.street,
  city: form.city,
  zipCode: form.zipCode,
  country: form.country,
  startDate: mapDateOnFormSchema(form.startDate),
  hideAddressOnReport: form.hideAddressOnReport,
  ...mapDefaultPreviousAddresses(form.previousAddresses),
  ...mapDefaultAdditionalFields(form.additionalFields),
});

const mapDefaultPreviousAddresses = (addresses) =>
  addresses.reduce(
    (accumulator, address) => ({
      ...accumulator,
      [`previous[${address.id}].startDate`]: mapDateOnFormSchema(address.startDate),
      [`previous[${address.id}].endDate`]: mapDateOnFormSchema(address.endDate),
      [`previous[${address.id}].street`]: address.street,
      [`previous[${address.id}].city`]: address.city,
      [`previous[${address.id}].zipCode`]: address.zipCode,
      [`previous[${address.id}].country`]: address.country,
      [`previous[${address.id}].noNationalIdNumber`]: address.noNationalIdNumber,
      [`previous[${address.id}].nationalIdNumber`]: address.nationalIdNumber,
      [`previous[${address.id}].hideAddressOnReport`]: address.hideAddressOnReport,
    }),
    {}
  );

const mapDefaultAdditionalFields = (additionalFields) =>
  additionalFields.reduce(
    (accumulator, field) => ({
      ...accumulator,
      [`${additionalFieldsPrefix}.${field.fieldId}`]: field.contents,
    }),
    {}
  );

export const mapDefaultEducationValues = (education, noEducation) =>
  education.reduce(
    (accumulator, degree) => ({
      ...accumulator,
      [`education[${degree.id}].highestDegree`]: degree.highestDegree,
      [`education[${degree.id}].fieldOfStudy`]: degree.fieldOfStudy,
      [`education[${degree.id}].startDate`]: mapDateOnFormSchema(degree.startDate),
      [`education[${degree.id}].endDate`]: mapDateOnFormSchema(degree.endDate),
      [`education[${degree.id}].diplomaReceivedDate`]: degree.diplomaReceivedDate
        ? new Date(degree.diplomaReceivedDate)
        : null,
      [`education[${degree.id}].universityName`]: degree.universityName,
      [`education[${degree.id}].universityCountry`]: degree.universityCountry,
      [`education[${degree.id}].universityZipCode`]: degree.universityZipCode,
      [`education[${degree.id}].universityCity`]: degree.universityCity,
      [`education[${degree.id}].universityStreet`]: degree.universityStreet,
      [`education[${degree.id}].universityWebsiteUrl`]: degree.universityWebsiteUrl,
      [`education[${degree.id}].universityPhoneNumber`]: degree.universityPhoneNumber,
      [`education[${degree.id}].noCertificate`]: degree.noCertificate,
      [`education[${degree.id}].fileGroup`]: degree.fileGroup,
    }),
    { noEducation }
  );

export const mapDefaultConflictOfInterestValues = (conflictOfInterest) => ({
  noConflictOfInterest: conflictOfInterest.noConflictOfInterest,
  deviationsDisclosed: conflictOfInterest.deviationsDisclosed ? 'true' : 'false',
  comment: conflictOfInterest.comment || '',
  ...conflictOfInterest.assignments.reduce(
    (accumulator, assignment) => ({
      ...accumulator,
      [`assignments[${assignment.id}].company`]: assignment.company,
      [`assignments[${assignment.id}].typeOfAssignment`]: assignment.typeOfAssignment,
    }),
    {}
  ),
});

export const conflictOfInterestBodyMapper = (conflictOfInterest) => ({
  noConflictOfInterest: conflictOfInterest.noConflictOfInterest,
  assignments:
    conflictOfInterest.assignments && !conflictOfInterest.noConflictOfInterest
      ? mapWorkplaces(conflictOfInterest.assignments)
      : [],
  deviationsDisclosed: conflictOfInterest.deviationsDisclosed === 'true',
  comment: conflictOfInterest.deviationsDisclosed === 'true' ? conflictOfInterest.comment : null,
});

export const idNumberMessage = {
  BR: 'National ID for locals and/or Passport for foreign citizens',
  CN: 'For Citizens: Chinese ID only',
  DK: 'Government Id Number',
  PL: 'PESEL number (government ID number) or NIP number (Tax ID number)',
  BE: 'National ID for locals and/or Passport for foreign citizens',
  CA: 'Government Id Number',
  CO: 'Government ID nr: (Colombian ID Number “Cédula de Identidad,” or “Número de Identificación Tributaria”-NIT without the verification digit number), Passport number or Residence card number for foreigners',
  MT: 'Government ID nr- Local ID Number for Locals and Foreigners registered in Malta (with an Maltese ID card) OR Passport number',
  MX: 'Government ID nr (CUR P or RFC Number)',
  PT: 'Fiscal Declaration Number (Portuguese)',
  SG: 'NRIC or Passport No. for foreigners (Singapore National Registration Identity Card number. (e.g. S1234567D) from the national ID card for Singaporeans or other NRIC/Passport No. for foreigners)',
  ZA: 'Government ID: (South African PIN number is a 13-digit number containing only numeric characters, defined as YYMMDDSSSSCAZ. Please note that if an ID number is not available, the Passport number is also acceptable)',
  UA: 'Government Id Number (Mandatory only for Citizens: Ukrainian Individual Taxpayer Identification Number (INN))',
  IT: 'Fiscal declaration Number (The Fiscal code is alphanumeric code which contains 16 characters)',
};

export const documentMessage = {
  FR: 'CITIZENS: valid national ID card both sides or full clear valid passport / FOREIGNERS: valid foreign passport, valid temporary residence permit, valid resident card, residence certificate of an Algerian national',
  IT: 'VALID Italian ID Card OR VALID Passport (from any Country). For candidates born in Italy but living abroad and holding a foreign ID/Passport, an official document showing the town of birth will be needed (example: Birth Certificate)',
  HR: 'For Citizens: Valid ID both sides OR Valid Passport / For Foreigners: Full Clear Valid Passport AND Residence permit',
  GR: 'For Citizens: National Greek ID Card; For Foreigners: Copy of Passport',
  IN: 'Passport/ID Copy - Both sides',
  NZ: 'Two proofs of identity required; National ID for locals and/or Passport for foreign citizens',
  PT: 'Citizen Card (Cartão de cidadão)/Passport copy (For Citizens: Valid Portuguese Citizen Card OR Valid National ID card Cartão de Cidadão OR Valid Portuguese passport; For Foriegners: Valid Foreign passport) in color– PDF format A4)',
  UA: 'Passport/ID copy (For Citizens: Internal Passport (pages 1-5 + the page with the address registration stamp) or Ukrainian International Passport; For Foreigners: Passport/ID copy)',
};
