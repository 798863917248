import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { onUserLogout, hideNotification, redirectFromLoginPage } from 'store/actions';
import store from 'store/store';
import { getSignedIn, getAccessToken, getRefreshTimeoutId } from 'utils/localStorage';
import { signOut } from 'api/account';
import { routes } from 'constants/routes';

export const logoutActions = () => {
  clearTimeout(getRefreshTimeoutId());

  // "redirectFromLoginPage" forces App component to rerender which will ensure that app will go to login page.
  // App component normally doesn't rerender if we refresh app when being logged in
  store.dispatch(redirectFromLoginPage());

  localStorage.clear();

  store.dispatch(onUserLogout());
  setTimeout(() => {
    // don't show notification when refresh token expires and user is logged out
    if (!getSignedIn()) store.dispatch(hideNotification());
  }, 100);
};

export const logout = async () => {
  const accessToken = getAccessToken();
  logoutActions();
  if (accessToken) await signOut(accessToken);
};

export const useGotoLogoutPage = () => {
  const history = useHistory();

  return useCallback(() => {
    history.push(routes.logoutPage);
  }, [history]);
};
