import styled from 'styled-components/macro';
import { Form as FormBase, Button as ButtonBase, Flexbox, ErrorMessageFe } from 'components/Shared/sharedStyle';
import colors from 'constants/colors';
import { smallUp } from 'constants/media-queries';
import images from 'constants/images';

export const LoginFlexbox = styled(Flexbox)`
  position: relative;
  z-index: 1;
  background-color: black;

  &::after {
    background-image: url(${images.loginBackground});
    background-size: cover;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    z-index: -1;
    opacity: 0.7;
  }
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  background: linear-gradient(90deg, ${colors.white} 50%, ${colors.primaryOrangeBright} 50%);

  @media ${smallUp} {
    width: auto;
    height: auto;
  }
`;

export const LogoContainer = styled.div`
  background-color: ${colors.primaryOrangeBright};
  padding: 40px 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 0px 0px 0px 20px;

  @media ${smallUp} {
    border-radius: 20px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  background-color: ${colors.white};
  padding: ${({ padding }) => padding ?? '40px 50px'};
  padding-top: 30px;
  border-radius: 0px 20px 0px 0px;

  @media ${smallUp} {
    border-radius: 20px;
  }
`;

export const Form = styled(FormBase)`
  width: 300px;
  padding: 30px 20px;
  padding-top: 0px;
`;

export const Button = styled(ButtonBase)`
  width: 100%;
`;

export const PasswordIcon = styled.img`
  width: 24px;
  height: auto;
  position: absolute;
  right: 10px;
  top: calc(50% + 3px);
  transform: translateY(-50%);
  cursor: pointer;
  opacity: ${({ isPasswordVisible }) => (isPasswordVisible ? 1 : 0.5)};
`;

export const PasswordErrorMessage = styled(ErrorMessageFe)`
  ${({ longMessage }) =>
    longMessage &&
    `
    width: 300px;
    left: -15px;
  `}

  width: ${({ width }) => width ?? '300px'};
  left: ${({ left }) => left ?? '0px'};
  top: ${({ top }) => top ?? '0px'};

  span {
    display: block;
    color: ${colors.error};
  }
`;
