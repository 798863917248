import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import hasPermission from 'utils/hasPermission';
import roles from 'constants/roles';
import {
  messagesReceived,
  messagesSent,
  messagesReceivedByAssignedCandidates,
  messagesSentByAssignedCandidates,
} from 'api/messages';
import { hideNewMessagesNotification } from 'store/actions';
import { Flexbox, Divider } from 'components/Shared/sharedStyle';
import { WithModal } from 'components/Shared/sharedComponents';
import Grid from 'components/Shared/Grid/Grid';
import NewMessageAsCandidate from 'components/Messages/NewMessageAsCandidate';
import NewMessageAsGP from 'components/Messages/NewMessageAsGP';
import MessagePreview from 'components/Messages/MessagePreview';
import { MessagesContainer, MessagesListColumn, TabItem } from 'components/Messages/styleMessages';
import {
  headlines,
  candidateHeadlines,
  receivedMessagesMapper,
  sentMessagesMapper,
} from 'components/Messages/configMessages';

const tabs = {
  received: 'received',
  sent: 'sent',
};

const Messages = () => {
  const [activeTab, setActiveTab] = useState(tabs.received);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [openedMessages, addNewOpenedMessage] = useState([]);
  const [forceSentUpdate, triggerUpdate] = useState(0);
  const [isMobilePreviewVisible, setMobilePreviewStatus] = useState(false);
  const { t } = useTranslation();

  const isMobile = useSelector((state) => state.isMobile);
  const unreadMessagesNumber = useSelector((state) => state.unreadMessagesNumber);
  const dispatch = useDispatch();

  useEffect(() => {
    if (openedMessages.length === unreadMessagesNumber) {
      dispatch(hideNewMessagesNotification());
    }
  }, [openedMessages, unreadMessagesNumber, dispatch]);

  const isCandidate = hasPermission([roles.candidate]);
  const isAdminOrInvestigator = hasPermission([roles.investigator, roles.admin]);

  const selectMessage = (message) => {
    if (isMobile) setMobilePreviewStatus(true);

    setSelectedMessage({
      subject: message.plainSubject ? message.plainSubject : message.subject,
      body: message.body,
      date: message.plainDate ? message.plainDate : message.date,
      id: message.id,
      isViewed: message.isViewed,
      isReceived: message.isReceived,
      attachments: message.attachments,
    });
  };

  const receivedMapper = useCallback(
    ({ data: messages }) => receivedMessagesMapper({ messages, openedMessages }),
    [openedMessages]
  );

  return (
    <MessagesContainer>
      <h2>{t('message.messages')}</h2>
      <Flexbox>
        <TabItem onClick={() => setActiveTab(tabs.received)} isActive={tabs.received === activeTab}>
          {t('message.received')}
        </TabItem>
        <TabItem onClick={() => setActiveTab(tabs.sent)} isActive={tabs.sent === activeTab}>
          {t('message.sent')}
        </TabItem>
      </Flexbox>
      <Flexbox>
        <MessagesListColumn>
          <div hidden={activeTab === tabs.sent}>
            <Grid
              headlines={isCandidate ? candidateHeadlines : headlines}
              // When an investigator has 'MESSAGE ADDRESSEE' status, messagesSentByAssignedCandidates includes also unassigned candidates messages.
              fetchDataFunction={isAdminOrInvestigator ? messagesSentByAssignedCandidates : messagesReceived}
              mapperFunction={receivedMapper}
              translationsKey="messagesGrid"
              onRowClick={selectMessage}
              refreshMapperDependency={openedMessages}
              rowsPerPage={10}
            />
          </div>
          <div hidden={activeTab === tabs.received}>
            <Grid
              headlines={isCandidate ? candidateHeadlines : headlines}
              fetchDataFunction={isAdminOrInvestigator ? messagesReceivedByAssignedCandidates : messagesSent}
              mapperFunction={sentMessagesMapper}
              translationsKey="messagesGrid"
              onRowClick={selectMessage}
              refreshMapperDependency={openedMessages}
              triggerUpdate={forceSentUpdate}
              rowsPerPage={10}
            />
          </div>
          <Divider />
          {!isCandidate ? (
            <NewMessageAsGP
              onSubmit={triggerUpdate}
              subject={selectedMessage ? `RE: ${selectedMessage.subject}` : ''}
            />
          ) : (
            <NewMessageAsCandidate
              onSubmit={triggerUpdate}
              subject={selectedMessage ? `RE: ${selectedMessage.subject}` : ''}
            />
          )}
        </MessagesListColumn>
        {!isMobile && selectedMessage && (
          <MessagePreview
            message={selectedMessage}
            addNewOpenedMessage={addNewOpenedMessage}
            openedMessages={openedMessages}
          />
        )}
        {isMobile && selectedMessage && (
          <WithModal isOpen={isMobilePreviewVisible} onClose={() => setMobilePreviewStatus(false)}>
            <MessagePreview
              message={selectedMessage}
              addNewOpenedMessage={addNewOpenedMessage}
              openedMessages={openedMessages}
            />
          </WithModal>
        )}
      </Flexbox>
    </MessagesContainer>
  );
};

export default Messages;
