import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { yearMonthDatePickerFormat } from 'utils/dateUtils';
import {
  getMaxDateForEndDateInPreviousAddresses,
  getMaxDateForStartDateInPreviousAddresses,
  getMinDateForStartAndEndDateInPreviousAddresses,
} from 'utils/candidateFormDateUtils';
import { getMatchingCountryValue } from 'utils/countries';
import icons from 'constants/icons';
import { FormRow, FormGroup, IconButton, LabelWrapper, Div } from 'components/Shared/sharedStyle';
import { Input, Select, DatePicker, FormCheckbox } from 'components/Shared/sharedComponents';
import { getPersonalInfoSectionNum, getPreviousAdressBaseNumber } from 'utils/candidateFormSections';
import { SectionName } from 'components/CandidateForm/styleCandidateForm';

const PreviousAddressForm = ({ formFunctions, remove, groupId, defaultState, index, editingLocked }) => {
  const { t } = useTranslation();

  const sectionNumber = getPersonalInfoSectionNum();
  const baseNumber = getPreviousAdressBaseNumber(index);
  const countriesMappedList = useSelector((state) => state.countries.countriesMappedList);

  const [isNationalIdFieldDisabled, setNationalIdFieldStatus] = useState(false);

  const { register, errors, getValues, watch, triggerValidation, formState } = formFunctions;

  const { isSubmitted } = formState;

  useEffect(() => {
    const currentAddressObject = defaultState.find((address) => address.id === groupId);

    if (currentAddressObject && currentAddressObject.noNationalIdNumber) {
      setNationalIdFieldStatus(true);
    }
  }, [defaultState, groupId]);

  const startDate = watch(`previous[${groupId}].startDate`);
  const endDate = watch(`previous[${groupId}].endDate`);

  useEffect(() => {
    if (isSubmitted) {
      triggerValidation('dateRangeError');
    }
  }, [startDate, isSubmitted, triggerValidation, endDate]);

  const getCountryValue = () => {
    const { previous } = getValues({ nest: true });
    const selectedCountryForThisGroup = previous && previous[groupId] && previous[groupId].country;
    const value = selectedCountryForThisGroup || defaultState.find((address) => address.id === groupId);

    if (!value) {
      return { value: '', label: '' };
    }
    return getMatchingCountryValue(value.country || value);
  };

  const onNoIdCheck = (e) => {
    if (e.target.checked) {
      formFunctions.setValue(`previous[${groupId}].nationalIdNumber`, '');
      setNationalIdFieldStatus(true);
    } else {
      setNationalIdFieldStatus(false);
    }

    if (formFunctions.formState.isSubmitted) {
      formFunctions.triggerValidation(`previous[${groupId}].nationalIdNumber`);
    }
  };

  const errorsReference = (field) => errors.previous && errors.previous[groupId] && errors.previous[groupId][field];

  const shouldDisplayNationalIdNumber = () => {
    const selectedCountry = watch(`previous[${groupId}].country`);

    if (watch(`country`) === selectedCountry) {
      formFunctions.setValue(`previous[${groupId}].nationalIdNumber`, '');
      return false;
    }

    let foundSameCountry = false;
    watch(`previous`) &&
      Object.values(watch(`previous`)).forEach((address, addresIndex) => {
        if (address.country === selectedCountry && addresIndex < index) foundSameCountry = true;
      });

    if (foundSameCountry) {
      formFunctions.setValue(`previous[${groupId}].nationalIdNumber`, '');
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <SectionName>
        <h3>{t('candidateForm.personalDetails.sectionName', { index: index + 1 })}</h3>
        {!editingLocked && <IconButton type="button" onClick={() => remove(groupId)} icon={icons.delete_bin} />}
      </SectionName>
      <FormRow>
        <FormGroup>
          <DatePicker
            name={`previous[${groupId}].startDate`}
            dateFormat={yearMonthDatePickerFormat}
            showMonthYearPicker
            error={errorsReference('startDate')}
            formFunctions={formFunctions}
            label={`${sectionNumber}.${baseNumber + 1} ${t('candidateForm.labels.startDate')}`}
            disabled={editingLocked}
            onChange={([date]) => date}
            scrollableYearDropdown
            minDate={getMinDateForStartAndEndDateInPreviousAddresses({ getValues, currentDateRangeId: groupId })}
            maxDate={
              getMaxDateForStartDateInPreviousAddresses({ getValues, currentDateRangeId: groupId, endDate }) ??
              new Date()
            }
            yearDropdownItemNumber={100}
          />
        </FormGroup>
        <FormGroup>
          <DatePicker
            name={`previous[${groupId}].endDate`}
            dateFormat={yearMonthDatePickerFormat}
            showMonthYearPicker
            error={errorsReference('endDate')}
            formFunctions={formFunctions}
            label={`${sectionNumber}.${baseNumber + 2} ${t('candidateForm.labels.endDate')}`}
            disabled={editingLocked}
            onChange={([date]) => date}
            scrollableYearDropdown
            minDate={getMinDateForStartAndEndDateInPreviousAddresses({
              getValues,
              currentDateRangeId: groupId,
              startDate,
            })}
            maxDate={getMaxDateForEndDateInPreviousAddresses({ getValues, currentDateRangeId: groupId }) ?? new Date()}
            yearDropdownItemNumber={100}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Input
            register={register}
            name={`previous[${groupId}].street`}
            error={errorsReference('street')}
            label={`${sectionNumber}.${baseNumber + 3} ${t('candidateForm.labels.street')}`}
          />
        </FormGroup>
        <FormGroup>
          <Input
            register={register}
            name={`previous[${groupId}].city`}
            error={errorsReference('city')}
            label={`${sectionNumber}.${baseNumber + 4} ${t('candidateForm.labels.city')}`}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Input
            register={register}
            name={`previous[${groupId}].zipCode`}
            error={errorsReference('zipCode')}
            label={`${sectionNumber}.${baseNumber + 5} ${t('candidateForm.labels.zipCode')}`}
          />
        </FormGroup>
        <FormGroup>
          <Select
            name={`previous[${groupId}].country`}
            selectFunctions={formFunctions}
            label={`${sectionNumber}.${baseNumber + 6} ${t('candidateForm.labels.country')}`}
            options={countriesMappedList}
            error={errorsReference('country')}
            defaultValue={getCountryValue()}
            disabled={editingLocked}
          />
        </FormGroup>
      </FormRow>
      {shouldDisplayNationalIdNumber() ? (
        <FormRow>
          <FormGroup>
            <Input
              register={register}
              name={`previous[${groupId}].nationalIdNumber`}
              error={errorsReference('nationalIdNumber')}
              label={`${sectionNumber}.${baseNumber + 7} ${t('candidateForm.labels.nationalIdNumber')}`}
              disabled={isNationalIdFieldDisabled}
            />
            <FormCheckbox
              label={t('candidateForm.labels.noNationalIdNumber')}
              name={`previous[${groupId}].noNationalIdNumber`}
              register={register}
              error={errors.noNationalIdNumber}
              onChange={onNoIdCheck}
            />
          </FormGroup>
          <FormGroup />
        </FormRow>
      ) : (
        <Div marginBottom="10px">
          <LabelWrapper>{`${sectionNumber}.${baseNumber + 7} ${t(
            'candidateForm.labels.nationalIdNumber'
          )} (already provided for this country)`}</LabelWrapper>
        </Div>
      )}
    </>
  );
};

PreviousAddressForm.propTypes = {
  formFunctions: PropTypes.object.isRequired,
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  remove: PropTypes.func.isRequired,
  defaultState: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      previous: PropTypes.arrayOf(PropTypes.object),
    })
  ).isRequired,
  index: PropTypes.number.isRequired,
  editingLocked: PropTypes.bool.isRequired,
};

export default PreviousAddressForm;
