const emplymentTypes = {
  fullTime: {
    name: 'fullTime',
    value: 0,
  },
  partTime: {
    name: 'partTime',
    value: 1,
  },
};

export const getEmplymentOptions = (t) => [
  { label: t(`candidateForm.labels.${emplymentTypes.fullTime.name}`), value: emplymentTypes.fullTime.value },
  { label: t(`candidateForm.labels.${emplymentTypes.partTime.name}`), value: emplymentTypes.partTime.value },
];
