import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { showNotification } from 'store/actions';
import { sendMessageAsGP } from 'api/messages';
import { unlockEditing } from 'api/candidate';
import { Form, FormGroup, Button, Divider, Flexbox } from 'components/Shared/sharedStyle';
import { Input, TextArea, MultipleFilesUploader } from 'components/Shared/sharedComponents';
import { FloatingMessageContainer, FloatingMessageScroll } from 'components/Messages/styleMessages';
import { sendAsGPFloaringMsgBoxSchema } from 'components/Messages/messagesSchema';
import usePrevious from 'hooks/usePrevious';

const FloatingMessageAsGP = ({ candidate, setHeightOnChange, animateTransitionRef }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoading, setLoadingStatus] = useState(false);
  const [shouldReset, setShouldResetStatus] = useState(null);

  const formFunctions = useForm({
    validationSchema: sendAsGPFloaringMsgBoxSchema,
  });

  const { reset, watch, handleSubmit, register, errors } = formFunctions;

  const prevAttachmentRef = usePrevious(watch('attachment'));

  useEffect(() => {
    if (prevAttachmentRef !== watch('attachment') && prevAttachmentRef !== undefined) {
      animateTransitionRef.current = false;
      setHeightOnChange();
    }
  }, [animateTransitionRef, prevAttachmentRef, setHeightOnChange, watch]);

  const submitForm = async (form) => {
    setLoadingStatus(true);

    try {
      const uploadForm = new FormData();
      uploadForm.append('request.Subject', form.subject);
      uploadForm.append('request.Body', form.body);
      uploadForm.append('request.Receiver', candidate?.id);

      if (form.attachment) {
        form.attachment.forEach((file, index) => {
          uploadForm.append(`request.Attachments[${index}].FileType`, 'Attachment');
          uploadForm.append(`request.Attachments[${index}].File`, file);
          uploadForm.append(`request.Attachments[${index}].Number`, index);
        });
      }

      await sendMessageAsGP(uploadForm);
      dispatch(
        showNotification({
          message: t('notifications.saved'),
          type: 'success',
        })
      );
      reset();
      setShouldResetStatus(true);
      setShouldResetStatus(false);
    } catch (error) {
      dispatch(
        showNotification({
          message: t('notifications.error'),
          type: 'error',
        })
      );
    }

    setLoadingStatus(false);
  };

  const unlock = async (e) => {
    e.preventDefault();
    setLoadingStatus(true);
    try {
      await unlockEditing(candidate?.id);
      dispatch(
        showNotification({
          message: t('notifications.unlocked'),
          type: 'success',
        })
      );
    } catch (error) {
      dispatch(
        showNotification({
          message: error.message,
          type: 'error',
        })
      );
    }
    setLoadingStatus(false);
  };

  const candidateDisplayName = candidate ? `${candidate.name} ${candidate.surname}` : '';

  return (
    <FloatingMessageContainer>
      <FloatingMessageScroll>
        <h4>{`${t('message.to')}: ${candidateDisplayName}`}</h4>
        <Form onSubmit={handleSubmit(submitForm)}>
          <FormGroup>
            <Input register={register} name="subject" error={errors.subject} label={t('common.topic')} />
          </FormGroup>
          <FormGroup>
            <TextArea register={register} name="body" error={errors.body} label={t('common.message')} noResize />
          </FormGroup>
          <div>
            <label>{t('message.attachment')}</label>
            <MultipleFilesUploader
              label={t('message.addAttachment')}
              name="attachment"
              errors={errors.attachment}
              formFunctions={formFunctions}
              shouldReset={shouldReset}
              narrow
              oneLineAndPassWidth="222px"
            />
          </div>
          <Divider small />
          <Flexbox justify="space-between" direction="column">
            <Button disabled={isLoading} type="submit" margin="10px 0px 10px 0px">
              {t('common.send')}
            </Button>
            <Button disabled={!candidate?.id || !candidate?.unlockingAllowed} onClick={unlock}>
              {t('candidateForm.unlockEditing')}
            </Button>
          </Flexbox>
        </Form>
      </FloatingMessageScroll>
    </FloatingMessageContainer>
  );
};

FloatingMessageAsGP.propTypes = {
  candidate: PropTypes.object,
  setHeightOnChange: PropTypes.func,
  animateTransitionRef: PropTypes.object,
};

export default FloatingMessageAsGP;
