import styled, { keyframes } from 'styled-components/macro';

import colors from 'constants/colors';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const NotificationContainer = styled.div`
  width: 300px;
  position: absolute;
  top: 40px;
  right: 40px;
  border-radius: 15px;
  background-color: ${({ color }) => color};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  animation: ${({ isVisible }) => (isVisible ? fadeIn : fadeOut)} 0.5s linear;
  transition: visibility 0.5s linear;
  position: fixed;
  padding: 15px 20px;
  color: ${colors.white};
  z-index: 1;
  box-shadow: 0px 2px 5px #bdbdbd;

  @media (max-width: 760px) {
    top: 70px;
    right: 0;
    left: 0;
    margin: 0 auto;
  }
`;
