import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { educationType, fileTypes } from 'constants/types';
import { saveEducation } from 'api/candidate';
import { uploadFiles, removeSelectedFiles } from 'utils/uploadMultipleFiles';
import useDispatchNotification from 'components/Shared/Notification/DispatchNotificationHook';
import { addCandidateFormErrors, addFileToRemoveList } from 'store/actions';
import { Button, Form, Divider, Flexbox } from 'components/Shared/sharedStyle';
import { FormCheckbox } from 'components/Shared/sharedComponents';
import { NextStepsButtons, ButtonsContainer } from 'components/CandidateForm/styleCandidateForm';
import { getEducationSectionNum } from 'utils/candidateFormSections';
import { mapDefaultEducationValues, educationBodyMapper } from 'components/CandidateForm/Form/configCandidateForm';
import { educationSchema } from 'components/CandidateForm/Form/candidateFormSchema';
import EducationForm from './EducationForm';

const EducationStep = ({
  previousStep,
  nextStep,
  defaultFormState,
  defaultFiles,
  noEducation,
  editingLocked,
  fetchFiles,
  fetchCandidateDetails,
  validationFunctionsArray,
  dirtySectionsChecker,
  saveFormFunctions,
}) => {
  const { dispatchSuccessNotification, dispatchErrorNotification } = useDispatchNotification();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const sectionNumber = getEducationSectionNum();
  const isCandidateFormSubmitted = useSelector((state) => state.isCandidateFormSubmitted);

  const [isLoading, setLoadingStatus] = useState(false);
  const [degrees, updateDegrees] = useState([]);
  const [certificatesToRemoveFromView, setCertificatesToRemoveFromView] = useState([]);

  const formFunctions = useForm({
    validationSchema: educationSchema,
  });

  const { register, reset, getValues, handleSubmit, errors, setValue, formState, triggerValidation } = formFunctions;

  const lastSavedFormState = useRef('');
  // setTimeout waits till files are registerd because it happends in useEffect in MultipleFilesUploader
  const setLastSavedFormState = useCallback(
    () =>
      setTimeout(() => {
        lastSavedFormState.current = JSON.stringify(getValues());
      }, 0),
    [getValues]
  );

  const submitForm = useCallback(async () => {
    const form = getValues({ nest: true });

    setLoadingStatus(true);

    try {
      await saveEducation(educationBodyMapper(form));

      if (form.noEducation) {
        const filesToRemove = [];
        Object.keys(form.education).forEach((id) => {
          form.education[id]?.degree && form.education[id]?.degree.forEach((degree) => filesToRemove.push(degree.id));
        });

        if (filesToRemove.length) await removeSelectedFiles(filesToRemove);

        await fetchCandidateDetails(); // to delet later and make dates reset without refreshing data
      } else if (form.education) {
        const certificatesToRemove = [];

        Object.keys(form.education).forEach((id) => {
          if (form.education[id].noCertificate) {
            certificatesToRemove.push(id);

            if (form.education[id].degree) {
              form.education[id].degree.forEach((degree) => degree?.id && dispatch(addFileToRemoveList(degree.id)));
            }
          }
        });

        setCertificatesToRemoveFromView(certificatesToRemove);

        const filesToUpload = [];
        Object.keys(form.education).forEach((id) => {
          !form.education[id].noCertificate &&
            form.education[id].degree &&
            filesToUpload.push([form.education[id].degree, fileTypes.degree]);
        });

        await uploadFiles(filesToUpload);
        await fetchFiles();
      }

      setLastSavedFormState();
      // lastSavedFormState.current = JSON.stringify(getValues());
      dispatchSuccessNotification(t('notifications.saved'));
    } catch (catchedError) {
      dispatchErrorNotification({ catchedError });
    }

    setLoadingStatus(false);
  }, [
    dispatch,
    dispatchErrorNotification,
    dispatchSuccessNotification,
    fetchCandidateDetails,
    fetchFiles,
    getValues,
    setLastSavedFormState,
    t,
  ]);

  // should run only once
  useEffect(() => {
    // "handleSubmit" will be run in candidateForm and SubmittingDataStep,
    // it changes "hook-form" state to submitted which runs validation on every keystroke
    validationFunctionsArray.push(handleSubmit(() => {}));

    // used to determine whether to show "Leave Guard" and then save form
    dirtySectionsChecker.education = () => lastSavedFormState.current !== JSON.stringify(getValues());
    saveFormFunctions.education = submitForm;
  }, [dirtySectionsChecker, getValues, handleSubmit, submitForm, saveFormFunctions, validationFunctionsArray]);

  useEffect(() => {
    if (!defaultFormState) return;

    if (defaultFormState.length) {
      updateDegrees(defaultFormState.map((address) => address.id));
    } else {
      // Workaround to present degrees as object instead array in form state
      const firstElementId = `${new Date().getTime()}-1`;
      updateDegrees([firstElementId]);
    }

    reset(mapDefaultEducationValues(defaultFormState, noEducation));
    setLastSavedFormState();
  }, [defaultFormState, reset, noEducation, getValues, setLastSavedFormState]);

  // adds errors to redux state on every keystroke when condition is true
  useEffect(() => {
    if (isCandidateFormSubmitted) {
      // makes deep copy of error object - fixes "Invariant failed" redux error
      const newErrors = JSON.parse(JSON.stringify(errors));

      dispatch(addCandidateFormErrors({ education: newErrors.education || {} }));
    }
  });

  const proceedToNextStep = async () => {
    if (!editingLocked) await submitForm();
    nextStep();
  };

  const addAnotherDegree = () => {
    const id = `${new Date().getTime()}-1`; // must be a string to be parsed correctly as object key by redux, Date() returns number.

    updateDegrees((degrees) => degrees.concat(id));

    setTimeout(() => {
      if (isCandidateFormSubmitted) {
        triggerValidation(`education[${id}]`);
      }
    }, 0);
  };

  const removeGroup = (id) => {
    updateDegrees((degrees) => degrees.filter((groupId) => groupId !== id));

    const degreesToRemove = getValues()[`education[${id}].degree`] ? getValues()[`education[${id}].degree`] : [];

    const filesToRemove = [...degreesToRemove];

    if (filesToRemove) {
      dispatch(addFileToRemoveList(filesToRemove.map((file) => file.id)));
    }

    if (degrees.length - 1 === 0) {
      setValue('noEducation', true);
    }
  };

  const shouldIncludesRemoveFunction = degrees.length !== 1 && !editingLocked;

  const onNoEducationCheck = () => {
    if (formState.isSubmitted) triggerValidation();
  };

  return (
    <Form>
      <fieldset disabled={editingLocked}>
        <h2>{`${sectionNumber}. ${t('candidateForm.education.title')}`}</h2>
        <p>{t('candidateForm.education.description')}</p>
        <FormCheckbox
          label={t('candidateForm.education.noEducation')}
          name="noEducation"
          register={register}
          onChange={onNoEducationCheck}
          error={formFunctions.errors.noEducation}
        />
        <div hidden={formFunctions.watch('noEducation')}>
          <Flexbox direction="column">
            {degrees.map((id, index) => (
              <EducationForm
                key={id} // passing index as key may lead to incorrect unmounting
                formFunctions={formFunctions}
                groupId={id}
                remove={shouldIncludesRemoveFunction && index === degrees.length - 1 ? removeGroup : null}
                editingLocked={editingLocked}
                index={index}
                defaultFiles={defaultFiles}
                certificatesToRemoveFromView={certificatesToRemoveFromView}
              />
            ))}
            <Divider marginTop="15px" />
            <Button onClick={addAnotherDegree} type="button">
              {t('candidateForm.education.addDegree')}
            </Button>
          </Flexbox>
        </div>
      </fieldset>
      <Divider />
      <ButtonsContainer>
        <Button onClick={previousStep} outlined type="button">
          {t('common.previous')}
        </Button>
        <NextStepsButtons>
          <Button onClick={submitForm} disabled={editingLocked || isLoading} type="button">
            {t('common.save')}
          </Button>
          <Button disabled={isLoading} onClick={proceedToNextStep} type="button">
            {t('common.next')}
          </Button>
        </NextStepsButtons>
      </ButtonsContainer>
    </Form>
  );
};

EducationStep.propTypes = {
  nextStep: PropTypes.func.isRequired,
  defaultFormState: educationType,
  defaultFiles: PropTypes.object,
  previousStep: PropTypes.func.isRequired,
  noEducation: PropTypes.bool,
  editingLocked: PropTypes.bool.isRequired,
  fetchFiles: PropTypes.func.isRequired,
  fetchCandidateDetails: PropTypes.func.isRequired,
  validationFunctionsArray: PropTypes.array.isRequired,
  dirtySectionsChecker: PropTypes.object.isRequired,
  saveFormFunctions: PropTypes.object.isRequired,
};

EducationStep.defaultProps = {
  defaultFormState: [],
  defaultFiles: [],
  noEducation: false,
};

export default EducationStep;
