import styled from 'styled-components/macro';

import { status } from 'constants/status';
import colors from 'constants/colors';
import { IconButton } from 'components/Shared/sharedStyle';

export const CandidatesContainer = styled.div`
  padding: 40px;
  width: 100%;

  @media (max-width: 760px) {
    overflow-x: scroll;
    padding: 30px 10px;
  }
`;

export const Circle = styled.div`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid ${colors.primary};
  margin: 1px;
  background-color: ${({ color }) => color};

  ${({ statusCode }) =>
    (statusCode === status.rejected || statusCode === status.refused) &&
    `
    border: 1px solid ${colors.secondaryGray};
  `}
`;

export const ActionsColumn = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const EventsCellContainer = styled.div`
  position: relative;
`;

export const EventsModalContainer = styled.div`
  border-radius: 15px 15px 0 15px;
  position: absolute;
  padding: 20px;
  right: calc(100% + 10px);
  bottom: 20px;
  background-color: ${colors.white};
  box-shadow: 1px 1px 4px ${colors.secondaryGray};
  width: max-content;
  z-index: 1;

  ${IconButton} {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  p {
    margin: 2px 0;
  }
`;

export const SendEmailsContainer = styled.div`
  margin: 5px 20px 5px 0;
`;
