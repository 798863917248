import styled from 'styled-components/macro';

import images from 'constants/images';
import colors from 'constants/colors';
import { Flexbox } from 'components/Shared/sharedStyle';

export const WelcomePageFlexbox = styled(Flexbox)`
  position: relative;
  z-index: 1;
  background-color: black;
  flex: 1;

  &::after {
    background-image: url(${images.welcomeBackground});
    background-size: cover;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    z-index: -1;
    opacity: 0.7;
  }
`;

export const WelcomePageContainer = styled.div`
  border-radius: 15px;
  background-color: ${colors.white};
  padding: 30px 50px;
  max-width: 750px;

  @media (max-width: 760px) {
    padding: 10px 15px;
    margin: 0 20px;
  }
`;
