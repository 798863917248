import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { evaluationItemType, referenceType } from 'constants/types';
import { WysiwygEditor } from 'components/Shared/sharedComponents';
import { Divider } from 'components/Shared/sharedStyle';
import StatusButtons from 'components/CandidateView/StatusButtons';
import { getMemberOfHouseholdsSectionNum } from 'utils/candidateFormSections';
import { textBoxesRefsNames } from 'components/CandidateView/configCandidateView';

const MembersOfHouseholds = ({ textBoxesRefs, defaultEvaluation, evaluation, updateEvaluationStatus }) => {
  const { t } = useTranslation();
  const sectionNumber = getMemberOfHouseholdsSectionNum() + 1;
  const sectionTitle = t('candidateViewSection.household');

  return (
    <>
      <h2>{`${sectionNumber}. ${t('candidateViewSection.household')}`}</h2>
      <WysiwygEditor
        defaultValue={defaultEvaluation?.comment}
        editorRef={textBoxesRefs}
        saveRefInAnotherRef
        name={textBoxesRefsNames?.householdComment}
        sectionTitle={sectionTitle}
      />
      <StatusButtons value={evaluation} evaluationKey="household" onClick={updateEvaluationStatus} />
      <Divider />
    </>
  );
};

MembersOfHouseholds.propTypes = {
  textBoxesRefs: referenceType.isRequired,
  defaultEvaluation: evaluationItemType.isRequired,
  evaluation: PropTypes.number,
  updateEvaluationStatus: PropTypes.func.isRequired,
};

export default MembersOfHouseholds;
