import { useHistory } from 'react-router-dom';
import qs from 'qs';

export const queryNames = {
  range: 'range',
  search: 'search',
  countries: 'countries',
  page: 'page',
  // sorting is saved on BE and can be accesed with getSavedParameter()
};

export const useUrlQuery = () => {
  const history = useHistory();

  const getUrlQuery = () => qs.parse(history.location.search.replaceAll('?', ''));

  const setUrlQuery = ({ name, value }) => {
    const query = { ...getUrlQuery(), [name]: value };
    const queryWithoutEmptyKeys = removeKeyIfValueIsEmpty(query);
    history.replace(`${history.location.pathname}?${qs.stringify(queryWithoutEmptyKeys)}`);
  };

  return { getUrlQuery, setUrlQuery };
};

const removeKeyIfValueIsEmpty = (obj) => {
  const copiedObject = structuredClone(obj);

  Object.keys(copiedObject).forEach((key) => {
    if (!copiedObject[key]) {
      delete copiedObject[key];
    }
  });

  return copiedObject;
};
