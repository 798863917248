import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, PieChart, Pie, Tooltip, Cell, Legend } from 'recharts';
import { activeCandidatesData } from './chartData';
import colors from 'constants/colors';
import { Flexbox } from 'components/Shared/sharedStyle';
import { Rectangle } from './style';

const PieChartComponent = ({ title, name }) => {
  const data = activeCandidatesData(name);

  const COLORS = [colors.primaryOrangeBright, colors.darkGrey, '#dfa427', '#FF8042', '#bb6032'];

  const renderLegend = ({ payload }) => {
    return (
      <>
        {payload.map((entry, index) => (
          <Flexbox align="center" key={`item-${index}`}>
            <Rectangle color={payload[index].color} />
            <div>{entry.value}</div>
          </Flexbox>
        ))}
      </>
    );
  };

  return (
    <>
      <h2 style={{ marginLeft: '25px' }}>{title}</h2>
      <Flexbox justify="center">
        <ResponsiveContainer width={400} height={300}>
          <PieChart>
            <Pie
              dataKey="value"
              // isAnimationActive={false}
              data={data}
              cx="40%"
              cy="50%"
              outerRadius={80}
              fill="#8884d8"
              label
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Legend layout="vertical" align="right" verticalAlign="middle" chartWidth="100" content={renderLegend} />
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </Flexbox>
    </>
  );
};

PieChartComponent.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default PieChartComponent;
