import React from 'react';
import PropTypes from 'prop-types';

import { ErrorMessageFe, Label } from 'components/Shared/sharedStyle';
import { CheckboxNode, CheckboxDiv } from 'components/Shared/Checkbox/styleCheckbox';

// React only controled checkbox
const Checkbox = ({ value, onChange, label, name, error, longMessage, margin, disabled, width, marginTop }) => (
  <CheckboxDiv width={width} marginTop={marginTop}>
    <CheckboxNode
      checked={value}
      onChange={onChange}
      name={name}
      id={name}
      hasError={!!error}
      disabled={disabled}
      type="checkbox"
    />
    <Label margin={margin} htmlFor={name}>
      {label}
    </Label>
    {error && <ErrorMessageFe longMessage={longMessage}>{error.message}</ErrorMessageFe>}
  </CheckboxDiv>
);

Checkbox.propTypes = {
  value: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  longMessage: PropTypes.bool,
  margin: PropTypes.string,
  disabled: PropTypes.bool,
  width: PropTypes.string,
  marginTop: PropTypes.string,
};

Checkbox.defaultProps = {
  error: null,
  label: '',
  longMessage: false,
};

export default Checkbox;
