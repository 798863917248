import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { dateToStringYearMonthDay } from 'utils/dateUtils';
import icons from 'constants/icons';
import { IconButton } from 'components/Shared/sharedStyle';
import { EventsModalContainer } from 'components/CandidatesList/styleCandidatesList';

const EventsModal = ({ onModalClose, events, candidateName, onModalOpen }) => {
  const { t } = useTranslation();

  const activityTypes = {
    FileUploaded: t('events.fileUploaded'),
    DataSubmitted: t('events.dataSubmitted'),
    MessageSent: t('events.messageSent'),
  };

  useEffect(() => {
    onModalOpen();
  }, [onModalOpen]);

  return (
    <EventsModalContainer>
      <IconButton icon={icons.close} onClick={onModalClose} />
      <h3>{t('events.title', { candidateName })}</h3>
      {events.length ? (
        events.map((event, index) => (
          <p key={index}>
            <b>{dateToStringYearMonthDay(event.timeStamp)}</b> {activityTypes[event.activityType]}
          </p>
        ))
      ) : (
        <i>{t('events.noEvents')}</i>
      )}
    </EventsModalContainer>
  );
};

EventsModal.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      timeStamp: PropTypes.string.isRequired,
      activityType: PropTypes.string.isRequired,
    })
  ).isRequired,
  candidateName: PropTypes.string.isRequired,
  onModalOpen: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
};

export default EventsModal;
