import React, { useEffect } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { checkUnreadMessages, onResize } from 'store/actions';
import hasPermission from 'utils/hasPermission';
import roles from 'constants/roles';
import { routes } from 'constants/routes';
import Sidebar from 'components/Sidebar/Sidebar';
import CandidatesList from 'components/CandidatesList/CandidatesList';
import Header from 'components/Header/Header';
import ProtectedRoute from 'components/Shared/ProtectedRoute';
import { Flexbox } from 'components/Shared/sharedStyle';
import CandidateForm from 'components/CandidateForm/CandidateForm';
import Messages from 'components/Messages/Messages';
import MessagesHistory from 'components/Messages/MessagesHistory';
import CandidateReport from 'components/CandidateView/CandidateReport';
import CandidateView from 'components/CandidateView/CandidateView';
import Statistics from 'components/Statistics/Statistics';
import InvestigatorsList from 'components/Investigators/InvestigatorsList';
import WelcomePage from 'components/WelcomePage/WelcomePage';
import CustomersList from 'components/Customers/Customer/CustomersList';
import Customer from 'components/Customers/Customer/Customer';
import EditCountries from 'components/EditCountries/EditCountries';
import FrequentTexts from 'components/FrequentTexts/FrequentTexts';
import AddCustomerUser from 'components/Customers/CustomerUser/AddCustomerUser';
import CustomerUsersList from 'components/Customers/CustomerUser/CustomerUsersList';
import AddCandidate from 'components/Customers/AddCandidate/AddCandidate';
import DocumentPins from 'components/Customers/DocumentPins';
import MyOrganization from 'components/Customers/MyOrganization';
import Notification from 'components/Shared/Notification/Notification';
import AddInvestigator from 'components/Investigators/AddInvestigator/AddInvestigator';
import { GlobalModal } from 'components/Shared/sharedComponents';
import LogoutPage from 'components/Login/LogoutPage';
import LogoutModal from 'components/Shared/Modal/LogoutModal';

const Dashboard = () => {
  if (hasPermission([roles.investigator, roles.customer, roles.customerSupervisor])) {
    return <Redirect to={routes.candidates} />;
  }

  if (hasPermission([roles.candidate])) {
    return <Redirect to={routes.welcome} />;
  }

  return null;
};

const SignedInContent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkUnreadMessages());
    window.addEventListener('resize', () => dispatch(onResize()));
  }, [dispatch]);

  return (
    <>
      <Sidebar />
      <Flexbox direction="column">
        <Header />
        <Switch>
          <Route path="/" exact component={Dashboard} />

          {/* Shared routes */}
          <ProtectedRoute
            path={routes.logoutPage}
            component={LogoutPage}
            roles={[roles.admin, roles.investigator, roles.candidate, roles.customer, roles.customerSupervisor]}
          />

          <ProtectedRoute
            path={routes.messages}
            component={Messages}
            roles={[roles.admin, roles.investigator, roles.candidate]}
          />
          <ProtectedRoute
            path={routes.candidates}
            component={CandidatesList}
            roles={[roles.admin, roles.investigator, roles.customer, roles.customerSupervisor]}
          />

          {/* Admin routes*/}
          <ProtectedRoute path={routes.investigatorsList} component={InvestigatorsList} roles={[roles.admin]} />
          <ProtectedRoute path="/add-investigator" component={AddInvestigator} roles={[roles.admin]} />
          <ProtectedRoute path="/edit-countries" component={EditCountries} roles={[roles.admin]} />
          <ProtectedRoute path="/frequent-texts" component={FrequentTexts} roles={[roles.admin]} />

          {/* Admin and Investigator routes */}
          <ProtectedRoute path="/candidate/:id" component={CandidateView} roles={[roles.admin, roles.investigator]} />
          <ProtectedRoute path="/statistics" component={Statistics} roles={[roles.admin, roles.investigator]} />
          <ProtectedRoute path="/customers" component={CustomersList} roles={[roles.admin, roles.investigator]} />
          <ProtectedRoute path="/add-customer" component={Customer} roles={[roles.admin, roles.investigator]} />
          <ProtectedRoute path="/edit-customer/:id" component={Customer} roles={[roles.admin, roles.investigator]} />
          <ProtectedRoute
            path={routes.addCustomerUser}
            component={AddCustomerUser}
            roles={[roles.admin, roles.investigator, roles.customerSupervisor]}
          />
          <ProtectedRoute
            path="/customer-users"
            component={CustomerUsersList}
            roles={[roles.admin, roles.investigator]}
          />
          <ProtectedRoute
            path="/messages-history/:candidateId/:candidateName/:candidateSurName"
            component={MessagesHistory}
            roles={[roles.admin, roles.investigator]}
          />

          {/* Customer routes */}
          <ProtectedRoute
            path={routes.addCandidate}
            component={AddCandidate}
            roles={[roles.admin, roles.investigator, roles.customer, roles.customerSupervisor]}
          />
          <ProtectedRoute
            path={routes.myPins}
            component={DocumentPins}
            roles={[roles.customer, roles.customerSupervisor]}
          />
          <ProtectedRoute
            path={routes.users}
            component={MyOrganization}
            roles={[roles.customer, roles.customerSupervisor]}
          />

          {/* Candidate routes */}
          <ProtectedRoute path="/welcome" component={WelcomePage} roles={[roles.candidate]} />
          <ProtectedRoute path="/my-recruitment/:fieldId?" component={CandidateForm} roles={[roles.candidate]} />
          <ProtectedRoute path={routes.myReport} component={CandidateReport} roles={[roles.candidate]} />

          <Route path="*" component={Dashboard} />
        </Switch>
      </Flexbox>
      <Notification />
      <GlobalModal />
      <LogoutModal />
    </>
  );
};

export default SignedInContent;
