export const checkIfTableWasCopiedFromExcelOrEditedOrLoadedFirstTime = (eventArray) => {
  for (let i = 0; i < eventArray?.ops?.length; i++) {
    if (eventArray.ops[i]?.hasOwnProperty('insert') || eventArray.ops[i]?.hasOwnProperty('delete')) {
      return true;
    }
  }
  return false;
};

export const numberToOrdinal = (number) => {
  if (typeof number !== 'number' || isNaN(number)) return '';

  let lastDigit = number % 10;
  let lastTwoDigits = number % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return number + 'th';
  }

  switch (lastDigit) {
    case 1:
      return number + 'st';
    case 2:
      return number + 'nd';
    case 3:
      return number + 'rd';
    default:
      return number + 'th';
  }
};
