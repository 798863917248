import styled from 'styled-components/macro';
import { Table } from 'components/Shared/Grid/styleGrid';

import { FullWidthContainer } from 'components/Shared/sharedStyle';

export const PageContainer = styled(FullWidthContainer)`
  ${Table} {
    margin-top: 10px;
  }
`;
