import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { addFileToRemoveList } from 'store/actions';
import { getFields, updateFields } from 'api/country';
import { uploadTemplate, removeTemplate } from 'utils/uploadMultipleFiles';
import { Divider, Button, Container, FormRow, FormGroup } from 'components/Shared/sharedStyle';
import { Select } from 'components/Shared/sharedComponents';
import useDispatchNotification from 'components/Shared/Notification/DispatchNotificationHook';
import { getMatchingCountryValue } from 'utils/countries';
import { NextStepsButtons, ButtonsContainer } from 'components/CandidateForm/styleCandidateForm';
import {
  editCountriesFormNames,
  editCountriesSchema,
  updateFieldsMapper,
  mapDefaultCountryValues,
  fieldsMapper,
  FieldTypes,
} from 'components/EditCountries/configEditCountries';
import EditField from 'components/EditCountries/EditField';
import { LoaderInsideElements } from 'components/Shared/Loader/Loaders';

const { Fcountry } = editCountriesFormNames;

const EditCountries = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dispatchSuccessNotification, dispatchErrorNotification } = useDispatchNotification();
  const countriesMappedList = useSelector((state) => state.countries.countriesMappedList);

  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState([]);

  const formFunctions = useForm({ validationSchema: editCountriesSchema, defaultValues: { country: '', fields: {} } });

  const { reset, errors, watch, handleSubmit, getValues } = formFunctions;

  const country = watch(Fcountry);

  const getCountryFields = useCallback(async () => {
    if (!country) return;

    setIsLoading(true);

    const resFields = await getFields(country);
    setFields(resFields);

    setIsLoading(false);
  }, [country]);

  useEffect(() => {
    getCountryFields();
  }, [country, getCountryFields]);

  useEffect(() => {
    reset(mapDefaultCountryValues(fields, country));
  }, [country, fields, reset]);

  const addAnotherField = () => {
    const mappedFields = fieldsMapper(getValues({ nest: true }));
    setFields(mappedFields.concat({ id: uuid() }));
  };

  const removeField = (id) => {
    const removedFieldData = fields.filter((field) => field.id === id);

    removedFieldData.forEach((field) => {
      field?.template && dispatch(addFileToRemoveList(field?.template.id));
    });

    const mappedFields = fieldsMapper(getValues({ nest: true }));
    setFields(mappedFields.filter((field) => field.id !== id));
  };

  const saveCountry = async (form) => {
    setIsLoading(true);

    const templatesToUpload = [];
    form.fields &&
      Object.keys(form.fields).forEach(
        (id) =>
          form.fields[id]?.type === FieldTypes.uploadDownload &&
          form.fields[id]?.template &&
          templatesToUpload.push({ files: form.fields[id].template, id })
      );

    const templatesIdsToRemove = [];
    form.fields &&
      Object.keys(form.fields).forEach((id) => {
        if (form.fields[id].type !== FieldTypes.uploadDownload && form.fields[id]?.template?.length) {
          form.fields[id]?.template.forEach((template) => templatesIdsToRemove.push(template.id));
        }
      });

    try {
      await removeTemplate(templatesIdsToRemove);
      await updateFields(country, updateFieldsMapper(form));
      await uploadTemplate(templatesToUpload);
      await getCountryFields(); // must refetch files to know their BE ids to be able to remove them in the future
      dispatchSuccessNotification(t('notifications.saved'));
    } catch (catchedError) {
      dispatchErrorNotification({ catchedError });
    }

    setIsLoading(false);
  };

  return (
    <Container>
      <h2>{t('countries.editCountries')}</h2>
      <Divider />
      <FormRow>
        <FormGroup>
          <Select
            name={Fcountry}
            error={errors[Fcountry]}
            selectFunctions={formFunctions}
            label={t(`candidateForm.labels.${Fcountry}`)}
            options={countriesMappedList}
            defaultValue={getMatchingCountryValue(country)}
          />
        </FormGroup>
        <FormGroup />
      </FormRow>
      {isLoading && <LoaderInsideElements />}
      {Object.entries(watch()).length > 1 && // needed so it doesn't rerender type as undefined for the first time
        fields.length > 0 &&
        fields.map((field, index) => (
          <EditField key={index} groupId={field.id} index={index} formFunctions={formFunctions} remove={removeField} />
        ))}
      <Divider />
      <ButtonsContainer>
        <Button disabled={isLoading || !watch(Fcountry)} onClick={addAnotherField}>
          {t('countries.addField')}
        </Button>
        <NextStepsButtons>
          <Button disabled={isLoading || !watch(Fcountry)} onClick={handleSubmit(saveCountry)}>
            {t('common.save')}
          </Button>
        </NextStepsButtons>
      </ButtonsContainer>
    </Container>
  );
};

export default EditCountries;
