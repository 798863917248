import styled, { css } from 'styled-components/macro';
import ReactSelect from 'react-select';
import { Link as RouterLink } from 'react-router-dom';

import colors from 'constants/colors';
import icons from 'constants/icons';
import { Table } from 'components/Shared/Grid/styleGrid';

export const basicCSSproperties = css`
  display: ${({ display }) => display};
  position: ${({ position }) => position};
  margin: ${({ margin }) => margin};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-right: ${({ marginRight }) => marginRight};
  padding: ${({ padding }) => padding};
  padding-top: ${({ paddingTop }) => paddingTop};
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
  padding-right: ${({ paddingRight }) => paddingRight};
  padding-left: ${({ paddingLeft }) => paddingLeft};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  min-width: ${({ minWidth }) => minWidth};
  height: ${({ height }) => height};
  ${({ pointer }) => (pointer ? '&:hover { cursor: pointer}' : '')};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: ${({ textAlign }) => textAlign};
  color: ${({ color }) => (color ? color : 'inherit')};
  background-color: ${({ backgroundColor }) => backgroundColor};
  text-transform: ${({ uppercase }) => uppercase && 'uppercase'};
  text-transform: ${({ textTransform }) => textTransform};
  white-space: ${({ whiteSpace }) => whiteSpace};
  cursor: ${({ cursor }) => cursor};

  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;

export const H1 = styled.h1`
  ${basicCSSproperties}
`;

export const H2 = styled.h2`
  ${basicCSSproperties}
`;

export const H3 = styled.h3`
  ${basicCSSproperties}
`;

export const H4 = styled.h4`
  ${basicCSSproperties}
`;

export const H5 = styled.h5`
  ${basicCSSproperties}
`;

export const Div = styled.div`
  ${basicCSSproperties}
`;

export const Paragraph = styled.p`
  ${basicCSSproperties}
`;

export const Span = styled.span`
  ${basicCSSproperties}
`;

export const Label = styled.label`
  ${basicCSSproperties}
`;
export const A = styled.a`
  ${basicCSSproperties}
`;

export const Flexbox = styled.div`
  display: flex;
  ${basicCSSproperties}
  justify-content: ${({ justify }) => justify || 'flex-start'};
  align-items: ${({ align }) => align || 'stretch'};
  flex-direction: ${({ direction }) => direction || 'row'};
  width: ${({ width }) => (width ? width : '100%')};
  gap: ${({ gap }) => gap};
`;

export const FlexboxClickableBlock = styled.div`
  ${basicCSSproperties}
  display: flex;
  justify-content: ${({ justify }) => justify || 'flex-start'};
  align-items: ${({ align }) => align || 'stretch'};
  flex-direction: ${({ direction }) => direction || 'row'};
  cursor: pointer;
`;

const inputBaseStyle = css`
  border-radius: 15px;
  border: 1px solid ${colors.secondaryGray};
  padding: 7px 15px;
  width: 100%;
  margin-top: 5px;

  ${({ hasError }) =>
    hasError &&
    `
    border: 1px solid ${colors.error};
  `}

  &:focus {
    outline: unset;
  }

  // disables microsoft edge reveal password popup
  ::-ms-reveal {
    display: none;
  }
`;

export const Input = styled.input`
  ${inputBaseStyle}
  ${basicCSSproperties}
`;

export const TextArea = styled.textarea`
  ${inputBaseStyle}
  resize: ${({ noResize }) => noResize && 'none'};
  resize: ${({ resizeOnlyY }) => resizeOnlyY && 'vertical'};
  resize: ${({ resizeOnlyX }) => resizeOnlyX && 'horiizontal'};
`;

export const DatePickerLabel = styled.label`
  .react-datepicker__close-icon::after {
    background: none;
    color: ${colors.secondaryGray};
    padding-top: 8px;
    padding-right: 10px;
    font-size: 25px;
  }

  .react-datepicker__day--selected {
    background-color: ${colors.primaryOrangeBright};
  }

  .react-datepicker__day--keyboard-selected {
    background-color: ${colors.primaryOrangeBright};
  }

  .react-datepicker__year-read-view--down-arrow {
    margin-top: -3px;
  }
`;

export const Select = styled(ReactSelect)`
  .react-select__control {
    border-radius: 15px;
    min-height: unset;
    min-width: ${({ minWidth }) => (minWidth ? minWidth : '200px')};

    font-size: 14px;
    padding: 7px 15px;
    margin-top: 5px;
    ${basicCSSproperties}

    ${({ hasError }) =>
      hasError &&
      `
      border: 1px solid ${colors.error};
    `}

    ${({ isDisabled }) =>
      isDisabled &&
      `
      border: 1px solid ${colors.secondaryGray};
      background-color: #EBEBE4 ;

      .react-select__single-value {
        color: ${colors.defaultFont};
      }
    `}
  }

  .react-select__control--is-focused,
  .react-select__control--is-focused:hover,
  .react-select__control:hover {
    box-shadow: unset;
    border-color: ${colors.secondaryGray};
  }

  .react-select__value-container {
    padding: 0;
  }

  .react-select__dropdown-indicator {
    padding: 0;

    svg {
      width: 15px;
      height: 15px;
    }
  }

  .react-select__clear-indicator {
    padding: 0;

    svg {
      height: 18px;
    }
  }

  .react-select__indicator-separator {
    margin: 0 5px;
    padding: 0;
    display: ${({ isClearable }) => !isClearable && 'none'};
  }

  .react-select__value-container > div:last-child {
    margin: 0;
    padding: 0;
  }

  .react-select__menu {
    z-index: 100;
  }
  .react-select__multi-value__label {
    padding: 0px;
    padding-left: 6px;
  }
`;

const buttonCSS = css`
  border-radius: 15px;
  width: max-content;
  background-color: ${({ outlined }) => (outlined ? 'inherit' : colors.lightBlue)};
  border: 1px solid ${colors.blueContour};
  color: ${({ outlined }) => (outlined ? colors.textColor : colors.textColor)};
  padding: 7px 60px;
  cursor: pointer;
  text-transform: uppercase;
  height: auto;

  &:focus {
    outline: unset;
  }

  &:hover {
    background-color: ${colors.blueContour};
    border-color: ${colors.blueContourHover};
    color: ${colors.textColor};
  }

  &:disabled {
    background-color: ${colors.lightGrey};
    border-color: ${colors.secondaryGray};
    color: ${colors.textColor};
    cursor: auto;

    &:hover {
      background-color: ${colors.greyBackgroundNotClickable};
      border-color: ${colors.greyContourNotClickable};
    }
  }

  @media (max-width: 760px) {
    font-size: 0.9rem;
    padding: 7px 50px;
  }
`;

export const TextButton = styled.button`
  ${buttonCSS}
  border: unset;
  background-color: inherit;
  color: ${colors.textColor};
  text-transform: unset;
  width: max-content;
  align-self: center;

  &:hover {
    background-color: unset;
    border-color: unset;
    color: ${colors.primaryOrangeBright};
  }
`;

export const Button = styled.button`
  ${buttonCSS}
  ${basicCSSproperties}
`;

export const SidebarButton = styled(Button)`
  color: ${colors.white};
  background-color: ${colors.primaryOrangeBright};

  &:hover {
    color: ${colors.white};
    background-color: ${colors.secondaryOrangeBright};
    border-color: ${colors.secondaryOrange};
  }
`;

export const UploadInput = styled.label`
  ${buttonCSS}
  display: block;
  text-align: center;
  margin: 5px 0 10px 0;

  ${({ disabled }) =>
    disabled &&
    `
    &, &:hover {
      background-color: ${colors.secondaryGray};
      border-color: ${colors.secondaryGray};
      color: ${colors.white};
    }
  `}

  input {
    display: none;
  }
`;

export const ErrorMessageFe = styled.p`
  color: ${colors.error};
  font-size: 0.7rem;
  position: absolute;
  bottom: ${({ longMessage }) => (longMessage ? '-28px' : '-15px')};
  left: 10px;
  margin: 0;
`;

export const ErrorMessageBe = styled.p`
  color: ${colors.error};
  font-size: 0.7rem;
  position: absolute;
  text-align: center;
  bottom: 5px;
  left: 30px;
  margin: 0;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;

  & > ${ErrorMessageFe} {
    top: 15px;
    bottom: unset;
    left: 25px;
  }
`;

export const FormRow = styled.div`
  display: flex;

  & > div:first-child {
    margin-right: 20px;
  }

  & > div:last-child {
    margin-left: 20px;
  }

  & > div:only-child {
    margin-left: 0px;
  }

  @media (max-width: 760px) {
    flex-direction: column;

    & > div:first-child {
      margin-right: 0;
    }

    & > div:last-child {
      margin-left: 0;
    }
  }
`;

export const FormGroup = styled.div`
  position: relative;
  flex: 1;
  margin-bottom: 25px;
  display: ${({ display }) => display};
  flex-direction: ${({ flexDirection }) => flexDirection};
  ${basicCSSproperties}
`;

export const Logo = styled.img`
  width: 150px;
  margin-bottom: 0px !important;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0px;
  margin-bottom: 20px;
  background: ${colors.primaryOrangeBright};
  height: 58.6px;
`;

export const Divider = styled.hr`
  border: unset;
  border-top: 1px solid ${colors.secondaryGray};
  width: 100%;
  display: block;
  margin: ${({ small }) => (small ? '10px 0' : '30px 0')};
  ${basicCSSproperties}
`;

export const SelectedFile = styled.span`
  color: ${colors.primary};
  font-weight: bold;
  padding-left: 25px;
  position: relative;
  align-items: flex-end;

  // adds 3 dots at the end of the second line
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word; // wrap word even if it doesnt have spaces

  ${({ download }) =>
    download &&
    `
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  `}

  &::before {
    position: absolute;
    width: 20px;
    height: 20px;
    background-image: url(${icons.description});
    background-size: cover;
    content: '';
    left: 0;
    top: -1px;
  }
`;

export const SelectedFileOneLine = styled(SelectedFile)`
  width: ${({ width }) => width};
  display: inline-block;
  text-overflow: ellipsis; // add 3 dots
  white-space: nowrap; // only one line
`;

const iconCSS = css`
  width: 20px;
  height: 20px;
  background-image: url(${({ icon }) => icon});
  background-size: cover;
  outline: unset;
  border: unset;
  background-color: unset;
  padding: 10px;

  &:hover {
    opacity: 0.8;
  }
`;

export const Icon = styled.button`
  ${iconCSS}
  padding: 8px;
`;

export const IconButton = styled.button`
  ${iconCSS}
  cursor: pointer;
  padding: 8px;
`;

export const IconButtonRounded = styled.button`
  ${iconCSS}
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
`;

export const TooltipIcon = styled.span`
  ${iconCSS};
  display: inline-block;
  border-radius: 50%;
`;

export const Link = styled(RouterLink)`
  /* color: ${colors.secondaryBlue}; */
`;

export const LabelWrapper = styled.span`
  ${TooltipIcon} {
    position: absolute;
    margin-left: 5px;
    top: -1px;
    cursor: initial;

    &:hover {
      opacity: 1;
    }
  }
`;

export const FullWidthContainer = styled.div`
  padding: 40px;
  width: 100%;

  @media (max-width: 760px) {
    overflow-x: scroll;
    padding: 30px 10px;
  }
`;

export const PageWithTableContainer = styled(FullWidthContainer)`
  ${Table} {
    margin-top: 10px;
  }
`;

export const Container = styled.div`
  padding: 40px;
  max-width: 1000px;
`;

export const DetailRow = styled.div`
  margin: ${({ margin }) => (margin ? margin : '10px 0')};
  display: flex;

  & > span:first-child {
    display: inline-block;
    width: 250px;

    @media (max-width: 760px) {
      width: 200px;
    }
  }

  & > div {
    width: max-content;
    display: flex;
    flex-direction: column;
  }
`;
