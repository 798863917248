export const filterTypes = {
  candidates: 'candidates',
  statistics: 'statistics',
};

export const filterForm = {
  candidates: [
    {
      value: { daysInProcess: 15 },
      label: 'Days in process: 15',
    },
    {
      value: { daysInProcess: 30 },
      label: 'Days in process: 30',
    },
    {
      value: { onlyCompleted: true },
      label: 'Completed candidates',
    },
    {
      value: { newCandidates: true },
      label: 'New candidates',
    },
    {
      value: { assignedToCurrentUser: true },
      label: 'Assigned to me',
    },
    {
      value: { '': '' },
      label: 'All in process',
    },
  ],
  statistics: [
    {
      value: { daysInProcess: 15 }, // valuse to input later, after consultation with BE
      label: 'last week',
    },
    { value: 'currentMonth', label: 'current month' },
    { value: 'lastMonth', label: 'last month' },
    { value: 'currentYear', label: 'current year' },
    { value: 'lastYear', label: 'last year' },
  ],
};

export const candidateListDefaultOption = filterForm.candidates[4];
