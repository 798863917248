import React, { useState } from 'react';
import icons from 'constants/icons';
import { Logo } from 'components/Shared/sharedStyle';
import LoginForm from 'components/Login/LoginForm';
import ForgotPasswordForm from 'components/Login/ForgotPasswordForm';
import { LoginFlexbox, LoginContainer, LogoContainer, FormContainer } from 'components/Login/styleLogin';

const Login = () => {
  const [isLoginFormVisible, setLoginFormVisible] = useState(true);

  return (
    <LoginFlexbox align="center" justify="center">
      <LoginContainer>
        <LogoContainer>
          <Logo src={icons.logoVertical} />
        </LogoContainer>
        <FormContainer>
          {isLoginFormVisible ? (
            <LoginForm toggleForm={() => setLoginFormVisible(false)} />
          ) : (
            <ForgotPasswordForm toggleForm={() => setLoginFormVisible(true)} />
          )}
        </FormContainer>
      </LoginContainer>
    </LoginFlexbox>
  );
};

export default Login;
