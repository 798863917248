import useDispatchNotification from 'components/Shared/Notification/DispatchNotificationHook';
import { useHistory } from 'react-router-dom';

const useRequestHandler = () => {
  const { dispatchSuccessNotification, dispatchErrorNotification } = useDispatchNotification();
  const history = useHistory();

  return async ({ requestFunction, successMsg, errMsg, gotoOnSuccess }) => {
    try {
      const res = await requestFunction;
      successMsg && dispatchSuccessNotification(successMsg);
      gotoOnSuccess && history.push(gotoOnSuccess);
      return res;
    } catch (catchedError) {
      dispatchErrorNotification({ catchedError, errMsg });
    }
  };
};

export default useRequestHandler;
