import { useEffect, useRef } from 'react';

export default function usePrevious(value, defaultValue) {
  // pass defaultValue if usePrevious causes unnecessary re-renders.
  // if value is and object you may need to pass the same object reference so it doesn't re-render.
  const ref = useRef(defaultValue);

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}
