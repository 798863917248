import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { sendMessageAsCandidate } from 'api/messages';
import { Form, FormGroup, Button } from 'components/Shared/sharedStyle';
import { Input, TextArea, MultipleFilesUploader } from 'components/Shared/sharedComponents';
import useDispatchNotification from 'components/Shared/Notification/DispatchNotificationHook';
import { sendAsCandidateSchema } from 'components/Messages/messagesSchema';

const NewMessageAsCandidate = ({ onSubmit, subject }) => {
  const { dispatchSuccessNotification, dispatchErrorNotification } = useDispatchNotification();
  const { t } = useTranslation();

  const [isLoading, setLoadingStatus] = useState(false);
  const [shouldReset, setShouldResetStatus] = useState(null);

  const formFunctions = useForm({
    validationSchema: sendAsCandidateSchema,
  });

  const { handleSubmit, errors, register, reset } = formFunctions;

  const submitForm = async (form) => {
    setLoadingStatus(true);

    try {
      const uploadForm = new FormData();
      uploadForm.append('request.subject', form.subject);
      uploadForm.append('request.body', form.body);

      if (form.attachment) {
        form.attachment.forEach((file, index) => {
          uploadForm.append(`request.Attachments[${index}].FileType`, 'Attachment');
          uploadForm.append(`request.Attachments[${index}].File`, file);
          uploadForm.append(`request.Attachments[${index}].Number`, index);
        });
      }

      await sendMessageAsCandidate(uploadForm);
      dispatchSuccessNotification(t('notifications.saved'));

      onSubmit((state) => state + 1);
      reset();
      setShouldResetStatus(true);
      setShouldResetStatus(false);
    } catch (catchedError) {
      dispatchErrorNotification({ catchedError });
    }

    setLoadingStatus(false);
  };

  return (
    <>
      <h3>{t('message.newMessage')}</h3>
      <Form onSubmit={handleSubmit(submitForm)}>
        <FormGroup>
          <Input
            register={register}
            name="subject"
            error={errors.subject}
            label={t('common.topic')}
            defaultValue={subject}
          />
        </FormGroup>
        <FormGroup>
          <TextArea register={register} name="body" error={errors.body} label={t('common.message')} resizeOnlyY />
        </FormGroup>
        <FormGroup>
          <label>{t('message.attachment')}</label>
          <MultipleFilesUploader
            label={t('message.addAttachment')}
            name="attachment"
            errors={errors.attachment}
            formFunctions={formFunctions}
            shouldReset={shouldReset}
          />
        </FormGroup>
        <Button disabled={isLoading} type="submit">
          {t('common.send')}
        </Button>
      </Form>
    </>
  );
};

NewMessageAsCandidate.defaultProps = {
  subject: '',
};

NewMessageAsCandidate.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  subject: PropTypes.string,
};

export default NewMessageAsCandidate;
