import { useEffect } from 'react';
import { logout } from 'hooks/useLogout';

// hack to logout after using RouteLeaveGuard
const LogoutPage = () => {
  useEffect(() => {
    logout();
  }, []);

  return null;
};

export default LogoutPage;
