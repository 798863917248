import React from 'react';
import { useTranslation } from 'react-i18next';

import { getInvestigatorsList } from 'api/investigator';
import Grid from 'components/Shared/Grid/Grid';
import Link from 'components/Shared/Link';
import { Button, PageWithTableContainer } from 'components/Shared/sharedStyle';
import { investigatorsHeadlines, investigatorsMapper } from 'components/Investigators/configInvestigatorsList';
import { useSelector } from 'react-redux';

const InvestigatorsList = () => {
  const { t } = useTranslation();

  const triggerUpdateWithModal = useSelector((state) => state.modal.triggerUpdateWithModal);

  return (
    <PageWithTableContainer>
      <Link to="/add-investigator">
        <Button>{t('investigator.addInvestigator')}</Button>
      </Link>
      <Grid
        headlines={investigatorsHeadlines}
        fetchDataFunction={getInvestigatorsList}
        mapperFunction={investigatorsMapper}
        rowsPerPage={15}
        triggerUpdate={triggerUpdateWithModal}
        translationsKey="investigator.investigatorGrid"
      />
    </PageWithTableContainer>
  );
};

export default InvestigatorsList;
