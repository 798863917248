import React, { useLayoutEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { changeCandidateFormValidStatus } from 'store/actions';
import { Divider } from 'components/Shared/sharedStyle';
import { sections } from 'utils/candidateFormSections';
import {
  StaticErrorMessage,
  ListOfErrorsContainer,
  Notification,
  MobileErrorsSwitcher,
  List,
} from 'components/CandidateForm/styleCandidateForm';

const mapNestedErrors = (errors) =>
  Object.keys(errors)
    .map((groupKey) => {
      const { ...groupErrors } = errors[groupKey];
      return Object.keys(groupErrors).map((key) => {
        // expression is for handeling fileType and fileSize errors
        return {
          message: groupErrors[key].message ? groupErrors[key].message : groupErrors[key][0]?.message?.error,
          key: `${key}[${groupKey}]`,
        };
      });
    })
    .flat()
    .filter((error) => error.message);

const mapErrors = (errors) => {
  return Object.keys(errors)
    .map((error) => {
      // expression is for handeling fileType and fileSize errors
      return {
        message: errors[error].message ? errors[error].message : errors[error][0]?.message?.error,
        key: error,
      };
    })
    .filter((error) => error.message);
};

const ListOfErrors = ({ setActiveFormStep, isEditing }) => {
  const [personalInformationErrors, setPersonalInformationErrors] = useState([]);
  const [educationErrors, setEducationErrors] = useState([]);
  const [workplaceErrors, setWorkplaceErrors] = useState([]);
  const [conflictOfInterestErrors, setConflictOfInterestErrors] = useState([]);
  const [isMobilePreviewOpen, setMobilePreviewStatus] = useState(false);

  const { t } = useTranslation();

  const isMobile = useSelector((state) => state.isMobile);
  const errors = useSelector((state) => state.candidateFormErrors);
  const candidateSections = useSelector((state) => state.candidateSections);
  const dispatch = useDispatch();

  // useLayoutEffect runs before component renders. Aviods error flashing on refresh submitted form.
  useLayoutEffect(() => {
    const personalInformationErrors = mapErrors(errors.personalInformation);
    const previousAddresses = mapNestedErrors(errors.personalInformation.previous || {});
    const additionalFields = mapErrors(errors.personalInformation.additionalFields || {});
    setPersonalInformationErrors([...personalInformationErrors, ...previousAddresses, ...additionalFields]);

    const conflictOfInterestErrors = mapErrors(errors.conflictOfInterest);
    const assignments = mapNestedErrors(errors.conflictOfInterest.assignments || {});
    setConflictOfInterestErrors([...conflictOfInterestErrors, ...assignments]);

    setEducationErrors(mapNestedErrors(errors.education));

    const workplaceNestedErrors = mapNestedErrors(errors?.workplace.workplace || {});
    const workplaceDateRangeError = mapErrors(errors.workplace || {});
    setWorkplaceErrors([...workplaceNestedErrors, ...workplaceDateRangeError]);

    if (
      Object.keys(errors.personalInformation).length ||
      Object.keys(errors.education).length ||
      Object.keys(errors.workplace).length ||
      Object.keys(errors.conflictOfInterest).length
    ) {
      dispatch(changeCandidateFormValidStatus(false));
    } else {
      dispatch(changeCandidateFormValidStatus(true));
    }
  }, [errors, dispatch]);

  const hasErrors =
    !!personalInformationErrors.length ||
    !!educationErrors.length ||
    !!workplaceErrors.length ||
    !!conflictOfInterestErrors.length;

  const errorsLength =
    personalInformationErrors.length +
    educationErrors.length +
    workplaceErrors.length +
    conflictOfInterestErrors.length;

  const onErrorClick = (sectionName) => {
    if (isMobilePreviewOpen) {
      setMobilePreviewStatus(false);
    }

    setActiveFormStep(candidateSections.indexOf(sectionName));
  };

  return (
    <ListOfErrorsContainer isOpen={isMobilePreviewOpen} isEditing={isEditing}>
      {isMobile && hasErrors && (
        <MobileErrorsSwitcher isOpen={isMobilePreviewOpen} onClick={() => setMobilePreviewStatus((status) => !status)}>
          {`${t('errors.errors')} ${errorsLength}`}
        </MobileErrorsSwitcher>
      )}
      {isEditing && <Notification>{t('candidateForm.submittingData.submittingNotification')}</Notification>}
      <List isOpen={isMobilePreviewOpen}>
        {hasErrors && isEditing && <Divider small />}
        {hasErrors && <h2>{t('errors.title')}</h2>}
        {!!personalInformationErrors.length && (
          <>
            <h4>{t('candidateForm.personalDetails.title')}</h4>
            {personalInformationErrors.map(({ message, key }) => (
              <StaticErrorMessage key={key} onClick={() => onErrorClick(sections.personalInformation)}>
                {message}
              </StaticErrorMessage>
            ))}
            <Divider small />
          </>
        )}
        {!!educationErrors.length && (
          <>
            <h4>{t('candidateForm.education.title')}</h4>
            {educationErrors.map(({ message, key }) => (
              <StaticErrorMessage key={key} onClick={() => onErrorClick(sections.education)}>
                {message}
              </StaticErrorMessage>
            ))}
            <Divider small />
          </>
        )}
        {!!workplaceErrors.length && (
          <>
            <h4>{t('candidateForm.workplace.title')}</h4>
            {workplaceErrors.map(({ message, key }) => (
              <StaticErrorMessage key={key} onClick={() => onErrorClick(sections.workplaces)}>
                {message}
              </StaticErrorMessage>
            ))}
            <Divider small />
          </>
        )}
        {!!conflictOfInterestErrors.length && (
          <>
            <h4>{t('candidateForm.conflictOfInterest.title')}</h4>
            {conflictOfInterestErrors.map(({ message, key }) => (
              <StaticErrorMessage key={key} onClick={() => onErrorClick(sections.conflictOfInterest)}>
                {message}
              </StaticErrorMessage>
            ))}
            <Divider small />
          </>
        )}
      </List>
    </ListOfErrorsContainer>
  );
};

ListOfErrors.propTypes = {
  setActiveFormStep: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
};

export default ListOfErrors;
