const colors = {
  primary: '#17365d',
  white: '#ffffff',
  primaryOrangeBright: '#C34628',
  darkGrey: '#d6d6dd',
  lightGrey: '#f8f9fd',
  secondaryOrangeBright: '#9c3920',
  secondaryOrange: '#7C2417',
  textColor: '#424242',
  whiteBlue: '#e5eef7',
  lightBlue: '#bed6eb',
  blueContour: '#95bbd8',
  blueContourHover: '#6aa0c2',
  greyBackgroundNotClickable: '#f1f1f7',
  greyContourNotClickable: '#a1a4a9',
  secondaryBlue: '#597ea8',
  primaryGray: '#f7f8fc',
  secondaryGray: '#bfc1c4',
  defaultFont: '#313131',
  warning: '#F9F900',
  error: '#FF004E',
  success: '#4ebe27',
};

export default colors;
