import axios from 'axios';
import { baseURL, myAxios } from 'api/axios';

export const signIn = (body) => myAxios.post('/v1/account/signIn', body);

// Sends request after clearing localStorage and it won't fall into signOut error loop.
export const signOut = (accessToken) =>
  axios.post(
    `${baseURL}/v1/account/signOut`,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

export const refreshToken = (body) => myAxios.post('/v1/account/refresh', body);

export const confirmEmail = (body) => myAxios.post('/v1/account/confirmEmail', body);

export const resetPassword = (body) => myAxios.post('/v1/account/resetPassword', body);

export const requestPasswordReset = (body) => myAxios.post('/v1/account/requestPasswordReset', body);

export const createInvestigator = (body) => myAxios.post('/v1/account/registerUser', body);

export const getSavedParameter = (params) =>
  myAxios.get('/v1/account/getSavedParameter', {
    params,
  });
