import React from 'react';
import { useTranslation } from 'react-i18next';

import hasPermission from 'utils/hasPermission';
import roles from 'constants/roles';
import { getCustomersUsers } from 'api/customer';
import Grid from 'components/Shared/Grid/Grid';
import Link from 'components/Shared/Link';
import { Button, PageWithTableContainer } from 'components/Shared/sharedStyle';
import { routes } from 'constants/routes';
import { customerUsersHeadlines, customerUsersMapper } from '../configCustomer';

const CustomerUsersList = () => {
  const { t } = useTranslation();
  const hasGpPermissions = hasPermission([roles.investigator]);

  return (
    <PageWithTableContainer>
      <Link to={routes.addCustomerUser}>
        <Button>{hasGpPermissions ? t('customer.addCustomerUser') : t('customer.addUser')}</Button>
      </Link>
      <Grid
        headlines={customerUsersHeadlines}
        fetchDataFunction={getCustomersUsers}
        mapperFunction={customerUsersMapper}
        rowsPerPage={15}
        translationsKey="customer.customerUsersGrid"
      />
    </PageWithTableContainer>
  );
};

export default CustomerUsersList;
