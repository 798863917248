import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { sendEmailsToCandidates } from 'api/candidate';
import useRequestHandler from 'api/RequestHandlerHook';
import { Button } from 'components/Shared/sharedStyle';
import { SendEmailsContainer } from 'components/CandidatesList/styleCandidatesList';

const CandidatesEmailsButton = () => {
  const { t } = useTranslation();
  const requestHandler = useRequestHandler();
  const invitationCandidatesIds = useSelector((state) => state.invitationCandidatesIds);

  const [isLoading, toggleLoading] = useState(false);

  const sendEmails = async () => {
    toggleLoading(true);

    await requestHandler({
      requestFunction: await sendEmailsToCandidates({
        candidateIds: invitationCandidatesIds,
      }),
      successMsg: t('notifications.sent'),
    });

    toggleLoading(false);
  };

  return (
    <SendEmailsContainer>
      <Button padding="7px 30px" onClick={sendEmails} disabled={isLoading || invitationCandidatesIds.length === 0}>
        {t('sendEmails')}
      </Button>
    </SendEmailsContainer>
  );
};

export default CandidatesEmailsButton;
