import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from 'components/Shared/Grid/Grid';
import { Divider } from 'components/Shared/sharedStyle';
import BarChartComponent from 'components/Shared/Charts/BarChart';
import PieChart from 'components/Shared/Charts/PieChart';
import { getCurrentInvestigatorsStatistics, getCurrentCustomersStatistics } from 'components/Statistics/statisticsMock';
import { filterTypes } from 'constants/filters-form-consts';
import { currentInvestigatorsHeadlines, statisticsMapper } from 'components/Statistics/config';
import { PageContainer } from 'components/Statistics/styleStatistics';

// Component isn't currently used in app process.
// it is created as demo with random data

const Statistics = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <BarChartComponent title={"Investigator's closed candidates"} name="investigator" />
      <BarChartComponent title={"Customer's closed candidates"} name="customer" />
      <PieChart title="Active candidates per investigator" name="investigator" />

      <h2>{t('statisticsGrid.currentInvestigators')}</h2>
      <Grid
        headlines={currentInvestigatorsHeadlines}
        mapperFunction={statisticsMapper}
        fetchDataFunction={getCurrentInvestigatorsStatistics}
        translationsKey="statisticsGrid"
        rowsPerPage={3}
      />
      <h2>{t('statisticsGrid.currentCustomers')}</h2>
      <Grid
        headlines={currentInvestigatorsHeadlines}
        mapperFunction={statisticsMapper}
        fetchDataFunction={getCurrentCustomersStatistics}
        translationsKey="statisticsGrid"
        rowsPerPage={3}
      />

      <Divider />

      <h2>{t('statisticsGrid.historyInvestigators')}</h2>
      <Grid
        headlines={currentInvestigatorsHeadlines}
        mapperFunction={statisticsMapper}
        fetchDataFunction={getCurrentInvestigatorsStatistics}
        translationsKey="statisticsGrid"
        rowsPerPage={3}
        filterType={filterTypes.statistics}
      />
      <h2>{t('statisticsGrid.historyCustomers')}</h2>
      <Grid
        headlines={currentInvestigatorsHeadlines}
        mapperFunction={statisticsMapper}
        fetchDataFunction={getCurrentCustomersStatistics}
        translationsKey="statisticsGrid"
        rowsPerPage={3}
        filterType={filterTypes.statistics}
      />
    </PageContainer>
  );
};

export default Statistics;
