import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { evaluationItemType, referenceType, educationType } from 'constants/types';
import { WysiwygEditor } from 'components/Shared/sharedComponents';
import { DetailRow, Divider } from 'components/Shared/sharedStyle';
import { getEducationSectionNum } from 'utils/candidateFormSections';
import { SectionTitle } from 'components/CandidateView/styleCandidateView';
import StatusButtons from 'components/CandidateView/StatusButtons';
import { getEducationDefinition, textBoxesRefsNames } from 'components/CandidateView/configCandidateView';

const Education = ({
  education,
  noEducation,
  files,
  textBoxesRefs,
  defaultEvaluation,
  evaluation,
  updateEvaluationStatus,
}) => {
  const { t } = useTranslation();

  const sectionNumber = getEducationSectionNum() + 1;
  const educationDefinition = getEducationDefinition(education, files, sectionNumber);
  const sectionTitle = t('candidateForm.education.title');

  return (
    <>
      <h2>{`${sectionNumber}. ${sectionTitle}`}</h2>
      <WysiwygEditor
        defaultValue={defaultEvaluation.header}
        editorRef={textBoxesRefs}
        saveRefInAnotherRef
        name={textBoxesRefsNames.educationHeader}
        sectionTitle={`${sectionTitle} ${t('common.header')}`}
      />
      {noEducation ? (
        <p>{t('candidateForm.education.noEducation')}</p>
      ) : (
        educationDefinition.map((section, index) => (
          <React.Fragment key={index}>
            <SectionTitle>
              <h4>{t('candidateForm.education.sectionName', { index: index + 1 })}</h4>
            </SectionTitle>
            {section.map((field, fieldIndex) => (
              <DetailRow key={fieldIndex}>
                <span>{field.label}</span>
                <div>{field.value}</div>
              </DetailRow>
            ))}
          </React.Fragment>
        ))
      )}
      <WysiwygEditor
        defaultValue={defaultEvaluation.comment}
        editorRef={textBoxesRefs}
        saveRefInAnotherRef
        name={textBoxesRefsNames.educationComment}
        sectionTitle={`${sectionTitle} ${t('common.footer')}`}
      />
      <StatusButtons value={evaluation} evaluationKey="education" onClick={updateEvaluationStatus} />
      <Divider />
    </>
  );
};

Education.propTypes = {
  education: educationType.isRequired,
  noEducation: PropTypes.bool.isRequired,
  textBoxesRefs: referenceType.isRequired,
  defaultEvaluation: evaluationItemType.isRequired,
  evaluation: PropTypes.number,
  files: PropTypes.object,
  updateEvaluationStatus: PropTypes.func.isRequired,
};

export default Education;
