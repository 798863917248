import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import colors from 'constants/colors';

import useDispatchNotification from 'components/Shared/Notification/DispatchNotificationHook';
import { generateReport, getCandidateDetails } from 'api/candidate';
import { sendReportToCustomer } from 'api/customer';
import { downloadFile } from 'utils/downloadFile';
import { Button, Divider, Flexbox, Paragraph } from 'components/Shared/sharedStyle';
import { FormCheckbox } from 'components/Shared/sharedComponents';

const ReportActions = ({ onFormSubmit, reportFile, consentForm, reportDates, setReportDatesAction }) => {
  const { dispatchSuccessNotification, dispatchErrorNotification } = useDispatchNotification();
  const { t } = useTranslation();
  const history = useHistory();
  const { id: candidateId } = useParams();

  const isMobile = useSelector((state) => state.isMobile);

  const [isLoading, setIsLoadingStatus] = useState(false);
  const [isChecked, setCheckboxStatus] = useState(false);
  const [shouldDisplayDownloadButton, setDownloadButtonVisibility] = useState(reportFile);
  const [reportId, setReportId] = useState(reportFile && reportFile[0].id);

  const getNewReportId = async () => {
    const newReportId = await generateReport(candidateId);
    setDownloadButtonVisibility(true);
    setReportId(newReportId);
  };

  const saveForm = async () => {
    setIsLoadingStatus(true);
    try {
      await onFormSubmit();
      await getNewReportId();
      await getReportDates(candidateId);
    } catch (catchedError) {
      dispatchErrorNotification({ catchedError });
    }
    setIsLoadingStatus(false);
  };

  const sendReportToCustomerAction = async () => {
    setIsLoadingStatus(true);
    try {
      await sendReportToCustomer(candidateId);

      dispatchSuccessNotification(t('notifications.sentReportToCustomer'));

      await getReportDates(candidateId);
    } catch (catchedError) {
      dispatchErrorNotification({ catchedError });
    }
    setIsLoadingStatus(false);
  };

  const getReportDates = async () => {
    const data = await getCandidateDetails(candidateId);
    setReportDatesAction(data);
  };

  const downloadReport = () => downloadFile({ id: reportId });

  const toggleCheckbox = () => setCheckboxStatus((status) => !status);

  return (
    <>
      <Button onClick={() => history.goBack()} disabled={isLoading} outlined>
        {t('candidateView.goBackToList')}
      </Button>
      <Divider />
      <Flexbox direction="column" align="center">
        <Flexbox justify="center">
          <Button disabled={isLoading} margin="0 10px" onClick={saveForm}>
            {t('candidateView.generateReport')}
          </Button>
          <Button disabled={!shouldDisplayDownloadButton || isLoading} margin="0 10px" onClick={downloadReport}>
            {t('candidateView.downloadReport')}
          </Button>
        </Flexbox>
        <Paragraph paddingRight={!isMobile && '220px'} marginBottom="0px">
          {reportDates.reportGenerated && `${t('candidateView.reportWasGenerated')} ${reportDates.reportGenerated}`}
        </Paragraph>
      </Flexbox>
      <Divider />
      <Flexbox direction="column" align="center">
        <FormCheckbox
          label={t('candidateView.reportConfirmation')}
          onChange={toggleCheckbox}
          name="reportConfirmation"
        />
        <Flexbox direction="column" justify="center" align="center">
          <Flexbox justify="center">
            <Button
              disabled={!consentForm || !isChecked || !reportDates.reportGenerated || isLoading}
              margin="0 10px"
              onClick={sendReportToCustomerAction}
            >
              {t('candidateView.sendToCustomer')}
            </Button>
          </Flexbox>
          {!consentForm && isChecked && (
            <Paragraph color={colors.error} fontSize="12px" position="absolute" paddingTop="7px">
              {t('candidateView.candidateDidntSignConsent')}
            </Paragraph>
          )}
          <Paragraph paddingRight={!isMobile && '195px'}>
            {reportDates.reportSentToCustomer &&
              `${t('candidateView.reportWasSendToCustomer')} ${reportDates.reportSentToCustomer}`}
          </Paragraph>
        </Flexbox>
      </Flexbox>
    </>
  );
};

ReportActions.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  reportFile: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired
  ),
  consentForm: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired
  ),
  reportDates: PropTypes.object.isRequired,
  setReportDatesAction: PropTypes.func.isRequired,
};

ReportActions.defaultProps = {
  reportFile: null,
};

export default ReportActions;
