import { useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { downloadFile } from 'utils/downloadFile';
import { personalDetailsFilesTypes } from 'constants/types';
import { downloadTemplate } from 'api/country';
import { signFile } from 'api/candidate';
import { showFullScreenLoader, hideFullScreenLoader } from 'store/actions';
import { getUploadedAdditionalFileId, setActiveCandidateFormPage } from 'utils/localStorage';
import { FormRow, Button, Flexbox, Paragraph } from 'components/Shared/sharedStyle';
import { Input, MultipleFilesUploader } from 'components/Shared/sharedComponents';
import {
  DownloadAndUpload,
  AdditionalFieldsSectionName,
  AdditionalFieldsFormGroup,
} from 'components/CandidateForm/styleCandidateForm';
import { getPersonalInfoSectionNum, getAdditionalFieldLabel } from 'utils/candidateFormSections';
import useDispatchNotification from 'components/Shared/Notification/DispatchNotificationHook';
import { FieldTypes } from 'components/EditCountries/configEditCountries';
import {
  additionalFieldsPrefix,
  additionalFieldsBeFilesPrefix,
  additionalFieldName,
  additionalFieldError,
} from 'components/CandidateForm/Form/configCandidateForm';

const AdditionalFieldsForm = ({
  sectionIndex,
  formFunctions,
  countiresSections,
  editingLocked,
  defaultFiles,
  findChangesAndSaveCandidateForm,
  isFileSignedWithScriveLoading,
}) => {
  const { dispatchErrorNotification } = useDispatchNotification();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const sectionNumber = getPersonalInfoSectionNum();
  const countriesList = useSelector((state) => state.countries.countriesList);

  const { register, errors, getValues, unregister, triggerValidation, formState } = formFunctions;

  const mappedCountiresSections = useMemo(() => {
    const rows = [];

    countiresSections[sectionIndex].forEach((field, index) => {
      if (index === 0) {
        rows.push([countriesList[field]]);
        return;
      }
      if (index % 2 === 0) {
        rows[rows.length - 1].push({ ...field, index });
        return;
      }

      rows.push([{ ...field, index }]);
    });

    // adds FormGroup if second element in row doesn't exist
    !rows[rows.length - 1][1] && rows[rows.length - 1].push({ id: Math.random() }); // id is for key.

    return rows;
  }, [countiresSections, countriesList, sectionIndex]);

  const numberOfPreviousAdresses = getValues({ nest: true }).previous
    ? Object.keys(getValues({ nest: true }).previous).length
    : 0;

  const eSignFile = async (fieldDefinitionId) => {
    dispatch(showFullScreenLoader());

    try {
      await findChangesAndSaveCandidateForm();

      const [redirectUrl] = await Promise.all([
        signFile({
          fieldDefinitionId,
          returnUrl: `${window.location.href}/${fieldDefinitionId}`,
          rejectUrl: window.location.href,
        }),
      ]);

      setActiveCandidateFormPage(sectionNumber);
      window.location.href = redirectUrl;
    } catch (catchedError) {
      dispatch(hideFullScreenLoader());
      dispatchErrorNotification({ catchedError });
    }
  };

  const downloadTemplateFile = (id) => {
    downloadFile({
      customFunction: downloadTemplate,
      id,
    });
  };

  // Store value of countiresSections[sectionIndex] and keep it up-to-date.
  // This allows to use value in useEffect cleanup without making it a dependancy, which would run cleanup on every value change.
  const countryFieldsRef = useRef(null);
  const prevCountryFieldsRef = useRef(null);

  useEffect(() => {
    prevCountryFieldsRef.current = countryFieldsRef.current ?? null;
    countryFieldsRef.current = countiresSections[sectionIndex];
  }, [countiresSections, sectionIndex]);

  useEffect(() => {
    const ids = countryFieldsRef.current.filter((item) => item?.id).map((item) => item.id);

    // validate new additionalFields on country change
    if (formState.isSubmitted) {
      ids.forEach((id) => {
        triggerValidation(`${additionalFieldsPrefix}.${id}`);
      });
    }

    // clear additionalFields errors when country is removed or changed to timeperiod less than 6 months in presonal details
    return () => {
      if (
        formState.isSubmitted &&
        JSON.stringify(prevCountryFieldsRef.current) !== JSON.stringify(countryFieldsRef.current) // prevents clearing on save in education and work history where all fields are refetched so everying is remounted
      ) {
        ids.forEach((id) => {
          unregister(`${additionalFieldsPrefix}.${id}`);
          triggerValidation(`${additionalFieldsPrefix}.${id}`);
        });
      }
    };
  }, [unregister, triggerValidation, formState.isSubmitted]);

  return (
    <fieldset disabled={editingLocked}>
      {mappedCountiresSections?.length &&
        mappedCountiresSections.map((row, rowIndex) => {
          return (
            <FormRow key={`${rowIndex}-${typeof row[0] === 'string' ? row[0] : row[0].id}`}>
              {/* field is taken from getAllFields endpoint */}
              {/* first field is name of the country, rest are country fields */}
              {/* based on matching field.id, values from candidateOwnDetails are displayed */}
              {row.map((field) => {
                return (
                  <AdditionalFieldsFormGroup
                    isSectionTitle={typeof field === 'string'}
                    key={rowIndex === 0 ? field : field.id}
                  >
                    {typeof field === 'string' && (
                      <AdditionalFieldsSectionName>
                        <h4>{field}</h4>
                      </AdditionalFieldsSectionName>
                    )}
                    {field.type === FieldTypes.text && (
                      <Input
                        register={register}
                        name={additionalFieldName(field)}
                        error={additionalFieldError(field, errors)}
                        label={getAdditionalFieldLabel({
                          sectionNumber,
                          numberOfPreviousAdresses,
                          sections: countiresSections,
                          sectionIndex,
                          fieldIndex: field.index,
                          fieldName: field.name,
                        })}
                      />
                    )}
                    {(field.type === FieldTypes.uploadDownload || field.type === FieldTypes.upload) && (
                      <>
                        <label>
                          {getAdditionalFieldLabel({
                            sectionNumber,
                            numberOfPreviousAdresses,
                            sections: countiresSections,
                            sectionIndex,
                            fieldIndex: field.index,
                            fieldName: field.name,
                          })}
                        </label>
                        <DownloadAndUpload withDownload={field?.template?.id}>
                          {field?.template?.id && (
                            <Flexbox direction="column" width="auto">
                              <Button type="button" onClick={() => downloadTemplateFile(field.template.id)}>
                                {t('common.download')}
                              </Button>
                              {field.electronicSignature && (
                                <>
                                  <Paragraph marginTop="5px" marginBottom="10px">
                                    or
                                  </Paragraph>
                                  <Button
                                    disabled={
                                      isFileSignedWithScriveLoading && getUploadedAdditionalFileId() === field.id
                                    }
                                    type="button"
                                    onClick={() => eSignFile(field.id)}
                                  >
                                    {t('candidateForm.personalDetails.eSign')}
                                  </Button>
                                </>
                              )}
                            </Flexbox>
                          )}
                          <div>
                            <MultipleFilesUploader
                              label={t('common.uploadScan')}
                              name={additionalFieldName(field)}
                              errors={additionalFieldError(field, errors)}
                              formFunctions={formFunctions}
                              disabled={
                                editingLocked ||
                                (isFileSignedWithScriveLoading && getUploadedAdditionalFileId() === field.id)
                              }
                              tooltipMessage={field.description}
                              defaultFiles={defaultFiles[`${additionalFieldsBeFilesPrefix}.${field.id}`]}
                              showLoader={isFileSignedWithScriveLoading && getUploadedAdditionalFileId() === field.id}
                              oneLineAndPassWidth="240px"
                            />
                          </div>
                        </DownloadAndUpload>
                      </>
                    )}
                  </AdditionalFieldsFormGroup>
                );
              })}
            </FormRow>
          );
        })}
    </fieldset>
  );
};

AdditionalFieldsForm.propTypes = {
  sectionIndex: PropTypes.number.isRequired,
  countiresSections: PropTypes.array.isRequired,
  formFunctions: PropTypes.object.isRequired,
  editingLocked: PropTypes.bool.isRequired,
  defaultFiles: personalDetailsFilesTypes,
  findChangesAndSaveCandidateForm: PropTypes.func.isRequired,
  isFileSignedWithScriveLoading: PropTypes.bool.isRequired,
};

AdditionalFieldsForm.defaultProps = {
  defaultFiles: {},
};

export default AdditionalFieldsForm;
