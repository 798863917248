import { myAxios, axiosDownloadFile } from 'api/axios';

export const getCustomerData = () => myAxios.get('/customer/getCustomer');

export const getCustomersList = () => myAxios.get('/customer/getCustomerList');

export const getCustomersUsers = () => myAxios.get('/customer/getCustomerUserList');

export const createCustomer = (body) => myAxios.post('/customer/create', body);

export const getCustomer = (id) => myAxios.get(`/customer/get`, { params: { id } });

export const editCustomer = (body) => myAxios.put('/customer/edit', body);

export const registerUser = (body) => myAxios.post('/customer/registerUser', body);

export const registerCustomerOwnUser = (body) => myAxios.post('/customer/registerCustomerOwnUser', body);

export const getDocumentPins = () => myAxios.get('/customer/documentPins');

export const downloadReport = (candidateId) => axiosDownloadFile.get(`/customer/downloadReport/${candidateId}`);

export const sendReportToCustomer = (candidateId) => myAxios.post(`/customer/sendReport/${candidateId}`);

export const getMyOrganizationUsers = () => myAxios.get('/customer/getCurrentCustomerUserList');
