export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const USER_LOGOUT = 'USER_LOGOUT';

export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';

export const REDIRECT_FROM_LOGIN_PAGE = 'REDIRECT_FROM_LOGIN_PAGE';

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const SET_COUNTRIES_ADDITIONAL_FIELDS = 'SET_COUNTRIES_ADDITIONAL_FIELDS';

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';

export const SHOW_FULL_SCREEN_LOADER = 'SHOW_FULL_SCREEN_LOADER';
export const HIDE_FULL_SCREEN_LOADER = 'HIDE_FULL_SCREEN_LOADER';

export const SET_MODAL_STATUS = 'SET_MODAL_STATUS';
export const TRIGGER_UPDATE_WITH_MODAL = 'TRIGGER_UPDATE_WITH_MODAL';

export const SET_CANDIDATE_SECTIONS = 'SET_CANDIDATE_SECTIONS';
export const ADD_CANDIDATE_FORM_ERRORS = 'ADD_CANDIDATE_FORM_ERRORS';
export const CLEAR_CANDIDATE_FORM_ERRORS = 'CLEAR_CANDIDATE_FORM_ERRORS';
export const CHANGE_VALID_STATUS = 'CHANGE_VALID_STATUS';
export const CHANGE_SUBMIT_STATUS = 'CHANGE_SUBMIT_STATUS';
export const FIND_UNSAVED_CANDIDATE_FORM_CHANGES = 'FIND_UNSAVED_CANDIDATE_FORM_CHANGES';
export const SAVE_ENTIRE_CANDIDATE_FORM = 'SAVE_ENTIRE_CANDIDATE_FORM';
export const SAVED_CANDIDATE_FORM = 'SAVED_CANDIDATE_FORM';

export const MARK_ACTIVITIES_AS_VIEWED = 'MARK_ACTIVITIES_AS_VIEWED';

export const CHECK_UNREAD_MESSAGES = 'CHECK_UNREAD_MESSAGES';
export const HIDE_NEW_MESSAGES_NOTIFICATION = 'HIDE_NEW_MESSAGES_NOTIFICATION';

export const MOBILE_LAYOUT = 'MOBILE_LAYOUT';
export const DESKTOP_LAYOUT = 'DESKTOP_LAYOUT';

export const ADD_FILE_TO_REMOVE_LIST = 'ADD_FILE_TO_REMOVE_LIST';
export const CLEAR_FILES_TO_REMOVE_LIST = 'CLEAR_FILES_TO_REMOVE_LIST';

export const SCRIVE_FILE_LOADING = 'SCRIVE_FILE_LOADING';
export const SCRIVE_FILE_FINISHED_LOADING = 'SCRIVE_FILE_FINISHED_LOADING';

export const ENABLE_SAVING_REFRESH_TOKEN_EXPIRATION_TIME = 'ENABLE_SAVING_REFRESH_TOKEN_EXPIRATION_TIME';
export const DISABLE_SAVING_REFRESH_TOKEN_EXPIRATION_TIME = 'DISABLE_SAVING_REFRESH_TOKEN_EXPIRATION_TIME';

export const SET_INVITATION_CANDIDATES_IDS = 'SET_INVITATION_CANDIDATES_IDS';
