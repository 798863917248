import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { markMessageViewed, downloadAttachment } from 'api/messages';
import { downloadFile } from 'utils/downloadFile';
import { Flexbox, Divider, SelectedFile, Div } from 'components/Shared/sharedStyle';
import { MessagePreviewContainer } from 'components/Messages/styleMessages';

const MessagePreview = ({ message, openedMessages, addNewOpenedMessage }) => {
  useEffect(() => {
    if (message.isReceived && !message.isViewed && !openedMessages.includes(message.id)) {
      markMessageViewed(message.id);
      addNewOpenedMessage((state) => state.concat(message.id));
    }
  }, [message, openedMessages, addNewOpenedMessage]);

  return (
    <MessagePreviewContainer>
      <Flexbox align="center" justify="space-between">
        <h3>{message.subject}</h3>
        <p>{message.date}</p>
      </Flexbox>
      <Divider small />
      <Div whiteSpace="pre-line">{message.body}</Div>
      <Divider small />
      <Flexbox direction="column">
        {message.attachments.map((file) => (
          <SelectedFile
            onClick={() => downloadFile({ customFunction: downloadAttachment, id: file.id, fileName: file.fileName })}
            download
            key={file.id}
          >
            {file.fileName}
          </SelectedFile>
        ))}
      </Flexbox>
    </MessagePreviewContainer>
  );
};

MessagePreview.propTypes = {
  message: PropTypes.shape({
    subject: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    isReceived: PropTypes.bool,
    isViewed: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    attachments: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  openedMessages: PropTypes.arrayOf(PropTypes.string).isRequired,
  addNewOpenedMessage: PropTypes.func.isRequired,
};

export default MessagePreview;
