import styled from 'styled-components/macro';
import { smallUp } from 'constants/media-queries';
import { basicCSSproperties } from 'components/Shared/sharedStyle';

export const LevelCheckboxContainer = styled.div`
  display: flex;

  p {
    width: 159px;
  }

  .react-select__control {
    min-width: 25px;
  }

  @media ${smallUp} {
    p {
      width: 212px;
    }
  }

  ${basicCSSproperties};
`;

export const LevelCheckboxNames = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  ${basicCSSproperties};
`;
