import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Flexbox,
  FlexboxClickableBlock,
  Container,
  Button,
  IconButton,
  Paragraph,
} from 'components/Shared/sharedStyle';
import icons from 'constants/icons';
import { downloadFile } from 'utils/downloadFile';
import { getCustomerName, getUserName } from 'utils/localStorage';
import { isReportAvailable, downloadCandidateReport } from 'api/candidate';
import useDispatchNotification from 'components/Shared/Notification/DispatchNotificationHook';

const CandidateReport = () => {
  const { dispatchErrorNotification } = useDispatchNotification();
  const { t } = useTranslation();
  const history = useHistory();

  const customerName = getCustomerName();

  const [isRaportPresent, setIsRaportPresent] = useState(false);

  useEffect(() => isReportAvailableAction(), []);

  const isReportAvailableAction = async () => {
    const reportAvailable = await isReportAvailable();
    setIsRaportPresent(reportAvailable);
  };

  const userName = () => {
    const nameWithSpace = getUserName();
    const name = nameWithSpace.split(' ');
    return `${name[0]}_${name[1]}`;
  };

  const downloadReport = async (fileName) => {
    try {
      await downloadFile({ customFunction: downloadCandidateReport, fileName });
    } catch (catchedError) {
      dispatchErrorNotification({ catchedError });
    }
  };

  return (
    <Container>
      <h2>{t('myReport.title')}</h2>
      <p>{t(`${isRaportPresent ? 'myReport.reportPresent' : 'myReport.noReport'}`, { customerName })}</p>

      {isRaportPresent && (
        <>
          <Flexbox margin="25px 0px">
            <FlexboxClickableBlock align="center" onClick={() => downloadReport(`${userName()}-Report.pdf`)}>
              <IconButton icon={icons.fileRed} />
              <Paragraph fontWeight="bold">{`${userName()}-Report`}</Paragraph>
            </FlexboxClickableBlock>
          </Flexbox>
          <Flexbox margin="25px 0px">
            <Button width="190px" onClick={() => history.push('/messages')}>
              {t('common.message')}
            </Button>
          </Flexbox>
          <Button width="190px" marginBottom="25px" marginRight="25px">
            {t('myReport.reject')}
          </Button>
          <Button width="190px">{t('myReport.confirm')}</Button>
        </>
      )}
    </Container>
  );
};

export default CandidateReport;
