import * as yup from 'yup';
import i18n from 'i18n';
import createRequiredError from 'utils/createRequiredError';

const requiredError = createRequiredError('customer.labels');

export const addInvestigatorSchema = yup.object().shape({
  firstName: yup.string().required(requiredError),
  lastName: yup.string().required(requiredError),
  email: yup.string().email(i18n.t('errors.email')).required(requiredError),
});
