import * as types from './actionTypes';
import { setCountriesRedux } from 'utils/countries';

const initialState = {
  language: 'en',
  countries: setCountriesRedux('en'),
  countriesAdditionalFields: {},
  isRedirectedFromLoginPage: false,
  isFullScreenLoaderVisible: false,
  notification: {
    isVisible: false,
    message: '',
    type: '',
  },
  modal: {
    isVisible: false,
    type: '',
    data: null,
    triggerUpdateWithModal: 0,
  },
  candidateSections: [],
  candidateFormSectionsToSave: {
    personalInformation: false,
    workplace: false,
    education: false,
    conflictOfInterest: false,
  },
  candidateFormErrors: {
    personalInformation: {},
    workplace: {},
    education: {},
    conflictOfInterest: {},
  },
  isCandidateFormValid: false,
  isCandidateFormSubmitted: false,
  viewedActivities: [],
  unreadMessagesNumber: 0,
  isMobile: window.innerWidth <= 760,
  filesToRemove: [],
  isScriveFileLoading: false,
  invitationCandidatesIds: [],
};

const onSignInSuccess = (state, payload) => ({
  ...state,
  userRole: payload,
});

const changeLanguage = (state, langCode) => ({
  ...state,
  language: langCode,
  countries: setCountriesRedux(langCode),
});

const setCountriesAdditionalFields = (state, countriesAdditionalFields) => ({
  ...state,
  countriesAdditionalFields,
});

const redirectFromLoginPage = (state) => ({
  ...state,
  isRedirectedFromLoginPage: true,
});

const showFullScreenLoader = (state) => ({
  ...state,
  isFullScreenLoaderVisible: true,
});

const hideFullScreenLoader = (state) => ({
  ...state,
  isFullScreenLoaderVisible: false,
});

const showNotification = (state, payload) => ({
  ...state,
  notification: {
    isVisible: true,
    ...payload,
  },
});

const hideNotification = (state) => ({
  ...state,
  notification: {
    ...state.notification,
    isVisible: false,
  },
});

const setModalStatus = (state, { isVisible, type, data }) => ({
  ...state,
  modal: {
    ...state.modal,
    isVisible,
    type,
    data,
  },
});

const triggerUpdateWithModal = (state) => ({
  ...state,
  modal: {
    ...state.modal,
    triggerUpdateWithModal: state.modal.triggerUpdateWithModal + 1,
  },
});

const setCandidateSections = (state, sections) => ({
  ...state,
  candidateSections: sections,
});

const addCandidateFormErrors = (state, errors) => ({
  ...state,
  candidateFormErrors: {
    ...state.candidateFormErrors,
    ...errors,
  },
});

const clearCandidateFormErrors = (state) => ({
  ...state,
  candidateFormErrors: {
    personalInformation: {},
    workplace: {},
    education: {},
    conflictOfInterest: {},
  },
});

const changeCandidateFormValidStatus = (state, isValid) => ({
  ...state,
  isCandidateFormValid: isValid,
});

const changeCandidateFormSubmitStatus = (state, isSubmitted) => ({
  ...state,
  isCandidateFormSubmitted: isSubmitted,
});

const findUnsavedCandidateFormChanges = (state, sections) => ({
  ...state,
  candidateFormSectionsToSave: {
    ...state.candidateFormSectionsToSave,
    ...sections,
  },
});

const savedCandidateForm = (state, resetedSectionsToSave) => ({
  ...state,
  candidateFormSectionsToSave: {
    ...resetedSectionsToSave,
  },
});

const markActivityAsViewed = (state, userId) => ({
  ...state,
  viewedActivities: state.viewedActivities.concat(userId),
});

const checkUnreadMessages = (state, unreadMessagesNumber) => ({
  ...state,
  unreadMessagesNumber,
});

const hideNewMessagesNotification = (state) => ({
  ...state,
  unreadMessagesNumber: 0,
});

const mobileLayout = (state) => ({
  ...state,
  isMobile: true,
});

const desktopLayout = (state) => ({
  ...state,
  isMobile: false,
});

const addFileToRemoveList = (state, fileId) => ({
  ...state,
  filesToRemove: state.filesToRemove.concat(fileId),
});

const clearFilesToRemoveList = (state) => ({
  ...state,
  filesToRemove: [],
});

const scriveFileLoading = (state) => ({
  ...state,
  isScriveFileLoading: true,
});

const scriveFileFinishedLoading = (state) => ({
  ...state,
  isScriveFileLoading: false,
});

const setInvitationCandidatesIds = (state, payload) => {
  const { candidateId, selected } = payload;

  const tempInvitationCandidatesIds = structuredClone(state.invitationCandidatesIds);

  return {
    ...state,
    invitationCandidatesIds: selected
      ? [...tempInvitationCandidatesIds, candidateId]
      : tempInvitationCandidatesIds.filter((e) => e !== candidateId),
  };
};

function appReducer(state = initialState, action) {
  const handlers = {};
  handlers[types.SIGN_IN_SUCCESS] = onSignInSuccess;
  handlers[types.CHANGE_LANGUAGE] = changeLanguage;
  handlers[types.SET_COUNTRIES_ADDITIONAL_FIELDS] = setCountriesAdditionalFields;
  handlers[types.REDIRECT_FROM_LOGIN_PAGE] = redirectFromLoginPage;
  handlers[types.SHOW_FULL_SCREEN_LOADER] = showFullScreenLoader;
  handlers[types.HIDE_FULL_SCREEN_LOADER] = hideFullScreenLoader;
  handlers[types.SHOW_NOTIFICATION] = showNotification;
  handlers[types.HIDE_NOTIFICATION] = hideNotification;
  handlers[types.SET_MODAL_STATUS] = setModalStatus;
  handlers[types.TRIGGER_UPDATE_WITH_MODAL] = triggerUpdateWithModal;
  handlers[types.SET_CANDIDATE_SECTIONS] = setCandidateSections;
  handlers[types.ADD_CANDIDATE_FORM_ERRORS] = addCandidateFormErrors;
  handlers[types.CLEAR_CANDIDATE_FORM_ERRORS] = clearCandidateFormErrors;
  handlers[types.CHANGE_VALID_STATUS] = changeCandidateFormValidStatus;
  handlers[types.CHANGE_SUBMIT_STATUS] = changeCandidateFormSubmitStatus;
  handlers[types.FIND_UNSAVED_CANDIDATE_FORM_CHANGES] = findUnsavedCandidateFormChanges;
  handlers[types.SAVED_CANDIDATE_FORM] = savedCandidateForm;
  handlers[types.MARK_ACTIVITIES_AS_VIEWED] = markActivityAsViewed;
  handlers[types.CHECK_UNREAD_MESSAGES] = checkUnreadMessages;
  handlers[types.HIDE_NEW_MESSAGES_NOTIFICATION] = hideNewMessagesNotification;
  handlers[types.MOBILE_LAYOUT] = mobileLayout;
  handlers[types.DESKTOP_LAYOUT] = desktopLayout;
  handlers[types.ADD_FILE_TO_REMOVE_LIST] = addFileToRemoveList;
  handlers[types.CLEAR_FILES_TO_REMOVE_LIST] = clearFilesToRemoveList;
  handlers[types.SCRIVE_FILE_LOADING] = scriveFileLoading;
  handlers[types.SCRIVE_FILE_FINISHED_LOADING] = scriveFileFinishedLoading;
  handlers[types.SET_INVITATION_CANDIDATES_IDS] = setInvitationCandidatesIds;

  if (!action || !action.type || !handlers[action.type]) {
    return state;
  }

  return handlers[action.type](state, action.payload);
}

export default function rootReducer(state, action) {
  if (action.type === types.USER_LOGOUT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
}
