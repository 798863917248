import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { conflictOfInterestTypes } from 'constants/types';
import { saveConflictOfInterest } from 'api/candidate';
import { getHtmlContentFromRef } from 'utils/getHtmlContent';
import { addCandidateFormErrors } from 'store/actions';
import { getCustomerName } from 'utils/localStorage';
import useDispatchNotification from 'components/Shared/Notification/DispatchNotificationHook';
import { Button, Form, Divider, Flexbox, FormGroup, H3 } from 'components/Shared/sharedStyle';
import { FormCheckbox, Radio, WysiwygEditor } from 'components/Shared/sharedComponents';
import { getConflictSectionNum } from 'utils/candidateFormSections';
import { NextStepsButtons, ButtonsContainer } from 'components/CandidateForm/styleCandidateForm';
import { conflictOfInterestSchema } from 'components/CandidateForm/Form/candidateFormSchema';
import {
  mapDefaultConflictOfInterestValues,
  conflictOfInterestBodyMapper,
} from 'components/CandidateForm/Form/configCandidateForm';
import AssignmentForm from 'components/CandidateForm/Form/ConflictOfInterestStep/AssignmentForm';

const ConflictOfInterestStep = ({
  previousStep,
  nextStep,
  defaultFormState,
  editingLocked,
  validationFunctionsArray,
  dirtySectionsChecker,
  saveFormFunctions,
}) => {
  const { dispatchSuccessNotification, dispatchErrorNotification } = useDispatchNotification();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isCandidateFormSubmitted = useSelector((state) => state.isCandidateFormSubmitted);
  const sectionNumber = getConflictSectionNum();
  const customerName = getCustomerName();

  const [isLoading, setLoadingStatus] = useState(false);
  const [assignments, updateAssignments] = useState([]);

  const commentEditorRef = useRef(null);

  const formFunctions = useForm({
    validationSchema: conflictOfInterestSchema,
  });

  const { register, reset, getValues, handleSubmit, errors, watch, setValue, unregister } = formFunctions;

  const lastSavedFormState = useRef('');
  // setTimeout waits till files are registerd because it happends in useEffect in MultipleFilesUploader
  const setLastSavedFormState = useCallback(
    () =>
      setTimeout(() => {
        lastSavedFormState.current = JSON.stringify(getValues());
      }, 0),
    [getValues]
  );

  const submitForm = useCallback(async () => {
    setLoadingStatus(true);

    try {
      const mappedValues = conflictOfInterestBodyMapper(getValues({ nest: true }));
      await saveConflictOfInterest(mappedValues);

      setLastSavedFormState();
      dispatchSuccessNotification(t('notifications.saved'));
    } catch (catchedError) {
      dispatchErrorNotification({ catchedError });
    }

    setLoadingStatus(false);
  }, [getValues, setLastSavedFormState, dispatchSuccessNotification, t, dispatchErrorNotification]);

  useEffect(() => {
    register('comment');

    return () => unregister('comment');
  }, [register, unregister]);

  // should run only once
  useEffect(() => {
    // "handleSubmit" will be run in candidateForm and SubmittingDataStep,
    // it changes "hook-form" state to submitted which runs validation on every keystroke
    validationFunctionsArray.push(handleSubmit(() => {}));

    // used to determine whether to show "Leave Guard" and then save form
    dirtySectionsChecker.conflictOfInterest = () => lastSavedFormState.current !== JSON.stringify(getValues());
    saveFormFunctions.conflictOfInterest = submitForm;
  }, [validationFunctionsArray, handleSubmit, dirtySectionsChecker, saveFormFunctions, submitForm, getValues]);

  useEffect(() => {
    if (!defaultFormState) return;

    const degreesCounter = defaultFormState.assignments.length;
    const firstElementId = `${new Date().getTime()}-1`; // Workaround to present assignments as object instead of array in form state
    updateAssignments(
      degreesCounter ? defaultFormState.assignments.map((assignment) => assignment.id) : [firstElementId]
    );

    reset(mapDefaultConflictOfInterestValues(defaultFormState));
    setLastSavedFormState();
  }, [defaultFormState, getValues, reset, setLastSavedFormState]);

  // adds errors to redux state on every keystroke when condition is true
  useEffect(() => {
    if (isCandidateFormSubmitted) {
      // makes deep copy of error object - fixes "Invariant failed" redux error
      const newErrors = JSON.parse(JSON.stringify(errors));

      dispatch(addCandidateFormErrors({ conflictOfInterest: newErrors }));
    }
  });

  const proceedToNextStep = async () => {
    if (!editingLocked) await submitForm();
    nextStep();
  };

  const addAssignment = () => {
    updateAssignments(assignments.concat(new Date().getTime()));
  };

  const removeGroup = (id) => {
    updateAssignments((state) => state.filter((groupId) => groupId !== id));

    if (assignments.length - 1 === 0) {
      setValue('noConflictOfInterest', true);
    }
  };

  const shouldIncludesRemoveFunction = assignments.length !== 1 && !editingLocked;

  const triggerFormValidation = useCallback(() => {
    if (formFunctions.formState.isSubmitted) {
      formFunctions.triggerValidation();
    }
  }, [formFunctions]);

  const onEditorChange = useCallback(() => {
    const comment = getHtmlContentFromRef(commentEditorRef);
    if (!comment) {
      setValue('comment', '');
    } else {
      setValue('comment', comment);
    }
    triggerFormValidation();
  }, [setValue, triggerFormValidation]);

  return (
    <Form>
      <fieldset disabled={editingLocked}>
        <h2>{`${sectionNumber}. ${t('candidateForm.conflictOfInterest.title')}`}</h2>
        <h3>{t('candidateForm.conflictOfInterest.companyEngagement')}</h3>
        <p>{t('candidateForm.conflictOfInterest.description', { customerName })}</p>
        <FormCheckbox
          label={t('candidateForm.labels.noExternalEngagement')}
          name="noConflictOfInterest"
          register={register}
          error={errors.noConflictOfInterest}
          onChange={triggerFormValidation}
        />
        <div hidden={formFunctions.watch('noConflictOfInterest')}>
          <Flexbox direction="column">
            {assignments.map((id, index) => (
              <AssignmentForm
                key={id} // passing index as key may lead to incorrect unmounting
                formFunctions={formFunctions}
                groupId={id}
                remove={shouldIncludesRemoveFunction ? removeGroup : null}
                index={index}
              />
            ))}
          </Flexbox>
          <Button onClick={addAssignment} type="button">
            {t('candidateForm.conflictOfInterest.AddExternalEngagement')}
          </Button>
        </div>
        <Divider />
        <H3 paddingTop="7px">{t('candidateForm.conflictOfInterest.deviationsTitle')}</H3>
        <Radio
          label={t('candidateForm.labels.deviationsDisclosed', { customerName })}
          name="deviationsDisclosed"
          register={register}
          onChange={triggerFormValidation}
        />
        <FormGroup>
          <WysiwygEditor
            defaultValue={(defaultFormState && defaultFormState.comment) || ''}
            editorRef={commentEditorRef}
            error={watch('deviationsDisclosed') === 'true' && errors.comment ? errors.comment : null}
            onChange={onEditorChange}
            name="deviationsDisclosed"
            readOnly={editingLocked}
          />
        </FormGroup>
      </fieldset>
      <Divider />
      <ButtonsContainer>
        <Button onClick={previousStep} outlined type="button">
          {t('common.previous')}
        </Button>
        <NextStepsButtons>
          <Button onClick={submitForm} disabled={editingLocked} type="button">
            {t('common.save')}
          </Button>
          <Button disabled={isLoading} onClick={proceedToNextStep} type="button">
            {t('common.next')}
          </Button>
        </NextStepsButtons>
      </ButtonsContainer>
    </Form>
  );
};

ConflictOfInterestStep.propTypes = {
  nextStep: PropTypes.func.isRequired,
  previousStep: PropTypes.func.isRequired,
  defaultFormState: conflictOfInterestTypes.isRequired,
  editingLocked: PropTypes.bool.isRequired,
  validationFunctionsArray: PropTypes.array.isRequired,
  dirtySectionsChecker: PropTypes.object.isRequired,
  saveFormFunctions: PropTypes.object.isRequired,
};

export default ConflictOfInterestStep;
