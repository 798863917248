import * as yup from 'yup';

import createRequiredError from 'utils/createRequiredError';
import createMultipleFileValidation from 'utils/createMultipleFileValidation';

const requiredError = createRequiredError('message.labels');
const multipleFileValidation = createMultipleFileValidation(requiredError, false);

export const sendAsCandidateSchema = yup.object().shape({
  subject: yup.string().required(requiredError),
  body: yup.string().required(requiredError),
});

export const sendAsGPSchema = yup.object().shape({
  receiver: yup.string().required(requiredError),
  subject: yup.string().required(requiredError),
  body: yup.string().required(requiredError),
  attachment: multipleFileValidation,
});

export const sendAsGPFloaringMsgBoxSchema = yup.object().shape({
  subject: yup.string().required(requiredError),
  body: yup.string().required(requiredError),
  attachment: multipleFileValidation,
});
