import {
  getFirstDayOfCurrentMonth,
  getFirstDayOfPreviousMonthFromDate,
  getFirstDayOfNextMonthFromDate,
} from 'utils/dateUtils';

export const getMinDateForCurrentAddress = ({ getValues }) => {
  const formValues = getValues({ nest: true });

  if (!formValues?.previous) return;

  const newestAddress = Object.values(formValues?.previous).find((address) => {
    return address?.endDate || address?.startDate;
  });

  const date = newestAddress?.endDate || newestAddress?.startDate;

  return getFirstDayOfNextMonthFromDate(date);
};

export const getMinDateForStartAndEndDateInPreviousAddresses = ({ getValues, currentDateRangeId, startDate }) => {
  const formValues = getValues({ nest: true });
  if (!formValues?.previous) return;

  const dates = [];

  const currentIndex = Object.keys(formValues?.previous).findIndex((key) => key === currentDateRangeId);

  // adds addresses until it reaches previous to current address - assumes that addresses are sorted
  Object.entries(formValues?.previous).forEach(([, value], index) => {
    if (index <= currentIndex) return;

    if (value?.endDate) dates.push(value?.endDate);
    if (value?.startDate) dates.push(value?.startDate);
  });

  if (startDate && !dates[0]) return startDate;
  if (!startDate) return getFirstDayOfNextMonthFromDate(dates[0]);

  return startDate > getFirstDayOfNextMonthFromDate(dates[0]) ? startDate : getFirstDayOfNextMonthFromDate(dates[0]);
};

export const getMaxDateForEndDateInPreviousAddresses = ({ getValues, currentDateRangeId }) => {
  const formValues = getValues({ nest: true });
  if (!formValues?.previous) return;

  const firstDayOfThisMonth = getFirstDayOfCurrentMonth();

  const dates = formValues?.startDate ? [firstDayOfThisMonth, formValues?.startDate] : [firstDayOfThisMonth];

  // adds addresses until it reaches previous to current address - assumes that addresses are sorted
  Object.entries(formValues?.previous).find(([key, value]) => {
    if (key !== currentDateRangeId) {
      if (value?.endDate) dates.push(value?.endDate);
      if (value?.startDate) dates.push(value?.startDate);
    }

    return key === currentDateRangeId;
  });

  return getFirstDayOfPreviousMonthFromDate(dates.at(-1));
};

export const getMaxDateForStartDateInPreviousAddresses = ({ getValues, currentDateRangeId, endDate }) => {
  // TODO: when startDate and endDate are the same month, startDate will be grayed out. It works but this is a small are when it could be improved.
  const formValues = getValues({ nest: true });
  if (!formValues?.previous) return;

  const firstDayOfThisMonth = getFirstDayOfCurrentMonth();

  const dates = formValues?.startDate ? [firstDayOfThisMonth, formValues?.startDate] : [firstDayOfThisMonth];

  // adds addresses until it reaches current address - assumes that addresses are sorted
  const currentAdress = Object.entries(formValues?.previous).find(([key, value]) => {
    if (value?.endDate) dates.push(value?.endDate);
    if (key === currentDateRangeId && !value?.endDate) dates.push(null); // covers if empty adress between current and last filled adress
    if (value?.startDate) dates.push(value?.startDate);

    return key === currentDateRangeId;
  });

  if (!dates.at(-1)) return getFirstDayOfPreviousMonthFromDate(dates.at(-2));

  if (currentAdress && dates.at(-1) === currentAdress[1]?.startDate) {
    return !endDate ? getFirstDayOfPreviousMonthFromDate(dates.at(-3)) : getFirstDayOfPreviousMonthFromDate(endDate);
  }

  return dates.at(-1);
};

export const doDatesRangesCoverEveryMonthInGivenYears = ({ dates, requiredYears }) => {
  let isSequenceCorrect = true;

  const firstDayOfThisMonth = getFirstDayOfCurrentMonth();

  const monthToCheck = new Date(firstDayOfThisMonth);
  monthToCheck.setFullYear(monthToCheck.getFullYear() - requiredYears); // first month of a year, number of years ago

  while (monthToCheck <= firstDayOfThisMonth) {
    const oneOfDatesRangeCoversThisMonth = dates.some(
      // monthToCheck <= firstDayOfThisMonth - dates with months next to each other are valid: endDate: 01.2020 and nextStartDate: 02.2020.
      // monthToCheck < firstDayOfThisMonth - dates must have overlaping months to be valid: endDate: 02.2020 and nextStartDate: 02.2020.
      (date) => {
        return monthToCheck >= date.startDate && monthToCheck <= date.endDate;
      }
    );

    if (!oneOfDatesRangeCoversThisMonth) {
      isSequenceCorrect = false;
      break;
    }

    monthToCheck.setMonth(monthToCheck.getMonth() + 1);
  }

  return isSequenceCorrect;
};

// Checks every month individually - not used because min and max date approch is used.
// Can be usefull in the future because filtering dates should be more universal approach.
// But it would be slower and require manually hiding year buttons
/*
export const filterDatePickerDate = ({ checkDate, getValues, currentDateRangeId, isCurrentAddress }) => {
  const formValues = getValues({ nest: true });

  const firstDayOfThisMonth = getFirstDayOfCurrentMonth();

  const getFirstDayInMonthOfProvidedDate = (date) => new Date(date?.getFullYear(), date?.getMonth(), 1);
  const firstDayOfCheckDateMonth = getFirstDayInMonthOfProvidedDate(checkDate);

  const dates = isCurrentAddress ? [] : [{ startDate: formValues?.startDate, endDate: firstDayOfThisMonth }];

  Object.keys(formValues?.previous).forEach((id) => {
    if (id === currentDateRangeId) return;

    dates.push({
      startDate: formValues?.previous[id].startDate,
      endDate: formValues?.previous[id].endDate,
    });
  });

  const dateInAlreadySelectedRange = dates.some((date) => {
    return firstDayOfCheckDateMonth >= date?.startDate && firstDayOfCheckDateMonth <= date?.endDate;
  });

  const enableDate = !dateInAlreadySelectedRange;
  return enableDate;
};
*/
