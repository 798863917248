import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import icons from 'constants/icons';
import { yearMonthDatePickerFormat } from 'utils/dateUtils';
import { getMatchingCountryValue } from 'utils/countries';
import { FormRow, FormGroup, IconButton, Paragraph } from 'components/Shared/sharedStyle';
import { Input, DatePicker, MultipleFilesUploader, FormCheckbox, Select } from 'components/Shared/sharedComponents';
import { SectionName } from 'components/CandidateForm/styleCandidateForm';
import { getEducationBaseNumber, getEducationSectionNum } from 'utils/candidateFormSections';
import { educationFormNames, getGroupNameInEducationObj } from './configEducation';

const { FfileGroup } = educationFormNames;

const EducationForm = ({
  formFunctions,
  remove,
  groupId,
  index,
  editingLocked,
  defaultFiles,
  certificatesToRemoveFromView,
}) => {
  const { t } = useTranslation();
  const sectionNumber = getEducationSectionNum();
  const baseNumber = getEducationBaseNumber(index);
  const groupName = getGroupNameInEducationObj(groupId);

  const countriesMappedList = useSelector((state) => state.countries.countriesMappedList);

  const [shouldReset, setShouldResetStatus] = useState(false);

  const { register, unregister, errors, setValue, triggerValidation, watch, formState } = formFunctions;

  const startDate = watch(`education[${groupId}].startDate`);
  const endDate = watch(`education[${groupId}].endDate`);
  const formNoCertificate = watch(`education[${groupId}].noCertificate`);
  const universityCountry = watch(`education[${groupId}].universityCountry`);

  useEffect(() => {
    register({ name: `education[${groupId}].fileGroup` });
    setValue(`education[${groupId}].fileGroup`, index);

    return () => unregister(`${groupName}.${FfileGroup}`);
  }, [groupId, register, setValue, index, unregister, groupName]);

  const errorsReference = (field) => errors.education && errors.education[groupId] && errors.education[groupId][field];

  useEffect(() => {
    if (certificatesToRemoveFromView.find((id) => id === groupId)) {
      setShouldResetStatus(true);
    }
  }, [groupId, certificatesToRemoveFromView]);

  useEffect(() => {
    setShouldResetStatus(false);
  }, [shouldReset]);

  const triggerDatesValidationAndReturnDate = (date) => {
    (async () => {
      if (formState.isSubmitted) {
        await triggerValidation(`education[${groupId}].startDate`);
        await triggerValidation(`education[${groupId}].endDate`);
        await triggerValidation(`education[${groupId}].diplomaReceivedDate`);
      }
    })();
    return date;
  };

  const [degreeFiles] = useMemo(() => {
    // you have to use useMemo and null so it will not glith during first upload
    // if you pass directly to MultipleFilesUploader props, it will glith
    let degreeFiles = null;

    if (defaultFiles && defaultFiles.Degree) {
      degreeFiles = defaultFiles?.Degree?.filter((file) => file.group === index);
    }

    return [degreeFiles];
  }, [defaultFiles, index]);

  return (
    <>
      <SectionName>
        <h3>{t('candidateForm.education.sectionName', { index: index + 1 })}</h3>
        {remove && <IconButton onClick={() => remove(groupId)} icon={icons.delete_bin} />}
      </SectionName>
      <FormRow>
        <FormGroup>
          <Input
            register={register}
            name={`education[${groupId}].highestDegree`}
            error={errorsReference('highestDegree')}
            label={`${sectionNumber}.${baseNumber + 1} ${t('candidateForm.labels.highestDegree')}`}
          />
        </FormGroup>
        <FormGroup>
          <Input
            register={register}
            name={`education[${groupId}].fieldOfStudy`}
            error={errorsReference('fieldOfStudy')}
            label={`${sectionNumber}.${baseNumber + 2} ${t('candidateForm.labels.fieldOfStudy')}`}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <DatePicker
            name={`education[${groupId}].startDate`}
            dateFormat={yearMonthDatePickerFormat}
            showMonthYearPicker
            error={errorsReference('startDate')}
            formFunctions={formFunctions}
            label={`${sectionNumber}.${baseNumber + 3} ${t('candidateForm.education.startOfAttendance')}`}
            disabled={editingLocked}
            onChange={([date]) => triggerDatesValidationAndReturnDate(date)}
            maxDate={endDate ? endDate : new Date()}
            scrollableYearDropdown={true}
            yearDropdownItemNumber={70}
          />
        </FormGroup>
        <FormGroup>
          <DatePicker
            name={`education[${groupId}].endDate`}
            dateFormat={yearMonthDatePickerFormat}
            showMonthYearPicker
            error={errorsReference('endDate')}
            formFunctions={formFunctions}
            label={`${sectionNumber}.${baseNumber + 4} ${t('candidateForm.education.endOfAttendance')}`}
            disabled={editingLocked}
            onChange={([date]) => triggerDatesValidationAndReturnDate(date)}
            minDate={startDate}
            maxDate={new Date()}
            scrollableYearDropdown={true}
            yearDropdownItemNumber={70}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Input
            register={register}
            name={`education[${groupId}].universityName`}
            error={errorsReference('universityName')}
            label={`${sectionNumber}.${baseNumber + 5} ${t('candidateForm.labels.universityName')}`}
          />
        </FormGroup>
        <FormGroup>
          <Select
            name={`education[${groupId}].universityCountry`}
            selectFunctions={formFunctions}
            label={`${sectionNumber}.${baseNumber + 6} ${t('candidateForm.labels.universityCountry')}`}
            options={countriesMappedList}
            error={errorsReference('universityCountry')}
            defaultValue={getMatchingCountryValue(universityCountry)}
            disabled={editingLocked}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Input
            register={register}
            name={`education[${groupId}].universityStreet`}
            error={errorsReference('universityStreet')}
            label={`${sectionNumber}.${baseNumber + 7} ${t('candidateForm.labels.universityStreet')}`}
          />
        </FormGroup>
        <FormGroup>
          <Input
            register={register}
            name={`education[${groupId}].universityZipCode`}
            error={errorsReference('universityZipCode')}
            label={`${sectionNumber}.${baseNumber + 8} ${t('candidateForm.labels.universityZipCode')}`}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Input
            register={register}
            name={`education[${groupId}].universityCity`}
            error={errorsReference('universityCity')}
            label={`${sectionNumber}.${baseNumber + 9} ${t('candidateForm.labels.universityCity')}`}
          />
        </FormGroup>
        <FormGroup>
          <Input
            register={register}
            name={`education[${groupId}].universityPhoneNumber`}
            error={errorsReference('universityPhoneNumber')}
            label={`${sectionNumber}.${baseNumber + 10} ${t('candidateForm.labels.universityPhoneNumber')}`}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Input
            register={register}
            name={`education[${groupId}].universityWebsiteUrl`}
            error={errorsReference('universityWebsiteUrl')}
            label={`${sectionNumber}.${baseNumber + 11} ${t('candidateForm.labels.universityWebsiteUrl')}`}
          />
        </FormGroup>
        <FormGroup>
          <DatePicker
            name={`education[${groupId}].diplomaReceivedDate`}
            dateFormat={yearMonthDatePickerFormat}
            showMonthYearPicker
            error={errorsReference('diplomaReceivedDate')}
            formFunctions={formFunctions}
            label={`${sectionNumber}.${baseNumber + 12} ${t('candidateForm.labels.diplomaReceivedDate')}`}
            disabled={editingLocked}
            onChange={([date]) => triggerDatesValidationAndReturnDate(date)}
            scrollableYearDropdown={true}
            minDate={startDate}
            maxDate={new Date()}
            yearDropdownItemNumber={70}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Paragraph display="inline" marginRight="20px">
            {`${sectionNumber}.${baseNumber + 13} ${t('candidateForm.labels.degree')}`}
          </Paragraph>
          <FormCheckbox
            label={t('candidateForm.labels.dontHaveCertificate')}
            name={`education[${groupId}].noCertificate`}
            register={register}
            error={errors.noCertificate}
            onChange={() => triggerValidation(`education[${groupId}].degree`)}
            margin="0px !important"
          />
          <MultipleFilesUploader
            label={t('common.uploadScan')}
            name={`education[${groupId}].degree`}
            errors={errorsReference('degree')}
            formFunctions={formFunctions}
            disabled={editingLocked || formNoCertificate}
            defaultFiles={degreeFiles}
            fileGroup={index}
            grayedText={formNoCertificate}
            shouldReset={shouldReset}
          />
        </FormGroup>
      </FormRow>
    </>
  );
};

EducationForm.propTypes = {
  formFunctions: PropTypes.object.isRequired,
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired,
  editingLocked: PropTypes.bool.isRequired,
  remove: PropTypes.func,
  defaultFiles: PropTypes.object,
  certificatesToRemoveFromView: PropTypes.arrayOf(PropTypes.string).isRequired,
};

EducationForm.defaultProps = {
  remove: null,
};

export default EducationForm;
