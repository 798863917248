import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { hideNotification } from 'store/actions';
import colors from 'constants/colors';
import { NotificationContainer } from './style';

const types = {
  success: colors.primaryOrangeBright,
  error: colors.error,
};

// React.memo prevents running component loginc on route change
const Notification = React.memo(() => {
  const { isVisible, message, type } = useSelector((state) => state.notification);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => dispatch(hideNotification()), 4000);
    }
  }, [isVisible, dispatch]);

  return (
    <NotificationContainer isVisible={isVisible} color={types[type]}>
      {message}
    </NotificationContainer>
  );
});

export default Notification;
